import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Group, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class RestrictedMenuMessageModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE
  };
  
  constructor(props) {
    super(props);
  }
  
  render() {
    const { open, toggle, className, next, currentSubscription, hasTested } = this.props;
    const createTestSubscriptionButton = document.getElementById('create-test-subscription');
    return (
      <CustomModal
        heading={(!currentSubscription && hasTested) ? 'Bitte wählen Sie Ihr Abonnement' : 'Testzeitraum starten'}
        headingClassName={'restricted-menu-message-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack>
            <Group>
              {(!currentSubscription && hasTested) ?
              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}><p>Um hellohousing zu nutzen, benötigen Sie ein Abonnement. Lernen Sie jetzt den Funktionsumfang von hellohousing Starter, Premium und Professional kennen und wählen Sie das für Ihre Bedürfnisse passende Abonnement aus. Alle Abonnements sind monatlich kündbar.</p></span>
              :
              <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}><p>Klicken Sie auf <span style={{fontWeight: 'bold'}}>Fortfahren</span>, um den hellohousing Testzeitraum jetzt zu starten.</p><p>Sie testen hellohousing im vollen Funktionsumfang 30 Tage kostenlos und unverbindlich. Der Testzeitraum endet automatisch.</p></span>}
            </Group>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={(!currentSubscription && hasTested) ? 'Abonnements kennenlernen' : 'Fortfahren'}
                    className='purple-btn'
                    onClick={()=> {
                      if(!currentSubscription && hasTested){
                        this.props.history.push('/profile/yourSubscription', {newSubscription: true});
                      }else{
                        document.getElementById('create-test-subscription')?.click();
                      }

                      setTimeout(() => toggle(), 500);
                    }}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSubscription: state.generalReducer.currentSubscription,
    hasTested: state.generalReducer.hasTested
  };
};

export default connect(mapStateToProps)(RestrictedMenuMessageModal);

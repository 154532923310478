import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTenantBills } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import { EmptyStateContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import tap from '../../../../assets/images/module/general/TapIconDark.svg';
import { Group, Stack } from '@mantine/core';
import 'chartjs-plugin-style';
import { Chart as ChartJS, LinearScale, RadialLinearScale, BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip, LineElement, PointElement, LineController } from "chart.js";
import { Bar, Chart } from "react-chartjs-2";
import moment from 'moment';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// ChartJS.defaults.global.plugins.datalabels.anchor = 'end';
// ChartJS.defaults.global.plugins.datalabels.align = 'end';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

export class CompareHeatingConsumptionBarChart extends Component {

  tableHeadCellStyling = {
    color: '#727293',
    padding: '0 9px 0 9px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    textTransform: 'capitalize',
    height: '48px',
  }
  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    height: '48px',
    minWidth: '90px',
    // borderBottom: 'none',
    // borderRight: 'none'
  }

  state ={
    basicCostHeating: 0,
    consumptionCostHeating: 0,
    basicCostWarmWater: 0,
    consumptionCostWarmWater: 0,
    basicCostHeatingObject1: null,
    basicCostHeatingObject2: null,
    basicCostWarmWaterObject1: null,
    basicCostWarmWaterObject2: null,
    chartDataConsumption: null,
    chartDataCost: null,
    chartOptionsConsumption: null,
    chartOptionsCost: null,
    consumptionData: [],
    costData: [],
  }

  constructor(props){
    super(props);
    ChartJS.register(LinearScale, RadialLinearScale, BarElement, BarController, CategoryScale, LineElement, PointElement, LineController, Decimation, Filler, Legend, Title, Tooltip);
  }
  componentDidMount(){
    this.componentDidMountStuff();
  }

  componentDidUpdate(prevProps){
    console.log('componentDidUpdate_123', this.props.selectedTenantData);
    if(prevProps.selectedTenantData !== this.props.selectedTenantData){
      this.componentDidMountStuff();
    }
  }

  componentDidMountStuff(){
    const {selectedTenantData, isTablet, messagePreview, propertySelected, utilityData} = this.props;
    console.log('utilityData: ', utilityData, selectedTenantData);
    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');
    
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
    
        const table = document.createElement('table');
        table.style.margin = '0px';
    
        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }
    
      return tooltipEl;
    };
    
    const externalTooltipHandler = (context) => {
      // Tooltip Element
      const {chart, tooltip} = context;
      const tooltipEl = getOrCreateTooltip(chart);

      console.log('externalTooltipHandler: ', context);

      tooltipEl.style.marginTop = '10px'
      tooltipEl.style.backgroundColor = 'white';
      tooltipEl.style.color = '#11263C'
      tooltipEl.style.boxShadow = '0 3px 3px rgba(0,0,0,.2)'
      
      // Hide if no tooltip
      // if (tooltip.opacity === 0) {
      //   tooltipEl.style.opacity = 0;
      //   return;
      // }
    
      // Set Text
      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map(b => b.lines);

        console.log('bodyLines: ', bodyLines);
    
        const tableHead = document.createElement('thead');
    
      //   titleLines.forEach(title => {
      //     const tr = document.createElement('tr');
      //     tr.style.borderWidth = 0;
    
      //     const th = document.createElement('th');
      //     th.style.borderWidth = 0;
      //     const text = document.createTextNode(title);
    
      //     th.appendChild(text);
      //     tr.appendChild(th);
      //     tableHead.appendChild(tr);
      //   });
    
        const tableBody = document.createElement('tbody');
      //   bodyLines.forEach((body, i) => {
          //const colors = tooltip.labelColors[i];

          //console.log('body: ', body);
    
          // const span = document.createElement('span');
          // span.style.background = colors.backgroundColor;
          // span.style.borderColor = colors.borderColor;
          
          // span.style.height = '10px';
          // span.style.width = '10px';
          // span.style.display = 'inline-block';
    
          const tr = document.createElement('tr');
          tr.style.backgroundColor = 'inherit';
          tr.style.borderWidth = 0;
    
          const td = document.createElement('td');
          td.style.borderWidth = 0;

          const div = document.createElement('div');
          div.style.display= 'flex';
          div.style.flexDirection= 'column';
          // div.style.width= 
          // (bodyLines.toString().includes('Aktuell:') && bodyLines.toString().includes('Vorjahr:')) ?
          // ((bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[0].length > 3 && bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[1].length > 3) ? '180px' : bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[1].length > 3 ? '150px' : bodyLines.toString().split('Aktuell:')[1].split(',Vorjahr:')[0].length > 3 ? '150px' : '130px')
          // :
          // '130px';
    
          // const text = document.createTextNode(bodyLines.toString().includes('Aktuell') && bodyLines.toString().includes('Vorjahr') ? 'Aktuell - Vorjahr' : bodyLines.toString().includes('Aktuell') ? 'Aktuell' : bodyLines.toString().includes('Vorjahr') && 'Vorjahr' );
          // const span1 = document.createElement('span');
          // span1.style.color= '#5041BC';
          // span1.style.fontSize= '9px';
          // span1.append(text);


          console.log('length: ', bodyLines.toString());
          const span2 = document.createElement('span');
          span2.style.color= '#11263C';
          span2.style.fontSize= '16px';
          span2.style.fontWeight= 'bold';
          span2.append(`${bodyLines.toString().split(':')[1]} ${bodyLines.toString().includes('kWh') ? 'kWh' : '€'}`);
          //span.appendChild(body.toString().includes('Aktuell') ? 'Aktuell' : 'Vorjahr');
          //td.appendChild(span);
          const span3 = document.createElement('span');
          span3.style.color= 'rgb(60, 60, 67, 0.6)';
          span3.style.fontSize= '10px';
          span3.append(bodyLines.toString().includes('Aktuell') && bodyLines.toString().includes('Vorjahr') ? 
          tooltip.title +' '+' - '+ (tooltip.title.toString().split(' ')[0] +' '+ (parseInt(tooltip.title.toString().split(' ')[1]) - 1).toString())
          :
          bodyLines.toString().includes('Vorjahr') ?
          (tooltip.title.toString().split(' ')[0] +' '+ (parseInt(tooltip.title.toString().split(' ')[1]) - 1).toString())
          :
          tooltip.title);

          //div.append(span1);
          div.append(span2);
          div.append(span3);
          td.appendChild(div);
          tr.appendChild(td);
          tableBody.appendChild(tr);
      
    
        const tableRoot = tooltipEl.querySelector('table');
    
        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }
    
        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }
    
      const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
    
      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.borderRadius='10px';
      tooltipEl.style.whiteSpace = 'nowrap';
      tooltipEl.style.left = positionX + 250 + 'px'
      // tooltipEl.style.left = this.props.isMobile ? '320px' : positionX + tooltip.caretX + 'px';
      // tooltipEl.style.top = this.props.isMobile ? '0px' : positionY + tooltip.caretY + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
      // tooltipEl.style.marginTop = !this.props.isMobile && (context.tooltip.caretY <= 40 ? '-90px' : '-70px');
      // tooltipEl.style.marginLeft = !this.props.isMobile && (context.chart.width - context.tooltip.caretX <= 100 ? '-100px' : '0px');
      tooltipEl.style.transition = 'all 0.5s ease 0s';
      tooltipEl.style.zIndex = 9999999;
    };
    const consumptionData = [
      selectedTenantData?.previousTenantHeatingEnergyAmountkWh && (Math.round(selectedTenantData?.previousTenantHeatingEnergyAmountkWh * 100) / 100), 
      selectedTenantData?.tenantHeatingEnergyAmountkWh && (Math.round(selectedTenantData?.tenantHeatingEnergyAmountkWh * 100) / 100)
    ];

    const currentBillingPeriodStartDate = moment(utilityData?.data?.billingPeriodStart ? utilityData?.data?.billingPeriodStart : utilityData?.data?.billingPeriod?.startDate);
    const currentBillingPeriodEndDate = moment(utilityData?.data?.billingPeriodEnd ? utilityData?.data?.billingPeriodEnd : utilityData?.data?.billingPeriod?.endDate);
    const previuseBillingPeriodStartDate = utilityData?.data?.previousBillingPeriod?.startDate ? moment(utilityData?.data?.previousBillingPeriod?.startDate) : currentBillingPeriodStartDate.clone().subtract(1, 'year');
    const previuseBillingPeriodEndDate = utilityData?.data?.previousBillingPeriod?.endDate ? moment(utilityData?.data?.previousBillingPeriod?.endDate) : currentBillingPeriodEndDate.clone().subtract(1, 'year');

    const chartDataConsumption = {
      labels: [`${previuseBillingPeriodStartDate.format('DD.MM.YYYY')} - ${previuseBillingPeriodEndDate.format('DD.MM.YYYY')}`, `${currentBillingPeriodStartDate.format('DD.MM.YYYY')} - ${currentBillingPeriodEndDate.format('DD.MM.YYYY')}`],//dayjs.monthsShort(), //dayjs.monthsShort()
      datasets: [
        // {
        //   type: 'line',
        //   data: consumptionData,
        // },
        {
            label: 'Aktuelles Jahr',
            //hoverBackgroundColor: 'rgb(85, 85, 255, 1)',
            type: 'bar',
            barThickness: 60,
            maxBarThickness: 60,
            data: consumptionData,
            fill: true,
            hoverBackgroundColor: ['rgba(178,178,178,1)', 'rgb(85, 85, 255, 1)'],
            backgroundColor: ['rgba(178,178,178,1)', 'rgb(85, 85, 255, 1)'],
            // datalabels: {
            //   display: false
            // }
        }
        // {
        //     label: 'Vorjahr',
        //     pointHoverRadius: 6,
        //     pointHoverBackgroundColor: '#E3DBF1',
        //     pointHoverBorderColor: '#ffffff',
        //     pointHoverBorderWidth: 2,
        //     pointBackgroundColor: 'transparent',
        //     pointBorderColor: 'transparent',
        //     data: oldData,
        //     fill: true,
        //     borderColor: '#E3DBF1',
        //     tension: 0.4,
        //     backgroundColor: 'rgb(255, 255, 255, 0.01)',
        //     segment: props.selectedMonthSelector === 'lastYearTotal' && {
        //       backgroundColor: ctx => sectionPreviousYear(ctx, props.highConsumption ? 'rgb(255, 203, 203, 0.5)' : 'rgb(232, 244, 234, 0.65)', segmentlastYearEndMonth, segmentlastYearStartMonth)
        //     },
        // }
      ]
    }
    const costData = [
      selectedTenantData?.previousTenantTotalHeatingCost && (Math.round(selectedTenantData?.previousTenantTotalHeatingCost * 100) / 100), 
      selectedTenantData?.totalAllocatedHeatingCosts && (Math.round(selectedTenantData?.totalAllocatedHeatingCosts * 100) / 100)
    ];
    const chartDataCost = {
      labels: [`${previuseBillingPeriodStartDate.format('DD.MM.YYYY')} - ${previuseBillingPeriodEndDate.format('DD.MM.YYYY')}`, `${currentBillingPeriodStartDate.format('DD.MM.YYYY')} - ${currentBillingPeriodEndDate.format('DD.MM.YYYY')}`],
      datasets: [
        // {
        //   label: 'costLineChart',
        //   type: 'line',
        //   data: costData,
        // },
        {
            label: 'costBarChart',
            type: 'bar',
            barThickness: 60,
            maxBarThickness: 60,
            data: costData,
            fill: true,
            hoverBackgroundColor: ['rgba(178,178,178,1)', 'rgb(85, 85, 255, 1)'],
            backgroundColor: ['rgba(178,178,178,1)', 'rgb(85, 85, 255, 1)'],
            
        }
      ]
    }
    
    const consumptionDataMaximumNumberLength = Math.max(...consumptionData) ? parseInt(Math.max(...consumptionData)).toString().length : 4;
    const costDataMaximumNumberLength = Math.max(...costData) ? parseInt(Math.max(...costData)).toString().length : 4;
    console.log('costDataMaximumNumberLength: ', Math.max(...costData));
    let zoom={
      pan: {
          enabled: true,
          mode: 'x'
      },
      zoom: {
          pinch: {
              enabled: true       // Enable pinch zooming
          },
          wheel: {
              enabled: true       // Enable wheel zooming
          },
          mode: 'x',
      }
    };
    let x = {
      grid: {
        display: true,
        color: function (context) {
          if(context.tick){
            if (context.tick.value === 0) {
              return 'rgba(178,178,178,1)';
            }
          }
        },
      }
    };
    let yMaxConsumption = Math.ceil((Math.max(...consumptionData) + Math.pow(10, consumptionDataMaximumNumberLength - 1) - (consumptionDataMaximumNumberLength >= 5 ? 5000 : 0)) / 100) * 100
    let yMaxCost = Math.ceil((Math.max(...costData) + Math.pow(10, costDataMaximumNumberLength - 1) - (costDataMaximumNumberLength >= 5 ? 5000 : 0)) / 100) * 100;
    console.log('costDataMaximumNumberLength: ', yMaxCost);
    let y = {
      ticks: {
          maxTicksLimit: 5,
          padding: 15,
          color: '#727293'
      },
      title: {
          text: 'kWh',
          display: false,
          color: '#A9A9A9'
      },
      border: {
          display: false
      },
      grid: {
        display: true,
        borderDash: function (context) {
          if (context.tick.value === 0) {
            return [];
          }
          return [10];
        },
        borderDashOffset: 2,
        color: function (context) {
          if (context.tick.value === 0) {
            return 'rgba(178,178,178,1)';
          }
          return 'rgba(0, 0, 0, 0.1)';
        },
      }
      
    };
    let plugins = {
      customValue: {
        offset: 5,
        dash: [5, 5]
      },
      legend: {
          position: 'top',
          align: 'end'
      },
      
      events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
      tooltip: {
          enabled: false,
          position: 'nearest',
          //external: externalTooltipHandler,
          display: false
          //intersect: false
      },
      legend: {
          position: 'bottom',
          align: 'end',
          labels:{
              boxHeight: 4,
          },
          display: false
      },
      datalabels: {
        formatter: function(value, context) {
          console.log('formatter:  ', context, value);
          if(context.dataset.label === 'costLineChart' || context.dataset.label === 'costBarChart'){
            if(context.dataset.type === 'line'){
              if(context.dataIndex === 0){
                return `${costData[0] < costData[1] ? '+' : '-'}${Math.round(((Math.max(...costData) - Math.min(...costData)) / Math.max(...costData)) * 100)}%`;
              }else{
                return null
              }
            }else{
              if(context.chart.data.datasets[0].data[context.dataIndex]){
                return `${Math.round(context.chart.data.datasets[0].data[context.dataIndex]).toLocaleString("de-DE", {style:"currency", currency:"EUR",minimumFractionDigits: 0,maximumFractionDigits: 0})}`;
              }else{
                return 'Keine Informationen verfügbar';
              }
            }
          }else{
            if(context.dataset.type === 'line'){
              if(context.dataIndex === 0){
                return `${consumptionData[0] < consumptionData[1] ? '+' : '-'}${Math.round(((Math.max(...consumptionData) - Math.min(...consumptionData)) / Math.max(...consumptionData)) * 100)}%`;
              }else{
                return null
              }
            }else{
              if(context.chart.data.datasets[0].data[context.dataIndex]){
                return `${Math.round(context.chart.data.datasets[0].data[context.dataIndex]).toLocaleString("de-DE", {minimumFractionDigits: 0,maximumFractionDigits: 0})} kWh`;
              }else{
                return 'Keine Informationen verfügbar';
              }
            }
          }
        },
        font: function(context) {
          if(context.dataset.data[context.dataIndex] === null){
            return {size: 9}
          }
        },
        anchor: function(context) {
          if(context.dataset.type === 'line'){
            return 'start'
          }else{
            return 'end'
          }
        },
        align: function(context) {
          if(context.dataset.type === 'line'){
            return 'right'
          }else{
            return 'end'
          }
        },
        offset: function(context) {
          if(context.dataset.label === 'costLineChart' || context.dataset.label === 'costBarChart'){
            if(context.dataset.type === 'line'){
              return 75
            }else{
              return 6
            }
          }else{
            if(context.dataset.type === 'line'){
              return 60
            }else{
              return 6
            }
          }
          
        },
        color: function(context) {
          if(context.dataset.label === 'costLineChart' || context.dataset.label === 'costBarChart'){
            if(context.dataset.type === 'line'){
              return costData[0] < costData[1] ? 'rgb(232, 25, 60)' : 'rgb(5, 146, 102)'
            }
          }else{
            if(context.dataset.type === 'line'){
              return consumptionData[0] < consumptionData[1] ? 'rgb(232, 25, 60)' : 'rgb(5, 146, 102)'
            }
          }
        },
        backgroundColor: function(context) {
          if(context.dataset.label === 'costLineChart' || context.dataset.label === 'costBarChart'){
            if(context.dataset.type === 'line'){
              return costData[0] < costData[1] ? 'rgb(255, 203, 203)' : 'rgb(232, 244, 234)'
            }else{
              return 'rgba(255,255,255,1)';
            }
          }else{
            if(context.dataset.type === 'line'){
              return consumptionData[0] < consumptionData[1] ? 'rgb(255, 203, 203)' : 'rgb(232, 244, 234)'
            }else{
              return 'rgba(255,255,255,1)';
            }
          }
        },
        padding: function(context) {
          if(context.dataset.label === 'costLineChart' || context.dataset.label === 'costBarChart'){
            if(context.dataset.type === 'line'){
              return 4
            }else{
              return 2
            }
          }else{
            if(context.dataset.type === 'line'){
              return 4
            }else{
              return 2
            }
          }
        },
        borderRadius: 4
      }
    };
    let interaction = {
      intersect: false,
      mode: 'index',
    };
    const consumptionDone=()=> {
      var url= ChartJS.getChart('consumption-chart').toBase64Image();
      console.log('consumptionDone_url: ', url);
      if(document.getElementById("consumption-chart-image")){
        document.getElementById("consumption-chart-image").src=url;
      }
    }

    const costDone=()=> {
      var url= ChartJS.getChart('cost-chart').toBase64Image();
      console.log('costDone_url: ', url);
      if(document.getElementById("cost-chart-image")){
        document.getElementById("cost-chart-image").src=url;
      }
    }
    const chartOptionsConsumption= {
      locale: 'de',
      animation: {
          duration: 100,
          onComplete: this.props.messagePreview && consumptionDone
      },
      bezierCurve: false,
      responsive: true,
      interaction,
      plugins,
      zoom,
      scales: {
          x,
          y: {...y//, max: yMaxConsumption
          },
      },
      //showAllTooltips: true
      // tooltips: {
      //   callbacks: {
      //     label: (tooltipItem) => {
      //       // const value = tooltipItem.yLabel;
      //       // const difference = percentageDifferences[index];
      //       // if (index > 0 && difference) {
      //       //   return `${value} (${difference}%)`;
      //       // }
      //       return tooltipItem;
      //     },

      //   }
      // }
    };
    const chartOptionsCost= {
      locale: 'de',
      animation: {
          duration: 100,
          onComplete: this.props.messagePreview && costDone
      },
      bezierCurve: false,
      responsive: true,
      interaction,
      plugins,
      zoom,
      scales: {
          x,
          y: {...y//, max: yMaxCost
          },
      },
    };

    this.setState({
      chartDataConsumption, 
      chartDataCost,
      chartOptionsConsumption,
      chartOptionsCost,
      consumptionData,
      costData
    });
  }

  renderCustomPlugin=(data)=>{
    return ({
      id: 'customValue',
      afterDraw: (chart, args, opts) => {
        const {
          ctx,
          data: {
            datasets
          },
          _metasets
        } = chart;
        const drawLineArrowhead=(p1, p2, startSize, endSize)=> {
          ctx.beginPath()
          ctx.moveTo(p1.x, p1.y);
          ctx.lineTo(p2.x, p2.y);
          
          if (startSize > 0) {
            let lineAngle = Math.atan2(p2.y - p1.y, p2.x - p1.x);
            let delta = Math.PI/6  
            for (let i=0; i<2; i++) {
              ctx.moveTo(p1.x, p1.y);
              let x = p1.x + startSize * Math.cos(lineAngle + delta)
              let y = p1.y + startSize * Math.sin(lineAngle + delta)
              ctx.lineTo(x, y);
              delta *= -1
            }
          }
          if (endSize > 0) {
            let lineAngle = Math.atan2(p1.y - p2.y, p1.x - p2.x);
            let delta = Math.PI/6  
            for (let i=0; i<2; i++) {
              ctx.moveTo(p2.x, p2.y);
              let x = p2.x + endSize * Math.cos(lineAngle + delta)
              let y = p2.y + endSize * Math.sin(lineAngle + delta)
              ctx.lineTo(x, y);
              delta *= -1
            }
          }
          ctx.stroke();
        }
        console.log('datasets: ', datasets, _metasets);
        if(data[0] && data[1]){
          datasets[0].data.forEach((dp, i) => {
            let barValue = `${data[0] < data[1] ? '+' : '-'}${Math.abs(Math.round(((data[1] / data[0]) - 1) * 100))}%`; //${Math.round(((Math.max(...data) - Math.min(...data)) / Math.max(...data)) * 100)}
            const barValueWidth = ctx.measureText(barValue).width;
            const offset = opts.offset || 0;
            const dash = opts.dash || [];
            if (_metasets[0].data[0].y >= _metasets[0].data[1].y) {
              ctx.beginPath();
              ctx.setLineDash(dash);
              ctx.strokeStyle =  'rgb(114, 114, 147, 0.4)';
              ctx.moveTo(_metasets[0].data[0].x + 30, _metasets[0].data[0].y);
              ctx.lineTo(_metasets[0].data[0].x + ((_metasets[0].data[1].x - _metasets[0].data[0].x) / 2), _metasets[0].data[0].y);
              ctx.stroke();
  
              ctx.beginPath();
              ctx.setLineDash([]);
              ctx.strokeStyle =  'rgb(114, 114, 147, 0.4)';
              drawLineArrowhead({x:_metasets[0].data[0].x + ((_metasets[0].data[1].x - _metasets[0].data[0].x) / 2), y:_metasets[0].data[0].y}, {x:_metasets[0].data[1].x - ((_metasets[0].data[1].x - _metasets[0].data[0].x) / 2), y:_metasets[0].data[1].y}, 5, 5);
  
              ctx.beginPath();
              ctx.setLineDash(dash);
              ctx.strokeStyle =  'rgb(114, 114, 147, 0.4)';
              ctx.lineTo(_metasets[0].data[1].x - ((_metasets[0].data[1].x - _metasets[0].data[0].x) / 2), _metasets[0].data[1].y);
              ctx.lineTo(_metasets[0].data[1].x - 30, _metasets[0].data[1].y);
              ctx.stroke();
            } else {
              ctx.beginPath();
              ctx.setLineDash(dash);
              ctx.strokeStyle =  'rgb(114, 114, 147, 0.4)';
              ctx.moveTo(_metasets[0].data[0].x + 30, _metasets[0].data[0].y);
              ctx.lineTo(_metasets[0].data[0].x + ((_metasets[0].data[1].x - _metasets[0].data[0].x) / 2), _metasets[0].data[0].y);
              ctx.stroke();
  
              ctx.beginPath();
              ctx.setLineDash([]);
              ctx.strokeStyle =  'rgb(114, 114, 147, 0.4)';
              drawLineArrowhead({x:_metasets[0].data[0].x + ((_metasets[0].data[1].x - _metasets[0].data[0].x) / 2), y:_metasets[0].data[0].y}, {x:_metasets[0].data[1].x - ((_metasets[0].data[1].x - _metasets[0].data[0].x) / 2), y:_metasets[0].data[1].y}, 5, 5);
  
              ctx.beginPath();
              ctx.setLineDash(dash);
              ctx.strokeStyle =  'rgb(114, 114, 147, 0.4)';
              ctx.lineTo(_metasets[0].data[1].x - ((_metasets[0].data[1].x - _metasets[0].data[0].x) / 2), _metasets[0].data[1].y);
              ctx.lineTo(_metasets[0].data[1].x - 30, _metasets[0].data[1].y);
              ctx.stroke();
            }
  
            ctx.textAlign = 'center';
            ctx.fillStyle =  data[0] < data[1] ? 'rgb(255, 203, 203)' : 'rgb(232, 244, 234)';
            ctx.fillRect(_metasets[0].data[1].x - ((_metasets[0].data[1].x - _metasets[0].data[0].x) / 2) - (barValueWidth / 2) - offset, (_metasets[0].data[1].y - ((_metasets[0].data[1].y - _metasets[0].data[0].y) / 2)) - 10, barValueWidth + 10, 21);
            ctx.fillStyle =  data[0] < data[1] ?  'rgb(232, 25, 60)' : 'rgb(5, 146, 102)';
            ctx.fillText(barValue, _metasets[0].data[1].x - ((_metasets[0].data[1].x - _metasets[0].data[0].x) / 2), (_metasets[0].data[1].y - ((_metasets[0].data[1].y - _metasets[0].data[0].y) / 2)) + offset, _metasets[0].data[1].width);
          });
        }
      }
    })
  }
  render(){
    const {selectedTenantData, isTablet, messagePreview, propertySelected, utilityData} = this.props;
    const {chartDataConsumption, chartDataCost, chartOptionsConsumption, chartOptionsCost, consumptionData, costData} = this.state;

    console.log('selectedTenantData: ', selectedTenantData);
    //const {basicCostHeating, basicCostWarmWater, consumptionCostHeating, consumptionCostWarmWater, basicCostHeatingObject1, basicCostHeatingObject2, basicCostWarmWaterObject1, basicCostWarmWaterObject2} = this.state;

      return ((chartDataConsumption) &&
      <Group noWrap spacing={0} style={{width: isTablet ? '100%' : '80%', background: 'white'//, border: '1px solid rgb(218, 218, 230)'
      }}>
        <Stack style={{width: messagePreview ? '60%' : !isTablet ? '50%' : '100%', padding: '30px 20px 40px'//, borderRight: '1px solid rgb(218, 218, 230)'
      }}>
          <span style={{fontSize: '16px', fontWeight: '700', marginBottom: '10px'}}>{'Energieverbrauch (kWh)'}</span>
          <Chart //height={props.isMobile && '300px'} 
          id='consumption-chart'
          plugins={[this.renderCustomPlugin(consumptionData), ChartDataLabels]}
          data={chartDataConsumption} options={chartOptionsConsumption}/>
        </Stack>
        <Stack style={{width: messagePreview ? '60%' : !isTablet && '50%', padding: '30px 20px 40px'}}>
          <span style={{fontSize: '16px', fontWeight: '700', marginBottom: '10px'}}>{'Energiekosten (€)'}</span>
          <Chart //height={props.isMobile && '300px'}
          id='cost-chart' 
          plugins={[this.renderCustomPlugin(costData), ChartDataLabels]}
          data={chartDataCost} options={chartOptionsCost}/>
        </Stack>
      </Group>
      );
          
  }
}

const mapStateToProps = (state) => {
  return {
    allReducer: state,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    tenantBills: state.UtilityBillingReducer.utilityBillingData,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    heatingFilter: state.UtilityBillingReducer.HeatingAllocationFilter,
    propertySelected: state.generalReducer.property,
  };
};

const mapDispatchToProps = {
  setTenantBills,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareHeatingConsumptionBarChart);


import React, { Component } from 'react';
import {
  FiltersContainer,
  SearchContainer,
  SortContainer,
  HeadingContainer,
  ColorButton,
  MeterMasterDataRecord,
  MeterModal,
  EmptyStateContainer,
  SortAlphabetically,
  AddPropertyModal,
  SwitchContainer,
} from '../../../../neoverv';
import { TENANT_ROUTE } from '../../../../routes/module/RouteName';
import Autoload from '../../../../neoverv/Autoload';
import MeterIcon from '../../../../assets/images/module/general/blueEmptyMeter.svg';
import homeIcon from '../../../../assets/images/module/general/PropertyIcon.svg';
import { getMasterData } from '../../../../redux/action/Meter/meterActions';
import { connect } from 'react-redux';
import GeneralDataHandler from '../../../../neoverv/helpers/DataHandler/General';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import MobileHeadingContainer from '../../../../neoverv/components/Module/General/MobileHeadingContainer';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import RedTick from '../../../../assets/images/module/general/RedTick.svg';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import MeterShopIcon from '../../../../assets/images/module/general/MeterShopIcon.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import breadcrumbsContent from '../../../../../src/assets/breadcrumbs.json';
import { Group, Skeleton, Stack } from '@mantine/core';
import AddPropertyTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyTypeModal';
import UnitsIcon from '../../../../assets/images/module/general/UnitsIcon.svg';
import MultipleTenants from '../../../../assets/images/module/general/MultipleTenants.svg';
import PropertyIcon from '../../../../assets/images/module/general/PropertyIcon.svg';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkEmpty, filterByAlphabet, searchMasterData } = Autoload.MainHelper;
const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class MasterData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      ascendingOrder: true,
      selectedUnitType: null,
      openMeterModal: false,
      meter: null,
      filteredReadings: [],
      allocationKeys: [],
      rentalUnits: [],
      addPropertyModal: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      isTablet: window.innerWidth <= TABLET_SIZE,
      skeletonLoading: false,
      showArticleCondition: '',
      addPropertyTypeModal: false,
      selectedPropertyType: '',
      propertyMeters: [],
      addPropertyMeter: false,
      showArchivedMeters: false,
      allRentalUnitMetersArchived: false
    };
    this.newMeterData = null;
  }

  componentDidMount = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.setState({skeletonLoading: true, showArticleCondition: this.props.rentalUnits.length > 0 ? 'rental_unit_created' : ''});
    if(this.props.propertySelected && this.props.propertySelected.id !== undefined){
      this._fetchPropertyMeters();
      GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected);
    } else {
      this.ShowLoader();
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.rentalUnits.length !== this.props.rentalUnits.length) {
      this.setState({showArticleCondition: this.props.rentalUnits.length > 0 ? 'rental_unit_created' : ''});
    }
  }

  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };

  toggleAddPropertyTypeModal = () => {
    this.setState({ addPropertyTypeModal: !this.state.addPropertyTypeModal });
  };

  _fetchPropertyMeters() {
    const propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters?propertyId=' + propertyid + '&offset=0&results=100',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handlePropertyMeters',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  //@desc meter master data response handler
  _handlePropertyMeters(response) {
    console.log('meter api response', response);
    const data = [];
    const allRentalUnitMeters = [];
    const allPropertyMeters = [];

    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      if (response.result[0].allocationKey === null) {
        Autoload.DataHandlerGeneral.GeneralErrorMessage(
          true,
          lang.newKeys.text_error_not_found
          // + response.message
        );
      }
      const meters = MeterDataHandler.getAllMeters(response.result);
      const allRentalUnitMeters = meters.filter((meter)=> meter.meterType === 'RENTAL_UNIT');
      const allPropertyMeters = meters.filter((meter)=> meter.meterType === 'PROPERTY');
      // meters.map((temp, i) => {
      //   meters[i].rentalUnitName = '-';
      //   return null;
      // });
      let tempMeterObject = {};
      meters.filter((meter)=> meter.meterType === 'RENTAL_UNIT').map((tem, i) => {
        if (tem.propertyMeter) {
          if (tempMeterObject['Property Meter']) {
            tempMeterObject['Property Meter'].push(tem);
          } else {
            tempMeterObject['Property Meter'] = [{ ...tem }];
          }
          return null;
        }
      });
      meters.filter((meter)=> meter.meterType === 'RENTAL_UNIT').map((tem, i) => {
        if (tempMeterObject[tem.rentalUnitId]) {
          tempMeterObject[tem.rentalUnitId] = [...tempMeterObject[tem.rentalUnitId], { ...tem }];
        } else {
          tempMeterObject[tem.rentalUnitId] = [{ ...tem }];
        }
        if (Object.keys(tempMeterObject).length < 1 && tem.rentalUnitId !== '') {
          tempMeterObject[tem.rentalUnitId] = [{ ...tem }];
        }
        return null;
      });
      Object.keys(tempMeterObject).map((key) => {
        data.push({ [key]: [...tempMeterObject[key]] });
        return null;
      });

      let propertyMeters = meters.filter((meter)=> meter.meterType === 'PROPERTY');
      console.log('propertyMeters: ', propertyMeters);
      this.setState({propertyMeters, allRentalUnitMetersArchived: allRentalUnitMeters.filter((rm)=> rm.lastBillingPeriodId !== null).length === allRentalUnitMeters.length});
    }
    
    console.log(data, 'filter reading im map');
    this.setState({ filteredReadings: data, masterDataApi: data }, () => {
      GlobalServices._getRentalUnitsByProperty(this, '_handle_rental_unit_response', this.props.propertySelected);
    });
  }

  //@desc allocations keys response handler
  _handle_allocation_keys_response(response) {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    const allocationKeys = GeneralDataHandler.getAllocationKeys(response.result);
    this.setState({ allocationKeys });
  }

  //@desc rental units response handler
  _handle_rental_unit_response(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    }
    const rentalUnits = GeneralDataHandler.getRantalUnits(response.result);

    let updatedFilteredReadings = this.state.filteredReadings.map((i) => {
      let unit = Object.keys(i).map((rentalUnitId) => rentalUnits.find((unit) => unit.id == rentalUnitId));
      console.log(unit, 'rental units units ');
      return { ...i, rentalUnitName: unit.length > 0 && typeof unit[0] !== 'undefined' ? unit[0].name : '' };
      // return { ...i, rentalUnitName: rentalUnits };
    });

    this.setState({ filteredReadings: updatedFilteredReadings, rentalUnits }, () => this.sortAlphabetically());
    this.ShowLoader();
  }

  handleSearchHandler = (e) => {
    this.setState({ searchValue: e.target.value }, //() => this.filterListBySearch()
    );
  };

  filterListBySearch = (e) => {
    const { masterDataApi } = this.state;
    let updatedList;
    updatedList = searchMasterData(this.state.searchValue, masterDataApi, ['location', 'meterNo']);
    this.setState({ filteredReadings: updatedList });
  };

  handleSort = (data, param, properties, ascendingOrder, nested) => {
    const sortedList = filterByAlphabet(data, param, properties, ascendingOrder, nested);
    this.setState({ filterdReceipts: sortedList, ascendingOrder: !this.state.ascendingOrder });
  };

  _addNewMeter(data) {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    data.propertyId = this.props.propertySelected && this.props.propertySelected.id;
    data.rentalUnitId = data.meterType === 'PROPERTY' ? null : data.rentalUnitId;
    data.rentalUnitName = data.meterType === 'PROPERTY' ? null : data.rentalUnitName;
    const payload = MeterDataHandler.generateMeterDatawithAllocationKey(data);
    console.log(payload, data, 'data in meter=========');
    let btnelement = document.getElementById('meter-button-container');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters',
      'POST',
      payload,
      btnelement.children[0],
      false,
      true,
      true,
      this,
      '_handleCreateMeter',
      { btnelement: btnelement },
      false,
      '',
      { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
    );
  }

  _handleCreateMeter(response) {
    const {isMobile, isTablet} = this.state;
    if (response.statuscode !== 200) {
      // Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
      if(response.statuscode === 409) {
        showNotification({
          id: 'meterDuplicate',
          message: "Diese Zählernummer wird bereits verwendet. Bitte wählen Sie eine andere Zählernummer.",
          icon: <img src={RedCross} alt='red-tick'/>,
          style: {marginLeft: !isMobile && !isTablet && '24%', width: '100%'},
        });
        var loader = document.getElementById('hello-housing-loader');
        loader.style.display = 'none';
      }else {
        Autoload.DataHandlerGeneral.GeneralErrorMessage(
          true,
          lang.newKeys.text_error_not_found
          // + response.message
        );
        var loader = document.getElementById('hello-housing-loader');
        loader.style.display = 'none';
        showNotification({
          message: "Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.",
          icon: <img src={RedCross} alt='red-tick'/>,
          style: {marginLeft: !isMobile && !isTablet && '24%', width: '100%'},
        });
      }
    } else{
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        'Meter Created': true
      });
      showNotification({
        message: "Der Zähler wurde angelegt.",
        icon: <img src={GreenTick} alt='green-tick'/>,
        style: {marginLeft: !isMobile && !isTablet && '24%', width: '100%'},
      });
      window.Intercom('trackEvent', 'meter-created');
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
      this._fetchPropertyMeters();
      this.setState({ openMeterModal: !this.state.openMeterModal });
    }
  }

  // @desc meter master row deletion response handler
  handle_delete_meter_response(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    if(response.status === true && response.statuscode === 200){
      showNotification({
        message: "Der Zähler wurde gelöscht.",
        icon: <img src={RedTick} alt='red-tick'/>,
        //style: {marginLeft: !isMobile && !isTablet && '24%', width: '100%'},
      });
    }
    const { closeModalFunc, rowId } = response.other;
    closeModalFunc();

    let { propertyMeters } = this.state;
    this.setState({propertyMeters: propertyMeters.filter((meter)=> meter.id !== rowId)});

    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this._fetchPropertyMeters();
  }

  _handleUpdateMeterMasterResponse(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    response.other.closeModalFunc();
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this._fetchPropertyMeters();
  }

  ShowLoader = () => {
    console.log('show loader abc');
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    var emptyDiv = document.getElementById('empty-master-meter-page');
    emptyDiv.style.display = 'none';
    var dataDiv = document.getElementById('respond-master-meter-page');
    dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
  };

  sortAlphabetically = () => {
    console.log('FILTERED : ', this.state.filteredReadings);
    console.log('UNITS : ', this.state.rentalUnits);
    // A to Z
    let filteredArray = [];
    let field = 'rentalUnitName';
    let nestedField = 'meterNo';

    filteredArray = this.state.filteredReadings.sort((a, b) =>
      (a[field] || '').toString().localeCompare((b[field] || '').toString())
    );
    filteredArray = filteredArray.map((i) => {
      let keys = Object.keys(i).filter((e) => e !== 'rentalUnitName');
      if (keys.length > 0 && Array.isArray(i[keys[0]]) && i[keys[0]].length > 0) {
        let sortedArray = i[keys[0]].sort((a, b) =>
          (a[nestedField] || '').toString().localeCompare((b[nestedField] || '').toString())
        );
        return { ...i, [keys[0]]: sortedArray };
      } else return i;
    });

    this.setState({ filteredReadings: filteredArray });
  };
  sortReverseAlphabetically = () => {
    console.log('FILTERED : ', this.state.filteredReadings);
    // Z to A
    let filteredArray = [];
    let field = 'rentalUnitName';
    let nestedField = 'meterNo';

    filteredArray = this.state.filteredReadings.sort((a, b) =>
      (b[field] || '').toString().localeCompare((a[field] || '').toString())
    );
    filteredArray = filteredArray.map((i) => {
      let keys = Object.keys(i).filter((e) => e !== 'rentalUnitName');
      if (keys.length > 0 && Array.isArray(i[keys[0]]) && i[keys[0]].length > 0) {
        let sortedArray = i[keys[0]].sort((a, b) =>
          (b[nestedField] || '').toString().localeCompare((a[nestedField] || '').toString())
        );
        return { ...i, [keys[0]]: sortedArray };
      } else return i;
    });

    this.setState({ filteredReadings: filteredArray });
  };
  _navigateTo(routeName) {
    this.props.history.push(routeName);
  }

  renderSwitch=()=>{
    const {showArchivedMeters} = this.state;
    return(
      <SwitchContainer
      className="heating-cost-switch"
      onChange={() => this.setState({showArchivedMeters: !showArchivedMeters})}
      checked={showArchivedMeters}
      switchText={<span style={{whiteSpace: 'nowrap'}}>{'archivierte Zähler einblenden'}</span>}
      disabled={false}
      />
    )
  }

  renderSorting=()=>{
    const {skeletonLoading} = this.state;
    return(
      <Skeleton visible={skeletonLoading}>
        <Group style={{width: '100%'}} position='right'>
          <SortAlphabetically
            sortingParam="alphabet"
            handleAscending={this.sortAlphabetically}
            handleDescending={this.sortReverseAlphabetically}
          />
        </Group>
      </Skeleton>
    )
  }
  render() {
    const { searchValue, ascendingOrder, openMeterModal, meter, filteredReadings, allocationKeys, rentalUnits, isMobile, isTablet, skeletonLoading, showArticleCondition, propertyMeters, addPropertyMeter, showArchivedMeters } =
      this.state;

    let keys = [];

    filteredReadings.map((item) => {
      keys.push(...Object.keys(item));
      return null;
    });

    console.log('unitName: ', this.state.unitName);
    console.log('unitIndex: ', this.state.unitIndex);
    // console.log('READINGS: ', filteredReadings);
    // console.log('RENTALUNITS: ', rentalUnits);

    return (
      <>
        <div id="empty-master-meter-page"></div>
        <div className="master-data-section" id="respond-master-meter-page" style={{ display: 'block' }}>
          <div style={{display: 'flex', marginBottom: '14px'}}>
            {!skeletonLoading && breadcrumbsContent.map((item)=> item.area === '/meter/master' && 
            item.breadcrumbs.map((breadcrumb)=>
            breadcrumb.type === 'tour' ? breadcrumb.condition === showArticleCondition && breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
            <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
              <img src={TourIcon} />
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </div>
            :
            breadcrumb.type === 'link' ?
            <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.open(breadcrumb.link, '_blank')}>
              <img src={MeterShopIcon} />
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </div>
            :
            breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
            <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
              <img src={HelpLogo} />
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </div>
            ))}
          </div>
          <FiltersContainer
            searchElement={
              <Stack style={{width: '100%'}} spacing={10}>
                <Group>
                  <Skeleton visible={skeletonLoading} style={{width: isMobile ? '100%' : '300px'}}>
                    <SearchContainer
                      className="search-meter-home"
                      placeholder={`${lang.general.text_search}...`}
                      value={searchValue}
                      onChange={this.handleSearchHandler}
                      disabled={false}
                    />
                  </Skeleton>
                  {!isMobile && this.renderSwitch()}
                </Group>
                {isMobile && this.renderSwitch()}
              </Stack>
            }
            sortElement={<Group style={{width: '100%'}} position='right'>{this.renderSorting()}</Group>}
          />
          <div className="divider"></div>
          <div className="data-section master-data-section-icon">
            {/* .sort((a, b) =>
                  ((a[Object.keys(a)[0]].length > 0 && a[Object.keys(a)[0]][0].rentalUnitName) || '')
                    .toString()
                    .localeCompare(
                      ((b[Object.keys(b)[0]].length > 0 && b[Object.keys(b)[0]][0].rentalUnitName) || '').toString()
                    )
                ) */}
                {console.log('propertyMeters: ', propertyMeters)}
                <>{propertyMeters.filter((meter)=> meter.meterNo.includes(searchValue) || meter.meterName.toString().toLowerCase().includes(searchValue))
                .filter((meter)=> showArchivedMeters ? meter : meter.lastBillingPeriodId === null)
                .map((meter, meterIndex) => {
                  <MeterMasterDataRecord
                  history={this.props.history}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  className="master-meter-table"
                  data={meter}
                  currentMeter={meterIndex}
                  labels={[lang.meter.text_number, 'Bezeichnung', lang.meter.text_type, lang.meter.text_location, '']}
                  parentContext={this}
                  allocationKeys={allocationKeys}
                  fetchPropertyMeters={()=>this._fetchPropertyMeters()}
                  getAllocationKeysByProperty={()=>GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected)}
                />
                })}</>
                
            {skeletonLoading ?
            <div>
              <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <Skeleton visible={skeletonLoading} style={{width: '30px', height: '20px'}}/>
                <Skeleton visible={skeletonLoading} style={{width: '100px', height: '36px'}}/>
              </div>
              <>
                <Skeleton visible={skeletonLoading} style={{height: '23px', marginBottom: '10px', marginTop: '10px'}}/>
                {[...Array(10)].map((e, i) => <Skeleton visible={skeletonLoading} style={{height: '75px', marginBottom: '8px'}}/>)}
                </>
            </div>
            :
            (checkEmpty(filteredReadings) && checkEmpty(propertyMeters)) ? 
            (
              // <EmptyStateContainer
              //   // className="empty-meter-reading"
              //   heading={lang.meter.text_no_master_data}
              //   subheading={lang.meter.text_no_master_data_subheading}
              //   icon={MeterIcon}
              //   btnHandler={() => this.setState({ openMeterModal: !openMeterModal })}
              //   btnText={lang.meter.text_add_meter}
              // />
              <EmptyStateContainer
                className="empty-meter-reading"
                heading={Autoload.MainHelper.checkEmpty(this.props.properties) ? "Ablesungen sind noch nicht verfügbar." : "Es wurden keine Zähler gefunden."}
                subheading={
                  Autoload.MainHelper.checkEmpty(this.props.properties)
                    ? 'Legen Sie zunächst eine Immobilie und Mieteinheiten an, um Zähler anzulegen.' 
                    : 'Legen Sie Zähler an, um Mieterverbräuche und Hausverbräuche zu erfassen.'
                }
                icon={MeterIcon}
                btnHandler={() => {
                  if (Autoload.MainHelper.checkEmpty(this.props.properties)) {
                    this.toggleAddPropertyTypeModal();
                  } else {
                    this.setState({ openMeterModal: !this.state.openMeterModal, addPropertyMeter: null });
                  }
                }}
                btnText={
                  Autoload.MainHelper.checkEmpty(this.props.properties)
                    ? lang.general.text_add_property
                    : lang.meter.text_add_meter
                }
              />
            )
            :
            (
              <div>
                <div className='property-meters'>
                  <MobileHeadingContainer
                    heading={'Hauszähler'}
                    headingIcon={PropertyIcon}
                    headingStyling={{width: '100%', alignItems: 'center'}}
                    rightContent={
                      <ColorButton
                        text={lang.meter.text_add_meter}
                        className="purple-btn"
                        onClick={() => {
                          this.setState({
                            openMeterModal: !openMeterModal,
                            addPropertyMeter: true,

                          });
                        }}
                      />
                    }
                  />
                  <>
                    {propertyMeters.length === 0 || (!showArchivedMeters && propertyMeters.filter((dt)=> dt.lastBillingPeriodId !== null).length === propertyMeters.length) ?
                    <EmptyStateContainer
                    heading={'Es wurden keine Zähler gefunden.'}
                    subheading={'Legen Sie Zähler an, um Mieterverbräuche und Hausverbräuche zu erfassen.'}
                    icon={MeterIcon}
                    btnHandler={() => {
                      this.setState({ openMeterModal: !this.state.openMeterModal, addPropertyMeter: null });
                    }}
                    btnText={lang.meter.text_add_meter}
                    styleMainContainer={{marginTop: '50px'}}
                    />
                    :
                    <MeterMasterDataRecord
                    history={this.props.history}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    className="master-meter-table"
                    data={propertyMeters.filter((meter)=> meter.meterNo.includes(searchValue) || meter.meterName.toString().toLowerCase().includes(searchValue))
                    .filter((meter)=> showArchivedMeters ? meter : meter.lastBillingPeriodId === null)}
                    currentMeter={0}
                    labels={[lang.meter.text_number, 'Bezeichnung', lang.meter.text_type, lang.meter.text_location, '']}
                    parentContext={this}
                    allocationKeys={allocationKeys}
                    fetchPropertyMeters={()=>this._fetchPropertyMeters()}
                    getAllocationKeysByProperty={()=>GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected)}
                    />}
                  </>
                </div>
                {
                <div className='rental-unit-meters' style={{marginTop: '20px'}}>
                  <MobileHeadingContainer
                    heading={'Mieterzähler'}
                    headingIcon={MultipleTenants}
                    headingIconStyle={{marginTop: '2px'}}
                    headingStyling={{width: '100%', alignItems: 'center'}}
                    rightContent={
                      <ColorButton
                        text={lang.meter.text_add_meter}
                        className="purple-btn"
                        disabled={this.state.rentalUnits.length === 0}
                        onClick={() => {
                          this.setState({
                            openMeterModal: !openMeterModal,
                            addPropertyMeter: false,

                          });
                        }}
                      />
                    }
                  />
                  <>{this.state.rentalUnits.length === 0 ? (
                <EmptyStateContainer
                  // className="empty-meter-reading"
                  heading= {'Zähler sind nicht verfügbar.'}//Bitte legen Sie zunächst Mietverhältnisse an, zu denen Ihre Zähler zuzuordnen sind"
                  icon={MeterIcon}
                  subheading={Autoload.MainHelper.checkEmpty(this.props.properties) ? 'Legen Sie zunächst eine Immobilie und Mieteinheiten an, um Zähler anzulegen.' : 'Legen Sie zunächst Mieteinheiten an, um Zähler anzulegen.'}
                  btnHandler={() => {
                    if (Autoload.MainHelper.checkEmpty(this.props.properties)) {
                      this.toggleAddPropertyTypeModal();
                    } else {
                      this._navigateTo(TENANT_ROUTE);
                    }
                    }}
                  btnText={Autoload.MainHelper.checkEmpty(this.props.properties)
                        ? lang.general.text_add_property
                        :'Einheit hinzufügen'}
                />
                ) :
                <>
                {!showArchivedMeters && this.state.allRentalUnitMetersArchived ?
                <EmptyStateContainer
                heading={'Es wurden keine Zähler gefunden.'}
                subheading={'Legen Sie Zähler an, um Mieterverbräuche und Hausverbräuche zu erfassen.'}
                icon={MeterIcon}
                btnHandler={() => {
                  this.setState({ openMeterModal: !this.state.openMeterModal, addPropertyMeter: null });
                }}
                btnText={lang.meter.text_add_meter}
                styleMainContainer={{marginTop: '50px'}}
                />
                :
                filteredReadings.map((reading, readingIndex) => {
                    
                    return Object.keys(reading).map((readingKey) => {
                      // @todo loop through the rental units and match there keys to show rental unit name
                      console.log('filteredReadings: ', reading, readingKey);
                      let unitName =
                        readingKey === 'Property Meter'
                          ? 'Property Meter'
                          : rentalUnits.map((rentalUnit) => (rentalUnit.id === readingKey ? rentalUnit.name : null)).filter(Boolean).toString();
                      let check_record = (unitName + '').replace(/,/g, '');
                      console.log('readingKey: ', readingKey);
                      let meters= reading[readingKey];
                      return check_record !== '' ? (
                        <div className={`${readingKey}-section ${check_record}`} key={readingKey} style={{marginTop: '20px'}}>
                          {!showArchivedMeters ? meters.filter((dt)=> dt.lastBillingPeriodId !== null).length !== meters.length && 
                          <MobileHeadingContainer
                            heading={unitName ? (!showArchivedMeters && meters.filter((dt)=> dt.lastBillingPeriodId !== null).length === meters.length) ? '' : meters.filter((meter)=> meter.meterNo.includes(searchValue) || meter.meterName.toString().toLowerCase().includes(searchValue)).length > 0 ? unitName : '' : '-'}
                            headingIcon={readingKey === 'Property Meter' && homeIcon}
                            headingStyling={{width: '100%', alignItems: 'center', marginTop: '30px', marginBottom: '20px'}}
                            rightContent={
                              null
                              // <ColorButton
                              //   text={lang.meter.text_add_meter}
                              //   className="purple-btn"
                              //   onClick={() => {
                              //     this.setState({
                              //       unitName: unitName ? unitName : '-',
                              //       unitIndex: readingIndex,
                              //       meter: reading[readingKey],
                              //       selectedUnitType: readingKey,
                              //       openMeterModal: !openMeterModal,
                              //       unitId: readingKey,
                              //       addPropertyMeter: false,
                              //     });
                              //   }}
                              // />
                            }
                          />
                        :
                        <MobileHeadingContainer
                            heading={unitName ? (!showArchivedMeters && meters.filter((dt)=> dt.lastBillingPeriodId !== null).length === meters.length) ? '' : meters.filter((meter)=> meter.meterNo.includes(searchValue) || meter.meterName.toString().toLowerCase().includes(searchValue)).length > 0 ? unitName : '' : '-'}
                            headingIcon={readingKey === 'Property Meter' && homeIcon}
                            headingStyling={{width: '100%', alignItems: 'center', marginTop: '30px', marginBottom: '20px'}}
                            rightContent={null}
                          />
                        }
                          {console.log('meters: ', reading[readingKey])}
                          {meters.length > 0 && (
                            <MeterMasterDataRecord
                              history={this.props.history}
                              isMobile={isMobile}
                              isTablet={isTablet}
                              className="master-meter-table"
                              data={meters.filter((meter)=> meter.meterNo.includes(searchValue) || meter.meterName.toString().toLowerCase().includes(searchValue))
                              .filter((meter)=> showArchivedMeters ? meter : meter.lastBillingPeriodId === null)
                              }
                              currentMeter={readingKey}
                              labels={[lang.meter.text_number, 'Bezeichnung', lang.meter.text_type, lang.meter.text_location, '']}
                              parentContext={this}
                              allocationKeys={allocationKeys}
                              fetchPropertyMeters={()=>this._fetchPropertyMeters()}
                              getAllocationKeysByProperty={()=>GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected)}
                            />
                          )}
                        </div>
                      ) : (
                        ''
                      );
                    });
                  })}
                  </>
                  }
                  </>
                </div>}
              </div>
            )}
            {openMeterModal && (
              <MeterModal
                history={this.props.history}
                meter={meter}
                className="add-meter-modal"
                open={openMeterModal}
                addNewMeter={(newMeterData) => this._addNewMeter(newMeterData)}
                toggle={() => this.setState({ openMeterModal: !this.state.openMeterModal, addPropertyMeter: false })}
                propertyId={this.props.propertySelected.id}
                rentalUnitName={this.state.unitName}
                rentalUnitIndex={this.state.unitIndex}
                unitId={this.state.unitId}
                addPropertyMeter={addPropertyMeter}
              />
            )}
            {/* add property */}
            {this.state.addPropertyModal && (
              <AddPropertyModal
                className="add_proprty_modal"
                edit={false}
                open={this.state.addPropertyModal}
                toggle={() => {
                  this.toggleAddPropertyModal();
                }}
                propertyType={this.state.selectedPropertyType}
              />
            )}
            {this.state.addPropertyTypeModal &&
          <AddPropertyTypeModal
            className="add_proprty_type_modal"
            open={this.state.addPropertyTypeModal}
            toggle={() => {
              this.toggleAddPropertyTypeModal();
            }}
            next={(selectedPropertyType)=> {
              this.setState({selectedPropertyType});
              this.toggleAddPropertyTypeModal();
              this.toggleAddPropertyModal();
            }}
          />
          }

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    masterData: state.getMasterDataReducer,
    propertySelected: state.generalReducer.property,
    properties: state.generalReducer.allProperties,
    rentalUnits: state.tenantDataReducer.rentalUnits,
  };
};
export default connect(mapStateToProps, { getMasterData })(MasterData);

import React, { Component, forwardRef } from 'react';
import CustomModal from './index';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { Accordion, Button, Group, MultiSelect, Stack, Stepper, CloseButton, Select, TextInput } from '@mantine/core';
import DropFile from '../DropFile/DropFile';
import { Form } from 'react-bootstrap';
import ColorButton from '../Buttons/ColorButton';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import SelfIcon from '../../../../../assets/images/module/general/SelfIcon.svg';
import TenantSingleIcon from '../../../../../assets/images/module/general/TenantSingleIcon.svg';
import TenantSingleIconBlue from '../../../../../assets/images/module/general/TenantSingleIconBlue.svg';
import TenantLiteIcon from '../../../../../assets/images/module/general/TenantLite.svg';
import buildingIcon from '../../../../../assets/images/module/general/building_icon.svg';
import Up from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import SearchIcon from '../../../../../assets/images/module/general/SearchIcon.svg';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import SwitchContainer from '../SwitchContainer';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment, loader } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;


class AddRecipientModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    name: null,
    email: null,
    street: null,
    zipCode: null,
    city: null,
    phone: null,
    nameErr: false,
    emailErr: false,
    streetErr: false,
    zipCodeErr: false,
    cityErr: false
  };

  componentDidMount(){
    const {recipientData, propertySelected} = this.props;
    console.log('recipientData: ', recipientData);
    if(this.props.edit){
      this.setState({
        name: recipientData?.name,
        email: recipientData?.email,
        phone: recipientData?.phone,
        street: recipientData?.street,
        zipCode: recipientData?.zipCode,
        city: recipientData?.city
      })
    }else{
      if(recipientData){
        this.setState({name: recipientData?.name});
      }
    }
  }

  updateRecipient=()=>{
    const {recipientData, switchName} = this.props;
    const {name, email, phone, street, city, zipCode} = this.state;
    let payload= {
      ...recipientData,
      name,
      email,
      phone,
      street,
      city,
      zipCode,
      isEmailEnabled: switchName === 'email' ? true : recipientData.isEmailEnabled,
      isPostEnabled: switchName === 'postal' ? true : recipientData.isPostEnabled,
      //isPostEnabled: (street && city && zipCode) ? true : false,
      //isHiddenForTenantPortal: recipientData.tenantId ? recipientData.isHiddenForTenantPortal : true
    }
    console.log('updateRecipient: ', payload);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messageRecipients/'+ recipientData.id,
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handlePutMessageRecipients',
      payload,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePutMessageRecipients(response){
    if(response.status && response.statuscode === 200){
      this.props.getMessageRecipientsByMessageId();
      this.props.toggle();
    }
  }

  postRecipient=()=>{
    const {messageId} = this.props;
    const {name, email, phone, street, city, zipCode} = this.state;
    let payload= [{
      messageId,
      tenantId: null,
      isEmailEnabled: email ? true : false,
      isPostEnabled: (street && city && zipCode) ? true : false,
      isHiddenForTenantPortal: true,
      name,
      email,
      phone,
      street,
      city,
      zipCode
    }]
    console.log('updateRecipient: ', payload);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messageRecipients',
      'POST',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handlePosttMessageRecipients',
      payload,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePosttMessageRecipients(response){
    if(response.status && response.statuscode === 200){
      this.props.getMessageRecipientsByMessageId();
      this.props.toggle();
    }
  }

  handleSubmit=(e)=>{
    e.preventDefault();
    const {switchName} = this.props;
    const { name, email, phone, street, zipCode, city, nameErr, emailErr, streetErr, zipCodeErr, cityErr } = this.state;
    if(!name){
      this.setState({nameErr: 'Angabe notwendig'});
    }else{
      this.setState({nameErr: false});
    }

    if(switchName === 'email'){
      if(!email){
        this.setState({emailErr: 'Angabe notwendig'});
      }else{
        this.setState({emailErr: false});
      }
    }

    if(switchName === 'postal'){
      if(!street){
        this.setState({streetErr: 'Angabe notwendig'});
      }else{
        this.setState({streetErr: false});
      }
  
      if(!zipCode){
        this.setState({zipCodeErr: 'Angabe notwendig'});
      }else{
        this.setState({zipCodeErr: false});
      }
  
      if(!city){
        this.setState({cityErr: 'Angabe notwendig'});
      }else{
        this.setState({cityErr: false});
      }
    }
    
    const isValidated = switchName === 'postal' ? (name && street && zipCode && city) : switchName === 'email' ? (email ? true : false) : true;


    if(isValidated){
      this.props.edit ? this.updateRecipient() : this.postRecipient();
    }else{
      showNotification({
        message: 'Angabe notwendig',
        icon: <img src={RedCross} />,
        id: 'missingValues'
      });
    }
  }

  validateEmail = (email) => {
    var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if(email.match(mailformat)) {
      return true;
    }
    else {
      return false;
    }
  };

  render() {
    const { open, toggle, className, edit, recipientData, propertySelected, saveInformation, switchName } = this.props;
    const { name, email, phone, street, zipCode, city, nameErr, emailErr, streetErr, zipCodeErr, cityErr } = this.state;
    console.log('recipientData: ', recipientData);
    return (
      <>
        <CustomModal
          heading={`Empfänger ${edit ? 'bearbeiten' : 'hinzufügen'}`}
          headingClassName='create-folder-modal-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
          <Stack>
            <Group grow>
              <TextInput
              label={'Name / Firma'}
              styles={{
                label: {
                  color: '#727293',
                  fontSize: '12px',
                  fontWeight: '400'
                },
                input: {
                  color: '#000000'
                }
              }}
              required
              value={name}
              onChange={(e)=> this.setState({name: e.target.value, nameErr: false})}
              error={nameErr}
              />
            {/* <TextInput
              label={'Telefonnummer (optional)'}
              styles={{
                label: {
                  color: '#727293',
                  fontSize: '12px',
                  fontWeight: '400'
                }
              }}
              value={phone}
              onChange={(e)=> this.setState({phone: e.target.value})}
              /> */}
            </Group>

            <Group grow>
              <TextInput
              label={'E-Mail Adresse'}
              styles={{
                label: {
                  color: '#727293',
                  fontSize: '12px',
                  fontWeight: '400'
                }
              }}
              value={email}
              error={emailErr}
              required={switchName === 'email'}
              // onBlur={(e)=> {
              //   if(this.validateEmail(e.target.value) === false) {
              //     showNotification({
              //       message: 'Email is not in correct format.', 
              //       icon: <img src={RedCross} />, 
              //       id: 'emailIncorrect'
              //     });
              //     this.setState({emailErr: 'Email format incorrect.'})
              //   } 
              // }}
              onChange={(e)=> this.setState({email: e.target.value, emailErr: false})}
              />
            </Group>

            <Group grow>
              <TextInput
              label={'Anschrift'}
              styles={{
                label: {
                  color: '#727293',
                  fontSize: '12px',
                  fontWeight: '400'
                }
              }}
              value={street}
              error={streetErr}
              required={switchName === 'postal'}
              onChange={(e)=> this.setState({street: e.target.value, streetErr: false})}
              />
            </Group>

            <Group grow>
              <TextInput
              label={'PLZ'}
              styles={{
                label: {
                  color: '#727293',
                  fontSize: '12px',
                  fontWeight: '400'
                }
              }}
              value={zipCode}
              error={zipCodeErr}
              required={switchName === 'postal'}
              onChange={(e)=> this.setState({zipCode: e.target.value, zipCodeErr: false})}
              />
              <TextInput
              label={'Ort'}
              styles={{
                label: {
                  color: '#727293',
                  fontSize: '12px',
                  fontWeight: '400'
                }
              }}
              value={city}
              error={cityErr}
              required={switchName === 'postal'}
              onChange={(e)=> this.setState({city: e.target.value, cityErr: false})}
              />
            </Group>

            <Group position='apart'>
              <div>
                <ColorButton
                text={'Abbrechen'}
                className="gray-btn"
                onClick={()=> toggle()}
                />
              </div>
              <div>
                <ColorButton
                text={edit ? 'Speichern' : 'Hinzufügen'}
                className="purple-btn"
                onClick={this.handleSubmit}
                />
              </div>
            </Group>
          </Stack>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    rentalUnits: state.tenantDataReducer.rentalUnits,
  };
};

export default connect(mapStateToProps)(AddRecipientModal);

import React, { Component } from 'react';
import CustomModal from './index';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { Group, Stack } from '@mantine/core';
import DropFile from '../DropFile/DropFile';
import { Form } from 'react-bootstrap';
import ColorButton from '../Buttons/ColorButton';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class ShareDocumentsModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    selectedFolder: null
  };

  constructor(props) {
    super(props);
  }

  componentDidMount(){
  }
  
  render() {
    const { open, toggle, className, type, data, checkedItems } = this.props;
    const {selectedFolder} = this.state;
    console.log('selectedFolder: ', selectedFolder);
    return (
      <>
        <CustomModal
          heading={`${type === 'move' ? 'Move' : 'Copy'} folder/file`}
          headingClassName='create-folder-modal-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
          <Stack style={{width: '100%'}}>
            <Group 
              style={{cursor: 'pointer', backgroundColor: selectedFolder === 'root' ? '#E3E3FC' : 'white', padding: '10px', borderRadius: '4px'}} 
              onClick={()=> {
                let {selectedFolder} = this.state;
                selectedFolder = selectedFolder ? (selectedFolder === 'root' ? null : 'root') : 'root';
                this.setState({selectedFolder});
            }}>
              <FolderIcon 
              style={{
                color: '#5655FE',
                fontSize: '20px'
              }}/>
              <span style={{fontWeight: '500', fontSize: '14px', fontFamily: 'Inter, sans-sarif'}}>{'Root'}</span>
            </Group>
            {data.map((dt)=> dt.isDirectory && checkedItems.find((ci)=> ci === dt.id) === undefined &&
              <Group 
              style={{cursor: 'pointer', backgroundColor: selectedFolder === dt.id ? '#E3E3FC' : 'white', //color: selectedFolder === dt.id && 'white', 
              padding: '10px', borderRadius: '4px'}} 
              onClick={()=> {
                let {selectedFolder} = this.state;
                selectedFolder = selectedFolder ? (selectedFolder === dt.id ? null : dt.id) : dt.id;
                this.setState({selectedFolder});
              }}>
                <FolderIcon 
                style={{
                  color: '#5655FE',
                  fontSize: '20px'
                }}/>
                <span style={{fontWeight: '500', fontSize: '14px', fontFamily: 'Inter, sans-sarif'}}>{dt.fileName}</span>
              </Group>
            )}
            <Group position='apart'>
              <div>
                  <ColorButton
                  onClick={()=> toggle()}
                  className="gray-btn"
                  text={'Abbrechen'}
                  />
              </div>
              <div>
                  <ColorButton
                  //buttonConfig={{ type: 'submit' }}
                  className="purple-btn"
                  text={type === 'move' ? 'Move' : 'Copy'}
                  disabled={!selectedFolder}
                  />
              </div>
            </Group>
          </Stack>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(ShareDocumentsModal);

import React, { Component, forwardRef } from 'react';
import CustomModal from './index';
import {
  ColorButton,
  ValueText,
  SwitchContainer,
  DropFile,
  AddCostTypeModal
} from '../../../../index';
import { Row, Col, Form, Tooltip, OverlayTrigger, ButtonGroup, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Help from '../../../../../assets/images/module/general/Help.svg';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';
import { connect } from 'react-redux';
import RangeDatePicker from './DateRangePicker';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';

import { showNotification, hideNotification } from '@mantine/notifications';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import AddIcon from '../../../../../assets/images/module/general/AddIcon.svg';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import Messenger from '../../../../../assets/images/module/general/Messenger.svg';
import SearchIcon from '../../../../../assets/images/module/general/SearchIcon.svg';
import Up from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import TenantSingleIconBlue from '../../../../../assets/images/module/general/TenantSingleIconBlue.svg';

// Get receipts endpoint from new config
import { config } from '../../../../config/Setting';
import { Check, ThreeDRotationSharp } from "@material-ui/icons";
import { Alert, Checkbox, Group, Loader, Select, Skeleton, Stack, Text } from "@mantine/core";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import dayjs from 'dayjs';
import CopyIcon from '../../../SVGs/copyIcon';
import PreviewMessagePdf from '../PreviewMessage/PreviewMessagePdf';
import PreviewMessageEmail from '../PreviewMessage/PreviewMessageEmail';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import MessagesIcon from '../../../../../assets/images/module/general/MessagesIcon.svg';
import PreviewMessagePdfBillingStatement from '../PreviewMessage/PreviewMessagePdfBillingStatement';
import PreviewMessagePdfMeterReadingsAndConsumptions from '../PreviewMessage/PreviewMessagePdfMeterReadingsAndConsumptions';
import PreviewMessagePdfHeatingInformation from '../PreviewMessage/PreviewMessagePdfHeatingInformation';
import Pdf, { usePdf } from '@mikecousins/react-pdf';
import { ZoomIn, ZoomOut} from '@material-ui/icons';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import PreviewMessageTenantPortal from '../PreviewMessage/PreviewMessageTenantPortal';
import UtilityBillingTemplate2 from '../../../../../views/pages/Module/UtilityBilling/UtilityBillingTemplate2';
import { saveAs } from 'file-saver';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment } = Autoload.MainHelper;
dayjs.extend(customParseFormat);
var JSZip = require("jszip");

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

const Item = forwardRef(({ label, value, ...others }, ref) => {
    console.log('Item: ', label, value, others);
    //const Flag = flags[value];
    return (
      <div ref={ref} {...others}>
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box mr={10}>
            <img src={others.picture}/>
          </Box>
          <div>{others.name}</div>
        </Box> */}
  
        {value === 'addRecipient' ?
        <span onClick={()=> others.openAddRecipientModal()}>{others.name}</span>
        :
        value === 'selectAll' ?
        <span onClick={()=> others.selectAllRecipients()}>{others.name}</span>
        :
        <Group spacing={10} className='message-card-ten-cards' key={value} 
        style={{
          height: '20.6px', 
          maxHeight: '20.6px', 
          background: 'transparent', 
          width: 'auto', 
          color: '#0e0e1d',
          fontSize: '14px',
          fontWeight: '500',
          fontFamily: 'sans-serif'
        }}>
            {others.picture && <img src={others.picture} style={{marginTop: '-3px'}}/>}
            <Group spacing={5}>
              <span>{others.name}</span>
              {others.rentalUnit && <span>{`(${others.rentalUnit})`}</span>}
            </Group>
            {/* {others.current && <span>(aktuell)</span>} */}
        </Group>}
      </div>
    );
  });

class BulkDownloadForMessageModal extends Component {
  state = {
    selectedRecipient: null,
    selectedView: 'postal',
    allBillingStatements: [],
    selectedBillingStatement: null,
    selectedCostAllocations: [],
    loader: null,
    meterReadingsSwitch: false,
    compositionOfHeatingCostsSwitch: false,
    costSplitWarmWaterAndHeatingSwitch: false,
    splitBaseConsumptionCostsSwitch: false,
    vatSwitch: false,
    laborCostSwitch: false,
    energyConsumptionCO2EmissionsSwitch: false,
    compareHeatingConsumptionBarChartSwitch: false,
    energyLevelsInfoSwitch: false,
    arbitraryBoardInfoSwitch: false, 
    energyAdviceCenterInfoSwitch: false,
    co2TaxInfoSwitch: false,
    ubData: null,
    messagePreviewPdf: null,
    ubPdfUrl: null,
    rentalPayment: null,
    rents: [],
    tenantRemainingRentObject: null,
    firstLoader: false,
    loaderRecipientId: null,
    isPreTaxInfoHidden: false,
    downloadedPdfs: [],
    billingStatementsAlreadyDownloaded: [],
    downloadedBsPdfs: [],
    selectedRecipientsList: [],
    dropdownIcon: Down,
    initiallyOpened: false,
    dropdownOptions: []
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const {allRecipients, utilityBillingData, messageData} = this.props;
    let data = utilityBillingData.find((ub) => ub.id == messageData.utilityBillingId);
    if(data !== undefined){
      this.setState({
        meterReadingsSwitch: !data.isMeterReadingAndConsumptionInfoHidden,
        compositionOfHeatingCostsSwitch: !data.isHeatingCostInfoHidden,
        costSplitWarmWaterAndHeatingSwitch: !data.isCostSplitWarmWaterAndHeatingCostInfoHidden,
        splitBaseConsumptionCostsSwitch: !data.isSplitBaseConsumptionCostInfoHidden,
        vatSwitch: !data.isVATHidden,
        laborCostSwitch: !data.isLaborCostHidden,
        energyConsumptionCO2EmissionsSwitch: !data.isEnergyConsumptionCO2EmissionsInfoHidden,
        compareHeatingConsumptionBarChartSwitch: !data.isPreviousPeriodEnergyComparisonInfoHidden,
        energyLevelsInfoSwitch: !data.isEnergyLevelsInfoHidden,
        arbitraryBoardInfoSwitch: !data.isArbitraryBoardInfoHidden, 
        energyAdviceCenterInfoSwitch: !data.isEnergyAdviceCenterInfoHidden,
        co2TaxInfoSwitch: !data.isCO2TaxInfoHidden,
        isPreTaxInfoHidden: data.isPreTaxInfoHidden,
        ubData: data,
        selectedRecipientsList: allRecipients
      })
    }
    console.log('allRecipients: ', allRecipients);
    let dropdownOptions= allRecipients.map((recipient)=> ({...recipient, value: recipient.id, label: recipient.name}));
    dropdownOptions.push({value: 'selectAll', label: 'Alle auswählen', name: 'Alle auswählen'});
    this.setState({dropdownOptions});
  }

  getRentalPayment=(id)=>{
    this.setState({loader: true});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalPayments/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_rental_payment',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_rental_payment(response){
    if(response.status && response.statuscode === 200){
      this.setState({rentalPayment: response.result},()=> {
        this.getAllTotal(response.result.tenantId);
      });
    }else{
      this.setState({loader: false, loaderRecipientId: null});
    }
  }

  getAllTotal=(tenantId)=>{
    const {propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalPayments/GetAllTotal?propertyId='+ propertySelected.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_rental_payment_get_all_total',
      {tenantId},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_rental_payment_get_all_total(response){
    if(response.status && response.statuscode === 200){
      console.log('handle_get_rental_payment_get_all_total:', response);
      let tenantRemainingRentObject= response.result.find((remainingRent)=> remainingRent.tenantId === response.other.tenantId)
      this.setState({tenantRemainingRentObject: tenantRemainingRentObject !== undefined ? (tenantRemainingRentObject.expectedNetRent + tenantRemainingRentObject.expectedAdvancePayment) - (tenantRemainingRentObject.receivedNetRent + tenantRemainingRentObject.receivedAdvancePayment) : null}, ()=> this.getRents(response.other.tenantId));
    }
  }

  getRents = (id) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rents?offset=0&fetch=100&tenantId=' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_rents',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_get_rents(response){
    if(response.status && response.statuscode === 200){
      this.setState({rents: response.result}, ()=> setTimeout(() => {
        this.downloadPdf();
      }, 600));
    }else{
      this.setState({loader: false, loaderRecipientId: null});
    }
  }

  getBillingStatements=()=>{
    this.setState({
      loader: true,
      firstLoader: true
      //firstLoader: this.state.allRecipients.find((recipient)=> recipient.id === this.state.selectedRecipient)?.isPostEnabled ? true : false
    }, ()=> {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'billingStatements?utilityBillingId='+this.props.currentDataId,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetBillingStatements',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    });
  }

  handleGetBillingStatements(response){
    if(response.status && response.statuscode === 200){
        let recipient = this.state.selectedRecipientsList.find((recipient)=> recipient.id === this.state.selectedRecipient);
        this.setState({allBillingStatements: response.result.filter((billingStatement)=> this.state.selectedRecipientsList.find((recipient)=> recipient.tenantId === billingStatement.tenantId) !== undefined), selectedBillingStatement: recipient ? response.result.find((billingStatement)=> billingStatement.tenantId === recipient.tenantId) : null}, ()=> {
          //this.setState({loader: false}, ()=> 
          setTimeout(() => {
            if(!this.props.messageData.utilityBillingId){
              this.downloadPdf();
            }
          }, 2000)
          //);
        });
    }
  }

  getBillingStatementById=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'billingStatements/'+id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetBillingStatementById',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
    );
  }

  handleGetBillingStatementById(response){
    if(response.status && response.statuscode === 200){
        this.setState({selectedBillingStatement: response.result});
    }
  }

  getCostAllocationsForBillingStatements=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'costAllocations/forBillingStatement?utilityBillingId='+this.props.currentDataId+'&billingStatementId='+id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetCostAllocationsForBillingStatements',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
    );
  }

  handleGetCostAllocationsForBillingStatements(response){
    if(response.status && response.statuscode === 200){
        this.setState({selectedCostAllocations: response.result});
    }
  }

  downloadPdf=()=>{
    const {messageData, allRecipients} = this.props;
    const {downloadedPdfs, selectedBillingStatement, billingStatementsAlreadyDownloaded, allBillingStatements, selectedRecipientsList, selectedRecipient} = this.state;
    this.setState({loader: true});
    const data = new FormData();
    let html = document.getElementById(`messageDivToPrint`);
    console.log('html: ', html);
    let doc = document.implementation.createHTMLDocument("New Document");
    try {
      doc.body.appendChild(html);
      const blob = new Blob([doc.documentElement.innerHTML], {
        type: 'text/html'
      });
      
      const fileName = `messagingPreview-${this.props.propertySelected.name}-${new Date().valueOf()}}`;
      let file = new File([blob], fileName);
      file.originalname = fileName;
      console.log('downloadPdfFromServer: ', file);
      // var a = document.createElement("a");
      // document.body.appendChild(a);
      // let url = window.URL.createObjectURL(file);
      // a.href = url;
      // a.download = 'test.html';
      // a.click();
      // window.URL.revokeObjectURL(url);

      // this.downloadPdfFromServer(count+1);

      data.append('file', file);
      fetch('https://app.hellohousing.de/api/reporting-api/pdf',
        {
          method: 'POST',
          body: data,
          headers: {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        }).then((response)=> response.blob()
        .then(data => {
          
          //download progress
          var a = document.createElement("a");
          document.body.appendChild(a);
          console.log('data: ', data);
          let url = window.URL.createObjectURL(data);

          this.setState({messagePreviewPdf: url, firstLoader: false, loaderRecipientId: null, downloadedPdfs: downloadedPdfs.find((downloadedPdf)=> downloadedPdf.selectedRecipient === selectedRecipient) === undefined ? [...downloadedPdfs, {billingStatementId: selectedBillingStatement?.id, blob: data, pdfLink: url, selectedRecipient}] : downloadedPdfs}, ()=> {
            if(messageData.utilityBillingId){
              this.setState({billingStatementsAlreadyDownloaded: [...billingStatementsAlreadyDownloaded, selectedBillingStatement?.id]}, ()=>{
                let remainingBillingStatments= allBillingStatements.filter((billingStatement)=> billingStatementsAlreadyDownloaded.find((alreadyDownloadedBillingStatementId)=> alreadyDownloadedBillingStatementId === billingStatement.id) === undefined && billingStatement.id !== selectedBillingStatement?.id)
                console.log('remainingBillingStatments: ', remainingBillingStatments);
                if(remainingBillingStatments.length > 0){
                    this.setState({selectedBillingStatement: remainingBillingStatments[0], selectedRecipient: selectedRecipientsList.find((recipient)=> recipient.tenantId === remainingBillingStatments[0].tenantId)?.id, messagePreviewPdf: null, ubPdfUrl: null});
                }else{
                    this.setState({loader: false});
                }
              });
            }else{
              let remainingMessagesToDownlaod= selectedRecipientsList.filter((selectedRecipient)=> downloadedPdfs.find((downloadedPdf)=> selectedRecipient.id === downloadedPdf.selectedRecipient) === undefined);
              console.log('remainingMessagesToDownlaod: ', remainingMessagesToDownlaod, downloadedPdfs);
              if(remainingMessagesToDownlaod.length > 0){
                this.setState({selectedRecipient: remainingMessagesToDownlaod[0].id, messagePreviewPdf: null, ubPdfUrl: null}, ()=> setTimeout(() => {
                  this.downloadPdf();
                }, 1000));
              }else{
                  this.setState({loader: false});
              }
            }
          });

          
          // a.href = url;
          // a.download = 'test.pdf';
          // a.click();
          //window.URL.revokeObjectURL(url);
          //downloadStatus.textContent=`PDF-Dokumente erstellen ${count > this.state.pages ? this.state.pages : count} / ${this.state.pages}`;
          
        }));
    } catch (e) {
      console.log(e);
      this.setState({loader: false, loaderRecipientId: null, firstLoader: false});
    }
  }

  convertToBase64=(data)=>{
    try {
        var reader = new FileReader();
        reader.readAsDataURL(data); 

        const setState=(base64)=>{
        // this.setState({customImageBase64: base64 ? base64 : null});
        return base64;
        }
        
        reader.onloadend = function() {
        var base64data = reader.result;                
        console.log('base64data: ', base64data);
        setState(base64data);
        }
    } catch (error) {
        console.log('convertToBase64Error: ', error);
    }
  }

  generateDuplicateName(originalName, existingNames) {
    const baseName = originalName.trim();
    let newName = `${baseName}`;
    let counter = 1;
  
    // Check if the new name already exists
    while (existingNames.includes(newName)) {
      newName = `${baseName} ${counter}`;
      counter++;
    }
    console.log('generateDuplicateName: ', originalName, existingNames, newName);
    return newName;
  }

  downloadAllFiles = async () => {
    const { downloadedPdfs, downloadedBsPdfs, allBillingStatements, ubData, selectedRecipientsList } = this.state;
    const { messageData } = this.props;
    console.log('downloadedPdfs: ', downloadedPdfs, selectedRecipientsList);

    let zip = new JSZip();
    let fileNames= [];
    const filePromises = downloadedPdfs.map(async (data) => {
        let bs = allBillingStatements.find((billingStatement) => billingStatement.id === data.billingStatementId);
        let pdfFilesToMerge = downloadedBsPdfs.find((data1) => data1.billingStatementId === data.billingStatementId)
            ? [data.blob, downloadedBsPdfs.find((data1) => data1.billingStatementId === data.billingStatementId)?.blob]
            : [data.blob];
        console.log('pdfFilesToMerge: ', pdfFilesToMerge);

        // Convert each PDF Blob to an ArrayBuffer and merge them
        const pdfFilesCovertedToArrayBuffer = await Promise.all(
            pdfFilesToMerge.map((pdfFile) => this.convertBlobToArrayBuffer(pdfFile))
        );

        // Merge the PDFs and add to the zip
        const pdfBytes = await this.mergePdfs(pdfFilesCovertedToArrayBuffer);
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        let fileName = `${bs ? bs.tenantName : selectedRecipientsList.find((selectedRecipient) => selectedRecipient.id === data.selectedRecipient)?.name}`.replace(/\//g, '∕');
        fileName = this.generateDuplicateName(fileName, fileNames);
        fileNames.push(fileName);
        zip.file(fileName+'.pdf', blob);
    });

    // Wait for all files to be added to the zip
    await Promise.all(filePromises);

    // Generate the ZIP file and trigger download
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, messageData.templateType === 'RentalPayment'
        ? 'Mahnung wegen Zahlungsverzug Miete.zip'
        : messageData.utilityBillingId
            ? (ubData?.date ? `Betriebskostenabrechnung (${ubData?.date}).zip` : 'Betriebskostenabrechnung.zip')
            : 'Nachrichten.zip');

    // Optional: Toggle the state after download
    setTimeout(() => {
        this.props.toggle();
    }, 1000);
  };

  async convertBlobToArrayBuffer(blob) {
    console.log('convertBlobToArrayBuffer_2234: ', blob);
    const buf = await blob.arrayBuffer();
    return buf;
  }

  async mergePdfs(pdfsToMerge) {
    console.log('mergePdfs', pdfsToMerge);
    const mergedPdf = await PDFDocument.create();
    const font = await mergedPdf.embedStandardFont(StandardFonts.Helvetica);

  const createInnerPromise = async (arrayBuffer) => {
    const pdf = await PDFDocument.load(arrayBuffer);
    return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  };

  const outerPromise = pdfsToMerge.map((arrayBuffer) => {
    const innerPromise = createInnerPromise(arrayBuffer);
    return innerPromise;
  });

  const resultOuterPromise = await Promise.all(outerPromise);

  let count = 0;
  let totalPages = 0;
  resultOuterPromise.forEach((pageArray, i) => {
    pageArray.forEach((page, index) => {
      totalPages = totalPages + 1;
    })
  });
  resultOuterPromise.forEach((pageArray, i) => {
    pageArray.forEach((page) => {
    //const { width, height } = page.getSize();
    //page.drawText(`Seite ${count+1} von ${totalPages}`, {
    //     x: width - 75.5,
    //     y: height - 34, //height - 47,
    //     size: 6,
    //     color: rgb(0.4470588235294118, 0.4470588235294118, 0.5803921568627451),
    //   })

      //add footer
      // var text = `${this.props.accountData.addressCompany && (this.props.accountData.addressCompany + ', ')}${this.props.accountData.addressStreet && (this.props.accountData.addressStreet + ',')} ${(this.props.accountData.addressZipCode)} ${this.props.accountData.addressCity}`;
      // const textWidth = font.widthOfTextAtSize(text, 8);
      // this.props.currentSubscription?.subscriptionKey.split('_')[0] !== 'STARTER' && page.drawText(text, {
      //   x: page.getWidth() / 2 - textWidth / 2,
      //   y: height - (height-30),
      //   size: 8,
      //   color: rgb(0, 0, 0)//rgb(0.5, 0.5, 0.5)
      // })
      //
      mergedPdf.addPage(page);
      //count = count + 1;
    });
  });

  return (await mergedPdf.save()).buffer;
  }

  handleSelectRecipient=(recipientId)=>{
    const {allRecipients} = this.props;
    const {selectedRecipientsList} = this.state;

    if(selectedRecipientsList.find((selectedRecipientsListItem)=> selectedRecipientsListItem.id === recipientId) === undefined){
        this.setState({selectedRecipientsList: [...selectedRecipientsList, allRecipients.find((recipient)=> recipient.id === recipientId)]});
    }else{
        this.setState({selectedRecipientsList: selectedRecipientsList.filter((selectedRecipientsListItem)=> selectedRecipientsListItem.id !== recipientId)});
    }

    
  }

  startDownloadProcess=()=>{
    const {selectedRecipientsList} = this.state;
    const {messageData} = this.props;

    console.log('startDownloadProcess', messageData, selectedRecipientsList);

    this.setState({selectedRecipient: selectedRecipientsList[0].id}, ()=> (messageData.templateType === 'RentalPayment' && selectedRecipientsList[0].rentalPaymentId) ? this.getRentalPayment(selectedRecipientsList[0].rentalPaymentId) : messageData.utilityBillingId !== null ? this.getBillingStatements() : this.downloadPdf());
  }

  selectAllRecipients=()=>{
    const {allRecipients} = this.props;
    let {selectedRecipientsList} = this.state;

    selectedRecipientsList=[];
    allRecipients.map((recipient)=> selectedRecipientsList.push(recipient));

    this.setState({selectedRecipientsList});
  }

  handleCheckBoxSelect=(id)=>{
    let {selectedRecipientsList} = this.state;
    const {allRecipients} = this.props;

    if(selectedRecipientsList.find((selectedRecipient)=> selectedRecipient.id === id) === undefined){
        const selectedRecipient= allRecipients.find((recipient)=> recipient.id === id);
        if(selectedRecipient !== undefined){
            selectedRecipientsList.push(selectedRecipient);
            this.setState({selectedRecipientsList});
        }
    }else{
        this.setState({selectedRecipientsList: selectedRecipientsList.filter((selectedRecipient)=> selectedRecipient.id !== id)});
    }

  }

  tenantTableContent=(headers)=>{
    const { selectedRecipientsList, tenants } = this.state;
    const {rentalUnits, propertySelected, disableRecipientSelection, disableTenantsSelection, allRecipients} = this.props;
    console.log('selectedRecipients_456_123: ', selectedRecipientsList);
    return (
    
    <TableContainer style={{maxHeight: '280px'}}>
      <Table stickyHeader aria-label="sticky table" className='documents-list-table'>
        <TableHead>
          <TableRow>
            {headers.map((header)=>
            <TableCell className={`tooltips recipient-add-modal-table-header-${header.toLowerCase()}`} style={{paddingRight: header === 'Versand:' && 0}}>
                <span style={{whiteSpace: 'nowrap'}}>{header}</span>
            </TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody style={{borderCollapse: 'separate', borderSpacing: '8px', overflowY: 'scroll'}}>
            {allRecipients.length > 0 &&
            <TableRow style={{background: '#F7F7FA'}}>
            <TableCell>
              <Group spacing={10} noWrap>
                <Group spacing={10} noWrap style={{cursor: 'pointer'}}>
                    <span>Alle auswählen</span>
                </Group>
              </Group>
            </TableCell>
            <TableCell width={'10%'} style={{textAlign: 'center'}}>
                <Group position='center'>
                    <Checkbox
                    checked={selectedRecipientsList.length === allRecipients.length}
                    indeterminate={selectedRecipientsList.length > 0 && selectedRecipientsList.length !== allRecipients.length}
                    classNames={{input: 'tenants-list-checkbox-rental-income-input', inner: 'tenants-list-checkbox-rental-income-inner'}}
                    onChange={()=> {
                        if(selectedRecipientsList.length === allRecipients.length){
                            this.setState({selectedRecipientsList: []})
                        }else{
                            this.setState({selectedRecipientsList: allRecipients})
                        }
                    }}
                    styles={{ 
                        input: {
                            cursor: 'pointer'
                        }
                    }}
                    />
                </Group>
            </TableCell>
            </TableRow>}
            {allRecipients.length > 0 &&
            allRecipients
            .sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            .map((row)=>
            <TableRow style={{background: '#F7F7FA'}}>
                <TableCell>
                <Group spacing={10} noWrap>
                    <Group spacing={10} noWrap style={{cursor: 'pointer'}}>
                        <Group style={{background: '#454576', width: '22px', borderRadius: '120px', minWidth: '22px'}} position='center' align={'center'}>
                            <span style={{textTransform: 'capitalize', color: 'white'}}>{row.name[0]}</span>
                        </Group>
                        <span>{row.name}</span>
                    </Group>
                </Group>
                </TableCell>
                <TableCell width={'10%'} style={{textAlign: 'center'}}>
                    <Group position='center'>
                        <Checkbox
                        checked={selectedRecipientsList.find((selectedRecipient)=> selectedRecipient.id === row.id)}
                        classNames={{input: 'tenants-list-checkbox-rental-income-input', inner: 'tenants-list-checkbox-rental-income-inner'}}
                        onChange={()=> this.handleCheckBoxSelect(row.id)}
                        styles={{ 
                            input: {
                                cursor: 'pointer'
                            }
                        }}
                        />
                    </Group>
                </TableCell>
            </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>)
  }
  
  render() {
    const { open, toggle, className, allRecipients, messageData, senderAddress, senderEmail, billingPeriod, rentalUnits, propertySelected, accountData, isMobile, profile, currentSubscription} = this.props;
    const { imgScale, selectedRecipient, selectedView, selectedBillingStatement, loader, meterReadingsSwitch, compositionOfHeatingCostsSwitch, costSplitWarmWaterAndHeatingSwitch, splitBaseConsumptionCostsSwitch, vatSwitch, laborCostSwitch, energyConsumptionCO2EmissionsSwitch, compareHeatingConsumptionBarChartSwitch, energyLevelsInfoSwitch, arbitraryBoardInfoSwitch, energyAdviceCenterInfoSwitch, co2TaxInfoSwitch, ubData, messagePreviewPdf, ubPdfUrl, rentalPayment, rents, tenantRemainingRentObject, isPreTaxInfoHidden, firstLoader, loaderRecipientId, billingStatementsAlreadyDownloaded, allBillingStatements, downloadedPdfs, downloadedBsPdfs, selectedRecipientsList, dropdownIcon, initiallyOpened, dropdownOptions } = this.state;

    console.log('rentalUnits: ', rentalUnits);
    console.log('selectedBillingStatement_565: ', selectedBillingStatement, allRecipients);
    console.log('ubData: ', ubData);
    console.log('propertySelected: ', propertySelected);

    console.log('ubPdfUrl: ', ubPdfUrl);

    console.log('selectedBillingStatement_64637: ', selectedBillingStatement, billingStatementsAlreadyDownloaded, allBillingStatements, downloadedPdfs);

    console.log('tenantRemainingRentObject: ', tenantRemainingRentObject);

    console.log('selectedRecipient_234: ', selectedRecipient);

    console.log('testingOneScenario: ', downloadedPdfs, downloadedBsPdfs);
    return (
      <>
        <CustomModal
          heading={(loader === false || loader == true) ? 'Dokumente erstellen' : 'Alle herunterladen (PDF)'}
          headingClassName='bulk-download-messages-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
          styleBody={{minHeight: loader === null ? '300px' : '40px'}}
        >
            <Stack style={{minHeight: 'inherit'}} justify={'space-between'}>
                <Stack style={{minHeight: 'inherit'}}>
                    {loader ?
                    <Stack style={{minHeight: 'inherit'}} justify={'center'}>
                        <Group style={{width: '100%'}} position={'center'} spacing={4} noWrap>
                            <span style={{whiteSpace: 'nowrap', fontWeight: '400'}}>Dokumente erstellen: <span style={{color: '#5555FF'}}>{`${downloadedPdfs.length >= selectedRecipientsList.length ? selectedRecipientsList.length : (downloadedPdfs.length+1)} von ${selectedRecipientsList.length}`}</span></span>
                            <Loader variant='dots' size={10} style={{marginTop: '8px'}} color='#5555FF' />
                        </Group>
                    </Stack>        
                    :
                    loader !== null &&
                    <span style={{fontWeight: '400'}}>Die Dokumente stehen nun bereit. Klicken Sie auf Speichern, um die Dokumente zu herunterzuladen.</span>}
                    {loader === null &&
                    <Stack>
                        <Group>
                            <span style={{fontWeight: '400'}}>Bitte wählen Sie aus, welche Abrechnungen Sie herunterladen wollen.</span>
                        </Group>
                        <Stack className='preview-recipients-list' spacing={5}>
                            {/* {allRecipients.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                            .map((recipient)=>
                            <Group className={`preview-recipients-list-item ${selectedRecipientsList.find((selectedRecipientListItem)=> selectedRecipientListItem.id === recipient.id) && 'preview-selected-recipient'}`} onClick={()=> this.handleSelectRecipient(recipient.id)} position='apart'>
                                    <Group spacing={8} noWrap>
                                        <Group style={{background: '#454576', width: '22px', borderRadius: '120px', minWidth: '22px'}} position='center' align={'center'}>
                                            <span style={{textTransform: 'capitalize', color: 'white'}}>{recipient.name[0]}</span>
                                        </Group>
                                        <span>{recipient.name}</span>
                                    </Group>
                            </Group>
                            )} */}

                            {/* <Select
                            data={dropdownOptions.filter((recipient)=> selectedRecipientsList.find((selectedRecipientListItem)=> selectedRecipientListItem.id === recipient.id) === undefined)}
                            className={'multi-select-messages-tenants'}
                            id={'multi-select-messages-tenants'}
                            classNames={{
                                input: 'recipients-selection-input'
                            }}
                            
                            //limit={20}
                            //ref={this.myRef}
                            //valueComponent={Value}
                            valueComponent={()=> null}
                            itemComponent={Item}
                            searchable
                            onSearchChange={(e)=> console.log('onSearchChange: ', e)}
                            value={null}
                            placeholder="Suche nach Empfängern"
                            //creatable={true}
                            getCreateLabel={(query) => `+ Erstellen ${query}`}
                            //onCreate={(query) => this.setState({openAddRecipientModal: true})}
                            //onClick={()=> this.setState({openRecipientsModal: true})} 
                            icon={<img src={SearchIcon}/>}
                            rightSection={<img src={dropdownIcon}/>}
                            onDropdownOpen={()=> this.setState({dropdownIcon: Up})}
                            onDropdownClose={()=> this.setState({dropdownIcon: Down})}
                            style={{width: '100%'}}
                            initiallyOpened={initiallyOpened}
                            styles={{
                                dropdown: {
                                display: 'block'
                                },
                                item: {
                                color: '#0e0e1d',
                                fontSize: '14px',
                                fontWeight: '500',
                                fontFamily: 'sans-serif'
                                }
                            }}
                            onChange={(value)=> {
                                console.log('onChange: ', value);

                                if(value === 'selectAll'){
                                this.selectAllRecipients();
                                } else {
                                    this.handleSelectRecipient(value)
                                }
                            }}
                            {...this.props} 
                            zIndex={9999999}
                            /> */}
                            <Stack>
                                <Group style={{maxHeight: '300px'}}>
                                    {this.tenantTableContent(['Name', 'Auswahl'])}
                                </Group>
                            </Stack>
                        </Stack>
                    </Stack>}
                </Stack>
                <Group style={{width: '100%', marginTop: '20px'}} position='apart'>
                    <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className={'gray-btn'}
                    onClick={()=> toggle()}
                    />
                    </div>

                    {(loader === false || loader == true) &&
                    <div>
                    <ColorButton
                    text={'Speichern'}
                    className={'purple-btn'}
                    onClick={()=> this.downloadAllFiles()}
                    disabled={loader === true}
                    />
                    </div>}

                    {(loader === null) &&
                    <div>
                    <ColorButton
                    text={'Herunterladen'}
                    className={'purple-btn'}
                    disabled={selectedRecipientsList.length === 0}
                    onClick={()=> this.startDownloadProcess()}
                    />
                    </div>}
                </Group>
            </Stack>
        </CustomModal>
        {messagePreviewPdf === null && messageData && //messageData.utilityBillingId ? (messagePreviewPdf === null && ubPdfUrl === null) : messagePreviewPdf === null &&
        <div id='message-divs-to-print' style={{visibility: 'hidden', marginTop: 1000}}>
          {(messageData.utilityBillingId ? selectedBillingStatement : messageData.templateType === 'RentalPayment' ? (rentalPayment !== null && rents.length > 0 && tenantRemainingRentObject !== null) : true) && 
          <div id='messageDivToPrint'>
            <Stack>
                <PreviewMessagePdf 
                isMobile={isMobile} 
                messageData={messageData} 
                selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} 
                senderAddress={senderAddress} 
                billingPeriod={billingPeriod} 
                rentalUnits={rentalUnits} 
                rentalPayment={rentalPayment} 
                rents={rents} 
                propertySelected={propertySelected} 
                selectedBillingStatement={selectedBillingStatement} 
                accountData={accountData} 
                profile={profile} 
                tenantRemainingRentObject={tenantRemainingRentObject}
                vatSwitch={vatSwitch}
                isPreTaxInfoHidden={isPreTaxInfoHidden}
                currentSubscription={currentSubscription}
                />  
            </Stack>
          </div>}
          {messageData.utilityBillingId && selectedBillingStatement &&
          <div>
            <UtilityBillingTemplate2 
            id={selectedBillingStatement?.id}
            messagePreview={true} 
            meterReadingsSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : meterReadingsSwitch} 
            compositionOfHeatingCostsSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : compositionOfHeatingCostsSwitch} 
            costSplitWarmWaterAndHeatingSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : costSplitWarmWaterAndHeatingSwitch} 
            splitBaseConsumptionCostsSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : splitBaseConsumptionCostsSwitch} 
            vatSwitch={vatSwitch} 
            laborCostSwitch={laborCostSwitch} 
            energyConsumptionCO2EmissionsSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyConsumptionCO2EmissionsSwitch} 
            compareHeatingConsumptionBarChartSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : compareHeatingConsumptionBarChartSwitch} 
            energyLevelsInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyLevelsInfoSwitch} 
            arbitraryBoardInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : arbitraryBoardInfoSwitch}
            energyAdviceCenterInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyAdviceCenterInfoSwitch}
            co2TaxInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : ((dayjs(ubData?.billingPeriod?.endDate).year() >= 2023) && ubData?.isCO2TaxActivated) ? co2TaxInfoSwitch : false}
            heatingFilter={propertySelected.propertyType === 'CONDOMINIUM' ? false : ubData?.heatingCostsActivated}
            isPreTaxInfoHidden={isPreTaxInfoHidden}
            getPdfUrl={(link, blob)=> this.setState({ubPdfUrl: link, downloadedBsPdfs: [...downloadedBsPdfs, {billingStatementId: selectedBillingStatement?.id, blob, pdfLink: link}], loaderRecipientId: null}, ()=> {
              if(messageData.utilityBillingId){
                this.downloadPdf();
              }
            })} />
          </div>}
        </div>}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    propertySelected: state.generalReducer.property,
    profile: state.generalReducer.profile,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    accountData: state.generalReducer.accountData,
    currentSubscription: state.generalReducer.currentSubscription
  };
};

export default connect(mapStateToProps)(BulkDownloadForMessageModal);

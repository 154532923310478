import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SidebarNav, PageContent, PageAlert, Page, MobileBottomNav, EmptyStateContainer } from '../neoverv';
// import { Chat } from '../neoverv'
import Logo from '../assets/images/module/general/Logo.svg';
import Avatar from '../assets/images/module/general/UserAccountBlue.svg';
import RedCross from '../assets/images/module/general/RedCross.svg';
import GreenTick from '../assets/images/module/general/GreenTick.svg';
//import avatar1 from '../assets/images/avatar1.png';
import nav from '../views/nav/main';
import RoutesModuleGeneral from '../routes/module/general';
import RoutesModulePartner from '../routes/module/partner';
import RoutesModuleMeter from '../routes/module/meter';
import RoutesModuleMessaging from '../routes/module/messaging';
import RoutesModuleDocuments from '../routes/module/documents';
import RoutesModulePayment from '../routes/module/payment';
import RoutesModuleProperty from '../routes/module/property';
import RoutesModuleReceipt from '../routes/module/receipt';
import RoutesModuleUser from '../routes/module/user';
import RoutesModuleTenantPortal from '../routes/module/tenantPortal';
import RoutesModuleUtilityBilling from '../routes/module/utility-billing';
import RoutesModuleProfile from '../routes/module/profile';
import ContextProviders from '../neoverv/components/Module/General/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../neoverv/helpers/handleTabAccessibility';
import SideBarDropDown from '../neoverv/components/Module/General/SidebarNav/components/SideBarDropDown';
import Autoload from '../neoverv/Autoload';
import { connect } from 'react-redux';
import GlobalServices from '../neoverv/helpers/services/GlobalServices';
import {
  setProfileData,
  changeCurrentProperty,
  setPropertyList,
  editProfileData,
  setFuelTypesList,
  setSubscriptionData,
  setCurrentSubscriptionData,
  setNextSubscriptionData,
  setAccountData,
  setCompanyLogo,
  setCurrentSubscriptionDataWithPaymentError,
  setFiles,
  setCurrentFolderId,
  setSharedFolder,
  setRefreshDocumentsData,
  setTenantFiles,
  setTenantCurrentFolderId,
  setRefreshTenantDocumentsData,
  setReceiptFiles,
  setReceiptCurrentId,
  setRefreshReceiptDocumentsData,
  setAddTenantDocument,
  setAddFileModal,
  setAddReceiptModal,
  setFileType,
  setRefreshMessagesData,
  setPublishMessageId,
  setSubscriptionMainLoader,
  resetData,
  setTestSubscriptionComplete,
  setOpenReceiptDuplicateProgressModal,
  setSelectedReceiptForDuplication,
  setShowAfterSignIn,
  setIsTesting
} from '../redux/action/General/generalActions';
import moment from 'moment';
import PropertyImage from '../assets/images/module/general/house-log.png';
import Spinner from 'react-bootstrap/Spinner';

import AppSidebar from "../components/AppSidebar";
import { Box, Grid, Group, Loader, Notification, Stack } from '@mantine/core';
import UtilityBillingTemplate from '../views/pages/Module/UtilityBilling/UtilityBillingTemplate';
import Dashboard from '../routes/dashboard';
import Setting from '../neoverv/config/Setting';
import MeterReadingsTemplate from '../views/pages/Module/Meter/MeterReadingsTemplate';
import PaymentTemplate from '../views/pages/Module/General/profile/PaymentTemplate';
import PaymentTemplateSuccess from '../views/pages/Module/General/profile/PaymentTemplateSuccess';
import InvoiceTemplatePdf from '../views/pages/Module/General/profile/InvoiceTemplatePdf';
import { hideNotification, showNotification } from '@mantine/notifications';
import MessagingTemplate from '../views/pages/Module/Messaging/MessagingTemplate';
import UtilityBillingTemplate2 from '../views/pages/Module/UtilityBilling/UtilityBillingTemplate2';
import TenantsPdfTemplate from '../views/pages/Module/User/TenantsPdfTemplate';
import RentalsPdfTemplate from '../views/pages/Module/User/RentalsPdfTemplate';
import TenantAdministration from '../views/pages/Module/User/TenantAdministration';
import RentalPaymentsPdfTemplate from '../views/pages/Module/User/RentalPaymentsPdfTemplate';
import ControlSheetPdfTemplate from '../views/pages/Module/UtilityBilling/ControlSheetPdfTemplate';
import TaxDeclarationPdf from '../views/pages/Module/Receipt/TaxDeclarationPdf';
// import TransactionsPdfExport from '../views/pages/Module/Receipt/TransactionsPdfExport';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TransactionsPdfExport from '../views/pages/Module/Receipt/TransactionsPdfExport';
import ReceiptDuplicateProgressModal from '../neoverv/components/Module/General/CustomModal/ReceiptDuplicateProgressModal';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;
const SMALLER_MOBILE_SIZE = 500;
var lang = Setting[Setting.language_default];
var { checkProperty, loader } = Autoload.MainHelper;
var myWorker = null;
var myTenantWorker = null;
var myReceiptWorker = null;
var myMessageWorker = null;
class DashboardLayout extends Component {  
  constructor(props) {
    super(props);

    // if (window.location.href.indexOf('login') != -1) {
    //   Autoload.Utils.clear_session_data();
    // }

    Autoload.Utils.check_auth(this);
    this.getRolePermission();
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      isTablet: window.innerWidth <= TABLET_SIZE,
      isSmallerMobile: window.innerWidth <= SMALLER_MOBILE_SIZE, 
      showChat1: true,
      showDashboard: false,
      skeletonPropertyDropdown: false,
      giveAppAccess: false,
      uploadedFiles: [],
      uploadNotification: false,
      uploadedTenantFiles: [],
      uploadType: null,
      uploadedReceiptFiles: [],
      uploadMultipleReceiptsNotification: false
    };
  }

  getRolePermission=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.sign_in_bas_url + 'rolePermissions?permission=View:PropertyOwnerPortal',//TenantPortal
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleRolePermissions',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handleRolePermissions(response){
    var rolePermissionEmptyState = document.getElementById('permission-denied');
    if(response.status){
      if(response.result){
        this.setState({giveAppAccess: true});
        rolePermissionEmptyState.style.display = 'none';
        GlobalServices._getPropertiesByAccountId(this, 'handlePropertiesResponse', this.props.profile);
        this._getFuelTypes();
        this.getAccountById();
      }else{
        this.setState({giveAppAccess: false});
        rolePermissionEmptyState.style.display = 'flex';
      }
    }else{
      this.setState({giveAppAccess: true});
      rolePermissionEmptyState.style.display = 'none';
    }
  }

  getAccountById=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + this.props.profile.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_account_by_id',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_account_by_id(response){
    if(response.status && response.statuscode === 200){
      this.props.setAccountData(response.result);
      this.props.setCompanyLogo(Autoload.MainHelper.checkProperty(response.result, 'useOwnLogo'));
    }
    this.getsubscriptionsAssignments();
  }

  handlePropertiesResponse(response) {
    const {profile, properties, currentSubscription} = this.props;
    this.setState({skeletonPropertyDropdown: false});
    let propertyData = [];
    var loader = document.getElementById('hello-housing-loader');

    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
      window.Intercom('shutdown');
    }

    if (response.status === true && response.statuscode === 200) {
      response.result.map((tem, i) => {
        propertyData.push({...tem,
          id: Autoload.MainHelper.checkProperty(tem, 'id'),
          propertyName: Autoload.MainHelper.checkProperty(tem, 'name'),
          name: Autoload.MainHelper.checkProperty(tem, 'name'),
          propertyAddress: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(tem, 'propertyAdress'),
            'streetno'
          ),
          propertyCity: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(tem, 'propertyAdress'),
            'city'
          ),
          propertyZipCode: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(tem, 'propertyAdress'),
            'zipCode'
          ),
          propertyNoOfUnits: Autoload.MainHelper.checkProperty(tem, 'numberOfRentalUnits'),
          propertyAreaOfUnits: Autoload.MainHelper.checkProperty(tem, 'totalRentingSpace'),
          cutOffDate: Autoload.MainHelper.checkProperty(tem, 'cutOffDate'),
          // `${Autoload.MainHelper.checkProperty(
          //   Autoload.MainHelper.checkProperty(tem, 'cutOffDate'),
          //   'day',
          //   '31'
          // )} ${moment()
          //   .month(
          //     parseInt(
          //       Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(tem, 'cutOffDate'), 'month', '12')
          //     ) - 1
          //   )
          //   .format('MMMM')}`
          //   ,
          heatingType: Autoload.MainHelper.checkProperty(tem, 'heatingType'),
          heatingTemp: Autoload.MainHelper.checkProperty(tem, 'heatingTemperature'),
          heatingTemperature: Autoload.MainHelper.checkProperty(tem, 'heatingTemperature'),
          consumptionValue: Autoload.MainHelper.checkProperty(tem, 'consumptionShare', 0),
          consumptionShare: Autoload.MainHelper.checkProperty(tem, 'consumptionShare', 0),
          energyFuelType: Autoload.MainHelper.checkProperty(tem, 'energyFuelType'),
          propertyType: Autoload.MainHelper.checkProperty(tem, 'propertyType'),
          totalRentingSpace: Autoload.MainHelper.checkProperty(tem, 'totalRentingSpace'),
          numberOfRentalUnits: Autoload.MainHelper.checkProperty(tem, 'numberOfRentalUnits'),
          centralWarmWaterAllocationKey: Autoload.MainHelper.checkProperty(tem, 'centralWarmWaterAllocationKey'),
          meterReadingConducted: Autoload.MainHelper.checkProperty(tem, 'meterReadingConducted'),
          pictureLocation: Autoload.MainHelper.checkProperty(tem, 'pictureLocation'),
          receiptEmail: Autoload.MainHelper.checkProperty(tem, 'receiptEmail'),
          meterReadingsEmail: Autoload.MainHelper.checkProperty(tem, 'meterReadingsEmail'),
          accountId: Autoload.MainHelper.checkProperty(tem, 'accountId'),
          totalOccupantsCount: Autoload.MainHelper.checkProperty(tem, 'totalOccupantsCount'),
          occupantsConfiguration: Autoload.MainHelper.checkProperty(tem, 'occupantsConfiguration'),
          isConsumptionSectionHidden: Autoload.MainHelper.checkProperty(tem, 'isConsumptionSectionHidden'),
          isDocumentSectionHidden: Autoload.MainHelper.checkProperty(tem, 'isDocumentSectionHidden'),
          isMessageSectionHidden: Autoload.MainHelper.checkProperty(tem, 'isMessageSectionHidden')
        });
      });

      // response && response.result.map((property) => {
      //   Autoload.HttpRequestAxios.HttpRequest(
      //     Autoload.Setting.property_api_base_url + 'properties/' + property.id + '/picture',
      //     'GET',
      //     null,
      //     null,
      //     false,
      //     true,
      //     false,
      //     this,
      //     '_getAllPropertyPictureResponse',
      //     { propertyData: property },
      //     false,
      //     '',
      //     {
      //       'Content-Type': 'application/json',
      //       Authorization: Autoload.Utils.getAuthToken(),
      //     }
      //   );
      // });
    }
    if (this.props.propertySelected && Object.keys(this.props.propertySelected).length === 0 && propertyData.length !== 0) {
        this.props.changeCurrentProperty(currentSubscription ? (currentSubscription?.subscriptionName === 'Starter' ? propertyData[propertyData.length - 1] : propertyData[0]) : propertyData[propertyData.length - 1]);
        this.setState({showDashboard: true});
    } else {
      this.setState({showDashboard: true});
      propertyData.find((property)=> property.id === this.props.propertySelected.id && this.props.changeCurrentProperty(property));
      //loader && (loader.style.display = 'none');
    }
    if(propertyData.length !== 0){
      this.props.setPropertyList(propertyData);
    }
    console.log('propertyData: ', propertyData);
    window.Intercom("update", {
      api_base: "https://api-iam.intercom.io",
      app_id: "vvrs5bx3",
      'Number of properties': propertyData.length.toString(),
      'Number of condominiums': propertyData.filter((property)=> property.propertyType === 'CONDOMINIUM').length.toString(),
      Environment: window.location.hostname.includes('app') ? 'Production' : 'Staging'
    });

    let lastRoute= localStorage.getItem('lastRoute');
    console.log('lastRoute: ', lastRoute);
    lastRoute && this.props.history.push(lastRoute);
    localStorage.removeItem('lastRoute');
  }

  getsubscriptionsAssignments = async () => {
    const userAccountID = this.props.profile.accountId;
    this.props.setSubscriptionMainLoader(true);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_profile_subscription_api_baseurl + 'subscriptionAssignments?accountId=' + userAccountID,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_subscriptions_Assignments_details',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_subscriptions_Assignments_details(response) {
    const {accountData} = this.props;
    console.log('profile ssubscription api', response);
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      let subscriptionData= response.result.map((data, index) => {
        return {
          subscriptionRenewDate: moment(data.nextRenewalDate).format('DD.MM.YYYY'),
          subscriptionAmount: checkProperty(checkProperty(data, 'subscriptionType'), 'price'),
          subscriptionBilling: checkProperty(checkProperty(data, 'subscriptionType'), 'billing'),
          subscriptionKey: checkProperty(checkProperty(data, 'subscriptionType'), 'subscriptionKey'),
          limitProperties: checkProperty(checkProperty(data, 'subscriptionType'), 'limitProperties'),
          limitRentalUnits: checkProperty(checkProperty(data, 'subscriptionType'), 'limitRentalUnits'),
          subscriptionName: checkProperty(checkProperty(data, 'subscriptionType'), 'name'),
          subscriptionTypeId: checkProperty(checkProperty(data, 'subscriptionType'), 'subscriptionTypeId'),
          testPeriod: checkProperty(checkProperty(data, 'subscriptionType'), 'testPeriod'),
          type:checkProperty(checkProperty(data, 'subscriptionType'), 'type'),
          subscriptionStatus: checkProperty(data, 'subscriptionStatus'),
          testPeriodEnd: checkProperty(data, 'testPeriodEndDate'),
          subscriptionEndDate: moment(data.testPeriodEndDate).format('DD.MM.YYYY'),
          subscriptionAssignment: checkProperty(data, 'id'),
          nextPaymentDue: moment(data.nextPaymentDate).format('DD.MM.YYYY'),
          cancellatinoDate: moment(data.cancelationDate).format('DD.MM.YYYY'),
          cancellationDateWithoutFormat: data.cancelationDate,
          startDate: moment(data.startDate).format('DD.MM.YYYY'),
          possibleCancellationDate: moment(data.nextPaymentDate).subtract(1, 'days').format('DD.MM.YYYY'),
        };
      });
      let currentSubscription = subscriptionData.find((subs)=> subs.subscriptionStatus === 'ACTIVE' ? subs.subscriptionKey === 'PROFESSIONAL_TESTING_12_2024' ? dayjs().isBefore(dayjs(subs.testPeriodEnd)) : moment(subs.startDate, 'DD.MM.YYYY').format('YYYY-MM-DDT00:00:00') <= moment().format('YYYY-MM-DDT00:00:00') : subs.subscriptionStatus === 'CANCELLED' ? moment(subs.nextPaymentDue, 'DD.MM.YYYY').format('YYYY-MM-DDT00:00:00') > moment().format('YYYY-MM-DDT00:00:00') : (subs.subscriptionStatus === 'SWITCHED' && subs.subscriptionKey === 'PROFESSIONAL_TESTING_12_2024') ? dayjs().isBefore(dayjs(subs.testPeriodEnd)) : null);
      let nextSubscription = subscriptionData.find((subs)=> subs.subscriptionStatus === 'ACTIVE' && moment(subs.startDate, 'DD.MM.YYYY').format('YYYY-MM-DDT00:00:00') > moment().format('YYYY-MM-DDT00:00:00'));
      this.props.setSubscriptionData(subscriptionData);

      console.log('accountData: ', this.props.accountData);
      this.props.setCurrentSubscriptionData(currentSubscription !== undefined ? (((currentSubscription?.subscriptionStatus === 'ACTIVE' || currentSubscription?.subscriptionStatus === 'CANCELLED') && (this.props.accountData?.accountPaymentStatus === 'PAYMENT_ERROR')) ? null//subscriptionData.find((subs)=> subs.subscriptionName === 'Starter') 
      : currentSubscription) : 
      null);//subscriptionData.find((subs)=> subs.subscriptionName === 'Starter'));
      this.props.setNextSubscriptionData(nextSubscription !== undefined ? nextSubscription : null);
      let isTesting= response.result.find((subscriptionAssignment)=> subscriptionAssignment?.subscriptionType?.subscriptionTypeId === '97f226d5-a137-48e6-aa9a-605d4488dec7' && (dayjs().isBefore(dayjs(subscriptionAssignment?.testPeriodEndDate)) || dayjs().isSame(dayjs(subscriptionAssignment?.testPeriodEndDate))));
      let hasTested= response.result.find((subscriptionAssignment)=> subscriptionAssignment?.subscriptionType?.subscriptionKey.includes('2023')	|| subscriptionAssignment?.subscriptionType?.subscriptionTypeId === '97f226d5-a137-48e6-aa9a-605d4488dec7' && dayjs().isAfter(dayjs(subscriptionAssignment?.testPeriodEndDate)));
      this.props.setTestSubscriptionComplete(hasTested ? true : false);
      this.props.setIsTesting(isTesting ? true : false);
      //this.props.setCurrentSubscriptionData(currentSubscription !== undefined ? currentSubscription : subscriptionData.find((subs)=> subs.subscriptionName === 'Starter'));
      if((currentSubscription !== undefined && currentSubscription !== null || currentSubscription?.subscriptionKey !== 'PROFESSIONAL_TESTING_12_2024') && (currentSubscription?.subscriptionStatus === 'ACTIVE' || currentSubscription?.subscriptionStatus === 'CANCELLED') && this.props.accountData.accountPaymentStatus === 'PAYMENT_ERROR'){
        if(currentSubscription?.subscriptionStatus === 'ACTIVE' || nextSubscription){
          showNotification({
            message: <span>Bei der Zahlungsabwicklung Ihres Abonnements ist ein Fehler aufgetreten. Ihr Zugriff auf hellohousing ist derzeit eingeschränkt. Bitte <span onClick={()=> this.props.history.push('/profile/paymentDetails/')} style={{color: '#5555FF', cursor: 'pointer'}}>reativieren Sie die Zahlungsmethode</span>, um den Zugriff wieder herzustellen.</span>,
            disallowClose: true,
            icon: <img src={RedCross} alt='red-cross'/>,
            autoClose: false,
            id: 'paymentErrorMessage',
          });
        }else{
          hideNotification('paymentErrorMessage');
        }
        this.props.setCurrentSubscriptionDataWithPaymentError(currentSubscription !== undefined ? currentSubscription : null//subscriptionData.find((subs)=> subs.subscriptionName === 'Starter')
        );
      }else{
        hideNotification('paymentErrorMessage');
        this.props.setCurrentSubscriptionDataWithPaymentError(null);
      }
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        'Current Plan': currentSubscription !== undefined ? currentSubscription?.subscriptionStatus === 'CANCELLED' ? 'CANCELLED' : currentSubscription?.subscriptionKey : 'No ACTIVE or CANCELLED Subscription Assigned'
      });
      //AWIN hit
      if(currentSubscription?.subscriptionStatus === 'ACTIVE'){
        console.log('inside', dayjs().isAfter(dayjs(currentSubscription?.testPeriodEnd)), (currentSubscription?.subscriptionName === 'Premium' || currentSubscription?.subscriptionName === 'Professional'));
        if(dayjs().isAfter(dayjs(currentSubscription?.testPeriodEnd)) && (currentSubscription?.subscriptionName === 'Premium' || currentSubscription?.subscriptionName === 'Professional') && accountData.awinUpdatedOn === '0001-01-01T00:00:00'){
          console.log('inside');
          window.dataLayer.push({ 
            event: currentSubscription?.subscriptionName === 'Premium' ? 'awinPremium' : currentSubscription?.subscriptionName === 'Professional' && 'awinProfessional',
            apiData: null
          });
          dayjs.extend(utc);
          this.putAccountById({...accountData, awinUpdatedOn:	dayjs.utc().format('YYYY-MM-DDTHH:mm:ss')})
        }
      }
    }
    setTimeout(() => {
      this.props.setSubscriptionMainLoader(false);  
    }, 1500);
    this.getDocumentsStatusByAccountId();
  }

  putAccountById=(payload)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + this.props.profile.accountId,
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handle_put_account_by_id',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_put_account_by_id(response){
    if(response.status && response.statuscode === 200){
      
    }
  }

  _getAllPropertyPictureResponse = (response) => {
    // console.log(response, '_getAllPropertyPictureResponse');
    if (response.result != 0) {
      let updatedPicturePropertyData = {
        ...response.other.propertyData,
        fileName: response.result === null ? PropertyImage : response.result,
      };
      // console.log('updatedPicturePropertyData',updatedPicturePropertyData)
      if (updatedPicturePropertyData.id === this.props.propertySelected.id) {
        this.props.changeCurrentProperty(updatedPicturePropertyData);
      }
      //console.log(updatedPicturePropertyData, 'updated one');
      this.props.editProfileData(updatedPicturePropertyData);
    }
  };

  handleResize = () => {

    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: true, isMobile: true });
    } else {
      this.setState({ sidebarCollapsed: false, isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }

    if (window.innerWidth <= SMALLER_MOBILE_SIZE) {
      this.setState({ isSmallerMobile: true });
    } else {
      this.setState({ isSmallerMobile: false });
    }
    
  };

  componentDidMount() {
    const {profile, properties} = this.props;
    myWorker = new Worker("worker.js");
    myTenantWorker = new Worker("tenantDocumentsWorker.js");
    myReceiptWorker = new Worker("receiptDocumentsWorker.js");
    myMessageWorker = new Worker("messageWorker.js");
    this.setState({skeletonPropertyDropdown: true});
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
    console.log('propertyData: ', profile);
    const crypto = require('crypto');

    const secretKey = process.env.REACT_APP_INTERCOM_SECRET_KEY;
    const userIdentifier = profile.id.toString(); // user's id

    const hash = crypto.createHmac('sha256', secretKey).update(userIdentifier).digest('hex');
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "vvrs5bx3",
      name: `${profile.firstName} ${profile.lastName}`, // Full name
      email: profile.email, // Email address
      user_id: profile.id,
      'Account Id': profile.accountId,
      'Number of properties': properties.length.toString(),
      'Number of condominiums': properties.filter((property)=> property.propertyType === 'CONDOMINIUM').length.toString(),
      Environment: window.location.hostname.includes('app') ? 'Production' : 'Staging',
      user_hash: hash
    });
    window.Intercom("update");

    this.getAllUsersByAccountId();
  }

  componentDidUpdate(prevProps){
    const {addFileModal, files, currentFolderId, propertySelected, setFiles, setCurrentFolderId, setRefreshDocumentsData, tenantFiles, setRefreshTenantDocumentsData, receiptFiles, setAddReceiptModal, setAddTenantDocument, receiptCurrentId, setReceiptCurrentId, setRefreshReceiptDocumentsData, setReceiptFiles, setTenantFiles, setSharedFolder, sharedFolder, history, fileType, referencedObjectId, publishMessageId, setRefreshMessagesData} = this.props;
    const {uploadedFiles, uploadNotification, uploadedTenantFiles, uploadedReceiptFiles} = this.state;
    console.log('this.props.addTenantDocument: ', this.props.addTenantDocument, this.props.addFileModal);
    const setState=(uFiles, field)=>{
      let uploadedFiles = this.state[field];
      uploadedFiles.push(uFiles);
      this.setState({[field]: uploadedFiles});
    }
    const setUploadType =(uploadType)=>{
      this.setState({uploadType});
    }
    const setUploadNotification=()=>{
      this.setState({uploadNotification: !this.state.uploadNotification});
    }

    const setUploadMultipleReceiptsNotification=()=>{
      this.setState({uploadMultipleReceiptsNotification: true});
    }

    
    console.log('history: ', history.location, prevProps.history.location);

    console.log('window.Worker: ', myMessageWorker);

    if(myWorker === null){
      myWorker = new Worker("worker.js");
    }
    if(myTenantWorker === null){
      myTenantWorker = new Worker("tenantDocumentsWorker.js");
    }
    if(myReceiptWorker === null){
      myReceiptWorker = new Worker("receiptDocumentsWorker.js");
    }
    if(myMessageWorker === null){
      myMessageWorker = new Worker("messageWorker.js");
    }
    // if(propertySelected && propertySelected.propertyType === 'CONDOMINIUM'){
    //   if(!history.location.pathname.includes('type=condo')){
    //     history.replace(history.location.pathname, `${history.location.pathname}?type=condo`);
    //   }
    // }

    let publishMessageButton = document.getElementById('publishMessage');
    const putPublishMessageById = ()=> this.putPublishMessageById();
      if(window.Worker){
        if(publishMessageButton !== null){
          publishMessageButton.onclick = function() {
            console.log('publishMessageButton: onclick_working');
            //setTimeout(() => {
              // const token= Autoload.Utils.getAuthToken();
              // try{
              //   myMessageWorker.postMessage({publishMessageId, token});
              // }catch(e){
              //   console.log('myMessageWorker: ', e);
              // }
            //}, 1000);
            putPublishMessageById();
          }
        }
        myMessageWorker.onmessage = function(e) {
          console.log('message received from worker', e);
          // if(e.data.response.result){
            if(window.location.href.includes('/messaging')){
              setRefreshMessagesData(true);
            }else{
              setPublishMessageId(null);
            }
            window.Intercom('trackEvent', e.data.response.result ? 'message-published' : 'message-published-failure');
          // }
        }
        
      }
    const uploadDocuments =(files, propertySelected, currentFolderId, token, sharedFolder, fileType)=> this.uploadDocuments(files, propertySelected, currentFolderId, token, sharedFolder, fileType);
    const uploadReceipt =(receiptFiles, receiptCurrentId)=> this.uploadReceipt(receiptFiles, receiptCurrentId);
    const uploadMultipleReceipts =(receiptFiles)=> this.uploadMultipleReceipts(receiptFiles);
    if(this.props.addFileModal){
      let button = document.getElementById('upload');
      console.log('button:', button);
      if(window.Worker){
        if(button !== null){
          button.onclick = function() {
            setTimeout(() => {
              const token= Autoload.Utils.getAuthToken();
              setUploadType('general');
              try{
                uploadDocuments(files, propertySelected, currentFolderId, token, sharedFolder, fileType);
                //myWorker.postMessage({files, currentFolderId, propertySelected, token, sharedFolder, fileType});
              }catch(e){
                console.log('myWorker: ', e);
              }
              uploadNotification === false && setUploadNotification();
              console.log('messagePost: ', myWorker);
            }, 1000);
          }
        }
        myWorker.onmessage = function(e) {
          console.log('message received from worker', e);
          if(e){
            setState(e.data.response.result.name, 'uploadedFiles');
            window.Intercom('trackEvent', 'document-created', {fileSize: e.data.response.result.fileSize});
            fileType === 'TenantDocument' ? setRefreshTenantDocumentsData(true) : setRefreshDocumentsData(true);
            if(uploadedFiles.length === files.length) {
              setTimeout(() => {
                if(receiptFiles.length === 0 || uploadedReceiptFiles.length === receiptFiles.length){
                  setUploadNotification();
                }
                setFiles([]);
                setSharedFolder(false);
                setCurrentFolderId(null);
              }, 2000);
            }
          }
        }
        
      }
    } else if(this.props.addTenantDocument){
      let button = document.getElementById('uploadTenantDocs');
      console.log('button:', button);
      if(window.Worker){
        if(button !== null){
          button.onclick = function() {
            setTimeout(() => {
              const token= Autoload.Utils.getAuthToken();
              console.log('tenantFiles: ', tenantFiles);
              setUploadType('tenant');
              let files = tenantFiles;
              myWorker.postMessage({files, currentFolderId, propertySelected, token, sharedFolder, fileType});
              uploadNotification === false && setUploadNotification();
              console.log('messagePost: ', myWorker);
            }, 1000);
          }
        }
        
        myWorker.onmessage = function(e) {
          console.log('message received from worker', e, uploadedTenantFiles.length === tenantFiles.length);
          if(e){
            console.log('message received from worker inside', e, uploadedTenantFiles.length === tenantFiles.length);
            setState(e.data.response.result.name, 'uploadedTenantFiles');
            window.Intercom('trackEvent', 'tenant-document-created');
            setRefreshTenantDocumentsData(true);
            if(uploadedTenantFiles.length === tenantFiles.length) {
              setTimeout(() => {
                if((files.length === 0 || uploadedFiles.length === files.length) && (receiptFiles.length === 0 || uploadedReceiptFiles.length === receiptFiles.length)){
                  setUploadNotification();
                }
                setTenantFiles([]);
                setAddTenantDocument(false);
              }, 2000);
            }
          }
        }
      }
    } else if(this.props.addReceiptDocument){
      let button = document.getElementById('uploadReceiptDocs');
      console.log('button:', button);
      if(window.Worker){
        if(button !== null){
          button.onclick = function() {
            setTimeout(() => {
              setUploadType('receipt');
              try{
                if(receiptFiles.length === 1 && receiptCurrentId){
                  uploadReceipt(receiptFiles, receiptCurrentId);
                  uploadNotification === false && setUploadNotification();
                }else if(receiptFiles.length > 0){
                  uploadMultipleReceipts(receiptFiles);
                  setUploadMultipleReceiptsNotification();
                }
              }catch(e){
                console.log('myWorker: ', e);
              }
              console.log('messagePost: ', myWorker);
            }, 1000);
          }
        }
        
        myReceiptWorker.onmessage = function(e) {
          console.log('message received from worker', e, uploadedReceiptFiles.length === receiptFiles.length);
          if(e){
            setState(e.data.response, 'uploadedReceiptFiles');
            window.Intercom('trackEvent', 'receipt-document-created');
            setRefreshReceiptDocumentsData(true);
            //if(uploadedReceiptFiles.length === receiptFiles.length) {
              setTimeout(() => {
                if((files.length === 0 || uploadedFiles.length === files.length) && (tenantFiles.length === 0 || uploadedTenantFiles.length === tenantFiles.length)){
                  setUploadNotification();
                }
                setReceiptFiles([]);
                setAddReceiptModal(false);
                setReceiptCurrentId(null);
              }, 2000);
            //}
          }
        }
      }
    }
  }

  getAllUsersByAccountId=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_api_baseurl + 'GetAllByAccountId?accountId='+ this.props.profile.accountId+ '&offset=0&fetch=1000',
      'GET',
      null,
      null,
      false,
      true,
      true,
      this,
      '_handleGetAllUsersByAccountId',
      null,
      false,
      '',
      { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
    );
  }

  _handleGetAllUsersByAccountId(response){
    console.log('_handleGetAllUsersByAccountId: ', response);
    if(response.status && response.statuscode === 200){
      // this.setState({allUsersInAccount: response.result});
      let ActiveTenantPortalUsers = response.result.filter((user)=> user.isActivated === true)?.length;
      window.Intercom('update', {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        'No tenant portal users': ActiveTenantPortalUsers
      });
    }
  }

  uploadDocuments=(files, propertySelected, currentFolderId, token, sharedFolder=false)=>{
    const {fileType} = this.props
    console.log('uploadFiles: ', files, propertySelected, currentFolderId, token, sharedFolder, fileType);
    if(files.length > 0){
      try{
        files.map((file, index)=> {
          const data = new FormData();
          data.append('file', fileType === 'TenantDocument' ? file.file : file);
          data.append('accountId', propertySelected.accountId);
          !sharedFolder && data.append('propertyId', propertySelected.id);
          data.append('name', fileType === 'TenantDocument' ? file.file.name : file.name);
          data.append('isDirectory', false);
          data.append('isReadOnly', false);
          data.append('isShared', sharedFolder);
          data.append('fileType', fileType);
          fileType === 'TenantDocument' && data.append('referencedObjectId', file.tenantId);
          currentFolderId !== null && currentFolderId !== 0 && data.append('parentDirectoryId', currentFolderId);
    
          Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.document_api_base_url + 'documents',
            'POST',
            data,
            null,
            false,
            true,
            false,
            this,
            'handleUploadDocuments',
            null,
            false,
            '',
            {
              Authorization: Autoload.Utils.getAuthToken(),
            }
          );
          // fetch('https://dev.hellohousing.de/api/storage-api/documents',
          //   {
          //     method: 'POST',
          //     headers: {
          //       'Authorization': token
          //     },
          //     body: data
          //   }).then((response)=> response.json()).then((jsonReponse)=> {
          //     console.log('jsonReponse: ', jsonReponse);
          //     if(jsonReponse.status){
          //       postMessage({response: jsonReponse, eol: index === (files.length-1)});
          //     }else{
          //       console.log('myWorkerError_end_point: ', jsonReponse.message);
          //     }
          //   }).catch((e)=> console.log('myWorkerError_end_point: ', e))
        });
      }catch(e){
        console.log('myWorkerError_loop:', e);
      }
    }
  }

  uploadReceipt=(files, receiptCurrentId)=>{
    if(files.length > 0){
      try{
        files.map((file, index)=> {
          let fileData= new FormData();
          fileData.append('files', file);
    
          Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.rm_api_base_url + 'upload/'+receiptCurrentId,
            'PUT',
            fileData,
            null,
            false,
            true,
            false,
            this,
            'handleUploadReceipt',
            {name: file.name},
            false,
            '',
            {
              Authorization: Autoload.Utils.getAuthToken(),
            }
          );
        });
      }catch(e){
        console.log('myReceiptWorkerError_loop:', e);
      }
    }
  }

  //receipt-api/FromAttachment
  uploadMultipleReceipts=(files)=>{
    const data = new FormData();
    files.map((file)=> data.append('file', file));
    data.append('propertyId', this.props.propertySelected.id);
    data.append('accountId', this.props.accountData.accountId);
    console.log('uploadMultipleReceipts: ', data);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'FromAttachment',
      'POST',
      data,
      null,
      false,
      true,
      false,
      this,
      'handlePostUploadMultipleReceipts',
      {files},
      false,
      '',
      {
        'Content-Type': 'multipart/form-data',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePostUploadMultipleReceipts(response){
    const {files, tenantFiles, setReceiptFiles, setAddReceiptModal, setRefreshReceiptDocumentsData} = this.props;
    const {uploadedFiles, uploadMultipleReceiptsNotification, uploadedReceiptFiles, uploadedTenantFiles} = this.state;
    const setState=(uFiles, field)=>{
      let uploadedFiles = [...this.state[field], ...uFiles];
      // uploadedFiles.push(uFiles);
      this.setState({[field]: uploadedFiles});
    }
    const setUploadNotification=()=>{
      this.setState({uploadMultipleReceiptsNotification: !uploadMultipleReceiptsNotification, uploadedReceiptFiles: []});
    }

    if(response.status && response.statuscode === 200){
      setState(response.other.files.map((file)=> file.name), 'uploadedReceiptFiles');
      window.Intercom('trackEvent', 'receipt-document-created');
      setRefreshReceiptDocumentsData(true);
      setTimeout(() => {
        setUploadNotification();
        setReceiptFiles([]);
        setAddReceiptModal(false);
      }, 2000);
    }
  }

  handleUploadReceipt(response){
    const {files, tenantFiles, setReceiptFiles, setAddReceiptModal, setReceiptCurrentId, setRefreshReceiptDocumentsData} = this.props;
    const {uploadedFiles, uploadNotification, uploadedReceiptFiles, uploadedTenantFiles} = this.state;

    const setState=(uFiles, field)=>{
      let uploadedFiles = this.state[field];
      uploadedFiles.push(uFiles);
      this.setState({[field]: uploadedFiles});
    }
    const setUploadNotification=()=>{
      this.setState({uploadNotification: !uploadNotification, uploadedReceiptFiles: !uploadNotification === false ? [] : uploadedReceiptFiles});
    }

    if(response.status && response.statuscode === 200){
      setState(response.other.name, 'uploadedReceiptFiles');
      window.Intercom('trackEvent', 'receipt-document-created');
      setRefreshReceiptDocumentsData(true);
      setTimeout(() => {
        if((files.length === 0 || uploadedFiles.length === files.length) && (tenantFiles.length === 0 || uploadedTenantFiles.length === tenantFiles.length)){
          setUploadNotification();
        }
        setReceiptFiles([]);
        setAddReceiptModal(false);
        setReceiptCurrentId(null);
      }, 2000);
    }
  }

  handleUploadDocuments(response){
    const {files, setFiles, setCurrentFolderId, setRefreshDocumentsData, setRefreshTenantDocumentsData, receiptFiles, setSharedFolder, fileType} = this.props;
    const {uploadedFiles, uploadNotification, uploadedReceiptFiles} = this.state;
    const setState=(uFiles, field)=>{
      let uploadedFiles = this.state[field];
      uploadedFiles.push(uFiles);
      this.setState({[field]: uploadedFiles});
    }
    const setUploadNotification=()=>{
      this.setState({uploadNotification: !uploadNotification});
    }

    if(response.status && response.statuscode === 200){
      setState(response.result.name, 'uploadedFiles');
      window.Intercom('trackEvent', 'document-created', {fileSize: response.result.fileSize});
      fileType === 'TenantDocument' ? setRefreshTenantDocumentsData(true) : setRefreshDocumentsData(true);
      if(uploadedFiles.length === files.length) {
        setTimeout(() => {
          if(receiptFiles.length === 0 || uploadedReceiptFiles.length === receiptFiles.length){
            setUploadNotification();
          }
          setFiles([]);
          setSharedFolder(false);
          setCurrentFolderId(null);
        }, 2000);
      }
    }
  }

  componentWillUnmount() {
    const {setAddReceiptModal, setAddTenantDocument, setAddFileModal, setFiles, setReceiptFiles, setTenantFiles, setCurrentFolderId, setReceiptCurrentId} = this.props;
    window.removeEventListener('resize', this.handleResize);
    myWorker.terminate();
    myTenantWorker.terminate();
    myReceiptWorker.terminate();
    setAddFileModal(false);
    setAddReceiptModal(false);
    setAddTenantDocument(false);
    setFiles([]);
    setSharedFolder(false);
    setTenantFiles([]);
    setReceiptFiles([]);
    setCurrentFolderId(null);
    setReceiptCurrentId(null);
  }

  putPublishMessageById=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messages/'+this.props.publishMessageId+'/publish',
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handlePutPublishMessagesById',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePutPublishMessagesById(response){
    if(response.status === false && response.statuscode !== 200){
      showNotification({
        message: "Es ist ein Fehler bei der Übermittlung der Nachricht aufgetreten. Bitte prüfen Sie den Versandstatus im Bereich Nachrichten und wenden Sie sich bei Fragen an den Kundenservice.",
        icon: <img src={RedCross} alt='red-cross'/>,
      });
    }
    if(window.location.href.includes('/messaging')){
      this.props.setRefreshMessagesData(true);
    }else{
      this.props.setPublishMessageId(null);
    }
    window.Intercom('trackEvent', (response.status && response.statuscode === 200 && response.result) ? 'message-published' : 'message-published-failure');
  }

  getDocumentsStatusByAccountId=()=>{
    const {accountData} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/status?accountId=' + accountData.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetDocumentsStatusByAccountId',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetDocumentsStatusByAccountId(response){
    if(response.status && response.statuscode === 200){
      console.log('handleGetDocumentsStatusByAccountId: ', response.result.usedSpaceInBytes, (Math.round(response.result.usedSpaceInBytes / (1024*1024) * 100) / 100));
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        //'Documents file size': response.result.usedSpace,
        'document file size': (Math.round(response.result.usedSpaceInBytes / (1024*1024) * 100) / 100)
      });
    }
  }

  _getFuelTypes() {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'systemFuelTypes',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleFuelTypeResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleFuelTypeResponse(response) {
    console.log('_handleFuelTypeResponse: ', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
    }else{
      const fuelTypes = response.result.map((fuelType) => {
        return {
          id: Autoload.MainHelper.checkProperty(fuelType, 'id'),
          fuelTypeName: Autoload.MainHelper.checkProperty(fuelType, 'fuelTypeName'),
          conversionkWh: Autoload.MainHelper.checkProperty(fuelType, 'conversionkWh'),
        };
      });
      this.props.setFuelTypesList(fuelTypes);
    }
  }

  toggleSideCollapse = () => {
    // this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  closeChat = () => {
    this.setState({ showChat1: false });
  };

  handleLogout = () => {
    Autoload.Utils.clear_session_data();
    window.location.href = "/login";
    window.Intercom('shutdown');
  }

  createTestSubscriptionAssignment=()=>{
    const userAccountID = this.props.profile.accountId;
    const payload= {
      accountId: userAccountID,
      subscriptionType: {
        SubscriptionTypeId: '97F226D5-A137-48E6-AA9A-605D4488DEC7'
      }
    }

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_profile_subscription_api_baseurl + 'subscriptionAssignments',
      'POST',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handle_subscriptions_Assignments_create',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_subscriptions_Assignments_create(response){
    console.log('handle_subscriptions_Assignments_create: ', response);
    if(response.status && response.statuscode === 200){
      showNotification({
        message: 'Der Testzeitraum wurde erfolgreich aktiviert.',
        icon: <img src={GreenTick} alt='green-tick'/>,
        id: 'createSubscriptionAssignmentSuccess'
      });
      this.getsubscriptionsAssignments(true);
      var metadata = {
        subscriptionKey: 'PROFESSIONAL_TESTING_12_2024'
      };
      window.Intercom('trackEvent', 'plan-changed', metadata);
      window.Intercom('update', {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        "Test Plan End Date": dayjs().add(30, 'days').format()
      });
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
    }
  }

  render() {
    const { sidebarCollapsed, isMobile, isTablet, showDashboard, skeletonPropertyDropdown, giveAppAccess, uploadNotification, uploadedFiles, uploadType, uploadedTenantFiles, uploadedReceiptFiles, isSmallerMobile, uploadMultipleReceiptsNotification } = this.state;
    const {files, setFiles, setCurrentFolderId, tenantFiles, setAddReceiptModal, setAddTenantDocument, receiptFiles, setReceiptCurrentId, setReceiptFiles, setTenantFiles, setSharedFolder, propertySelected, currentSubscription, nextSubscription, hasTested, openReceiptDuplicateProgressModal, setOpenReceiptDuplicateProgressModal, selectedReceiptForDuplication, setSelectedReceiptForDuplication, setRefreshReceiptDocumentsData, setShowAfterSignIn, accountData} = this.props;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';
    var loader = document.getElementById('hello-housing-loader');
    console.log('gtrFiles: ', files, tenantFiles, receiptFiles);
    console.log('propertySelected: ', propertySelected);

    console.log('uploadedReceiptFiles: ', uploadedReceiptFiles);

    console.log('uploadMultipleReceiptsNotification: ', uploadMultipleReceiptsNotification, receiptFiles);
    console.log('hasTested: ', currentSubscription, hasTested);
    //
    let blockUsersFromUsingApp = (currentSubscription === null || currentSubscription === undefined) ? true : ((currentSubscription?.subscriptionStatus === 'CANCELLED' && dayjs().isAfter(dayjs(currentSubscription?.nextPaymentDue, 'DD.MM.YYYY').subtract(1, 'day')) && nextSubscription === null) || (currentSubscription?.subscriptionKey === 'PROFESSIONAL_TESTING_12_2024' && dayjs().isAfter(dayjs(currentSubscription?.testPeriodEnd, 'DD.MM.YYYY')))) ? true : false;
    return (
      <ContextProviders>
        {isMobile && (!window.location.href.includes('/billingStatement/') || !window.location.href.includes('/meterReadingPdf/')) && <SideBarDropDown blockUsersFromUsingApp={blockUsersFromUsingApp} history={this.props.history} isMobile={isMobile} jumpToSubscriptions={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} currentSubscription={this.props.currentSubscription}/>}
        <div className={`app ${sidebarCollapsedClass}`} style={{marginBottom: sidebarCollapsedClass ? '65px' : 0, backgroundColor: '#F7F7FA'}}>
          <PageAlert />
          
          {/* "Old" spinner - needs to stay here but I've hidden it because it caused problems */}
          <div className="hh_loader" id="hello-housing-loader" style={{ display: 'none' }}>
            <div className="hh_loader_bg" id='hh_loader_bg' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <span className='download_status' id='download_status' style={{display: 'none', marginTop: '120px'}}/>
              <p className='allow_download' id='allow_download' style={{display: 'none'}}>Bitte erlauben Sie Downloads, wenn Sie danach gefragt werden. Sie finden das Dokument dann in Ihrem Download-Ordner.</p>
            </div>
            <Spinner animation="border" id='hh_loader_spinner' className="hh_loader_spinner" variant="primary" />
          </div>
            
          {/* Thanks to this the sidebar don't change their width on window resize */}
          {giveAppAccess && <Group
            spacing={0}
            sx={{
              alignItems: 'stretch',
              margin: 0
            }}
            id='main-app'
          >
            {
            isMobile ? 
            (!window.location.href.includes('/billingStatement/') || !window.location.href.includes('/meterReadingPdf/') || !window.location.href.includes('/messagingPdf') || !window.location.href.includes('/tenantsPdfExport') || !window.location.href.includes('/rentalsPdfExport') || !window.location.href.includes('/transactionsPdfExport')) &&
                <SidebarNav
                nav={nav}
                logo={Logo}
                logoText=""
                isSidebarCollapsed={sidebarCollapsed}
                isMobile={isMobile}
                isTablet={isTablet}
                isSmallerMobile={isSmallerMobile}
                toggleSidebar={this.toggleSideCollapse}
                {...this.props}
              /> : (window.location.href.includes('/billingStatement/') || window.location.href.includes('/meterReadingPdf/')) || window.location.href.includes('/paymentInitialize') || window.location.href.includes('/payment/status') || window.location.href.includes('/invoicePdf/') || window.location.href.includes('/messagingPdf') || window.location.href.includes('/tenantsPdfExport') || window.location.href.includes('/rentalsPdfExport') ?
              <></>
              :
              (
                <Box
                  sx={(theme) => ({
                    borderRight: `1px solid ${theme.colors.divider}`,
                    padding: 0,
                    width: isTablet ? 250 : 270,
                    position: "relative",
                    backgroundColor: 'white'
                  })}
                >

                  {/* App global sidebar */}
                    <AppSidebar
                    subscriptionData={this.props.subscription}
                    currentSubscription={currentSubscription}
                    jumpToSubscriptions={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                    isTablet={isTablet}
                    skeletonPropertyDropdown={skeletonPropertyDropdown}
                    propertySelected={this.props.propertySelected}
                    blockUsersFromUsingApp={blockUsersFromUsingApp}
                    resetData={()=> this.props.resetData()} 
                    setShowAfterSignIn={(value)=> setShowAfterSignIn(value)}
                    history={this.props.history}
                    accountData={accountData}
                    hasTested={hasTested}/>
                  
                  {/* <SidebarNav
                    nav={nav}
                    logo={Logo}
                    logoText=""
                    isSidebarCollapsed={sidebarCollapsed}
                    isMobile={isMobile}
                    toggleSidebar={this.toggleSideCollapse}
                    {...this.props}
                  /> */}
                </Box>)
        }

            {/* App content */}
            <Box
              sx={{
                backgroundColor: '#F7F7FA',
                flex: 1,
                padding: 24,
                overflow: 'hidden'
              }}
            >
          
              <Box id='app-content'>
                <Switch>
                    {RoutesModuleGeneral.map((page, key) => (
                      showDashboard &&
                      <Route path={page.path} component={page.component} key={key} /> 
                    ))}

                    {RoutesModuleProfile.map((page, key) => (
                      <Route path={page.path} component={page.component} key={key} /> 
                    ))}

                    {blockUsersFromUsingApp === false && RoutesModuleMeter.map((page, key) => (
                      <Route path={page.path} key={key} component={page.component}/>
                    ))}

                    {blockUsersFromUsingApp === false && RoutesModuleMessaging.map((page, key) => (
                      <Route path={page.path} key={key} component={page.component}/>
                    ))}
                    
                    {blockUsersFromUsingApp === false && RoutesModuleDocuments.map((page, key) => (
                      <Route path={page.path} key={key} component={page.component}/>
                    ))}
                    
                    {blockUsersFromUsingApp === false && RoutesModulePayment.map((page, key) => (
                      <Route path={page.path} component={page.component} key={key} />
                    ))}

                    {blockUsersFromUsingApp === false && RoutesModuleProperty.map((page, key) => (
                      <Route path={page.path} component={page.component} key={key} />
                    ))}

                    {blockUsersFromUsingApp === false && RoutesModuleReceipt.map((page, key) => (
                      <Route path={page.path} component={page.component} key={key} />
                    ))}

                    {blockUsersFromUsingApp === false && RoutesModuleUser.map((page, key) => (
                      <Route path={page.path} //component={page.component} 
                      render={(props)=> <page.component {...props} isMobile={isMobile}/>}
                      key={key} />
                    ))}

                    {blockUsersFromUsingApp === false && RoutesModuleUtilityBilling.map((page, key) => (
                      <Route path={page.path} component={page.component} key={key} />
                    ))}

                    {RoutesModulePartner.map((page, key) => (
                      <Route path={page.path} component={page.component} key={key} />
                    ))}

                    {blockUsersFromUsingApp === false && RoutesModuleTenantPortal.map((page, key) => (
                      <Route path={page.path} component={page.component} key={key} />
                    ))}

                    <Route path="/billingStatement/:id" exact component={UtilityBillingTemplate} />
                    <Route path="/billingStatement2/:id" exact component={UtilityBillingTemplate2} />

                    <Route path="/tenantsPdfExport" exact component={TenantsPdfTemplate} />

                    <Route path="/taxDeclarationPdfExport" exact component={TaxDeclarationPdf} />

                    <Route path="/transactionsPdfExport" exact component={TransactionsPdfExport} />

                    <Route path="/rentalsPdfExport" exact component={RentalsPdfTemplate} />

                    <Route path="/rentalPaymentsPdfExport" exact component={RentalPaymentsPdfTemplate} />

                    <Route path="/messagingPdf/" exact component={MessagingTemplate} />

                    <Route path="/meterReadingPdf/:id" exact component={MeterReadingsTemplate} />

                    <Route path="/invoicePdf/:id" exact component={InvoiceTemplatePdf} />
                    
                    <Route path="/paymentInitialize" exact component={PaymentTemplate} />

                    {/* Temporary hidden refreshed dashboard */}
                    <Route path="/new-dashboard" exact component={Dashboard} />

                    <Redirect from="/" to="/dashboard" />
                  </Switch>
              </Box>
            </Box>

          </Group>}
          <Group id='permission-denied' style={{display: 'none', justifyContent: 'center', height: '100vh'}} align='center'>
              <EmptyStateContainer
              heading="Sie haben keinen Zugriff auf diesen Bereich."
              subheading={<span>Bitte melden Sie sich erneut an oder wenden Sie sich an den <span style={{color: '#5555FF', cursor: 'pointer'}} onClick={()=> {window.location.href = 'mailto:service@hellohousing.de'; }}>hellohousing Service.</span></span>}
              icon={Avatar}
              showBtn={true}
              btnText={'Anmelden'}
              btnHandler={()=>this.handleLogout()}
              styleMainContainer={{marginTop: '60px'}}
              />
          </Group>

          <button id='get-all-subscriptions' style={{display: 'none'}} onClick={()=> this.getsubscriptionsAssignments()}/>
          <button id='create-test-subscription' style={{display: 'none'}} onClick={()=> this.createTestSubscriptionAssignment()}/>
          {/* Footer code */}
          {/* <Footer>
            <span>Copyright © 2019 Nice Dash. All rights reserved.</span>
            <span>
              <a href="#!">Terms</a> | <a href="#!">Privacy Policy</a>
            </span>
            <span className="ml-auto hidden-xs">
              Made with{' '}
              <span role="img" aria-label="taco">
                🌮
              </span>
            </span>
          </Footer> */}

          {/* chat code */}
          {/* <Chat.Container>
            {this.state.showChat1 && (
              <Chat.ChatBox name="Messages" status="online" image={avatar1} close={this.closeChat} />
            )}
          </Chat.Container> */}

          {openReceiptDuplicateProgressModal &&
          <ReceiptDuplicateProgressModal
          open={openReceiptDuplicateProgressModal}
          selectedReceiptForDuplication={selectedReceiptForDuplication}
          toggle={(success)=> {
            setOpenReceiptDuplicateProgressModal(false);
            setSelectedReceiptForDuplication(null);
            if(success){
              hideNotification('receiptDuplicationProcess');
              showNotification({
                message: 'Belegkopie erstellt.',
                icon: <img src={GreenTick} alt='green-tick'/>,
                id: 'receiptDuplicationProcess'
              });
            }
            setRefreshReceiptDocumentsData(true);
          }}
          />}

          {uploadNotification && (tenantFiles.length > 0 || files.length > 0 || receiptFiles.length > 0) &&
          <Notification 
          classNames={{
            root: 'upload-root-documents',
          }}
          onClose={()=> {
            if(uploadType === 'general' && uploadedFiles.length === files.length){
              setFiles([]);
              setSharedFolder(false);
              setCurrentFolderId(null);
              this.setState({uploadedFiles: [], uploadNotification: false});
            }else if(uploadType === 'tenant' && tenantFiles.length === uploadedTenantFiles.length){
              setTenantFiles([]);
              setAddTenantDocument(false);
              this.setState({uploadedTenantFiles: [], uploadNotification: false})
            }else if(uploadType === 'receipt' && receiptFiles.length === uploadedReceiptFiles.length){
              setReceiptFiles([]);
              setAddReceiptModal(false);
              setReceiptCurrentId(null);
              this.setState({uploadedReceiptFiles: [], uploadNotification: false})
            }
            else{
              this.setState({uploadNotification: false});
            }
          }}
          styles={{
            root: {position: 'fixed', right: 450, left: 450, top: 20},
            icon: {display: 'none'}
          }}>
            {files.length > 0 && files.map((file) =>
            file.name ?
            <Stack>
              <Group noWrap>
                {uploadedFiles.find((uf)=> uf === file.name) ? <img src={GreenTick} /> : <Loader variant="dots" size={'xs'}/>}
                <span style={{fontWeight: '300'}}>{`${file.name} ${uploadedFiles.find((uf)=> uf === file.name) ? 'hochgeladen' : 'wird hochgeladen'}`}</span>
              </Group>
            </Stack>
            :
            <Stack>
              <Group noWrap>
                {uploadedFiles.find((uf)=> uf === file.file.name) ? <img src={GreenTick} /> : <Loader variant="dots" size={'xs'}/>}
                <span style={{fontWeight: '300'}}>{`${file.file.name} ${uploadedFiles.find((uf)=> uf === file.file.name) ? 'hochgeladen' : 'wird hochgeladen'}`}</span>
              </Group>
            </Stack>)}
            {tenantFiles.length > 0 && tenantFiles.map((file) =>
            file.file.name &&
            <Stack>
              <Group noWrap>
                {uploadedTenantFiles.find((uf)=> uf === file.file.name) ? <img src={GreenTick} /> : <Loader variant="dots" size={'xs'}/>}
                <span style={{fontWeight: '300'}}>{`${file.file.name} ${uploadedTenantFiles.find((uf)=> uf === file.file.name) ? 'hochgeladen' : 'wird hochgeladen'}`}</span>
              </Group>
            </Stack>)}
            {receiptFiles.length > 0 && receiptFiles.map((file) =>
            file.name &&
            <Stack>
              <Group noWrap>
                {uploadedReceiptFiles.find((uf)=> uf === file.name) ? <img src={GreenTick} /> : <Loader variant="dots" size={'xs'}/>}
                <span style={{fontWeight: '300'}}>{`${file.name} ${uploadedReceiptFiles.find((uf)=> uf === file.name) ? 'hochgeladen' : 'wird hochgeladen'}`}</span>
              </Group>
            </Stack>)}
          </Notification>}

          {(uploadMultipleReceiptsNotification && receiptFiles.length > 0) &&
          <Notification 
          classNames={{
            root: 'upload-root-documents',
          }}
          onClose={()=> {
            if(uploadType === 'general' && uploadedFiles.length === files.length){
              setFiles([]);
              setSharedFolder(false);
              setCurrentFolderId(null);
              this.setState({uploadedFiles: [], uploadMultipleReceiptsNotification: false});
            }else if(uploadType === 'tenant' && tenantFiles.length === uploadedTenantFiles.length){
              setTenantFiles([]);
              setAddTenantDocument(false);
              this.setState({uploadedTenantFiles: [], uploadMultipleReceiptsNotification: false})
            }else if(uploadType === 'receipt' && receiptFiles.length === uploadedReceiptFiles.length){
              setReceiptFiles([]);
              setAddReceiptModal(false);
              setReceiptCurrentId(null);
              this.setState({uploadedReceiptFiles: [], uploadMultipleReceiptsNotification: false})
            }
            else{
              this.setState({uploadMultipleReceiptsNotification: false});
            }
          }}
          styles={{
            root: {position: 'fixed', right: 450, left: 450, top: 20},
            icon: {display: 'none'}
          }}>
            {receiptFiles.length > 0 &&
            <Stack>
              <Group noWrap>
                {uploadedReceiptFiles.length === receiptFiles.length ? <img src={GreenTick} /> : <Loader variant="dots" size={'xs'}/>}
                <span style={{fontWeight: '300'}}>{uploadedReceiptFiles.length === receiptFiles.length ? `${receiptFiles.length} Belege wurden erfolgreich erstellt.` : `${receiptFiles.length} Belege werden hochgeladen und verarbeitet. Dieser Vorgang kann einige Minuten dauern. Sie können weiterarbeiten und später zurückkehren.`}</span>
              </Group>
            </Stack>}
          </Notification>}
        </div>
      </ContextProviders>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    propertyData: state.generalReducer.property,
    propertySelected: state.generalReducer.property,
    properties: state.generalReducer.allProperties,
    subscription: state.generalReducer.subscription,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    addFileModal: state.generalReducer.addFileModal,
    files: state.generalReducer.files,
    currentFolderId: state.generalReducer.currentFolderId,
    sharedFolder: state.generalReducer.sharedFolder,
    refreshDocumentsData: state.generalReducer.refreshDocumentsData,
    fileType: state.generalReducer.fileType,
    referencedObjectId: state.generalReducer.referencedObjectId,
    //TenantDocuments
    addTenantDocument: state.generalReducer.addTenantDocument,
    tenantFiles: state.generalReducer.tenantFiles,
    tenantCurrentFolderId: state.generalReducer.tenantCurrentFolderId,
    refreshTenantDocumentsData: state.generalReducer.refreshTenantDocumentsData,
    //ReceiptDocuments
    addReceiptDocument: state.generalReducer.addReceiptDocument,
    receiptFiles: state.generalReducer.receiptFiles,
    receiptCurrentId: state.generalReducer.receiptCurrentId,
    refreshReceiptDocumentsData: state.generalReducer.refreshReceiptDocumentsData,
    publishMessageId: state.generalReducer.publishMessageId,
    nextSubscription: state.generalReducer.nextSubscription,
    hasTested: state.generalReducer.hasTested,
    openReceiptDuplicateProgressModal: state.generalReducer.openReceiptDuplicateProgressModal,
    selectedReceiptForDuplication: state.generalReducer.selectedReceiptForDuplication
  };
};

const actions = {
  setProfileData,
  changeCurrentProperty,
  setPropertyList,
  editProfileData,
  setFuelTypesList,
  setSubscriptionData,
  setCurrentSubscriptionData,
  setAccountData,
  setCompanyLogo,
  setCurrentSubscriptionDataWithPaymentError,

  setAddFileModal,
  setFiles,
  setCurrentFolderId,
  setSharedFolder,
  setRefreshDocumentsData,
  setFileType,
  //Tenant Documents
  setAddTenantDocument,
  setTenantFiles,
  setTenantCurrentFolderId,
  setRefreshTenantDocumentsData,
  //Receipt Documents
  setAddReceiptModal,
  setReceiptFiles,
  setReceiptCurrentId,
  setRefreshReceiptDocumentsData,
  //Messages
  setRefreshMessagesData,
  setPublishMessageId,
  //setNextSubscriptionData,
  setSubscriptionMainLoader,
  resetData,
  setTestSubscriptionComplete,

  //DuplicateReceipt
  setOpenReceiptDuplicateProgressModal,
  setSelectedReceiptForDuplication,
  setNextSubscriptionData,
  setSubscriptionMainLoader,
  resetData,
  setTestSubscriptionComplete,
  setShowAfterSignIn,
  setIsTesting
};
export default connect(mapStateToProps, actions)(DashboardLayout);
import React, { Component } from 'react';
import { ColorButton, TransparentButton, DeleteModal } from '../../../../../neoverv';
import { Badge } from 'react-bootstrap';
import Autoload from '../../../../../neoverv/Autoload';
import moment from 'moment';
import { connect } from 'react-redux';
import { Group, Skeleton, Stack } from '@mantine/core';
import { Row, Col, Form } from 'react-bootstrap';
import StarterBadgeIcon from '../../../../../assets/images/module/general/StarterBadgeIcon.svg';
import PremiumBadgeIcon from '../../../../../assets/images/module/general/PremiumBadgeIcon.svg';
import ProfessionalBadgeIcon from '../../../../../assets/images/module/general/ProfessionalBadgeIcon.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import NewSubscription from './NewSubscription';
import { Route } from 'react-router-dom';
import PaymentConfirmationModal from '../../../../../neoverv/components/Module/General/CustomModal/PaymentConfirmationModal';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import { hideNotification, showNotification } from '@mantine/notifications';
import {
  setSubscriptionData,
  setCurrentSubscriptionData,
  setCurrentSubscriptionDataWithPaymentError,
  setSelectedSubscriptionForPaymentConfirmation,
  setAccountData,
  setCompanyLogo,
  setNextSubscriptionData,
  setTestSubscriptionComplete,
  setIsTesting
} from '../../../../../redux/action/General/generalActions';
import { getCookie, setCookie } from '../../../../../neoverv/helpers/Utils';
import PaymentTemplateSuccessModal from '../../../../../neoverv/components/Module/General/CustomModal/PaymentTemplateSuccessModal';
import PaymentTemplateModal from '../../../../../neoverv/components/Module/General/CustomModal/PaymentTemplateModal';
import dayjs from 'dayjs';
import { id } from 'chartjs-plugin-style';
import RestrictedMenuMessageModal from '../../../../../neoverv/components/Module/General/CustomModal/RestrictedMenuMessageModal';
import SubscriptionBadgeIcon from '../../../../../neoverv/components/SVGs/SubscriptionBadgeIcon';
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, loader } = Autoload.MainHelper;
export class YourSubscription extends Component {
  state = {
    subscriptionPlan: null,
    subscriptionlastDate: '',
    openModal: false,
    subscriptionData: [
      {
        subscriptionRenewDate: '',
        subscriptionAmount: '',
        subscriptionBilling: '',
        subscriptionKey: '',
      },
    ],
    skeletonLoading: false,
    showNewSubscription: false,
    subscribtionSelected: '',
    paymentConfirmationModal: false,
    paymentMethodStatus: false,
    paymentMandate: null,
    subscriptionTypes: [],
    currentSubsId: null,
    type: null,
    openPaymentStatusModal: false,
    openPaymentRedirectModal: false,
    showLoader: false,
    nextSubscription: null,
    cancelSubscriptionModal: false,
    openRestrictedMenuMessage: false
    // subscriptionData: [{ subscriptionAmount: '', subscriptionRenewDate: '', subscriptionlastDate: '' }],
  };

  onInputChange = (field, value) => {
    this.setState({ [field]: value });
  };

  componentDidMount() {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'block';
    
    const {subscriptionData, subscriptionTypes, subscribtionSelected, paymentMandate} = this.state;
    const selectedSubscription = this.props.history.location.state && this.props.history.location.state.selectedSubscription ? this.props.history.location.state.selectedSubscription : null;
    let showNewSubscription = this.props.location.state && this.props.location.state.newSubscription ? this.props.location.state.newSubscription : false;
    
    this.getAccountById();

    this.setState({skeletonLoading: true, showNewSubscription, subscribtionSelected: selectedSubscription ? selectedSubscription : subscribtionSelected, paymentConfirmationModal: selectedSubscription && this.props.accountData.accountPaymentStatus === 'AUTHORIZED' && true, openPaymentStatusModal: selectedSubscription && this.props.accountData.accountPaymentStatus !== 'AUTHORIZED' && true});
    console.log('this.props.newSubscription: ', showNewSubscription);

    this.getsubscriptionsTypes();

    let paymentAuthorizeInProgress = getCookie('PaymentAuthorizeInProgress');
    let paymentReAuthorizeInProgress = getCookie('paymentReAuthorizeInProgress');
    
    if (paymentAuthorizeInProgress === '1') {
      loader(true);
      let route = getCookie('PaymentAuthorizeRoute');
      let subscribtionSelected = getCookie('SubscriptionSelected');
      if(route === '/profile/yourSubscription'){
        //setCookie('PaymentAuthorizeInProgress', '0');
        this.setState({type: 'authorize', subscribtionSelected: this.props.selectedSubscriptionForPaymentConfirmation}, ()=> {
          setCookie('SubscriptionSelected', null);
        });
        setTimeout(() => {
          //this.getPaymentStatus();
          this.setState({openPaymentStatusModal: true}, ()=> setCookie('PaymentAuthorizeRoute', null));
        }, 3000);
      }else{
        loader(false);
      }
    }else if(paymentReAuthorizeInProgress === '1'){
      loader(true);
      let route = getCookie('PaymentAuthorizeRoute');
      let subscribtionSelected = getCookie('SubscriptionSelected');
      if(route === '/profile/yourSubscription'){
        //setCookie('PaymentAuthorizeInProgress', '0');
        this.setState({type: 'authorize', subscribtionSelected: this.props.selectedSubscriptionForPaymentConfirmation}, ()=> {
          setCookie('SubscriptionSelected', null);
        });
        setTimeout(() => {
          //this.getPaymentStatus();
          this.setState({openPaymentStatusModal: true}, ()=> setCookie('PaymentAuthorizeRoute', null));
        }, 3000);
      }else{
        loader(false);
      }
    }

    // let url = new URL(window.location.href);
    // this.setState({
    //   paymentConfirmationModal: JSON.parse(url.searchParams.get("paymentStatus")) !== null && JSON.parse(url.searchParams.get("paymentStatus")) === true ? true : false, 
    //   paymentMethodStatus: JSON.parse(url.searchParams.get("paymentStatus"))
    // }) 
  }

  getAccountById=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + this.props.profile.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_account_by_id',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_account_by_id(response){
    if(response.status && response.statuscode === 200){
      this.props.setAccountData(response.result);
      this.props.setCompanyLogo(Autoload.MainHelper.checkProperty(response.result, 'useOwnLogo'));
    }
  }

  getPaymentStatus = () => {
    loader(true);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + this.props.profile.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handlePaymentStatusResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handlePaymentStatusResponse(response) {
    console.log('okk')
    this.paymentLoop = this.paymentLoop + 1;
    if (response.status) {
      const status = Autoload.MainHelper.checkProperty(response.result, 'accountPaymentStatus');

      switch (status) {
        case 'INITIATED':
          if (this.paymentLoop < 4) {
            setTimeout(() => {
              this.getPaymentStatus();
            }, 5000);
            break;
          } else {
            this.setState({ paymentStatus: 'PAYMENT_ERROR' });
            //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
            loader(false);
            //this.setState({ showPaymentMessage: true });
            break;
          }
        case 'AUTHORIZED':
          this.setState({ paymentStatus: status });
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_success);
          loader(false);
          //this.setState({ showPaymentMessage: true });
          break;
        case 'PAYMENT_ERROR':
          this.setState({ paymentStatus: 'PAYMENT_ERROR' });
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
          loader(false);
          //this.setState({ showPaymentMessage: true });
          break;
        default:
          this.setState({ paymentStatus: 'SERVER_ERROR' });
          //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
          loader(false);
          //this.setState({ showPaymentMessage: true });
          break;
      }
    } else {
      this.setState({ paymentStatus: 'SERVER_ERROR' });
      //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_paymentUpdated_failure);
      //this.setState({ showPaymentMessage: true });
      loader(false);
    }

    this.setState({openPaymentStatusModal: true});
  };

  getPaymentDetails = () => {
    console.log('this.props.profile', this.props.profile.accountId, this.props.profile);
    const userAccountID = this.props.profile.accountId;
    // console.log(userAccountID, 'userAccountID from redux - payment details page');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + userAccountID + '/paymentMandate',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_account_payment_details',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_account_payment_details(response) {
    console.log('handle_account_payment_details response', response);
    if (response.status == true && response.statuscode == 200) {
      this.setState({
        paymentMandate: response.result
      });
    }
    this.getsubscriptionsAssignments();
  }

  getsubscriptionsTypes = async () => {
    const userAccountID = this.props.profile.accountId;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'subscriptions',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_subscriptions_types',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_subscriptions_types(response){
    console.log('handle_subscriptions_types: ', response);
    if(response.status){
      this.setState({subscriptionTypes: response.result});
    }
    this.getPaymentDetails();
  }

  getsubscriptionsAssignments = async (subscriptionChanged=false) => {
    const userAccountID = this.props.profile.accountId;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_profile_subscription_api_baseurl + 'subscriptionAssignments?accountId=' + userAccountID,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_subscriptions_Assignments_details',
      {subscriptionChanged: subscriptionChanged},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_subscriptions_Assignments_details(response) {
    let {subscriptionTypes} = this.state;
    console.log('profile ssubscription api', response);
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {

      let subscriptionData= response.result.map((data, index) => {
        return {
          subscriptionRenewDate: moment(data.nextRenewalDate).format('DD.MM.YYYY'),
          subscriptionAmount: checkProperty(checkProperty(data, 'subscriptionType'), 'price'),
          subscriptionBilling: checkProperty(checkProperty(data, 'subscriptionType'), 'billing'),
          subscriptionKey: checkProperty(checkProperty(data, 'subscriptionType'), 'subscriptionKey'),
          subscriptionName: checkProperty(checkProperty(data, 'subscriptionType'), 'name'),
          subscriptionTypeId: checkProperty(checkProperty(data, 'subscriptionType'), 'subscriptionTypeId'),
          subscriptionStatus: checkProperty(data, 'subscriptionStatus'),
          testPeriodEnd: checkProperty(data, 'testPeriodEndDate'),
          subscriptionEndDate: moment(data.testPeriodEndDate).format('DD.MM.YYYY'),
          subscriptionAssignment: checkProperty(data, 'id'),
          nextPaymentDue: moment(data.nextPaymentDate).format('DD.MM.YYYY'),
          possibleCancellationDate: moment(data.nextPaymentDate).subtract(1, 'days').format('DD.MM.YYYY'),
          cancellatinoDate: moment(data.cancelationDate).format('DD.MM.YYYY'),
          limitProperties: checkProperty(checkProperty(data, 'subscriptionType'), 'limitProperties'),
          limitRentalUnits: checkProperty(checkProperty(data, 'subscriptionType'), 'limitRentalUnits'),
          type:checkProperty(checkProperty(data, 'subscriptionType'), 'type'),
          cancellationDateWithoutFormat: data.cancelationDate,
          startDate: moment(data.startDate).format('DD.MM.YYYY'),
        };
      });
      //response.result.splice(0, 1); // Remove first array element so only the second one interests us (I know it's not beautyiful but it works)
      let currentSubscription = subscriptionData.find((subs)=> subs.subscriptionStatus === 'ACTIVE' ? subs.subscriptionKey === 'PROFESSIONAL_TESTING_12_2024' ? dayjs().isBefore(dayjs(subs.testPeriodEnd)) : moment(subs.startDate, 'DD.MM.YYYY').format('YYYY-MM-DDT00:00:00') <= moment().format('YYYY-MM-DDT00:00:00') : subs.subscriptionStatus === 'CANCELLED' ? moment(subs.nextPaymentDue, 'DD.MM.YYYY').format('YYYY-MM-DDT00:00:00') > moment().format('YYYY-MM-DDT00:00:00') : (subs.subscriptionStatus === 'SWITCHED' && subs.subscriptionKey === 'PROFESSIONAL_TESTING_12_2024') ? dayjs().isBefore(dayjs(subs.testPeriodEnd)) : null);
      currentSubscription = currentSubscription !== undefined ? currentSubscription : null;//subscriptionData.find((subs)=> subs.subscriptionName === 'Starter');
      // if(subscriptionTypes.find((subscriptionType)=> subscriptionType?.subscriptionTypeId === currentSubscription?.subscriptionTypeId) === undefined){
      //   subscriptionTypes= [...subscriptionTypes, response.result.find((subscriptionAssignment)=> subscriptionAssignment.subscriptionType.subscriptionTypeId === currentSubscription?.subscriptionTypeId)?.subscriptionType];
      //   this.setState({subscriptionTypes});
      // }
      response.other.subscriptionChanged && this.props.setSubscriptionData(subscriptionData);
      
      response.other.subscriptionChanged && this.props.setCurrentSubscriptionData(currentSubscription !== undefined ? (((currentSubscription?.subscriptionStatus === 'ACTIVE' || currentSubscription?.subscriptionStatus === 'CANCELLED') && (this.props.accountData.accountPaymentStatus === null || this.props.accountData.accountPaymentStatus === 'PAYMENT_ERROR')) ? null//subscriptionData.find((subs)=> subs.subscriptionName === 'Starter') 
      : currentSubscription) : null);//subscriptionData.find((subs)=> subs.subscriptionName === 'Starter'));
      
      let isTesting= response.result.find((subscriptionAssignment)=> subscriptionAssignment?.subscriptionType?.subscriptionTypeId === '97f226d5-a137-48e6-aa9a-605d4488dec7' && (dayjs().isBefore(dayjs(subscriptionAssignment?.testPeriodEndDate)) || dayjs().isSame(dayjs(subscriptionAssignment?.testPeriodEndDate))));
      let hasTested= response.result.find((subscriptionAssignment)=> subscriptionAssignment?.subscriptionType?.subscriptionKey.includes('2023')	|| subscriptionAssignment?.subscriptionType?.subscriptionTypeId === '97f226d5-a137-48e6-aa9a-605d4488dec7' && dayjs().isAfter(dayjs(subscriptionAssignment?.testPeriodEndDate)));
      this.props.setTestSubscriptionComplete(hasTested ? true : false);
      this.props.setIsTesting(isTesting ? true : false);

      let nextSubscription = subscriptionData.find((subs)=> subs.subscriptionStatus === 'ACTIVE' && moment(subs.startDate, 'DD.MM.YYYY').format('YYYY-MM-DDT00:00:00') > moment().format('YYYY-MM-DDT00:00:00'));
      response.other.subscriptionChanged && this.props.setNextSubscriptionData(nextSubscription !== undefined ? nextSubscription : null);

      this.setState({
        subscriptionData,
        nextSubscription: subscriptionData.find((subs)=> subs.subscriptionStatus === 'ACTIVE' && moment(subs.startDate, 'DD.MM.YYYY').format('YYYY-MM-DDT00:00:00') > moment().format('YYYY-MM-DDT00:00:00'))
        //currentSubscription?.subscriptionStatus === 'CANCELLED' ? subscriptionData.find((subs)=> subs.subscriptionName !== 'Starter' && subs.subscriptionStatus === 'ACTIVE' && (dayjs(subs.startDate, 'DD.MM.YYYY').isSame(dayjs(currentSubscription?.cancellatinoDate, 'DD.MM.YYYY')) || dayjs(subs.startDate, 'DD.MM.YYYY').isAfter(dayjs(currentSubscription?.cancellatinoDate, 'DD.MM.YYYY')))) : null
      });
      //response.other.subscriptionChanged && this.props.setCurrentSubscriptionData(currentSubscription !== undefined ? currentSubscription : subscriptionData.find((subs)=> subs.subscriptionName === 'Starter'));

      //console.log('renewDate: ', this.props.currentSubscription?.nextPaymentDue);
      this.setState({
        renewDate: this.props.currentSubscription?.nextPaymentDue,
        currentSubsId: this.props.currentSubscription?.subscriptionAssignment,
        subscriptionName: this.props.currentSubscription?.subscriptionName
      });

      if(currentSubscription !== undefined && (currentSubscription?.subscriptionStatus === 'ACTIVE' || currentSubscription?.subscriptionStatus === 'CANCELLED') && this.props.accountData?.accountPaymentStatus === 'PAYMENT_ERROR'){
        if(currentSubscription?.subscriptionStatus === 'ACTIVE'){
          showNotification({
            message: <span>Bei der Zahlungsabwicklung Ihres Abonnements ist ein Fehler aufgetreten. Ihr Zugriff auf hellohousing ist derzeit eingeschränkt. Bitte <span onClick={()=> this.props.history.push('/profile/paymentDetails/')} style={{color: '#5555FF', cursor: 'pointer'}}>reativieren Sie die Zahlungsmethode</span>, um den Zugriff wieder herzustellen.</span>,
            disallowClose: true,
            icon: <img src={RedCross} alt='red-cross'/>,
            autoClose: false,
            id: 'paymentErrorMessage',
          })
        }else{
          hideNotification('paymentErrorMessage');
        }
        this.props.setCurrentSubscriptionDataWithPaymentError(currentSubscription !== undefined ? currentSubscription : null);//subscriptionData.find((subs)=> subs.subscriptionName === 'Starter'));
      }else{
        hideNotification('paymentErrorMessage');
        this.props.setCurrentSubscriptionDataWithPaymentError(null);
      }

      response.other.subscriptionChanged && 
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        'Current Plan': currentSubscription !== undefined ? currentSubscription?.subscriptionStatus === 'CANCELLED' ? 'CANCELLED' : currentSubscription?.subscriptionKey : 'No ACTIVE or CANCELLED Subscription Assigned'
      });

      // console.log(this.state.subscriptionData,this.state.subscriptionRenewDate.,'====subscriptionData====');
    }

    this.ShowLoader();
  }
  toggleModal = (subscriptionStatus, testPeriodEnd, id, subscriptionRenewDate) => {
    // if periodEndDate before todaydate or today and endDate is same true means still active - case 1
    // if  periodEndDate before todaydate then false means over - case 2
    // console.log(date, testDate, testPeriod, 'abc');
    var date = moment(new Date()).format('YYYY-MM-DD');
    var testDate = moment(testPeriodEnd).format('YYYY-MM-DD');
    var testPeriod = moment(date).isSameOrBefore(testDate);

    this.setState({
      subsStatus: subscriptionStatus,
      subsEndPeriod: testPeriodEnd,
      subsId: id,
      testPeriodCheck: testPeriod,
      renewDate: subscriptionRenewDate,
      openModal: !this.state.openModal,
    });
  };
  reactivateCancelSub() {
    const { currentSubsId } = this.state;
    this.cancelSubscriptionAPi(currentSubsId);
  }
  cancelSubscriptionAPi(id) {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_profile_subscription_api_baseurl + 'subscriptionAssignments/' + id + '/cancel',
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_cancel_subscription',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handle_cancel_subscription(response) {
    this.setState({ openModal: false });
    if(response.status){
      var metadata = {
        subscriptionKey: this.props.currentSubscription?.subscriptionKey
      };
      window.Intercom('trackEvent', 'plan-cancelled', metadata);
      showNotification({
        message: 'Die Kündigung Ihres Abonnements wurde durchgeführt.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      });
      this.getsubscriptionsAssignments(true);
    }
    else{
      showNotification({
        message: 'Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
      this.getsubscriptionsAssignments();
    }
    
  }
  reactivateSubscriptinApi(id) {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_profile_subscription_api_baseurl + 'subscriptionAssignments/' + id + '/reactivate',
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_reactivate_cancel_subscription',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handle_reactivate_cancel_subscription = (response) => {
    if (response.statuscode === 200 && response.status) {
      var metadata = {
        subscriptionKey: this.props.currentSubscription?.subscriptionKey
      };
      window.Intercom('trackEvent', 'plan-reactivated', metadata);
      showNotification({
        message: 'Die Kündigung wurde erfolgreich rückgängig gemacht.',
        icon: <img src={GreenTick} alt='green-tick'/>,
      });
      this.getsubscriptionsAssignments(true);
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
      this.getsubscriptionsAssignments();
    }

    
  };

  createSubscriptionAssignment=(subscriptionTypeId= null, followUpSubscribtion= null)=>{
    this.setState({showLoader: true});
    const userAccountID = this.props.profile.accountId;
    const payload= {
      accountId: userAccountID,
      subscriptionType: {
        SubscriptionTypeId: subscriptionTypeId ? subscriptionTypeId : this.state.subscribtionSelected//'346d7115-782a-43d4-a3c2-2a071a384088'
      }
    }

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_profile_subscription_api_baseurl + 'subscriptionAssignments',
      'POST',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handle_subscriptions_Assignments_create',
      {followUpSubscribtion, subscriptionTypeId},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_subscriptions_Assignments_create(response){
    console.log('handle_subscriptions_Assignments_create: ', response);
    if(response.status && response.statuscode === 200){
      showNotification({
        message: response.other.subscriptionTypeId === '97F226D5-A137-48E6-AA9A-605D4488DEC7' ? 'Der Testzeitraum wurde erfolgreich aktiviert.' : 'Vielen Dank! Das Abonnement wurde erfolgreich aktiviert.',
        icon: <img src={GreenTick} alt='green-tick'/>,
        id: 'createSubscriptionAssignmentSuccess'
      });
      let subscription = this.state.subscriptionTypes.find((subs)=> subs?.subscriptionTypeId === this.state.subscribtionSelected);
      this.props.history.push(`${window.location.pathname}?awinTracking=${subscription !== undefined ? subscription.name.toString().toLowerCase() : ''}`);
      this.getsubscriptionsAssignments(true);
      var metadata = {
        subscriptionKey: subscription?.subscriptionKey
      };
      window.Intercom('trackEvent', 'plan-changed', metadata);
      if(response.other.subscriptionTypeId === '97F226D5-A137-48E6-AA9A-605D4488DEC7'){
        window.Intercom('update', {
          api_base: "https://api-iam.intercom.io",
          app_id: "vvrs5bx3",
          "Test Plan End Date": dayjs().add(30, 'days').format()
        });
      }
      this.props.setSelectedSubscriptionForPaymentConfirmation(null);
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
      });
    }

    if(response.other.followUpSubscribtion){
      setTimeout(() => {
        this.createSubscriptionAssignment(response.other.followUpSubscribtion);  
      }, 1000);
    }
    this.setState({showLoader: false}, ()=> this.getAccountById());
  }

  ShowLoader = () => {
    const {subscriptionData, subscriptionTypes, subscribtionSelected, paymentMandate, nextSubscription} = this.state;
    //console.log('show loader abc');
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'none';
    // var emptyDiv = document.getElementById('empty-user-account-subs-details');
    // emptyDiv.style.display = 'none';
    // var dataDiv = document.getElementById('response-user-account-subs-details');
    // dataDiv.style.display = 'block';

    this.setState({skeletonLoading: false}, ()=> {
      if(this.props.location.state && this.props.location.state.newSubscription ? this.props.location.state.newSubscription : false){
        this.props.history.push('subscriptionsView', {subscriptionData, subscriptionTypes, subscribtionSelected, paymentMandate, nextSubscription})
      }
    });
  };

  authorizePayment=()=>{
    const userAccountID = this.props.profile.accountId;
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.account_api_baseurl + userAccountID + '/authorizePayment',
        'PUT',
        {
            accountId: userAccountID,
            paymentMethod: null
        },
        null,
        false,
        true,
        false,
        this,
        'handle_account_authorize_payment',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
}
handle_account_authorize_payment(response){
    console.log('handle_account_authorize_payment: ', response);
    if(response.status){
        setCookie('PaymentAuthorizeInProgress', '1');
        setCookie('SubscriptionSelected', this.state.subscribtionSelected);
        this.props.setSelectedSubscriptionForPaymentConfirmation(this.state.subscribtionSelected);
        setCookie('PaymentAuthorizeRoute', '/profile/yourSubscription');
        window.open(response.result, '_self');
    }
}

  render() {
    const { subsStatus, renewDate, testPeriodCheck, cancellatinoDate, subscriptionData, skeletonLoading, showNewSubscription, subscribtionSelected, paymentConfirmationModal, paymentMethodStatus, paymentMandate, subscriptionTypes, subscriptionName, openPaymentStatusModal, openPaymentRedirectModal, type, showLoader, nextSubscription, cancelSubscriptionModal, openRestrictedMenuMessage } = this.state;
    const {currentSubscription, currentSubscriptionWithPaymentError, accountData, isMobile, hasTested, isTesting} = this.props;
    // if periodEndDate before todaydate or today and endDate is same true means still active - case 1
    // if  periodEndDate before todaydate then false means over - case 2
    console.log('subscriptionData: ', subscriptionData);
    console.log('currentSubscription: ', currentSubscription);

    console.log('subscriptionTypes: ', subscriptionTypes);
    console.log('nextSubscription: ', nextSubscription);
    return (
      <>
        {/* {!showNewSubscription ?  */}
        <>
        {/* <Row style={{marginBottom: '18px', marginTop: '25px'}}>
              <Col xs={12}>
                <div className="payment-heading-main" style={{fontSize: '16px'}}>
                  Abonnements
                </div>
              </Col>
        </Row> */}
        <div id="response-user-account-subs-details" style={{ display: 'block', padding: 0 }}>
          {skeletonLoading ? 
          <div>
            <Skeleton visible={skeletonLoading} height={17.5} width={450} style={{marginBottom: '9px', marginTop: '6.5px'}}/>
            <Skeleton visible={skeletonLoading} height={84} width={352} style={{borderRadius: '4px', margin: '10px 24px 17px 0px'}} />
          </div>
          :
          <div>
            {currentSubscriptionWithPaymentError ? (
            <div className='current-subscription' style={{marginTop: '25px'}}>
              <span className='payment-heading-main' style={{marginBottom: '18px'}}>Aktuelles Abonnement</span>
              <div className='current-subscription-card'>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <span className='current-subscription-card-value'>{currentSubscriptionWithPaymentError.subscriptionAmount.toString().split('.')[0]}<span style={{fontSize: '13px'}}>{currentSubscriptionWithPaymentError.subscriptionAmount > 0 && currentSubscriptionWithPaymentError.subscriptionAmount.toString().split('.')[1]+'0'}</span>€</span>
                  <span className='current-subscription-card-monat'> / {currentSubscriptionWithPaymentError.subscriptionBilling === 'daily' ? 'täglich' : 'Monat'}</span>
                </div>
                <div>
                  {/* <img src={currentSubscriptionWithPaymentError.subscriptionKey.split('_')[0] === 'STARTER' ? StarterBadgeIcon : currentSubscriptionWithPaymentError.subscriptionKey.split('_')[0] === 'PREMIUM' ? PremiumBadgeIcon : ProfessionalBadgeIcon} height={'24px'} /> */}
                  <SubscriptionBadgeIcon name={currentSubscriptionWithPaymentError?.subscriptionName} />
                </div>
              </div>
              {currentSubscriptionWithPaymentError.subscriptionStatus === 'CANCELLED' ?
              nextSubscription ? 
                <>
                  <div className='current-subscription-text'>
                    Ab dem <span style={{fontWeight: 'bold'}}>{dayjs(nextSubscription.startDate, 'DD.MM.YYYY').format('DD.MM.YYYY')}</span> nutzen Sie dann das {nextSubscription?.subscriptionKey === 'STARTER_03_2023' ? 'kostenlose ' : ''}hellohousing <span style={{fontWeight: 'bold'}}>{nextSubscription.subscriptionName}</span> Paket.
                  </div>
                  {currentSubscriptionWithPaymentError.subscriptionKey.split('_')[0] !== 'STARTER' &&
                  <div className='current-subscription-text'>
                    Klicken Sie <span onClick={()=> this.reactivateSubscriptinApi(currentSubscriptionWithPaymentError.subscriptionAssignment)} style={{textDecoration: 'underline', cursor: 'pointer'}}>hier</span>, um die Kündigung rückgängig zu machen.
                  </div>}
                  {nextSubscription?.subscriptionKey !== 'STARTER_03_2023' &&
                  <div className='current-subscription-text'>
                    Klicken Sie <span onClick={()=> this.cancelSubscriptionAPi(nextSubscription.subscriptionAssignment)} style={{textDecoration: 'underline', cursor: 'pointer'}}>hier</span>, um hellohousing <span style={{fontWeight: 'bold'}}>{nextSubscription.subscriptionName}</span> zu kündigen.
                  </div>}
                </>
              :
              <>
              <div className='current-subscription-text'>
                Sie haben Ihr <span style={{fontWeight: 'bold'}}>{currentSubscriptionWithPaymentError.subscriptionName}</span> Paket gekündigt. Zur Nutzung von hellohousing benötigen Sie ein Abonnement. Ab dem <span style={{fontWeight: 'bold'}}>{currentSubscriptionWithPaymentError.cancellatinoDate}</span> verlieren Sie Zugriff auf alle Funktionen von hellohousing und auf alle Ihre Daten.
                {/* können Sie nicht mehr auf die Zusatzfunktionen des <span style={{fontWeight: 'bold'}}>{currentSubscriptionWithPaymentError.subscriptionName}</span> Paketes zugreifen. */}
              </div>
              {currentSubscriptionWithPaymentError.subscriptionKey.split('_')[0] !== 'STARTER' &&
              <div className='current-subscription-text'>
                Klicken Sie <span onClick={()=> this.reactivateSubscriptinApi(currentSubscriptionWithPaymentError.subscriptionAssignment)} style={{textDecoration: 'underline', cursor: 'pointer'}}>hier</span>, um die Kündigung rückgängig zu machen.
              </div>}
              </>
              :
              (currentSubscriptionWithPaymentError.subscriptionKey.split('_')[0] === 'STARTER' ?
              <div className='current-subscription-text'>
                Sie nutzen derzeit das kostenlose <span style={{fontWeight: 'bold'}}>Starter-Paket</span> von hellohousing.
              </div>
              :
              <>
              {currentSubscriptionWithPaymentError.subscriptionKey.split('_')[0] === 'PREMIUM' &&
              <div className='current-subscription-text' style={{height: 'auto'}}>
                Sie nutzen das hellohousing <span style={{fontWeight: 'bold'}}>Premium-Paket</span>.
              </div>}
              <div className='current-subscription-text'>
                Die Laufzeit Ihres Abonnements erneuert sich automatisch am <span style={{fontWeight: 'bold'}}>{currentSubscriptionWithPaymentError.subscriptionRenewDate}</span> und <span style={{fontWeight: 'bold'}}>{currentSubscriptionWithPaymentError.subscriptionAmount === 0 ? '0 €' : formatNumber(currentSubscriptionWithPaymentError.subscriptionAmount, true)}</span> werden mit Ihrer gewählten Zahlungsmethode eingezogen.
              </div>
              {currentSubscriptionWithPaymentError.subscriptionKey.split('_')[0] !== 'STARTER' &&
              <div className='current-subscription-text'>
                Nächster möglicher Kündigungstermin: {currentSubscriptionWithPaymentError.possibleCancellationDate}. Klicken Sie <span onClick={()=> this.setState({openModal: true})} style={{textDecoration: 'underline', cursor: 'pointer'}}>hier</span>, um zu kündigen.
              </div>}
              </>)}
            </div>
            )
            :
            currentSubscription ? (
                <div className='current-subscription' style={{marginTop: '25px'}}>
                  <span className='payment-heading-main' style={{marginBottom: '18px'}}>Aktuelles Abonnement</span>
                  <div className='current-subscription-card'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <span className='current-subscription-card-value'>{currentSubscription?.subscriptionAmount.toString().split('.')[0]}<span style={{fontSize: '13px'}}>{currentSubscription?.subscriptionAmount > 0 && currentSubscription?.subscriptionAmount.toString().split('.')[1]+'0'}</span>€</span>
                      <span className='current-subscription-card-monat'> / {currentSubscription?.subscriptionBilling === 'daily' ? 'täglich' : 'Monat'}</span>
                    </div>
                    <div>
                      {/* <img src={currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? StarterBadgeIcon : currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM' ? PremiumBadgeIcon : ProfessionalBadgeIcon} height={'24px'} /> */}
                      <SubscriptionBadgeIcon name={currentSubscription?.subscriptionName} />
                    </div>
                  </div>
                  {console.log('currentSubscription?.testPeriodEnd: ', moment(currentSubscription?.testPeriodEnd).isAfter(moment()))}
                  {currentSubscription?.subscriptionKey.split('_')[0] !== 'STARTER' && moment(currentSubscription?.testPeriodEnd).isAfter(moment()) &&
                  <div className='current-subscription-text' style={{height: 'auto'}}>
                    {currentSubscription?.subscriptionKey === 'PROFESSIONAL_TESTING_12_2024' ?
                    <Stack>
                      <span>Sie testen hellohousing kostenlos und unverbindlich bis zum <span style={{fontWeight: 'bold'}}>{moment(currentSubscription?.testPeriodEnd).format('DD.MM.YYYY')}</span>.</span>
                      {nextSubscription ? 
                      <span>Ab dem <span style={{fontWeight: 'bold'}}>{nextSubscription.startDate}</span> nutzen Sie hellohousing im <span style={{fontWeight: 'bold'}}>{nextSubscription.subscriptionName}</span> Abonnement.</span> 
                      :
                      <span>Wählen Sie noch heute Ihr Abonnement, um nach dem <span style={{fontWeight: 'bold'}}>{moment(currentSubscription?.testPeriodEnd).format('DD.MM.YYYY')}</span> Zugriff auf Ihre Daten zu behalten und hellohousing nahtlos weiter zu nutzen. Sie testen hellohousing in jedem Fall im vollen Umfang kostenlos bis zum Abschluss des Testzeitraums.</span>}

                      {nextSubscription &&
                      <div>
                          Klicken Sie <span onClick={()=> this.setState({openModal: true})} style={{textDecoration: 'underline', cursor: 'pointer'}}>hier</span>, um hellohousing <span style={{fontWeight: 'bold'}}>{nextSubscription.subscriptionName}</span> zu kündigen.
                      </div>}
                    </Stack>
                    :
                    <span>Sie testen hellohousing <span style={{fontWeight: 'bold'}}>{currentSubscription?.subscriptionName}</span> derzeit kostenlos bis zum <span style={{fontWeight: 'bold'}}>{moment(currentSubscription?.testPeriodEnd).format('DD.MM.YYYY')}</span>.</span>}
                  </div>
                  }
                  {currentSubscription?.subscriptionStatus === 'CANCELLED' ?
                  nextSubscription ? 
                    <>
                      <div className='current-subscription-text' style={{height: 'auto'}}>
                        Ab dem <span style={{fontWeight: 'bold'}}>{dayjs(nextSubscription.startDate, 'DD.MM.YYYY').format('DD.MM.YYYY')}</span> nutzen Sie dann das {nextSubscription?.subscriptionKey === 'STARTER_03_2023' ? 'kostenlose ' : ''}hellohousing <span style={{fontWeight: 'bold'}}>{nextSubscription.subscriptionName}</span> Paket.
                      </div>
                      {/* {currentSubscription?.subscriptionKey.split('_')[0] !== 'STARTER' &&
                      <div className='current-subscription-text' style={{height: 'auto'}}>
                        Klicken Sie <span onClick={()=> this.reactivateSubscriptinApi(currentSubscription?.subscriptionAssignment)} style={{textDecoration: 'underline', cursor: 'pointer'}}>hier</span>, um die Kündigung von hellohousing <span style={{fontWeight: 'bold'}}>{currentSubscription?.subscriptionName}</span> rückgängig zu machen.
                      </div>} */}
                      {nextSubscription?.subscriptionKey !== 'STARTER_03_2023' &&
                      <div className='current-subscription-text' style={{height: 'auto'}}>
                        Klicken Sie <span onClick={()=> currentSubscription?.subscriptionKey.includes('2023') ? this.cancelSubscriptionAPi(nextSubscription.subscriptionAssignment) : this.setState({openModal: true})} style={{textDecoration: 'underline', cursor: 'pointer'}}>hier</span>, um hellohousing <span style={{fontWeight: 'bold'}}>{nextSubscription.subscriptionName}</span> zu kündigen.
                      </div>}
                    </>
                  :
                  <>
                  <div className='current-subscription-text'>
                    Sie haben Ihr <span style={{fontWeight: 'bold'}}>{currentSubscription?.subscriptionName}</span> Paket gekündigt. Zur Nutzung von hellohousing benötigen Sie ein Abonnement. Ab dem <span style={{fontWeight: 'bold'}}>{currentSubscription?.cancellatinoDate}</span> verlieren Sie Zugriff auf alle Funktionen von hellohousing und auf alle Ihre Daten.
                    {/* können Sie nicht mehr auf die Zusatzfunktionen des <span style={{fontWeight: 'bold'}}>{currentSubscription?.subscriptionName}</span> Paketes zugreifen. */}
                  </div>
                  {currentSubscription?.subscriptionKey !== 'STARTER_03_2023' &&
                  <div className='current-subscription-text'>
                    Klicken Sie <span onClick={()=> this.reactivateSubscriptinApi(currentSubscription?.subscriptionAssignment)} style={{textDecoration: 'underline', cursor: 'pointer'}}>hier</span>, um die Kündigung rückgängig zu machen.
                  </div>}
                  </>
                  :
                  (currentSubscription?.subscriptionKey === 'STARTER_03_2023' ?
                  <div className='current-subscription-text'>
                    Sie nutzen derzeit das kostenlose <span style={{fontWeight: 'bold'}}>Starter-Paket</span> von hellohousing.
                  </div>
                  :
                  <>
                  {(currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' && currentSubscription?.subscriptionKey !== 'STARTER_03_2023') &&
                  <div className='current-subscription-text' style={{height: 'auto'}}>
                    Sie nutzen das hellohousing <span style={{fontWeight: 'bold'}}>Starter-Paket</span>.
                  </div>}
                  {currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM' &&
                  <div className='current-subscription-text' style={{height: 'auto'}}>
                    Sie nutzen das hellohousing <span style={{fontWeight: 'bold'}}>Premium-Paket</span>.
                  </div>}
                  {currentSubscription?.subscriptionAmount > 0 && <div className='current-subscription-text'>
                    Die Laufzeit Ihres Abonnements erneuert sich automatisch am <span style={{fontWeight: 'bold'}}>{currentSubscription?.subscriptionRenewDate}</span> und <span style={{fontWeight: 'bold'}}>{currentSubscription?.subscriptionAmount === 0 ? '0 €' : formatNumber(currentSubscription?.subscriptionAmount, true)}</span> werden mit Ihrer gewählten Zahlungsmethode eingezogen.
                  </div>}
                  {currentSubscription?.subscriptionAmount > 0 &&
                  <div className='current-subscription-text'>
                    Nächster möglicher Kündigungstermin: {currentSubscription?.possibleCancellationDate}. Klicken Sie <span onClick={()=> this.setState({openModal: true})} style={{textDecoration: 'underline', cursor: 'pointer'}}>hier</span>, um zu kündigen.
                  </div>}
                  </>)}
                </div>
            ) : (
              <Stack style={{maxWidth: isMobile ? 'auto' : '450px'}}>
                {(!currentSubscription && hasTested) ?
                <div className='current-subscription'>
                  <br/>
                  <span className='payment-heading-main' style={{fontWeight: 'bold', whiteSpace: !isMobile && 'nowrap'}}>Bitte wählen Sie Ihr Abonnement</span>
                  <br/>
                  <br/>
                  <span className='current-subscription-text'>Um hellohousing zu nutzen, benötigen Sie ein Abonnement. Lernen Sie jetzt den Funktionsumfang von hellohousing Starter, Premium und Professional kennen und wählen Sie das für Ihre Bedürfnisse passende Abonnement aus. Alle Abonnements sind monatlich kündbar.</span>
                </div>
                :
                <div className='current-subscription'>
                  <br/>
                  <span className='payment-heading-main' style={{fontWeight: 'bold', whiteSpace: !isMobile && 'nowrap'}}>Testen Sie hellohousing 30 Tage kostenlos und unverbindlich</span>
                  <br/>
                  <br/>
                  <span className='current-subscription-text'>Testen Sie hellohousing 30 Tage kostenlos und unverbindlich im vollen Funktionsumfang. Der Testzeitraum endet automatisch. Im Anschluss wählen Sie ein passendes Abonnement aus. Ihre Daten bleiben erhalten.</span>
                </div>}
                {!(!currentSubscription && hasTested) &&
                <div>
                  <ColorButton
                  text={'Testzeitraum starten'}
                  className='purple-btn'
                  onClick={()=> this.setState({openRestrictedMenuMessage: true})}//this.createSubscriptionAssignment('97F226D5-A137-48E6-AA9A-605D4488DEC7')
                  />
                </div>}
                {/* <div className='upgrade-subscription' style={{marginTop: 0}}>
                  <div className='upgrade-subscription-text' style={{marginTop: 0}} onClick={()=> this.setState({showNewSubscription: true, subscribtionSelected: ''}, ()=> this.props.history.push('subscriptionsView', {subscriptionData, subscriptionTypes, subscribtionSelected, paymentMandate, nextSubscription}))}>
                    Mehr zu den Abonnements erfahren
                  </div>
                </div> */}
              </Stack>
            )}
            {((!currentSubscription && hasTested) || (!nextSubscription && isTesting) || (currentSubscription?.subscriptionStatus === 'CANCELLED' && !nextSubscription)) && 
            <div style={{marginTop: '15px'}}>
              <ColorButton
              text={'Abonnements kennenlernen'}
              className={'purple-btn'}
              onClick={()=> this.setState({showNewSubscription: true, subscribtionSelected: ''}, ()=> this.props.history.push('subscriptionsView', {subscriptionData, subscriptionTypes, subscribtionSelected, paymentMandate, nextSubscription}))}
              />
            </div>}
            {<div className='upgrade-subscription'>
              <span className='payment-heading-main' style={{marginBottom: '18px'}}>Ihre Optionen</span>
              {subscriptionTypes.filter((subscription)=> subscription?.subscriptionKey.includes('2024') || subscription?.subscriptionKey.includes(currentSubscription?.subscriptionKey))
              .sort((a,b)=> a.price - b.price)
              .filter((subscriptionType)=> subscriptionData.find((data)=> (currentSubscriptionWithPaymentError ? data.subscriptionAssignment === currentSubscriptionWithPaymentError?.subscriptionAssignment : data.subscriptionAssignment === currentSubscription?.subscriptionAssignment) && data.subscriptionKey === subscriptionType.subscriptionKey) === undefined)
              .filter((subscriptionType)=> nextSubscription ? subscriptionType.subscriptionKey !== nextSubscription.subscriptionKey : subscriptionType)
              .map((subscriptionType)=> {
                console.log('subscriptionType: ', subscriptionType);
              const paidPlan= ((subscriptionType.subscriptionKey.includes('STARTER') && subscriptionType.subscriptionKey.includes('2024')) || subscriptionType.subscriptionKey.split('_')[0] !== 'STARTER');  
              return (paidPlan &&
              <div className='upgrade-subscription-card'>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}} onClick={()=> subscriptionType.status === 'Available' && this.setState({showNewSubscription: true, subscribtionSelected: subscriptionType.subscriptionTypeId}, ()=> this.props.history.push('subscriptionsView', {subscriptionData, subscriptionTypes, subscribtionSelected, paymentMandate}))}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span className='upgrade-subscription-card-value'>{subscriptionType.price.toString().split('.')[0]}<span style={{fontSize: '13px'}}>{((subscriptionType.subscriptionKey.includes('STARTER') && subscriptionType.subscriptionKey.includes('2024')) || subscriptionType.subscriptionKey.split('_')[0] !== 'STARTER') && subscriptionType.price.toString().split('.')[1]+'0'}</span>€</span>
                    <span className='upgrade-subscription-card-monat'> / {subscriptionType.billing === 'daily' ? 'täglich' : 'Monat'}</span>
                  </div>
                  <div style={{textAlign: 'right'}}>
                    {/* <img src={subscriptionType.subscriptionKey.split('_')[0] === 'PREMIUM' ?  PremiumBadgeIcon : subscriptionType.subscriptionKey.split('_')[0] === 'STARTER' ? StarterBadgeIcon : ProfessionalBadgeIcon} height={'24px'} /> */}
                    <SubscriptionBadgeIcon name={subscriptionType?.name} />
                    {(accountData.isPremiumPlanTested === false || accountData.isProfessionalPlanTested === false ) &&
                    <div className='upgrade-subscription-card-monat' style={{fontSize: '10px', whiteSpace: 'nowrap', fontWeight: 400, marginLeft: 0, marginTop: '5px'}}>
                      {subscriptionType.price > 0 && (hasTested ? 'Jetzt freischalten' : 'Kostenlos testen')}
                    </div>}
                  </div>
                </div>
                {/* <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                  <div className='upgrade-subscription-card-monat' style={{fontSize: '10px', whiteSpace: 'nowrap', marginLeft: 0, marginTop: '-10px'}}>
                    {subscriptionType.subscriptionKey.split('_')[0] === 'PREMIUM' ? accountData.isPremiumPlanTested === false && 'Jetzt freischalten' : subscriptionType.subscriptionKey.split('_')[0] === 'PROFESSIONAL' && accountData.isProfessionalPlanTested === false && 'Jetzt freischalten'}
                  </div> 
                </div> */}
              </div>);
              })}
              <div className='upgrade-subscription-text' onClick={()=> this.setState({showNewSubscription: true, subscribtionSelected: ''}, ()=> this.props.history.push('subscriptionsView', {subscriptionData, subscriptionTypes, subscribtionSelected, paymentMandate, nextSubscription}))}>
                Mehr zu den Abonnements erfahren
              </div>
            </div>}
          </div>}
        </div>
        {this.state.openModal &&
        <DeleteModal
          open={this.state.openModal}
          className="delete-rent-modal"
          toggle={()=>this.setState({openModal: !this.state.openModal})}
          // record={this.state.tenant}
          handleDelete={() => (currentSubscription?.subscriptionStatus === 'CANCELLED' || currentSubscription.subscriptionKey === 'PROFESSIONAL_TESTING_12_2024') ? this.cancelSubscriptionAPi(nextSubscription.subscriptionAssignment) : this.reactivateCancelSub(this.state.subsId)}
          // showInfo={[this.state.currentUnit.name, `${this.state.currentUnit.size} m²`]}
          textShowModal={'Abonnement kündigen'}
          showtext={false}
          showDeleteText={false}
          typeText={
            //(currentSubscription?.subscriptionStatus !== 'CANCELLED' ?
            <span><p>Sie benötigen ein gültiges Abonnement, um hellohousing nutzen zu können.</p><p style={{fontWeight: 'bold'}}>Wenn Sie Ihr Abonnement jetzt kündigen, dann verlieren Sie ab dem {currentSubscription.subscriptionKey === 'PROFESSIONAL_TESTING_12_2024' ? dayjs(currentSubscription?.testPeriodEnd).add(1, 'day').format('DD.MM.YYYY') : renewDate} Zugriff auf alle Funktionen von hellohousing sowie auf Ihre Daten.</p><p>Sind Sie sicher, dass Sie Ihr Abonnement kündigen wollen?</p></span> //: nextSubscription.nextPaymentDue
            // currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM' : nextSubscription.subscriptionName === 'Premium') ?
            // <Stack spacing={10}>
            //   <Group>
            //     <span>Mit der Kündigung von hellohousing Premium <span style={{fontWeight: 'bold'}}>verlieren Sie ab dem {currentSubscription?.subscriptionStatus === 'CANCELLED' ? nextSubscription.nextPaymentDue : renewDate} Zugriff auf diese Premium Funktionen und Daten</span></span>
            //   </Group>
            //   <Group>
            //     <ul style={{marginBottom: 0, paddingLeft: 14}}>
            //       <li>Nur noch 1 Immobilie mit maximal 9 Mieteinheiten verwalten</li>
            //       <li>Heizkostenabrechnungen durchführen</li>
            //       <li>Gewerbliche Mieter verwalten mit MwSt.-Ausweis</li>
            //       <li>Eigenes Logo einbinden</li>
            //       <li>Automatisierte Belegerkennung mit KI-Unterstützung</li>
            //       <li>Beschränkung auf 100 MB Speicherplatz für Dokumente (statt 1 GB)</li>
            //       <li>Expertensupport via Chat, Email und Telefon</li>
            //     </ul>
            //   </Group>
            //   <Group>
            //     <span>Sind Sie sicher, dass Sie Ihr Premium Abonnement kündigen wollen?</span>
            //   </Group>
            // </Stack>
            // :
            // (currentSubscription?.subscriptionStatus !== 'CANCELLED' ?
            // currentSubscription?.subscriptionKey.split('_')[0] === 'PROFESSIONAL' : nextSubscription.subscriptionName === 'Professional') ?
            // <Stack spacing={10}>
            //   <Group>
            //     <span>Mit der Kündigung von hellohousing Professional <span style={{fontWeight: 'bold'}}>verlieren Sie ab dem {renewDate} Zugriff auf diese Professional Funktionen und Daten</span></span>
            //   </Group>
            //   <Group>
            //     <ul style={{marginBottom: 0, paddingLeft: 14}}>
            //       <li>Nur noch 1 Immobilie mit maximal 9 Mieteinheiten verwalten</li>
            //       <li>Heizkostenabrechnungen durchführen</li>
            //       <li>Gewerbliche Mieter verwalten mit MwSt.-Ausweis</li>
            //       <li>Eigenes Logo einbinden</li>
            //       <li>Automatisierte Belegerkennung mit KI-Unterstützung</li>
            //       <li>Beschränkung auf 100 MB Speicherplatz für Dokumente (statt 2 GB)</li>
            //       <li>Expertensupport via Chat, Email und Telefon</li>
            //       <li>Funkzählerdaten einbinden und verarbeiten</li>
            //       <li>Kein Zugriff auf das Mieterportal mehr möglich</li>
            //     </ul>
            //   </Group>
            //   <Group>
            //     <span>Sind Sie sicher, dass Sie Ihr Professional Abonnement kündigen wollen?</span>
            //   </Group>
            // </Stack>
            // :
            // `Sie sind dabei Ihr hellohousing ${subscriptionName} Abonnement zu kündigen. Ab dem ${renewDate} nutzen Sie dann das Starter Paket. Sie können dann nicht mehr auf die Zusatzfunktionen des ${subscriptionName} Paketes zugreifen. Sind Sie sicher, dass Sie fortfahren möchten?`)
          }
          modalId="unit-delete-modal-footer"
          rightButtonText={'Abonnement kündigen'}
          leftButtonText={'Abbrechen'}
          leftButtonClassName={'purple-btn'}
        />}
        
        {/* // :
        // <>
        // <NewSubscription
        // subscriptionData={subscriptionData}
        // subscriptionTypes={subscriptionTypes}
        // subscribtionSelected={subscribtionSelected}
        // closeNewSubscription={(selectedSubscription=null)=> this.setState({showNewSubscription: false, subscribtionSelected: selectedSubscription, paymentConfirmationModal: selectedSubscription && accountData.accountPaymentStatus === 'AUTHORIZED' && true, openPaymentStatusModal: selectedSubscription && accountData.accountPaymentStatus !== 'AUTHORIZED' && true})}
        // paymentMandate={paymentMandate}
        // /> */}
        </>
        {paymentConfirmationModal &&
        <PaymentConfirmationModal
        open={paymentConfirmationModal}
        subscribtionSelected={subscribtionSelected}
        subscriptionTypes={subscriptionTypes}
        toggle={()=> this.setState({paymentConfirmationModal: false})}
        confirmPayment={()=> hasTested ? this.createSubscriptionAssignment() : currentSubscription?.subscriptionKey === 'PROFESSIONAL_TESTING_12_2024' ? this.createSubscriptionAssignment() : this.createSubscriptionAssignment('97F226D5-A137-48E6-AA9A-605D4488DEC7', this.state.subscribtionSelected)}
        showLoader={showLoader}
        />}
        {openPaymentStatusModal &&
        <PaymentTemplateSuccessModal
        open={openPaymentStatusModal}
        toggle={()=>this.setState({openPaymentStatusModal: false})}
        onNext={()=> this.setState({paymentConfirmationModal: true, openPaymentStatusModal: false})}
        type={'authorize'}
        history={this.props.history}
        authorize={()=>
          //this.authorizePayment()
          this.setState({openPaymentRedirectModal: true, openPaymentStatusModal: false})
        }
        getPaymentDetails={()=> this.getPaymentDetails()}
        />}
        {openPaymentRedirectModal &&
        <PaymentTemplateModal
        open={openPaymentRedirectModal}
        toggle={()=>this.setState({openPaymentRedirectModal: false})}
        type={type}
        route={'/profile/yourSubscription'}
        subscribtionSelected={subscribtionSelected}
        />}

      {openRestrictedMenuMessage &&
      <RestrictedMenuMessageModal
      open={openRestrictedMenuMessage}
      toggle={()=> this.setState({openRestrictedMenuMessage: false})}
      history={this.props.history}
      />}
      </>
    );
  }
}

// export default YourSubscription;

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
    currentSubscription: state.generalReducer.currentSubscription,
    currentSubscriptionWithPaymentError: state.generalReducer.currentSubscriptionWithPaymentError,
    accountData: state.generalReducer.accountData,
    selectedSubscriptionForPaymentConfirmation: state.generalReducer.selectedSubscriptionForPaymentConfirmation,
    hasTested: state.generalReducer.hasTested,
    isTesting: state.generalReducer.isTesting
  };
};

const actions = {
  setSubscriptionData,
  setCurrentSubscriptionData,
  setCurrentSubscriptionDataWithPaymentError,
  setSelectedSubscriptionForPaymentConfirmation,
  setAccountData,
  setCompanyLogo,
  setNextSubscriptionData,
  setTestSubscriptionComplete,
  setIsTesting
};

export default connect(mapStateToProps, actions)(YourSubscription);

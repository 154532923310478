import React, { Component } from 'react';
import CustomModal from './index';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { Group, Loader, Stack } from '@mantine/core';
import DropFile from '../DropFile/DropFile';
import { Form } from 'react-bootstrap';
import ColorButton from '../Buttons/ColorButton';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import RightArrowPartner from '../../../../../assets/images/module/general/RightArrowPartner.svg';
import { showNotification } from '@mantine/notifications';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@material-ui/core';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import AlertModal from './AlertModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment, loader } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class CostPositionValuesModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    rentalUnits: [],
    tenants: [],
    data: [],
    costPositionData: null,
    errorWarningModal: false,
    oldTotalAmount: null,
    tenantConsumptionInFocus: false,
    tenantCostShareInFocus: false,
    totalConsumptionInFocus: false,
    totalCostShareInFocus: false
  };

  componentDidMount(){
    const {allocationKey, propertySelected, costPositionData} = this.props;
    const {data} = this.state;
    this.getCostAllocations();
    this.setState({
        costPositionData: costPositionData
    });
  }

  getRentalUnits=()=>{
    this.setState({loader: true});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + this.props.propertySelected.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetRentalUnits',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetRentalUnits(response) {
    console.log('handleGetRentalUnits: ', response);
    if(response.status && response.statuscode === 200){
      this.setState({rentalUnits: response.result}, async ()=> {
        await response.result.map((rentalUnit)=> this.getTenants(rentalUnit.id));
        //this.getAllocationKeyValues(this.props.allocationKeyId);
        this.getCostAllocations();
      });
    }else{
      this.setState({loader: false});
    }
  }


  getTenants=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTenants',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetTenants(response){
    if(response.status && response.statuscode === 200){
      let {tenants} = this.state;
      response.result.map((tenant)=> tenants.push(tenant));
      this.setState({tenants});
    }
  }

  getAllocationKeyValues=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'allocationKeyValues?allocationKeyId=' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetAllocationKeyValues',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetAllocationKeyValues(response){
    console.log(response);
    if(response.status && response.statuscode === 200){
      //this.setState({data: response.result});
      this.setState({loader: false});
    }else{
      this.setState({loader: false});
    }
  }

  getCostAllocations=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url +
        'costAllocations/forCostPositions?utilityBillingId=' +
        this.props.utilityBillingId +
        '&costPositionId=' +
        this.props.costPositionId,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        '_handleGetModalData',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
  }

  _handleGetModalData(response){
    const {allocationKey, propertySelected, costPositionData} = this.props;
    if(response.status && response.statuscode === 200){
        this.setState({
            data: response.result,
            oldTotalAmount: (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR') ? costPositionData.amount : response.result.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantCostShare ? a.tenantCostShare : null), 0)
        });
    }
  }

  validateCosts=()=> {
    let totalIndividualCosts = this.state.data.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantCostShare ? a.tenantCostShare : null), 0);
    if(totalIndividualCosts > this.state.costPositionData.amount){
        //this.setState({errorWarningModal: true});
        showNotification({
            message: 'Die Einzelkosten dürfen die Gesamtkosten (WEG) nicht überschreiten.',
            icon: <img src={RedCross} alt='red-cross'/>,
            id: 'errorMessageCost'
          })
    } else {
        this.updateCostAllocations();
    }
  }

  updateCostAllocations=(callUpdateConsumptionsByCostPositionId=false)=> {  
    //callUpdateConsumptionsByCostPositionId === false && loader(true);
    let formatedData = this.state.data.map((data) => {
      return {
        id: checkProperty(data, 'id'),
        utilityBillingId: checkProperty(data, 'utilityBillingId'),
        costPositionId: checkProperty(data, 'costPositionId'),
        costTypeName: checkProperty(data, 'costTypeName'),
        allocationTypeName: checkProperty(data, 'allocationTypeName'),
        billingStatementId: checkProperty(data, 'billingStatementId'),
        tenantName: checkProperty(data, 'tenant'),
        rentalUnitId: checkProperty(data, 'rentalUnitId'),
        rentalUnitName: checkProperty(data, 'rentalUnit'),
        tenantOccupation: checkProperty(data, 'tenantOccupation', {}),
        tenantConsumption: checkProperty(data, 'tenantConsumption'),
        totalConsumption: checkProperty(data, 'totalConsumption'),
        consumptionUnits: checkProperty(data, 'consumptionUnits'),
        totalCostShare: checkProperty(data, 'totalCostShare'),
        tenantCostShare: checkProperty(data, 'tenantCostShare'),
        // amount: Autoload.MainHelper.ConverterLocaleNumber(data.amount, "de"),
      };
    });
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.swagger_mocked_api_baseUrl +
      Autoload.Setting.ub_api_base_url +
        'costAllocations/costAllocations/forCostPosition?utilityBillingId=' +
        this.props.utilityBillingId +
        '&costPositionId=' +
        this.props.costPositionId,
      'PUT',
      formatedData,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateCostAllocations',
      {callUpdateConsumptionsByCostPositionId},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleUpdateCostAllocations = (response) => {
    const {allocationKey, propertySelected, cosumptionType} = this.props;
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_saving_cost_position);
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'generalErrorMessage'
      })
    }
    if(response.status === true) {
      showNotification({
        message: 'Die Änderungen wurden übernommen.',
        icon: <img src={GreenTick} alt='green-tick'/>,
        id: 'successUpdateCostAllocations'
      });
      this.props.getTenantsConsumption();
    }
    console.log('response.other.callUpdateConsumptionsByCostPositionId: ', response.other.callUpdateConsumptionsByCostPositionId);
    if(response.other.callUpdateConsumptionsByCostPositionId === false){
        // (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR') ? 
        // this.props.saveAmount(this.state.costPositionData.amount) 
        // :
        // (cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') ?
        // this.props.saveAmount(this.state.data[0].totalConsumption)
        // : 
        // this.props.saveAmount(this.getTotalAmount());
        //loader(false);
    }else{
        this.updateConsumptionsByCostPositionId(this.state.costPositionData.id);
    }
    //this.resetForm();
  };

  getTotalAmount = () =>{
    return Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.data.reduce(
      (total, currentValue) => total + currentValue.tenantCostShare,
      0) + '', true)
  }

  updateConsumptionsByCostPositionId = (costPositionIds) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateConsumptions/' + this.props.currentDataId+'?costPositionIds='+costPositionIds,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateConsumptionsByCostPositionIdResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleUpdateConsumptionsByCostPositionIdResponse(response){
    this.getCostAllocations();
  }
  
  render() {
    const { open, toggle, className, costType, allocationKey, cosumptionType, consumptionStatus, propertySelected } = this.props;
    const {data, tenants, rentalUnits, costPositionData, errorWarningModal, oldTotalAmount, tenantConsumptionInFocus, tenantCostShareInFocus, totalConsumptionInFocus, totalCostShareInFocus} = this.state;
    console.log('data: ', data);
    console.log('allocationKey: ', allocationKey);
    console.log('cosumptionType: ', cosumptionType);

    console.log('tenantCostShareInFocus: ', tenantCostShareInFocus);
    return (
    <CustomModal
    heading={(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) ? 'Kosten individuell zuweisen' : 'Verteilerwerte der Mieter prüfen'}//<Group noWrap>{`Kostenart: ${costType}, Verteilerschlüssel: ${`${allocationKey.allocationKeyName} ${allocationKey.units && allocationKey.units !== '-' ? `(${allocationKey.units})` : ''}`}, (${cosumptionType}),`}{consumptionStatus}</Group>}
    open={open}
    toggle={()=> {
        // console.log('oldTotalAmount: ', oldTotalAmount, 
        // (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR') ? 
        // this.state.costPositionData.amount
        // :
        // (cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') &&
        // this.state.data[0].totalConsumption
        // );

        //newTotalAmount - start
        let newTotalAmount = (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR') ? 
        this.state.costPositionData.amount
        :
        (cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') ?
        this.state.data[0].totalCostShare
        :
        (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
        this.state.data.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantCostShare ? a.tenantCostShare : null), 0);
        //newTotalAmount - end
        console.log('oldTotalAmount: ', newTotalAmount !== false && newTotalAmount !== oldTotalAmount);
        if(newTotalAmount !== false && newTotalAmount !== oldTotalAmount){
            this.props.saveAmount(newTotalAmount);   
        }
        // (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR') ? 
        // this.props.saveAmount(this.state.costPositionData.amount) 
        // :
        // (cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') &&
        // this.props.saveAmount(this.state.data[0].totalConsumption)
        // : 
        // this.props.saveAmount(this.getTotalAmount());
        toggle();
    }}
    className={`${className}`}
    style={{maxWidth: '1000px'}}
    >
        <Stack>
            <Stack style={{marginBottom: '10px'}}>
                <Group>
                    <span className='modal-sub-headings'>Kostenart und Verteilerschlüssel</span>
                </Group>
                <Group spacing={50}>
                    <Stack spacing={0}>
                        <span style={{ fontSize: 12, marginBottom: 4, color: '#727293', fontFamily: 'Inter, sans-serif', fontWeight: '400' }}>{`Kostenart`}</span>
                        <span style={{ fontSize: 14, overflow: 'hidden', whiteSpace: 'normal', textOverflow: 'ellipsis', fontWeight: '400' }}>{costType}</span>
                    </Stack>
                    <Stack spacing={0}>
                        <span style={{ fontSize: 12, marginBottom: 4, color: '#727293', fontFamily: 'Inter, sans-serif', fontWeight: '400' }}>{`Verteilerschlüssel`}</span>
                        <span style={{ fontSize: 14, overflow: 'hidden', whiteSpace: 'normal', textOverflow: 'ellipsis', fontWeight: '400' }}>{`${allocationKey.allocationKeyName} ${allocationKey.units && allocationKey.units !== '-' ? `(${allocationKey.units})` : ''}`}</span>
                    </Stack>
                    {!(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
                    <Stack spacing={0}>
                        <span style={{ fontSize: 12, marginBottom: 4, color: '#727293', fontFamily: 'Inter, sans-serif', fontWeight: '400' }}>{`Art`}</span>
                        <span style={{ fontSize: 14, overflow: 'hidden', whiteSpace: 'normal', textOverflow: 'ellipsis', fontWeight: '400' }}>{`${cosumptionType === 'FIXED_RENTAL_UNIT' ? 'Fester Verteilerschlüssel' : 'Jährliche Verbrauchserfassung'}`}</span>
                    </Stack>}
                    
                    <Stack spacing={0}>
                        <span style={{ fontSize: 12, marginBottom: 4, color: '#727293', fontFamily: 'Inter, sans-serif', fontWeight: '400' }}>{`Verteilerwerte`}</span>
                        <span style={{ fontSize: 14, overflow: 'hidden', whiteSpace: 'normal', textOverflow: 'ellipsis', fontWeight: '400' }}>{consumptionStatus}</span>
                    </Stack>
                </Group>
                {/* <Group>
                    <span>{`(${cosumptionType === 'FIXED_RENTAL_UNIT' ? 'Fester Verteilerschlüssel' : 'Jährliche Verbrauchserfassung'})`}</span>
                    {consumptionStatus}
                </Group> */}
                {(propertySelected.propertyType !== 'REGULAR' ?
                (cosumptionType !== 'USAGE_BASED' &&
                !(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
                //!(allocationKey.allocationKeyName === 'Wohnfläche' && allocationKey.systemAllocationKey) &&
                //!(allocationKey.allocationKeyName === 'Anzahl Wohnungen' && allocationKey.systemAllocationKey) &&
                !(allocationKey.allocationKeyName === 'Personenanzahl' && allocationKey.systemAllocationKey))
                :
                (!(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) //&&
                //!(allocationKey.allocationKeyName === 'Wohnfläche' && allocationKey.systemAllocationKey) &&
                //!(allocationKey.allocationKeyName === 'Anzahl Wohnungen' && allocationKey.systemAllocationKey) &&
                //!(allocationKey.allocationKeyName === 'Personenanzahl' && allocationKey.systemAllocationKey)
                )
                ) &&
                <Group
                onClick={() => {
                    allocationKey.allocationKeyName === 'Anzahl Wohnungen' && allocationKey.systemAllocationKey ? propertySelected.propertyType !== 'REGULAR' ? this.props.redirectToPropertyScreen() : this.props.redirectToTenantScreen() 
                    : 
                    allocationKey.allocationKeyName === 'Wohnfläche' && allocationKey.systemAllocationKey ? propertySelected.propertyType !== 'REGULAR' ? this.props.redirectToPropertyScreen() : this.props.redirectToTenantScreen()
                    :
                    allocationKey.allocationKeyName === 'Personenanzahl' && allocationKey.systemAllocationKey ? this.props.redirectToTenantScreen()
                    :
                    cosumptionType === 'FIXED_RENTAL_UNIT' ? this.props.openFixedAllocationKeyModal() : this.props.redirectToConsumptionEditValues();
                    toggle();
                }}
                style={{color: '#5555FF', fontWeight: '400', fontFamily: 'Inter, sans-serif', fontSize: '14px', cursor: 'pointer'}}
                spacing={5} 
                align='center'
                >
                    {allocationKey.allocationKeyName === 'Anzahl Wohnungen' && allocationKey.systemAllocationKey ? propertySelected.propertyType !== 'REGULAR' ? 'Gesamtanzahl Mieteinheiten (WEG) anpassen (Immobilie)' : 'Mieteinheiten anpassen (Mieter)' 
                    : 
                    allocationKey.allocationKeyName === 'Wohnfläche' && allocationKey.systemAllocationKey ? propertySelected.propertyType !== 'REGULAR' ? 'Mietfläche Eigentumswohnung oder Gesamtmietfläche (WEG) anpassen (Immobilie)' : 'Mietflächen anpassen (Mieter)'
                    :
                    allocationKey.allocationKeyName === 'Personenanzahl' && allocationKey.systemAllocationKey ? 'Personenanzahl Mietverhältnisse anpassen (Mieter)'
                    :
                    cosumptionType === 'FIXED_RENTAL_UNIT' ? 'Verteilerwerte anpassen' : 'Verbrauchswerte anpassen (Ablesungen)'}
                    <img src={RightArrowPartner} style={{marginTop: '3px', width: '12px'}}/>
                </Group>}
            </Stack>
            <Stack spacing={0}>
                <Group>
                    <span className='modal-sub-headings'>Verteilerwerte und Kosten der Mieter</span>
                </Group>
                {data.length > 0 ? 
                <Table className="table-material-Zuweisung" responsive style={{borderCollapse: 'separate', borderSpacing: '0px 8px'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Mieter</TableCell>
                            <TableCell>Mieteinheit</TableCell>
                            <TableCell>Zeitraum</TableCell>
                            {!(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
                            <TableCell style={{whiteSpace: 'nowrap', textAlign: 'right'}}>Wert Mieter</TableCell>}
                            {!(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
                            <TableCell style={{textAlign: 'left'}}>Einheit</TableCell>}
                            <TableCell style={{whiteSpace: 'nowrap', textAlign: 'right'}}>Kosten Mieter</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length > 0 && 
                        data.sort((a,b)=> a.rentalUnitName === b.rentalUnitName ? dayjs(a.tenantOccupation.startDate).isAfter(dayjs(b.tenantOccupation.startDate)) : a.rentalUnitName.localeCompare(b.rentalUnitName))
                        //.sort((a,b)=> dayjs(a.tenantOccupation.startDate).isBefore(dayjs(b.tenantOccupation.startDate)))
                        .map((row)=>
                        <TableRow>
                            <TableCell width={'25%'}>
                                {row.tenantName}
                            </TableCell>
                            <TableCell width={'25%'}>
                                {row.rentalUnitName}
                            </TableCell>
                            <TableCell width={'38%'}>
                                {`${dayjs(row.tenantOccupation.startDate).format('DD.MM.YYYY')} - ${dayjs(row.tenantOccupation.endDate).format('DD.MM.YYYY')} (${row.tenantOccupation.totalDays} Tage)`}
                            </TableCell>
                            {!(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
                            <TableCell style={{textAlign: 'right'}}>
                                <NumericFormat
                                className={((cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') || (allocationKey.allocationKeyName === 'Personenanzahl' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR')) ? 'form-control' : 'numeric-format-text-view'}
                                value={row.tenantConsumption}
                                thousandSeparator="." 
                                decimalSeparator=","
                                suffix={''}
                                placeholder='0,00'
                                onFocus={()=> this.setState({tenantConsumptionInFocus: row.id})}
                                onBlur={()=> this.setState({tenantConsumptionInFocus: false}, ()=> this.updateCostAllocations(true))}
                                decimalScale={tenantConsumptionInFocus === row.id ? 5 : 2}
                                fixedDecimalScale={tenantConsumptionInFocus === row.id ? false : true}
                                allowNegative={true}
                                disabled={((cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') || (allocationKey.allocationKeyName === 'Personenanzahl' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR')) ? false : true}
                                style={{width: '80px', textAlign: 'right'}}
                                onValueChange={(values, sourceInfo) => {
                                    let updatedData = data.map((dt)=> {
                                        if(dt.id === row.id){
                                            dt.tenantConsumption = values.floatValue ? values.floatValue : 0;
                                            return dt;
                                        }else{
                                            return dt;
                                        }
                                    });
                                    this.setState({
                                      data: updatedData,
                                    });
                                }}
                                />
                            </TableCell>}
                            {!(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
                            <TableCell width={'10%'} style={{textAlign: 'left'}}>
                                {row.consumptionUnits ? row.consumptionUnits : '-'}
                            </TableCell>}
                            <TableCell style={{textAlign: 'right'}}>
                                <Group spacing={0} position='right'>
                                    <NumericFormat
                                    className={(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) ? 'form-control' : 'numeric-format-text-view'}
                                    value={row.tenantCostShare}
                                    thousandSeparator="." 
                                    decimalSeparator=","
                                    suffix={' €'}
                                    placeholder='0,00 €'
                                    onFocus={()=> this.setState({tenantCostShareInFocus: row.id})}
                                    onBlur={()=> this.setState({tenantCostShareInFocus: false}, ()=> (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR') ? this.validateCosts() : this.updateCostAllocations())}
                                    decimalScale={tenantCostShareInFocus === row.id ? 5 : 2}
                                    fixedDecimalScale={tenantCostShareInFocus === row.id ? false : true}
                                    allowNegative={true}
                                    disabled={propertySelected.propertyType !== 'REGULAR' ? 
                                    (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey ? false : true) //(cosumptionType === 'USAGE_BASED' ? true : false) 
                                    : 
                                    (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey ? false : true)}
                                    style={{width: '80px', textAlign: 'right'}}
                                    onValueChange={(values, sourceInfo) => {
                                        let updatedData = data.map((dt)=> {
                                            if(dt.id === row.id){
                                                console.log('onValueChange: ', values, values.floatValue ? values.floatValue : 0);
                                                dt.tenantCostShare = values.floatValue ? values.floatValue : 0;
                                                return dt;
                                            }else{
                                                return dt;
                                            }
                                        });
                                        this.setState({
                                        data: updatedData,
                                        });
                                    }}
                                    />
                                    {/* <div className='err invalid-feedback' style={{display: 'block', fontWeight: '500'}}>Ungültige</div> */}
                                </Group>
                            </TableCell>
                        </TableRow>)}
                        <TableRow>
                            <TableCell colSpan={!(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) ? 2 : 3}/>
                            {!(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
                            <TableCell style={{textAlign: 'right'}}>
                                <span style={{whiteSpace: 'nowrap'}}>
                                    {propertySelected.propertyType !== 'REGULAR' ? 'Gesamt (WEG)' : 'Gesamt'}
                                </span>
                            </TableCell>}
                            {!(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
                            <TableCell style={{textAlign: 'right'}}>
                                <NumericFormat 
                                className={((cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') || (allocationKey.allocationKeyName === 'Personenanzahl' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR')) ? 'form-control' : 'numeric-format-text-view'}
                                value={((cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') || (allocationKey.allocationKeyName === 'Personenanzahl' && allocationKey.systemAllocationKey) || (cosumptionType.includes('FIXED') && propertySelected.propertyType !== 'REGULAR')) ? data[0].totalConsumption : data.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantConsumption ? a.tenantConsumption : null), 0)}
                                thousandSeparator="." 
                                decimalSeparator=","
                                placeholder='0,00'
                                style={{width: '80px', textAlign: 'right'}}
                                onFocus={()=> this.setState({totalConsumptionInFocus: true})}
                                onBlur={()=> this.setState({totalConsumptionInFocus: false}, ()=> this.updateCostAllocations(true))}
                                decimalScale={totalConsumptionInFocus ? 5 : 2}
                                fixedDecimalScale={totalConsumptionInFocus ? false : true}
                                allowNegative={true}
                                disabled={!((cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') || (allocationKey.allocationKeyName === 'Personenanzahl' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR'))}
                                onValueChange={(values, sourceInfo) => {
                                    console.log('onValueChange: ', values);
                                    let {data} = this.state;
                                    data = data.map((dt)=> {
                                        dt.totalConsumption = values.floatValue ? values.floatValue : 0;
                                        return dt;
                                    });
                                    //data[0].totalConsumption = values.floatValue ? values.floatValue : 0;
                                    this.setState({data});
                                }}
                                />
                            </TableCell>}
                            {!(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
                            <TableCell style={{textAlign: 'left'}}>
                                {allocationKey.units && allocationKey.units !== '-' ? `${allocationKey.units}` : ''}
                            </TableCell>}
                            <TableCell style={{textAlign: 'right'}}>
                                <Group noWrap position='right' align={'center'}>
                                    {(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
                                    <span style={{marginRight: '20px', whiteSpace: 'nowrap'}}>
                                        {propertySelected.propertyType !== 'REGULAR' ? 'Gesamt (WEG)' : 'Gesamt'}
                                    </span>}
                                    <NumericFormat 
                                    className={(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR') ? 'form-control' : 'numeric-format-text-view'}
                                    value={(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR') ? costPositionData.amount : (cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') ? data[0].totalCostShare : (cosumptionType.includes('FIXED') && propertySelected.propertyType !== 'REGULAR') ? data[0].totalCostShare : data.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantCostShare ? a.tenantCostShare : null), 0)}
                                    thousandSeparator="." 
                                    decimalSeparator=","
                                    placeholder='0,00 €'
                                    suffix={' €'}
                                    style={{width: '80px', textAlign: 'right'}}
                                    onFocus={()=> this.setState({totalCostShareInFocus: true})}
                                    onBlur={()=> this.setState({totalCostShareInFocus: false}, ()=> (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR') ? this.validateCosts() : this.updateCostAllocations())}
                                    decimalScale={totalCostShareInFocus ? 5 : 2}
                                    fixedDecimalScale={totalCostShareInFocus ? false : true}
                                    allowNegative={true}
                                    disabled={!(allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR')}
                                    onValueChange={(values, sourceInfo) => {
                                        let {costPositionData} = this.state;
                                        costPositionData.amount = values.floatValue;
                                        this.setState({costPositionData});
                                    }}
                                    />
                                </Group>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    {/* <TableHead>
                        <TableRow>
                            <TableCell style={{paddingBottom: '8px', textAlign: 'right'}} colSpan={4}>
                                <span>
                                    {'Gesamt'}
                                </span>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{paddingBottom: '8px', textAlign: 'right'}}>
                                <span>
                                    {'Gesamt'}
                                </span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={data.length > 0}>
                            <TableCell width={'20%'}/>
                            <TableCell width={'20%'}/>
                            <TableCell width={'32%'}/>
                            <TableCell style={{textAlign: 'right'}}>
                                <NumericFormat 
                                className='numeric-format-text-view'
                                value={data.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantConsumption ? a.tenantConsumption : null), 0)}
                                thousandSeparator="." 
                                decimalSeparator=","
                                placeholder='0,00'
                                style={{width: '70px', textAlign: 'right'}}
                                decimalScale={2}
                                fixedDecimalScale={2}
                                allowNegative={false}
                                disabled={true}
                                />
                            </TableCell>
                        <TableCell width={'5%'}/>
                        <TableCell>
                            <NumericFormat 
                            className='numeric-format-text-view'
                            value={data.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantCostShare ? a.tenantCostShare : null), 0)}
                            thousandSeparator="." 
                            decimalSeparator=","
                            placeholder='0,00 €'
                            suffix={' €'}
                            style={{width: '80px', textAlign: 'right'}}
                            decimalScale={2}
                            fixedDecimalScale={2}
                            allowNegative={false}
                            disabled={true}
                            />
                        </TableCell>
                        </TableRow>
                    </TableBody> */}
                </Table>
                :
                <Group position='center' grow style={{margin: '100px 0px'}}>
                    <Loader color="rgba(86, 85, 255, 1.00)"/>
                </Group>}
            </Stack>
            <Group noWrap //position={((cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') || (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey)) ? 'apart' : 'right'}
            position='right'>
                <div>
                    <ColorButton
                    className="gray-btn"
                    text={'Schließen'}
                    onClick={() => {
                        //newTotalAmount - start
                        let newTotalAmount = (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR') ? 
                        this.state.costPositionData.amount
                        :
                        (cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') ?
                        this.state.data[0].totalCostShare
                        :
                        (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey) &&
                        this.state.data.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantCostShare ? a.tenantCostShare : null), 0);
                        //newTotalAmount - end

                        console.log('oldTotalAmount: ', oldTotalAmount, Math.round(newTotalAmount * 100) / 100);
                        if(newTotalAmount !== false && newTotalAmount !== oldTotalAmount){
                            this.props.saveAmount(Math.round(newTotalAmount * 100) / 100);   
                        }
                        toggle();
                    }}
                    />
                </div>
                {/* {((cosumptionType === 'USAGE_BASED' && propertySelected.propertyType !== 'REGULAR') || (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey)) &&
                <div>
                    <ColorButton
                    className="purple-btn"
                    text={'Speichern'}
                    onClick={() => (allocationKey.allocationKeyName === 'Individuelle Zuweisung' && allocationKey.systemAllocationKey && propertySelected.propertyType !== 'REGULAR') ? this.validateCosts() : this.updateCostAllocations()}
                    />
                </div>} */}
            </Group>
        </Stack>
        {errorWarningModal &&
        <AlertModal
        open={errorWarningModal}
        toggle={()=> this.setState({errorWarningModal: false})}
        heading={'Ungültige Kosten'}
        text={`Die Summe der Einzelkosten beträgt ${data.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.tenantCostShare ? a.tenantCostShare : null), 0)} und ist damit höher als die Gesamtkosten von ${costPositionData.amount}. Bitte korrigieren Sie dies vor dem Speichern.`}
        rightButton={false}
        cancel
        onCancel={()=> this.setState({errorWarningModal: false})}
        leftButtonText={'Schließen'}
        />}
    </CustomModal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(CostPositionValuesModal);
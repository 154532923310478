import React, { Component } from 'react';
import CustomModal from './index';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { Group, Stack } from '@mantine/core';
import DropFile from '../DropFile/DropFile';
import ColorButton from '../Buttons/ColorButton';
import { Form } from 'react-bootstrap';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  setFiles,
  setCurrentFolderId,
  setAddFileModal,
  setSharedFolder,
  setFileType
} from '../../../../../redux/action/General/generalActions';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment, loader } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class AddUpdateDuplicateDocumentModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    selectedDocumentType: [],
    rentalUnits: [],
    selectedRentalUnit: [],
    tenants: [],
    selectedTenant: [],
    duplicateFiles: [],
    openDuplicateFilesModal: false,
    newFiles: [],
    uploadCounter: 0,
    uploadComplete: false
  };

  componentDidMount(){
    console.log('this.props.propertySelected: ', this.props.propertySelected);
  }

  componentDidUpdate(){
    if(this.state.uploadCounter === (this.state.newFiles.length-1) && this.state.uploadComplete){
      this.props.getDocuments();
      this.props.toggle();
      loader(false);
      this.setState({uploadCounter: 0, uploadComplete: false});
    }
  }

  handleFileChange = (acceptedFile, rejectedFile) => {
    console.log('acceptedFile: ', acceptedFile);
    if (acceptedFile.length > 0) {
      if(acceptedFile.length > 1){
        //acceptedFile.map((file)=> this.addDocApi(file));
      }else{
        //this.addDocApi(acceptedFile[0]);
      }
      let {files} = this.state;
      if(files.length > 0){
        acceptedFile.map((af)=> files.find((file)=> file.path === af.path) === undefined && files.push(af));
        this.setState({files});
      }else{
        this.setState({files: acceptedFile});
      }
    }
    if (rejectedFile.length > 0) {
      // rejectedFile[0].errors.forEach((err) => {
      //   if (err.code === 'file-too-large') {
      //     this.setState({ selectedFileErr: { state: true, msg: lang.general.text_err_file_size } });
      //   }
      //   if (err.code === 'file-invalid-type') {
      //     this.setState({ selectedFileErr: { state: true, msg: lang.general.text_err_file_type } });
      //   }
      // });
      // let {files} = this.state;
      // files.filter()
    } else {
      this.setState({ selectedFileErr: { state: false } });
    }
  };

  removeFile = (selected) => {
    console.log('removeFile: ', selected);
    let {files} = this.state;
    files = files.filter((file)=> selected.path !== file.path);
    this.setState({files});
  };

  handleDeleteDoc = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_Error_message + response.message);
    }
    //this.getDocsApi();
  };

  onDocumentTypeSelect=(selectedList, selectedItem)=>{
    this.setState({selectedDocumentType: [selectedItem]}, ()=> selectedItem.name === 'tenant' && this.getRentalUnits());
  }

  onRentalUnitSelect=(selectedList, selectedItem)=>{
    this.setState({selectedRentalUnit: [selectedItem], selectedTenant: []}, ()=> this.getTenants(selectedItem.id));
  }

  onTenantSelect=(selectedList, selectedItem)=>{
    this.setState({selectedTenant: [selectedItem]});
  }

  getRentalUnits=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + this.props.propertySelected.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetRentalUnits',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetRentalUnits(response){
    if(response.status && response.statuscode === 200){
      this.setState({rentalUnits: response.result},()=>
      response.result.map((ru) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + ru.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handleGetTenants',
          null,
          false,
          '',
          {
            //'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }));
    }
  }

  getTenants=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTenants',
      null,
      false,
      '',
      {
        //'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetTenants(response){
    if(response.status && response.statuscode === 200){
      this.setState({tenants: response.result});
      //let {rentalUnits} = this.state;
      // rentalUnits = rentalUnits.map((rentalUnit)=> {
      //   if(rentalUnit.id === response.result[0].rentalUnitId){
      //     return {...rentalUnit, tenants: response.result};
      //   }else{
      //     return rentalUnit;
      //   }
      // });
      //this.setState({rentalUnits});
    }
  }

  creatNewFiles=()=>{
    const {duplicateFiles, files, allDocumentsInCurrentFolder} = this.props;
    console.log('creatNewFiles: ', allDocumentsInCurrentFolder);
    let {newFiles} = this.state;
    // const checkDuplicates=(name)=>{
    //   if(allDocumentsInCurrentFolder.find((file)=> file.name === name) !== undefined){
    //     let fileName= name.substring(0, dt.name.lastIndexOf('.'));
    //     if(fileName[fileName.length-1] === )
    //     let newName= name.splice(name.length - 1, 0, parseInt(name[name.length-1]) + 1);
    //     return checkDuplicates(newName);
    //   }else{
    //     return name;
    //   }
    // }
    const checkDuplicates=(fileName, index = 0) => {
      let checkName = fileName, ext = '';
      if(index){
        if(checkName.indexOf('.') > -1){
          let tokens = checkName.split('.'); ext = '.' + tokens.pop();
          checkName = tokens.join('.');
        }
   
        // make any pattern here
        checkName = `${checkName}_${index}${ext}`; 
      }
      
      const nameExists = allDocumentsInCurrentFolder.filter(f=>f.name === checkName).length > 0;
      return nameExists ? checkDuplicates(fileName, index + 1) : checkName;
    }
    duplicateFiles.map((duplicateFile)=> newFiles.push(duplicateFile.file));
    newFiles = newFiles.map((file)=> {
      
      let dt = file;
      //let newFile = new File([file], checkDuplicates(`${dt.name.substring(0, dt.name.lastIndexOf('.'))}_1.${dt.name.split('.').pop()}`), {type: file.type})
      let newFile = new File([file], checkDuplicates(dt.name), {type: file.type})
      console.log('creatNewFiles: ', newFile);
      return newFile;
    });
    this.setState({newFiles: [...newFiles, ...files]},()=> this.uploadNewFiles());
  }

  overrideFiles=()=>{
    const {propertySelected, currentFolderId, duplicateFiles, moveCopyDocuments=false} = this.props;
    console.log('overrideFiles: ', duplicateFiles);
    if(duplicateFiles.length > 0){
      loader(true);
      duplicateFiles.map((duplicateFile, index)=> {
        const data = new FormData();
        //data.append('id', duplicateFile.id);
        !moveCopyDocuments && data.append('file', duplicateFile.file);
        data.append('Name', duplicateFile.name);
        // data.append('accountId', propertySelected.accountId);
        // data.append('propertyId', propertySelected.id);
        // data.append('isDirectory', false);
        // data.append('isReadOnly', false);
        // data.append('isShared', false);
        // data.append('fileType', 'General');
        // currentFolderId !== null && data.append('parentDirectoryId', currentFolderId);
  
        Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.document_api_base_url + 'documents/'+ duplicateFile.id,
        'PUT',
        data,
        null,
        false,
        true,
        false,
        this,
        'handleOverrideFile',
        {end: duplicateFiles.length === (index + 1)},
        false,
        '',
        {
          //'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
        )
      });
    }else{
      showNotification({
        message: 'Bitte wählen Sie eine Datei zum Hochladen.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'filesNotSelected',
      });
    }
  }

  handleOverrideFile(response){
    if(response.status && response.statuscode === 200 && response.other.end){
      if(this.props.files.length > 0){
        this.props.uploadFiles();
      }else{
        this.props.getDocuments();
        this.props.toggle();
        loader(false);
      }
    }else if(response.other.end){
      showNotification({
        message: 'Einige Dateien wurden nicht erfolgreich hochgeladen.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'filesNotUploadedSuccessfully',
      });
      loader(false);
    }
  }

  uploadNewFiles=()=>{
    const {newFiles} = this.state;
    const {propertySelected, currentFolderId, setFiles, setCurrentFolderId, toggle, setAddFileModal, setSharedFolder, showTenantDocuments, hideShareFolder, tenantId, setFileType} = this.props;
    
    
    setAddFileModal(true);
    var elem = document.getElementById("upload");

    if(newFiles.length > 0){
      if(showTenantDocuments){
        let tenantfiles = newFiles.map((file)=> { return {file: file, tenantId: tenantId} });
        this.props.setFiles(tenantfiles);
        this.props.setSharedFolder(hideShareFolder)
        this.props.setCurrentFolderId(currentFolderId);
        setFileType('TenantDocument');
      }else{
        this.props.setFiles(newFiles);
        this.props.setSharedFolder(hideShareFolder)
        this.props.setCurrentFolderId(currentFolderId); 
        setFileType('General');
      }

      setTimeout(() => {
        if (typeof elem.onclick == "function") {
          elem.onclick.apply(elem);
          toggle();
        } 
      }, 1000);
      // loader(true);
      // newFiles.map((file, index)=> {
      //   const data = new FormData();
      //   data.append('file', file);
      //   data.append('accountId', propertySelected.accountId);
      //   data.append('propertyId', propertySelected.id);
      //   data.append('name', file.name);
      //   data.append('isDirectory', false);
      //   data.append('isReadOnly', false);
      //   data.append('isShared', false);
      //   data.append('fileType', 'General');
      //   currentFolderId !== null && data.append('parentDirectoryId', currentFolderId);
  
      //   Autoload.HttpRequestAxios.HttpRequest(
      //   Autoload.Setting.document_api_base_url + 'documents',
      //   'POST',
      //   data,
      //   null,
      //   false,
      //   true,
      //   false,
      //   this,
      //   'handleUploadFile',
      //   {end: newFiles.length === (index + 1)},
      //   false,
      //   '',
      //   {
      //     //'Content-Type': 'application/json',
      //     Authorization: Autoload.Utils.getAuthToken(),
      //   },
      //   true
      //   )
      // });
    }else{
      showNotification({
        message: 'Bitte wählen Sie eine Datei zum Hochladen.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'filesNotSelected',
      });
    }
  }

  handleUploadFile(response){
    console.log('handleUploadFile: ', response);
    if(response.status && response.statuscode === 200 && response.other.end){
      console.log('all done');
      this.setState({uploadComplete: true});
      // setTimeout(() => {
      //   this.props.getDocuments();
      //   this.props.toggle();
      //   loader(false);
      // }, 3000);
    }else if(response.status && response.statuscode === 200 && !response.other.end){
      this.setState({uploadCounter: this.state.uploadCounter + 1});
    }
    else if(response.other.end){
      showNotification({
        message: 'Einige Dateien wurden nicht erfolgreich hochgeladen.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'filesNotUploadedSuccessfully',
      });
      loader(false);
    }
  }
  
  render() {
    const { open, toggle, className, duplicateFiles, files } = this.props;
    const {documentType, selectedDocumentType, rentalUnits, selectedRentalUnit, selectedTenant, tenants, openDuplicateFilesModal} = this.state;
    console.log('files: ', files);
    console.log('rentalUnits: ', rentalUnits);
    return (
      <>
        <CustomModal
          heading={'Dokument mit gleichen Namen existiert bereits'}
          headingClassName='document-upload-modal-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
          <Stack style={{width: '100%'}}>
            {/* <Group>New Files</Group>
            {files.map((file)=>
            <Group grow>
              <div>
                {file.name}
              </div>
            </Group>)} */}
            <Group>
              <span className='override-documents-modal-text'>An diesem Speicherort gibt es bereits ein Dokument mit dem gleichen Namen. Wie wollen Sie fortfahren?</span>
            </Group>
            {duplicateFiles.map((file)=>
            <Group noWrap>
                <DescriptionIcon 
                style={{
                  color: '#5655FE',
                  fontSize: '20px'
                }}/>
                <span style={{fontWeight: '500', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{file.name}</span>
            </Group>)}
            <Group position='apart'>
              <div>
                  <ColorButton
                  onClick={()=> toggle()}
                  className="gray-btn"
                  text={'Abbrechen'}
                  />
              </div>
              <div>
                  <ColorButton
                  onClick={()=> this.creatNewFiles()}
                  className="purple-btn"
                  text={'Beide behalten'}
                  />
              </div>
              <div>
                  <ColorButton
                  onClick={()=> this.overrideFiles()}
                  className="purple-btn"
                  text={'Überschreiben'}
                  />
              </div>
            </Group>
          </Stack>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

const actions = {
  setFiles,
  setCurrentFolderId,
  setAddFileModal,
  setSharedFolder,
  setFileType
};

export default connect(mapStateToProps, actions)(AddUpdateDuplicateDocumentModal);

// import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { PDFDocument, rgb } from 'pdf-lib';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const redirection = (route) => {
  window.location.href = route;
};

//Made by salim (kindly don't change it)
const checkProperty = (obj, value, dValue = '') => {
  if (obj == '') {
    return dValue;
  }

  if (typeof obj != 'object') {
    return dValue;
  }

  if (obj == null || value == null) {
    return dValue;
  }
  if (typeof obj[value] === 'undefined') {
    return dValue;
  }
  if (obj[value] === '' || obj[value] == null) {
    return dValue;
  }
  return obj[value];
};

const checkEmpty2 =(value)=>{
// Handle null and undefined
if (value === null || value === undefined) {
  return true;
}

// Handle strings (empty string or whitespace only)
if (typeof value === "string" && value.trim() === "") {
  return true;
}

// Handle numbers (NaN is considered empty)
if (typeof value === "number" && isNaN(value)) {
  return true;
}

// Handle arrays (empty array)
if (Array.isArray(value) && value.length === 0) {
  return true;
}

// Handle objects (empty object)
if (typeof value === "object" && Object.keys(value).length === 0) {
  return true;
}

// Handle Sets and Maps (empty Set or Map)
if (value instanceof Set || value instanceof Map) {
  return value.size === 0;
}

// Fallback for all other types
return false;
}

const checkEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

const yearDifferenceMoment = (date) => {
  let year = moment(date, 'DD.MM.YYYY').year();
  let currentYear = new Date().getFullYear();
  return currentYear - year;
};

const yearDifference = (recordDate) => {
  let itemYear = Number('20' + recordDate.split('-')[0].split('.')[2]);
  let currentYear = new Date().getFullYear();
  let dateDiff = currentYear - itemYear;
  // console.log("diff", dateDiff)
  return dateDiff;
};

const filterTenantsAndUnits = (value, data, properties, searchOnCategory) => {
  if (value == '') {
    return data;
  }
  let topSearch = data.filter((item) => {
    for (let i = 0; i < searchOnCategory.length; i++) {
      if (typeof item[searchOnCategory[i]] !== 'string') {
        if (item[searchOnCategory[i]].toString().toLowerCase().includes(value.toLowerCase()) === true) {
          return true;
        }
      } else {
        if (item[searchOnCategory[i]].toLowerCase().includes(value.toLowerCase()) === true) {
          return true;
        }
      }
    }
  });

  // let innerSearch= data.filter((item)=>{
  //   return item.tenantsData.filter(tenants=>{
  //     for (let i = 0; i < properties.length; i++) {
  //       if (typeof tenants[properties[i]] !== 'string') {
  //         if (tenants[properties[i]].toString().toLowerCase().includes(value.toLowerCase()) === true) {
  //           return true;
  //         }
  //       }

  //       else {
  //         if (tenants[properties[i]].toLowerCase().includes(value.toLowerCase()) === true) {
  //           return true;
  //         }
  //       }
  //     }
  //   })
  //   })
  // console.log('topSearh', topSearch)
  return topSearch;
};

const mergeArrayByProperty = (target, source, prop) => {
  source.forEach((sourceElement) => {
    let targetElement = target.find((targetElement) => {
      return sourceElement[prop] === targetElement[prop];
    });
    targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
  });
};

const filterBySearchBarRentalTenants = (value, data) => {
  if (value == '') {
    return data;
  }
  //got filtered rents here;
  let list = data.filter((unit) => {
    if (unit.name.toLowerCase().includes(value.toLowerCase()) === true) {
      return true;
    } else {
      let tenants = unit.tenantsData.filter((tenant) => {
        if (tenant.name.toLowerCase().includes(value.toLowerCase()) === true) {
          return true;
        } else {
          return false;
        }
      });
      if (tenants.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  });

  //got filtered tenants with rents
  let finallist = list.map((unit) => {
    if (unit.name.toLowerCase().includes(value.toLowerCase()) === true) {
      return unit;
    } else {
      let tenants = unit.tenantsData.filter((tenant) => {
        if (tenant.name.toLowerCase().includes(value.toLowerCase()) === true) {
          return true;
        } else {
          return false;
        }
      });
      return { ...unit, tenantsData: tenants };
    }
  });

  return finallist;
};

const filterBySearchBar = (value, data, properties, nested, header = false) => {
  // console.log("runnn",data,properties)
  if (value == '') {
    // console.log("runnn",data)
    return data;
  }
  let list;
  let innerList = [];
  let innerSearchedList = [];
  let innerProperty = [];
  // console.log("receiptsData",typeof(data))
  list = data.filter((item) => {
    for (let i = 0; i < properties.length; i++) {
      if (typeof item[properties[i]] == 'object') {
        //property names of a property which is array like tabledata
        innerProperty = [...innerProperty, properties[i]];

        //By Recursion innerproperties of tabledata are searched and matcjed values from each tabledata object is saved in innerList
        innerList = [...innerList, filterBySearchBar(value, item[properties[i]], nested)];
        //  console.log(innerList)
      }
      if (typeof item[properties[i]] !== 'string') {
        // console.log(item[properties[i]]);
        if (item[properties[i]].toString().toLowerCase().includes(value.toLowerCase()) === true) {
          return true;
        }
      } else {
        if (item[properties[i]].toLowerCase().includes(value.toLowerCase()) === true) {
          return true;
        }
      }
    }
  });
  // console.log(innerList);
  innerList.map((inner, i) => {
    if (inner.length > 0) {
      // console.log([innerProperty[i]])
      if (header) innerSearchedList = innerSearchedList.concat({ ...data[i], [innerProperty[i]]: inner });
      else innerSearchedList = { ...innerSearchedList, [innerProperty[i]]: inner };
      // console.log('inner Search List', innerSearchedList);
    }
    //  console.log(innerSearchedList)
    return innerSearchedList;
  });

  // console.log(list)
  // list = list.concat(innerSearchedList);

  return list;
};

const filterBySwitchBtn = (data, param, paramValue) => {
  const list = data.filter((item) => {
    return item[param] === paramValue;
  });
  return list;
};

const filterByDate = (data, param, ascendingOrder, nested = false, property, nestedParam) => {
  // console.log(data);
  let sortedList = [];
  let sortedList2;
  if (data != undefined && data.length > 0) {
    sortedList = data.sort((a, b) => {
      // console.log("a",a,"b",b);
      if (!ascendingOrder) {
        return (
          new Date(...b[param].split('-')[0].split('.').reverse()) -
          new Date(...a[param].split('-')[0].split('.').reverse())
        );
      } else {
        // console.log(...a[param].split('-')[0]);
        // console.log( new Date(...a[param].split('-')[0].split('.').reverse()) -
        // new Date(...b[param].split('-')[0].split('.').reverse()))
        return (
          new Date(...a[param].split('-')[0].split('.').reverse()) -
          new Date(...b[param].split('-')[0].split('.').reverse())
        );
      }
    });
  }
  if (nested) {
    sortedList.map((item) => {
      // console.log(item[property])
      return filterByDate(item[property], nestedParam, ascendingOrder);
    });
    // console.log(sortedList)
  }

  // sortedList = data.sort((a, b) => {
  //   if (!ascendingOrder) {
  //     return new Date(...b[param].split('.').reverse()) - new Date(...a[param].split('.').reverse());
  //   } else {
  //     return new Date(...a[param].split('.').reverse()) - new Date(...b[param].split('.').reverse());
  //   }
  // });
  // console.log(sortedList)
  return sortedList;
};

const filterByDateUtility = (data, param, ascendingOrder, nested = false, property, nestedParam) => {
  // console.log(data);
  let sortedList = [];
  let sortedList2;
  if (data != undefined && data.length > 0) {
    sortedList = data.sort((a, b) => {
      if (!ascendingOrder) {
        // console.log(a[param].split('-')[0], b[param].split('-')[0]);
        return new Date(b[param].split('-')[0].split('.')) - new Date(a[param].split('-')[0].split('.'));
      } else {
        // console.log(a[param].split('-')[0], b[param].split('-')[0]);
        return new Date(a[param].split('-')[0].split('.')) - new Date(b[param].split('-')[0].split('.'));
      }
    });
  }
  if (nested) {
    sortedList.map((item) => {
      return filterByDate(item[property], nestedParam, ascendingOrder);
    });
  }
  return sortedList;
};

const filterByAlphabet = (data, param, properties = [], ascendingOrder, nested = false) => {
  let sortedList = [];
  if (nested) {
    data.map((item) => {
      Object.keys(item).map((key) => {
        if (properties.includes(key)) {
          sortedList = [...sortedList, filterByAlphabet(item[key], param, [], ascendingOrder, false)];
        }
      });
    });
  } else {
    if (ascendingOrder) {
      sortedList = data.sort((a, b) => (a[param] || '').toString().localeCompare((b[param] || '').toString()));
    } else {
      sortedList = data.sort((a, b) => (b[param] || '').toString().localeCompare((a[param] || '').toString()));
    }
  }

  return sortedList;
};
// for rental unit tenants
const nestedAlphabeticSort = (data, param, ascending, key) => {
  // console.log('order ascending', ascending)
  let list = data.map((unit) => {
    let innerList = unit.tenantsData.sort((a, b) => {
      if (ascending) {
        return a[param].localeCompare(b[param]);
      } else {
        return b[param].localeCompare(a[param]);
      }
    });
    return { ...unit, tenantsData: innerList };
  });
  // return list;

  //sort outerlist
  list.sort((a, b) => {
    if (ascending) {
      return a[param].localeCompare(b[param]);
    } else {
      return b[param].localeCompare(a[param]);
    }
  });
  console.log('final sorted', data, list);
  return list;

  // return data;
};

const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

const deleteRecord = (data, id) => {
  let updatedData = data.filter((record) => {
    return record.id !== id;
  });
  return updatedData;
};

// Validation functions
// const textFieldValidation=(field)=>{
//   if(!field.value){
//     return true;
//   }
// }
const emptyFieldValidation = (value) => {
  if (value === null || value === undefined || value?.length === 0) {
    return true;
  } else {
    return false;
  }
};
const invalidEmail = (value) => {
  const validEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!value || !validEmail.test(value)) {
    return true;
  } else {
    return false;
  }
};
const invalidPhone = (value) => {
  const validPhone = /^(?=.*[0-9])[- +()/0-9]+$/;  ///^(?=.*[0-9])[- +()0-9]+$/;
  if (!value || !validPhone.test(value)) {
    // console.log('err state')
    return true;
  } else {
    // console.log('no err')
    return false;
  }
};

const invalidName = (value) => {
  //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
  const validName = /[^a-zA-Z0-9äöüÄÖÜß]/;
  if (!value || !validName.test(value)) {
    return true;
  } else {
    return false;
  }
};

const filterObject = (dataObj = {}, allowedAttr = []) => {
  const filtered = Object.keys(dataObj)
    .filter((key) => allowedAttr.includes(key))
    .reduce((obj, key) => {
      // console.log(obj[key], dataObj[key]);
      obj[key] = dataObj[key];
      return obj;
    }, {});
  // console.log()
  return filtered;
};

const invalidSize = (value) => {
  // console.log('invalidNumber', value)
  // const validAmount = /^(\d+|\d(\.\d)*)(,\d+)?$/;
  const validAmount = /\d([\.,][\d])?/;
  if (value === null || value === undefined || !validAmount.test(value)) {
    // console.log('err state')
    return true;
  } else {
    // console.log('no err')
    return false;
  }
};

const invalidAlphaNumeric = (value) => {
  const valid = /^[a-zA-Z0-9\-\s]+$/;
  if (!value || !valid.test(value)) {
    return true;
  } else {
    return false;
  }
};
const invalidAmount2 = (value) => {
  // console.log('invalid amount', value);
  const validAmount = /^[-+]?(?:[0-9]+,)*[0-9]+(?:\.[0-9]+)?$/;
  // const validAmount = /\d([\.,][\d])?/;
  if (!value || !validAmount.test(value)) {
    // console.log('err state')
    return true;
  } else {
    // console.log('no err')
    return false;
  }
};

const invalidAmount = (value) => {
  const validAmount = /^(\d+|\d(\.\d)*)(,\d+)?$/;
  // const validAmount = /\d([\.,][\d])?/;
  if (!value || !validAmount.test(value)) {
    // console.log('err state')
    return true;
  } else {
    // console.log('no err')
    return false;
  }
};
const filterObjectMeter = (dataObj = {}, allowedAttr = []) => {
  const filtered = Object.keys(dataObj)
    .filter((key) => allowedAttr.includes(key))
    .reduce((obj, key) => {
      // console.log(obj[key], dataObj[key]);
      obj[key] = dataObj[key];
      return obj;
    }, {});
  return filtered;
};
const requiredFieldsMeter = (obj, fields) => {
  // console.log(obj, fields);
  let filterFields = filterObjectMeter(obj, fields);
  // console.log('filtered fields', filterFields);
  for (var field in filterFields) {
    if (filterFields[field] == '') {
      // console.log('if', field);
      return false;
    }
  }
  return true;
};
const requiredFields = (obj, fields) => {
  // console.log(obj, fields)
  let filterFields = filterObject(obj, fields);
  // console.log('filtered fields', filterFields)
  for (var field in filterFields) {
    // console.log('value', field, typeof field, filterFields[field], typeof filterFields[field]);
    if (
      filterFields[field] == '' ||
      filterFields[field] == null ||
      (isNaN(filterFields[field]) && field == 'amount') ||
      filterFields[field] == undefined
    ) {
      // console.log('false');
      return false;
    }
  }
  return true;
};

const searchMasterData = (search_text, data, nestedKeyProperties) => {
  let Final_Filtered_List = [];
  let ParentKey;

  for (let i = 0; i < data.length; i++) {
    ParentKey = Object.keys(data[i])[0];
    const items = [...data[i][ParentKey]];

    // --------for search of parent key------------------
    if (ParentKey.toLowerCase().includes(search_text.toLowerCase())) {
      Final_Filtered_List.push(data[i]);
      continue;
    }

    let nested_filtered_List = searchData(search_text, items, nestedKeyProperties, null);
    if (nested_filtered_List.length > 0) {
      data[i][ParentKey] = nested_filtered_List;
      Final_Filtered_List.push(data[i]);
    }
  }

  return Final_Filtered_List;
};
const searchData = (search_text, data, searchProperties, nestedSearchProperties) => {
  let Final_Filtered_List = [];
  let nested_keys;
  if (nestedSearchProperties !== null) {
    nested_keys = Object.keys(nestedSearchProperties);
  }
  for (let i = 0; i < data.length; i++) {
    const item = { ...data[i] };
    let itemAddFlag = false;
    // --------------For search all the main key values--------------------
    for (let j = 0; j < searchProperties.length; j++) {
      if (item[searchProperties[j]].toLowerCase().includes(search_text.toLowerCase()) === true) {
        itemAddFlag = true;
        break;
      }
    }
    if (itemAddFlag) {
      Final_Filtered_List.push(item);
      continue;
    }
    // --------------For search all the nested key values--------------------
    if (nestedSearchProperties !== null) {
      for (let k = 0; k < nested_keys.length; k++) {
        const nested_array = item[nested_keys[k]];
        const filteredNestedArray = searchData(search_text, nested_array, nestedSearchProperties[nested_keys[k]], null);
        if (filteredNestedArray.length > 0) {
          item[nested_keys[k]] = filteredNestedArray;
          itemAddFlag = true;
        }
      }
      if (itemAddFlag) {
        Final_Filtered_List.push(item);
      }
    }
  }

  return Final_Filtered_List;
};

//for input from currency to number
const ConverterLocaleNumber = (stringNumber, locale) => {
  var thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');
  var decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
    // .replace(new RegExp('\\' + decimalSeparator), ',')
  );

};

const ConverterCurrencyDe = (amount, symbol = true, curreny = 'usd') => {
  if (curreny == 'de') {
    if (amount.indexOf(',') == -1) {
      // if(amount.indexOf(".") == 1 && amount.length == 4 ){
      //   amount = amount.replace('.',',');

      // }else if(amount.indexOf(".") == 2 && amount.length == 5 ){
      //   amount = amount.replace('.',',');

      // }else if(amount.indexOf(".") == 3 && amount.length == 6 ){
      //   amount = amount.replace('.',',');

      // }else if(amount.substr(amount.indexOf(".")).length == 3){
      //   amount = amount.replace('.',',');
      // }

      if (amount.substr(amount.indexOf('.')).length == 3) {
        amount = amount.replace('.', ',');
      }
    }
  }

  if (amount.indexOf('€') == -1 && curreny == 'usd') {
    amount = ConverterLocaleNumber(amount, 'usd');
  } else {
    amount = ConverterLocaleNumber(amount, 'de');
  }

  amount = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);
  // console.log(amount);
  if (amount.toString().includes('NaN €')) {
    amount = '0,00 €';
  }

  if (!symbol) {
    amount = amount.slice(0, -1);
  }

  return amount;
};

const ConverterCurrencyDe_DisplayOnly = (amount, symbol = true) => {
  let germanNumberRegex = /^-?\d{1,3}(?:\.\d{3})*(?:,\d+)?$/;
  amount = germanNumberRegex.test(amount) ? typeof amount === 'string' && amount.includes(',') ? amount + ' €' : amount+ ',00 €' : new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);
  
  if (amount.toString().includes('NaN €')) {
    amount = '0,00 €';
  }

  if (!symbol) {
    amount = amount.slice(0, -2);
  }

  return amount.toString();
};

const ConvertCurrencDe_BackToNumber = (stringNumber, locale) => {
  var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
  var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

  return parseFloat(stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
  );
}

const ConverterCurrencyUsd = (amount, symbol = true) => {
  var amount = ConverterLocaleNumber(amount, 'de');
  amount = new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(amount);
  if (!symbol) {
    amount = amount.slice(0, -1);
  }
  return amount;
};

const cust_fb_element = (id, sec = 2000) => {
  var t = document.getElementById(id);
  console.log('not');
  if (t != null) {
    console.log('in');
    t.focus();
    console.log('after focus');
    setTimeout(() => {
      t.blur();
    }, sec);
  }
};

const loader = (visible = false) => {
  console.log('loaderVisible: ', visible);
  document.getElementById('hello-housing-loader').style.display = visible ? 'block' : 'none'; // Look at: src/layouts/Main.js:188
};

const month_name = (value, data_type = 'full', lang = 'de') => {
  var monthdata = {
    January: 'Januar',
    February: 'Februar',
    March: 'März',
    April: 'April',
    May: 'Mai',
    June: 'Juni',
    July: 'Juli',
    August: 'August',
    September: 'September',
    October: 'Oktober',
    November: 'November',
    December: 'Dezember',
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Marz',
    Apr: 'Apr',
    May: 'Mai',
    June: 'Juni',
    July: 'July',
    Aug: 'Aug',
    Sept: 'Sept',
    Oct: 'Okt',
    Nov: 'Nov',
    Dec: 'Dez',
  };

  if (data_type !== 'full') {
    return value;
  }

  if (lang !== 'de') {
    return value;
  }

  if (typeof monthdata[value] === 'undefined') {
    return value;
  }

  return monthdata[value];
};
const convertToCurrency = (value, currency) => {
  if (value) {
    if (currency) {
      return `${ConverterLocaleNumber(value).toLocaleString('de-DE')} €`
    }
    return ConverterLocaleNumber(value).toLocaleString('de-DE')
  } else {
    return '0,00 €'
  }
}

const convertToCurrencyFromNumber = (value, currency) => {
  if (value) {
    if (currency) {
      return `${value.toLocaleString('de-DE')} €`
    }
    return `${value.toLocaleString('de-DE')}`
  } else if (!value&& !currency) {
    return '0,00'
  } else {
    return '0,00 €'
  }
}



const convertCurrencyToNumber = (stringNumber) => {
  if ((typeof stringNumber === 'string' || stringNumber instanceof String) && !stringNumber.includes('€')) {
    console.log(stringNumber)
    return `${stringNumber} €`.replace('.', '').replace(',', '.').split('€')[0] * 1
  }
  return (typeof stringNumber === 'string' || stringNumber instanceof String) ? stringNumber.replace('.', '').replace(',', '.').split('€')[0] * 1 : stringNumber;
}

const isPdfFile = async (file) => {
  if (!file) return false;

  try {
    // Read the file as binary data
    const buffer = await file.arrayBuffer();
    const bytes = new Uint8Array(buffer);

    // Check for '%PDF-' marker at the beginning
    const pdfMarker = '%PDF-';
    const fileHeader = new TextDecoder().decode(bytes.slice(0, 1024));
    if (fileHeader.includes(pdfMarker)) {
      return true;
    }

    // Check for PDF-specific keywords
    const pdfKeywords = ['/Type /Page', '/Catalog', 'xref', 'startxref'];
    const fileContent = new TextDecoder().decode(bytes);
    if (pdfKeywords.some((keyword) => fileContent.includes(keyword))) {
      return true;
    }

    // Fallback: Use pdf-lib to parse the file
    try {
      const pdfDoc = await PDFDocument.load(buffer);
      if (pdfDoc) {
        return true;
      }
    } catch (err) {
      console.error('Error parsing file with pdf-lib:', err);
    }

    return false; // Not a valid PDF
  } catch (err) {
    console.error('Error reading file:', err);
    return false;
  }
};

const MainHelper = {
  redirection,
  checkProperty,
  yearDifference,
  filterBySearchBar,
  filterBySwitchBtn,
  filterByDate,
  emptyFieldValidation,
  invalidEmail,
  invalidName,
  checkEmpty,
  deleteRecord,
  filterByAlphabet,
  filterObject,
  invalidPhone,
  nestedAlphabeticSort,
  requiredFields,
  invalidAmount,
  invalidAmount2,
  requiredFieldsMeter,
  filterObjectMeter,
  filterTenantsAndUnits,
  mergeArrayByProperty,
  ConverterCurrencyDe,
  ConverterCurrencyDe_DisplayOnly,
  ConvertCurrencDe_BackToNumber,
  ConverterCurrencyUsd,
  ConverterLocaleNumber,
  convertCurrencyToNumber,
  convertToCurrencyFromNumber,
  convertToCurrency,
  yearDifferenceMoment,
  invalidSize,
  filterByDateUtility,
  searchData,
  searchMasterData,
  yearDifferenceMoment,
  filterBySearchBarRentalTenants,
  invalidAlphaNumeric,
  isValidDate,
  cust_fb_element,
  loader,
  month_name,
  checkEmpty2,
  isPdfFile
  //isPDFFile
  // mapStateToProps
};

export default MainHelper;

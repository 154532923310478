import React, { Component } from 'react';
//import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import { Group, Loader, Modal, Stack } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { load, unload } from '@finapi/web-form';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class FinApiWebFormModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    loader: false,
    openDeletionDelayModal: false
  };

  descriptionMsgStyle={
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0e0e1d',
  }
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const {toggle, getBankConnections, deleteBankConnection, webFormUrl, updateBankConnectionInProgress, getBankConnectionById} = this.props;

    let url = webFormUrl;
    if(url){
      this.setState({loader: true});
      // Split the URL at the last '/' character
      let parts = url.split('/');

      // Join the first parts (excluding the last element) to get the base URL
      let baseUrl = parts.slice(0, -1).join('/');

      // The last part will be the ID
      let id = parts[parts.length - 1];

      console.log("Base URL:", baseUrl);
      console.log("ID:", id);

      const callToggle=(callDeleteBankConnection=false, message='')=> {
        this.setState({openDeletionDelayModal: callDeleteBankConnection},()=> 
        setTimeout(() => {
          showNotification({
            icon: <img src={RedCross} alt='RedCross' />,
            message,
            id: 'generalErrorMessage'
          });
          toggle(callDeleteBankConnection);
        }, callDeleteBankConnection ? 5000 : 500));
      }
      const callGetBankConnections=()=> getBankConnections();
      const callGetBankConnectionById=()=> getBankConnectionById();
      const callDeleteBankConnection=()=> deleteBankConnection();
      const callToggleLoaderFalse=()=> this.setState({loader: false});
      load(document.getElementById('web-form-id'), { 
          token: id,
          targetUrl: window.location.href.includes('app') ? 'https://webform-live.finapi.io' : 'https://webform-sandbox.finapi.io', //baseUrl,
          language: "de"
        },
        {
          onLoaded: function() {
            // handle "onLoaded" event
            console.log('web-form: onLoaded');
            callToggleLoaderFalse();
          },
          onComplete: function() {
            // handle "onComplete" event
            console.log('web-form: onComplete');
            //callToggle();
            if(updateBankConnectionInProgress){
              callGetBankConnectionById();
            }else{
              callGetBankConnections();
            }
          },
          onFail: function() {
            // handle "onFail" event
            console.log('web-form: onFail');
            callToggleLoaderFalse();
            // showNotification({
            //   icon: <img src={RedCross} alt='RedCross' />,
            //   message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
            //   id: 'generalErrorMessage'
            // });
            // if(!updateBankConnectionInProgress){
            //   callDeleteBankConnection();
            // }else{
            //   callToggle();
            // }
            callToggle(!updateBankConnectionInProgress ? true : false, 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.');
            unload(document);
          },
          onAbort: function() {
            // handle "onAbort" event
            console.log('web-form: onAbort');
            callToggleLoaderFalse();
            // showNotification({
            //   message: 'Kontoeinrichtung wurde nicht abgeschlossen.',
            //   icon: <img src={RedCross} alt='RedCross' />
            // });
            // if(!updateBankConnectionInProgress){
            //   callDeleteBankConnection();
            // }else{
            //   callToggle();
            // }
            callToggle(!updateBankConnectionInProgress ? true : false, 'Kontoeinrichtung wurde nicht abgeschlossen.');
            unload(document);
          },
          onLoadError: function() {
            // handle "onLoadError" event
            console.log('web-form: onLoadError');
            callToggleLoaderFalse();
            // showNotification({
            //   icon: <img src={RedCross} alt='RedCross' />,
            //   message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
            //   id: 'generalErrorMessage'
            // });
            // if(!updateBankConnectionInProgress){
            //   callDeleteBankConnection();
            // }else{
            //   callToggle();
            // }
            callToggle(!updateBankConnectionInProgress ? true : false, 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.');
            unload(document);
          }
        },
        document
      );
    }
  }

  componentWillUnmount(){
    unload(document);
  }
  
  render() {
    const { open, toggle, className, heading, updateBankConnectionInProgress } = this.props;
    const { loader, isMobile, isTablet, openDeletionDelayModal } = this.state;
    
    console.log('openDeletionDelayModal: ', openDeletionDelayModal);
    return (
      <CustomModal
        heading={heading ? heading : 'finApi'}
        open={open}
        toggle={() => {
          toggle(updateBankConnectionInProgress ? false : true);
        }}
        //hideClose={true}
        className={`${className}`}
        style={{minWidth: (isMobile || isTablet) ? 'auto' : '1200px'}}
        styleBody={{minHeight: '560px'}}
        hideCloseButton={true}
      >
        <Group id={'web-form-id'} className='web-form-id'/>
        {openDeletionDelayModal &&
        <Stack align={'center'} justify={'center'} style={{width: '100%', height: '560px'}}>
          <span style={{fontFamily: 'Inter, sans-serif', fontWeight: '400'}}>Bitte warten Sie <Loader variant='dots' size={10} style={{marginTop: '6px'}} color='#5555FF'/></span>
        </Stack>}
      </CustomModal>
    );
  }
}

export default FinApiWebFormModal;

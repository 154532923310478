import React, { Component } from 'react';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { CustomDateInput } from '../../../../neoverv/index.js';
import { ColorButton, DeleteModal, EmptyStateContainer, AddPropertyModal } from '../../../../neoverv';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// import de from
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import Info from '../../../../assets/images/module/general/Info.svg';
import housingphoto from '../../../../assets/images/module/general/house-log.png';
import emptyPropertyHome from '.././../../../assets/images/module/general/emptyPropertyHome.svg';
import addicons from '../../../../assets/images/module/general/AddIcon.svg';
import subtracticon from '../../../../assets/images/module/general/subtracticon.svg';
import uparrow from '../../../../assets/images/module/general/SelectArrowUp.svg';
import downArrow from '../../../../assets/images/module/general/SelectArrowDown.svg';
import calendar from '../../../../assets/images/module/general/CalendarIcon.svg';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  updatePropetyInfo,
  editProfileData,
  deleteProperty,
  setPropertyList,
  changeCurrentProperty,
} from '../../../../redux/action/General/generalActions';
import PropertyDataHandler from '../../../../neoverv/helpers/DataHandler/Property.js';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices.js';
import { hideNotification, showNotification } from '@mantine/notifications';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import { Group, Skeleton, Stack } from '@mantine/core';
import AddPropertyTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyTypeModal.jsx';
import { NumericFormat } from 'react-number-format';
import AlertModal from '../../../../neoverv/components/Module/General/CustomModal/AlertModal.jsx';
import { Checkbox } from '@material-ui/core';
import imageCompression from 'browser-image-compression';
import { formatNumber } from '../../../../helpers/stringsManipulations.js';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
registerLocale('de', de);
class GeneralInformation extends Component {
  state = {
    addPropertyModal: false,
    id: '',
    propertyName: '',
    propertyNameErr: false,
    propertyAddress: '',
    propertyAddressErr: false,
    propertyCity: '',
    propertyCityErr: false,
    propertyZipCode: '',
    propertyZipCodeErr: false,
    propertyNoOfUnits: '',
    propertyNoOfUnitsErr: false,
    propertyAreaOfUnits: '',
    propertyAreaOfUnitsErr: false,
    emptyProperty: false,
    dropdownValueErr: '',
    heatingType: '',
    // heatingTemp: 0,
    date: {
      value: '',
      isSelected: false,
    },
    // date: new Date(new Date().getFullYear(), 11, 31),
    radiobtnValue: '',
    file: '',
    fileToUpload: '',
    fileUploadErr: { state: false, msg: '' },
    DropdownState: 'true',
    DropdownStateTwo: 'true',
    selectedOptionvalue: 0,
    heatingTemperature: null,
    energyFuelType: null,
    consumptionValue: 0,
    firstTimeConsumptionValue: true,
    cutOffDate: null,
    // customConsumption: null,
    heatingTypeDropDown: [
      // {
      //   id: 1,
      //   heatingType: 'Centralized with warm water',
      // },
      // {
      //   id: 2,
      //   heatingType: 'Centralized with out warm water',
      // },
      // {
      //   id: 3,
      //   heatingType: 'Decentralized',
      // },
      {
        id: 1,
        heatingType: lang.property.generalinfo_warm_water,
      },
      {
        id: 2,
        heatingType: lang.property.generalinfo_without_warm_water,
      },
      {
        id: 3,
        heatingType: lang.property.generalinfo_decentralized,
      },
    ],
    deleteUnitModal: false,
    fuelTypes: [],
    skeletonLoading: false,
    addPropertyTypeModal: false,
    selectedPropertyType: '',
    totalRentingSpace: null,
    totalRentingSpaceErrMsg: false,
    numberOfRentalUnits: null,
    numberOfRentalUnitsErrMsg: false,
    openCutOffDateAlert: false,
    allowCutOffDateChange: false,
    previousCheck: false,
    openCutOffDateModal: false,
    oldPropertyName: '',
    oldPropertyAddress: '',
    oldPropertyCity: '',
    oldPropertyZipCode: '',
    oldTotalRentingSpace: '',
    oldNumberOfRentalUnits: '',
    totalOccupantsCount: null,
    oldTotalOccupantsCount: null,
    rentalUnitSpaceErrMsg: false,
    rentalUnitSpace: '',
    oldRentalUnitSpace: null,
    rentalUnitInfo: null
  };

  componentDidMount() {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.setState({skeletonLoading: true});
    if(this.props.propertyData && this.props.propertyData.id !== undefined){
      this._fetchGeneralInfoData();
      this._getPropertyPicture();
      this.props.propertySelected && this.props.propertySelected.propertyType === 'CONDOMINIUM' && this.getRentalUnits();
    }else{
      this.ShowLoader();
    }
    this._getFuelTypes();
    // var emptyComponent=
    // var forgotForm = document.getElementById('empty-generalinfo-page');
    // var confirmationBox = document.getElementById('ins-sent');
    // forgotForm.style.display = 'none';
    // confirmationBox.style.display = 'block';
  }

  getRentalUnits=()=>{
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetRentalUnits',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetRentalUnits(response){
    if(response.status && response.statuscode === 200){
      if(response.result && response.result.length > 0){
        let rentalUnit = response.result.find((rentalUnit)=> rentalUnit.name === 'Eigentumswohnung / Gewerbeeinheit')
        this.setState({
          rentalUnitSpace: rentalUnit ? rentalUnit.space : '',
          oldRentalUnitSpace: rentalUnit ? rentalUnit.space : '',
          rentalUnitInfo: rentalUnit
        });
      }
    }
  }

  updateRentalUnit=()=>{
    let {rentalUnitInfo, rentalUnitSpace, totalRentingSpace} = this.state;
    rentalUnitInfo.space = rentalUnitSpace;

    if(rentalUnitSpace > totalRentingSpace){
      this.setState({rentalUnitSpaceErrMsg: true, oldRentalUnitSpace: rentalUnitSpace});
    }else{
      this.setState({rentalUnitSpaceErrMsg: false},()=>
        {
          var loader = document.getElementById('hello-housing-loader');
          loader.style.display = 'block';
          Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'RentalUnits/' + rentalUnitInfo.id,
          'PUT',
          rentalUnitInfo,
          null,
          false,
          true,
          false,
          this,
          'handleUpdateRentalUnit',
          null,
          false,
          '',
          {
            'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
          );
        }
      );
    }
  }

  handleUpdateRentalUnit(response){
    if(response.status && response.statuscode === 200){
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
      showNotification({
        id: 'save_property_data',
        icon: <img src={GreenTick} alt='green-tick'/>,
        message: 'Die Änderungen wurden übernommen.',
      });
      this.setState({oldRentalUnitSpace: this.state.rentalUnitSpace});
      this.ShowLoader();
    }
  }

  toggleDeleteUnitModal = (id) => {
    console.log('delete modal');
    this.setState({ deleteUnitModal: !this.state.deleteUnitModal });
  };
  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };
  toggleAddPropertyTypeModal = () => {
    this.setState({ addPropertyTypeModal: !this.state.addPropertyTypeModal });
  };

  _getFuelTypes() {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'systemFuelTypes',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleFuelTypeResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleFuelTypeResponse(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_Error_message + response.message);
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    const fuelTypes = response.result.map((fuelType) => {
      return {
        id: Autoload.MainHelper.checkProperty(fuelType, 'id'),
        fuelTypeName: Autoload.MainHelper.checkProperty(fuelType, 'fuelTypeName'),
        conversionkWh: Autoload.MainHelper.checkProperty(fuelType, 'conversionkWh'),
      };
    });
    this.setState({ fuelTypes });
  }

  _getPropertyPicture() {
    const propertyID = this.props.propertyData.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + propertyID + '/picture',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_getPropertyPictureResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _getPropertyPictureResponse(response) {
    if (response.status == true && response.statuscode == 200) {
      this.setState({ file: response.result });
    }
  }

  _fetchGeneralInfoData() {
    const propertyID = this.props.propertyData.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + propertyID,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_property_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_property_data(response) {
    console.log(response, 'property api get data working fine ');
    if (response.status == true && response.statuscode == 200) {
      let isCustomConsumption = false;
      if (
        response.result.consumptionShare !== 0 &&
        response.result.consumptionShare !== 50 &&
        response.result.consumptionShare !== 70 &&
        response.result.consumptionValue !== '70' &&
        response.result.consumptionValue !== '50' &&
        response.result.consumptionValue !== '0'
      ) {
        isCustomConsumption = true;
      }

      const HeatingCostGerman = Autoload.MainHelper.checkProperty(response.result, 'heatingType');
      const textHeatingType =
        HeatingCostGerman === 'central with warm water'
          ? lang.property.generalinfo_warm_water
          : HeatingCostGerman === 'central without warm water'
            ? lang.property.generalinfo_without_warm_water
            : HeatingCostGerman === 'decentralized'
              ? lang.property.generalinfo_decentralized
              : '';
      // // ;
      this.setState(
        {
          id: Autoload.MainHelper.checkProperty(response.result, 'id'),
          accountId: Autoload.MainHelper.checkProperty(response.result, 'accountId'),
          propertyName: Autoload.MainHelper.checkProperty(response.result, 'name'),
          totalRentingSpace: Autoload.MainHelper.checkProperty(response.result, 'totalRentingSpace'),
          numberOfRentalUnits: Autoload.MainHelper.checkProperty(response.result, 'numberOfRentalUnits'),
          propertyAddress: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(response.result, 'propertyAdress'),
            'streetno'
          ),
          propertyZipCode: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(response.result, 'propertyAdress'),
            'zipCode'
          ),
          propertyCity: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(response.result, 'propertyAdress'),
            'city'
          ),

          propertyAreaOfUnits:
            Autoload.MainHelper.checkProperty(response.result, 'totalRentingSpace').toString() === ''
              ? Autoload.MainHelper.checkProperty(response.result, 'totalRentingSpace')
              : Autoload.MainHelper.checkProperty(response.result, 'totalRentingSpace').toString() + ' m²',

          propertyNoOfUnits: Autoload.MainHelper.checkProperty(response.result, 'numberOfRentalUnits'),

          heatingType: textHeatingType, // german????
          heatingTemperature:
            Autoload.MainHelper.checkProperty(response.result, 'heatingTemperature').toString() === null
              ? Autoload.MainHelper.checkProperty(response.result, 'heatingTemperature')
              : Autoload.MainHelper.checkProperty(response.result, 'heatingTemperature').toString() + ' °C',
          // Autoload.MainHelper.checkProperty(response.result, 'heatingTemperature'),
          consumptionValue: Autoload.MainHelper.checkProperty(response.result, 'consumptionShare', '0'),
          // customConsumption: Autoload.MainHelper.checkProperty(response.result, 'consumptionShare', '0'),
          energyFuelType: Autoload.MainHelper.checkProperty(response.result, 'energyFuelType', null),
          cutOffDate: Autoload.MainHelper.checkProperty(response.result, 'cutOffDate'),
          //needs formatting
          date: {
            isSelected:
              response.result.cutOffDate && response.result.cutOffDate.day && response.result.cutOffDate.month
                ? true
                : false,
            value: moment(
              `${Autoload.MainHelper.checkProperty(
                Autoload.MainHelper.checkProperty(response.result, 'cutOffDate'),
                'day',
                '31'
              )} ${moment()
                .month(
                  parseInt(
                    Autoload.MainHelper.checkProperty(
                      Autoload.MainHelper.checkProperty(response.result, 'cutOffDate'),
                      'month',
                      '12'
                    )
                  ) - 1
                )
                .format('MMMM')}`,
              'DD MMMM'
            )._d,
          },
          // date:{isSelected: response.result.cutOffDate !='' ? true: false, value: response.result.cutOffDate } ,

          selectedOptionvalue: isCustomConsumption
            ? 'custom'
            : Autoload.MainHelper.checkProperty(response.result, 'consumptionShare', 0),
          
          oldPropertyName: Autoload.MainHelper.checkProperty(response.result, 'name'),
          oldPropertyAddress: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(response.result, 'propertyAdress'),
            'streetno'
          ),
          oldPropertyCity: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(response.result, 'propertyAdress'),
            'city'
          ),
          oldPropertyZipCode: Autoload.MainHelper.checkProperty(
            Autoload.MainHelper.checkProperty(response.result, 'propertyAdress'),
            'zipCode'
          ),
          oldTotalRentingSpace: Autoload.MainHelper.checkProperty(response.result, 'totalRentingSpace'),
          oldNumberOfRentalUnits: Autoload.MainHelper.checkProperty(response.result, 'numberOfRentalUnits'),
          totalOccupantsCount: Autoload.MainHelper.checkProperty(response.result, 'totalOccupantsCount'),
          oldTotalOccupantsCount: Autoload.MainHelper.checkProperty(response.result, 'totalOccupantsCount')
        },
        () => {
          console.log('this.astat', this.state);
          // const { consumptionValue } = this.state;
          // if( consumptionValue !== 70 && consumptionValue !== '70' && consumptionValue !== 50 && consumptionValue !== '50' && consumptionValue !== 0 && consumptionValue !== '0' ) {
          //   this.setState({ consumptionValue: 'custom' })
          // }
        }
      );
    }
    this.ShowLoader();
  }

  _addGeneralInfo() {
    hideNotification('save_property_data');
    const {propertySelected} = this.props;
    let heatingValue =
      this.state.heatingType === lang.property.generalinfo_warm_water
        ? 'central with warm water'
        : this.state.heatingType === lang.property.generalinfo_without_warm_water
          ? 'central without warm water'
          : this.state.heatingType === lang.property.generalinfo_decentralized
            ? 'decentralized'
            : '';
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const propertyID = this.props.propertyData.id;
    const accountID = this.props.profileData.accountId;
    //let btnelement = document.getElementById('save-btn-general-info');
    // const generalInfoData = {
    //   propertyId: propertyID,
    //   accountId: accountID,
    //   name: this.state.propertyName,
    //   propertyAdress: {
    //     streetno: this.state.propertyAddress,
    //     city: this.state.propertyCity,
    //     zipCode: this.state.propertyZipCode,
    //   },
    //   // numberOfRentalUnits: parseInt(this.state.propertyNoOfUnits),
    //   //numberOfRentalUnits: parseInt(this.state.propertyNoOfUnits),
    //   numberOfRentalUnits: this.state.numberOfRentalUnits ? this.state.numberOfRentalUnits : 0,
    //   cutOffDate: {
    //     month: parseInt(moment(this.state.date.value).format('M')),
    //     day: parseInt(moment(this.state.date.value).format('DD')),
    //   },
    //   //totalRentingSpace: parseInt(this.state.propertyAreaOfUnits + ''.replaceAll(' m²', '')),
    //   totalRentingSpace: this.state.totalRentingSpace ? this.state.totalRentingSpace : 0,
    //   // consumptionShare: parseInt(this.state.consumptionValue + ''.replaceAll(' %', '')),
    //   consumptionShare: propertySelected.consumptionValue,
    //   heatingType: propertySelected.heatingType,
    //   // heatingType: this.state.heatingType ? this.state.heatingType.toLowerCase() : '',
    //   //file,
    //   heatingTemperature: propertySelected.heatingTemperature,
    //   // this.state.heatingTemperature !== '' ? parseInt(this.state.heatingTemperature) : null,
    //   energyFuelType: propertySelected.energyFuelType,
    //   propertyType: propertySelected.propertyType
    // };
    this.setState({
      oldPropertyName: this.state.propertyName,
      oldPropertyAddress: this.state.propertyAddress,
      oldPropertyCity: this.state.propertyCity,
      oldPropertyZipCode: this.state.propertyZipCode,
      oldTotalRentingSpace: this.state.totalRentingSpace,
      oldNumberOfRentalUnits: this.state.numberOfRentalUnits,
      oldTotalOccupantsCount: this.state.totalOccupantsCount
    })
    const generalInfoData ={
      ...propertySelected,
      propertyId: propertyID,
      accountId: accountID,
      name: this.state.propertyName,
      propertyAdress: {
        streetno: this.state.propertyAddress,
        city: this.state.propertyCity,
        zipCode: this.state.propertyZipCode,
      },
      totalRentingSpace: this.state.totalRentingSpace ? this.state.totalRentingSpace : 0,
      numberOfRentalUnits: this.state.numberOfRentalUnits ? this.state.numberOfRentalUnits : 0,
      cutOffDate: {
        month: parseInt(moment(this.state.date.value).format('M')),
        day: parseInt(moment(this.state.date.value).format('DD')),
      },
      totalOccupantsCount: this.state.totalOccupantsCount,
      heatingType: propertySelected.heatingType,
      heatingTemperature: propertySelected.heatingTemperature,
      consumptionShare: propertySelected.consumptionShare,
      energyFuelType: propertySelected.energyFuelType,
      receiptEmail: propertySelected.receiptEmail,
      meterReadingsEmail: propertySelected.meterReadingsEmail,
      centralWarmwaterAllocationKey: propertySelected.centralWarmwaterAllocationKey,
      meterReadingConducted: propertySelected.meterReadingConducted,
      propertyType: propertySelected.propertyType,
      occupantsConfiguration: propertySelected.occupantsConfiguration,
      pictureLocation: propertySelected.pictureLocation,
      isConsumptionSectionHidden: propertySelected.isConsumptionSectionHidden,
      isDocumentSectionHidden: propertySelected.isDocumentSectionHidden,
      isMessageSectionHidden: propertySelected.isMessageSectionHidden
    }
    console.log('generalInfoData', generalInfoData);
    Autoload.HttpRequestAxios.HttpRequest(
      this.state.allowCutOffDateChange ? 
      Autoload.Setting.property_api_base_url + 'properties/' + propertyID+'?cutOffDateChange='+ (this.state.previousCheck ? 'FUTURE' : 'PAST-FUTURE')
      :
      Autoload.Setting.property_api_base_url + 'properties/' + propertyID,
      'PUT',
      generalInfoData,
      null,//btnelement.children[0],
      false,
      true,
      false,
      this,
      '_PutGeneralInfoResponse',
      { generalInfoData },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _PutGeneralInfoResponse(response) {
    if(response.status && response.statuscode === 200){
      showNotification({
        id: 'save_property_data',
        icon: <img src={GreenTick} alt='green-tick'/>,
        message: 'Die Änderungen wurden übernommen.',
      });
    }else{
      showNotification({
        id: 'save_property_data_error',
        icon: <img src={RedCross} alt='red-cross'/>,
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
      });
    }

    if (this.state.file) {
      if (this.state.fileToUpload) {
        this.putPropertyPicture(this.props.propertyData.id, response.other.generalInfoData);
      } else {
        this.updateProperties(response.other.generalInfoData);
      }
    } else {
      this.deletePropertyPicture(this.props.propertyData.id, response.other.generalInfoData);
    }
    
  }

  // handlePropertyData(response) {
    // if (response.status === true && response.statuscode === 200) {
  //     const propertyData = PropertyDataHandler.getPropertiesData(response.result)
  //     // this.props.setPropertyList(propertyData);
  //     var _newUpdatedProperty = {
  //       id: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'propertyId'),
  //       propertyName: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'name'),
  //       propertyAddress: Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'propertyAdress'), 'streetno'),
  //       propertyCity: Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'propertyAdress'), 'city'),
  //       propertyZipCode: Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'propertyAdress'), 'zipCode'),
  //       propertyNoOfUnits: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'numberOfRentalUnits'),
  //       propertyAreaOfUnits: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'totalRentalSpace'),
  //       cutOfDate: `${Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'cutOffDate'), 'day', '31')} ${moment()
  //         .month(parseInt(Autoload.MainHelper.checkProperty(Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'cutOffDate'), 'month', '12')) - 1)
  //         .format('MMMM')}`,
  //       heatingType: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'heatingType'),
  //       heatingTemp: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'heatingTemperature'),
  //       consumptionValue: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'consumptionShare',0),
  //       energyFuelType: Autoload.MainHelper.checkProperty(response.other.updated_property_response, 'energyFuelType'),
  //     };
  //     this.props.changeCurrentProperty(_newUpdatedProperty);
  //     Autoload.MainHelper.redirection('/dashboard');
  //     // this.props.history.push('/dashboard');
  //   }
  // }

  updateProperties(updated_property_response, deleteProperty=false) {
    // if (response.status === true && response.statuscode === 200) {
    // const propertyData = PropertyDataHandler.getPropertiesData(response.result)
    // this.props.setPropertyList(propertyData);
    // console.log('updated_property_response',updated_property_response)
    // var _newUpdatedProperty = {
    //   id: Autoload.MainHelper.checkProperty(updated_property_response, 'propertyId'),
    //   propertyName: Autoload.MainHelper.checkProperty(updated_property_response, 'name'),
    //   propertyAddress: Autoload.MainHelper.checkProperty(
    //     Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
    //     'streetno'
    //   ),
    //   propertyCity: Autoload.MainHelper.checkProperty(
    //     Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
    //     'city'
    //   ),
    //   propertyZipCode: Autoload.MainHelper.checkProperty(
    //     Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
    //     'zipCode'
    //   ),
    //   propertyNoOfUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'numberOfRentalUnits'),
    //   numberOfRentalUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'numberOfRentalUnits'),
    //   propertyAreaOfUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'totalRentalSpace'),
    //   totalRentingSpace: Autoload.MainHelper.checkProperty(updated_property_response, 'totalRentingSpace'),
    //   cutOffDate: {
    //     day: 
    //       Autoload.MainHelper.checkProperty(
    //       Autoload.MainHelper.checkProperty(updated_property_response, 'cutOffDate'),
    //       'day',
    //       '31'
    //       ),
    //     month: 
    //       parseInt(
    //         Autoload.MainHelper.checkProperty(
    //           Autoload.MainHelper.checkProperty(updated_property_response, 'cutOffDate'),
    //           'month',
    //           '12'
    //         )
    //       )
    //   },
    //   heatingType: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingType'),
    //   heatingTemp: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingTemperature'),
    //   consumptionValue: Autoload.MainHelper.checkProperty(updated_property_response, 'consumptionShare', 0),
    //   energyFuelType: Autoload.MainHelper.checkProperty(updated_property_response, 'energyFuelType', null),
    //   propertyType: Autoload.MainHelper.checkProperty(updated_property_response, 'propertyType', 'REGULAR'),
    // };
    let {propertySelected} = this.props;
    propertySelected={
      ...propertySelected,
      ...updated_property_response,
      propertyName: Autoload.MainHelper.checkProperty(updated_property_response, 'name'),
      name: Autoload.MainHelper.checkProperty(updated_property_response, 'name'),
      propertyAdress: Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
      propertyAddress: Autoload.MainHelper.checkProperty(
        Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
        'streetno'
      ),
      propertyCity: Autoload.MainHelper.checkProperty(
        Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
        'city'
      ),
      propertyZipCode: Autoload.MainHelper.checkProperty(
        Autoload.MainHelper.checkProperty(updated_property_response, 'propertyAdress'),
        'zipCode'
      ),
      propertyNoOfUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'numberOfRentalUnits'),
      propertyAreaOfUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'totalRentingSpace'),
      totalRentingSpace: Autoload.MainHelper.checkProperty(updated_property_response, 'totalRentingSpace'),
      numberOfRentalUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'numberOfRentalUnits'),
      cutOffDate: {
        day: 
          Autoload.MainHelper.checkProperty(
          Autoload.MainHelper.checkProperty(updated_property_response, 'cutOffDate'),
          'day',
          '31'
          ),
        month: 
          parseInt(
            Autoload.MainHelper.checkProperty(
              Autoload.MainHelper.checkProperty(updated_property_response, 'cutOffDate'),
              'month',
              '12'
            )
          )
      },
      pictureLocation: Autoload.MainHelper.checkProperty(updated_property_response, 'pictureLocation'),
      totalOccupantsCount: Autoload.MainHelper.checkProperty(updated_property_response, 'totalOccupantsCount'),
      isConsumptionSectionHidden:	Autoload.MainHelper.checkProperty(updated_property_response, 'isConsumptionSectionHidden'),
      isDocumentSectionHidden:	Autoload.MainHelper.checkProperty(updated_property_response, 'isDocumentSectionHidden'),
      isMessageSectionHidden:	Autoload.MainHelper.checkProperty(updated_property_response, 'isMessageSectionHidden'),
      // heatingType: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingType'),
      // heatingTemp: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingTemperature'),
      // consumptionValue: Autoload.MainHelper.checkProperty(updated_property_response, 'consumptionShare', 0),
      // consumptionShare: Autoload.MainHelper.checkProperty(updated_property_response, 'consumptionShare', 0),
      // energyFuelType: Autoload.MainHelper.checkProperty(updated_property_response, 'energyFuelType', null),
      // propertyType: Autoload.MainHelper.checkProperty(updated_property_response, 'propertyType', 'REGULAR'),
      // centralWarmWaterAllocationKey: Autoload.MainHelper.checkProperty(updated_property_response, 'centralWarmWaterAllocationKey'),
      // meterReadingConducted: Autoload.MainHelper.checkProperty(updated_property_response, 'meterReadingConducted'),
      // pictureLocation: Autoload.MainHelper.checkProperty(updated_property_response, 'pictureLocation'),
      // numberOfRentalUnits: Autoload.MainHelper.checkProperty(updated_property_response, 'numberOfRentalUnits'),
    }

    // // ;
    this.props.changeCurrentProperty(deleteProperty ? this.props.allProperties[0] : propertySelected);

    // Autoload.Redirection.reload(); //redirection('/dashboard');
    // this.props.history.push('/dashboard');
    // }
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    
    //Autoload.MainHelper.redirection('/dashboard');
    //this.props.history.push('/dashboard');
  }

  deleteProperty = (id) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    let btnelement = document.getElementById('delete-data-property');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + id,
      'DELETE',
      null,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      'handleDelete',
      { cancelbtn: btnelement.children[0], id: id },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleDelete = (response) => {
    console.log('delete property', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      //
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }

    if(response.status && response.statuscode === 200){
      window.Intercom('trackEvent', 'property-deleted');
    }
    this.deletePropertyPicture(this.props.propertyData.id);
    this.props.deleteProperty(response.other.id);
    // this._getPropertyListData();
  };

  _getPropertyListData() {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url +
      'properties?accountId=' +
      this.props.profileData.id +
      '&offset=0&fetch=100',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handlePropertyData',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handlePropertyData(response) {
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const propertyData = PropertyDataHandler.getPropertiesData(response.result);
      this.props.setPropertyList(propertyData);
      if (propertyData.length != 0) {
        this.props.changeCurrentProperty(propertyData[0]);
      }
      // this.props.history.push('/dashboard');
    }
    // this.ShowLoader();
  }
  ShowLoader = () => {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'none';
    // var emptyDiv = document.getElementById('empty-generalinfo-page');
    // emptyDiv.style.display = 'none';
    // var dataDiv = document.getElementById('respond-generalinfo-page');
    // dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
  };

  onDateChange = (field, date) => {
    this.setState({ [field]: { isSelected: true, value: date } });
  };

  onInputChange = (field, value) => {
    // this.setState({ [field]: value });
    this.setState({ [field]: value }, () => this.validatePropertyData());
  };

  compressImage= async (file)=>{
    const options = {
      maxSizeMB: 0.2,
      useWebWorker: true,
    }
    const compressedFile =  await imageCompression(file, options);
    console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    this.setState({fileToUpload: compressedFile});
  }

  handlePictureUpload_general = (e) => {
    e.preventDefault();
    if (e.target.files[0] && typeof e.target.files[0] != 'undefined') {
      let fileTypeErr = false;
      let fileDimensionErr = false;
      const file = e.target.files[0];
      if (!file.type.includes('image')) {
        fileTypeErr = true;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      const classContext = this;
      const props = this.props;
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 240 || width < 240) {
            fileDimensionErr = true;
          }
          if (fileTypeErr || fileDimensionErr) {
            const msg = `Invalid file ${fileTypeErr ? 'type' : ''} ${fileTypeErr && fileDimensionErr ? 'and' : ''} ${fileDimensionErr ? 'dimension' : ''
              }`;
            classContext.setState({ fileUploadErr: { state: true, msg } });
          } else {
            if(file.size > 300000){
              Autoload.Utils.compressImage(file, res=> 
              classContext.setState({
              fileToUpload: res, 
              file: URL.createObjectURL(file),
              fileUploadErr: { state: false, msg: '' }
              }, ()=> classContext.validateForm()));
            } else{
              classContext.setState({
                file: URL.createObjectURL(file),
                fileToUpload: file,
                fileUploadErr: { state: false, msg: '' },
              }, ()=> classContext.validateForm());
            }
            // const payload = new FormData();
            // payload.append('fil', file, file.name);
            // Autoload.HttpRequestAxios.HttpRequest(
            //   Autoload.Setting.property_api_base_url + 'Properties/' + props.propertyData.id + '/Picture',
            //   'PUT',
            //   payload,
            //   null,
            //   false,
            //   true,
            //   false,
            //   classContext,
            //   '_handlePictureUpload',
            //   null,
            //   false,
            //   '',
            //   {
            //     'Content-Type': 'application/json',
            //     Authorization: Autoload.Utils.getAuthToken(),
            //   }
            // );
          }
        };
      };
    }
  };

  deletePropertyPicture(id, updated_property_response= null) {
    let btnelement = document.getElementById('delete-propertypicture-button_id');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'Properties/' + id + '/Picture',
      'DELETE',
      null,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      'handleDeletePropertyPicture',
      { cancelbtn: btnelement.children[0], updated_property_response: updated_property_response },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleDeletePropertyPicture(response) {
    console.log('delete property picture', response);
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    this.setState({
      file: null,
    });
    this.updateProperties(response.other.updated_property_response, response.other.updated_property_response === null && true);
  }

  putPropertyPicture(id, updated_property_response) {
    const payload = new FormData();
    payload.append('file', this.state.file ? this.state.fileToUpload : null);
    let btnelement = document.getElementById('save_property_picture');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + id + '/Picture',
      'PUT',
      payload,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      'putPropertyPictureResponse',
      { updated_property_response: updated_property_response },
      false,
      '',
      { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
    );
  }

  putPropertyPictureResponse(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    let propertyData=response.other.updated_property_response;
    propertyData = {
      ...propertyData,
      pictureLocation: response.result
    }
    this.updateProperties(propertyData);
  }

  validatePropertyData = () => {
    this.setState({
      propertyNameErr: false,
      // propertyAddressErr: false,
      // propertyCityErr: false,
      // propertyZipCodeErr: false,
      // propertyNoOfUnitsErr: false,
      // propertyAreaOfUnitsErr: false,
    });
    let propertyNameErr,
      // propertyAddressErr,
      // propertyCityErr,
      // propertyZipCodeErr,
      // propertyNoOfUnitsErr,
      // propertyAreaOfUnitsErr,
      fileErr = false;
    //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
    const validName = /[^a-zA-Z0-9äöüÄÖÜß]/;
    const validNum = /^[0-9]/;

    if (!this.state.propertyName) {
      propertyNameErr = true;
    }
    // if (!this.state.propertyAddress) {
    //   propertyAddressErr = true;
    // }
    // if (!this.state.propertyCity) {
    //   propertyCityErr = true;
    // }
    // if (!this.state.propertyZipCode || this.state.propertyZipCode === null) {
    //   propertyZipCodeErr = true;
    // }
    // if (!this.state.propertyNoOfUnits || this.state.propertyNoOfUnits === null) {
    //   propertyNoOfUnitsErr = true;
    // }
    // if (
    //   !this.state.propertyAreaOfUnits ||
    //   this.state.propertyAreaOfUnits === null ||
    //   !validNum.test(this.state.propertyAreaOfUnits.replace('m²', ''))
    //   // !validNum.test(Number(this.state.propertyAreaOfUnits.replace('m≤', '')))
    // ) {
    //   propertyAreaOfUnitsErr = true;
    // }
    if (
      this.state.fileToUpload !== null &&
      this.state.fileToUpload !== '' &&
      this.state.fileToUpload !== undefined &&
      this.state.fileUploadErr.state
    ) {
      fileErr = true;
    }

    this.setState({
      propertyNameErr,
      // propertyAddressErr,
      // propertyCityErr,
      // // propertyZipCodeErr,
      // propertyNoOfUnitsErr,
      // propertyAreaOfUnitsErr,
    });
    if (
      propertyNameErr ||
      // propertyAddressErr ||
      // propertyCityErr ||
      // propertyZipCodeErr ||
      // propertyNoOfUnitsErr ||
      // propertyAreaOfUnitsErr ||
      fileErr
    ) {
      return false;
    }
    return true;
  };

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <span>
        Anteil der Heizkosten die verbrauchsabhängig umgelegt werden. Mehr Informationen im{' '}
        <a
          target="_blank"
          href="https://wissen.hellohousing.de/welcher-kostenanteil-soll-nach-verbrauch-umgelegt-werden"
        >
          Hilfe-Center
        </a>
        .
      </span>
    </Tooltip>
  );

  removeSymbol = (field, value, symbol) => {
    console.log('remove symbol', value.replaceAll(symbol, ''));

    this.setState(
      this.state.firstTimeConsumptionValue
        ? { [field]: value.replaceAll(symbol, ''), firstTimeConsumptionValue: false }
        : { [field]: value.replaceAll(symbol, '') }
    );
  };

  addSymbol = (field, value, symbol) => {
    if (value.toString().includes(symbol)) {
      return;
    }
    if (!isNaN(parseFloat(value))) {
      this.setState({ [field]: `${value}${symbol}` });
    } else {
      this.setState({ [field]: value.replaceAll(symbol, '') });
    }
  };

  validateForm = () => {
      // console.log('validate form');
      this.setState({
        propertyNameErrMsg: false,
        numberOfRentalUnitsErrMsg: false,
        totalRentingSpaceErrMsg: false,
        // addressErrMsg: false,
        // areaOfUnitsErrMsg: false,
        // noOfUnitsErrMsg: false,
        // cityErrMsg: false,
        // zipCodeErrMsg: false,
        // fuelTypeErrMsg: false,
        // heatingTempErrMsg: false,
        // heatingTypeErrMsg: false,
        // selectedOptionErrMsg: false,
      });
      let propertyNameErrMsg, numberOfRentalUnitsErrMsg, totalRentingSpaceErrMsg = false;
        // addressErrMsg,
        // areaOfUnitsErrMsg,
        // cityErrMsg,
        // zipCodeErrMsg,
        // noOfUnitsErrMsg,
        // fuelTypeErrMsg,
        // heatingTempErrMsg,
        // heatingTypeErrMsg,
        // selectedOptionErrMsg = false;
      const validNum = /^[0-9]/;
      //const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
      const validName = /[^a-zA-Z0-9äöüÄÖÜß]/;
      if (!this.state.propertyName) {
        propertyNameErrMsg = true;
      }

      if(this.props.propertySelected.propertyType !== 'REGULAR' && !this.state.numberOfRentalUnits){
        numberOfRentalUnitsErrMsg = true;
      }
  
      if(this.props.propertySelected.propertyType !== 'REGULAR' && !this.state.totalRentingSpace){
        totalRentingSpaceErrMsg = true;
      }
  
      // if(this.state.heatingType !== lang.property.generalinfo_decentralized && (!this.state.energyFuelType || !this.state.energyFuelType.fuelTypeName || this.state.energyFuelType.fuelTypeName === lang.newKeys.text_select)) {
      //   fuelTypeErrMsg = true;
      // }
      // if (!this.state.address) {
      //   addressErrMsg = true;
      // }
      // if (!this.state.city) {
      //   cityErrMsg = true;
      // }
      // if (!this.state.zipCode) {
      //   zipCodeErrMsg = true;
      // }
      // if (!this.state.areaOfUnits || !validNum.test(this.state.areaOfUnits)) {
      //   areaOfUnitsErrMsg = true;
      // }
      // if (
      //   !this.state.noOfUnits ||
      //   !validNum.test(this.state.noOfUnits) ||
      //   !validNum.test((this.state.noOfUnits + '').replace('m²', ''))
      // ) {
      //   noOfUnitsErrMsg = true;
      // }
  
      // if (
      //   !this.state.heatingTemp ||
      //   !validNum.test(this.state.heatingTemp) ||
      //   !validNum.test((this.state.heatingTemp + '').replace('°C', ''))
      // ) {
      //   heatingTempErrMsg = true;
      // }
      // if (
      //   !this.state.selectedOption ||
      //   !validNum.test(this.state.selectedOption) ||
      //   !validNum.test((this.state.selectedOption + '').replace('°%', ''))
      // ) {
      //   selectedOptionErrMsg = true;
      // }
  
      // if (!this.state.heatingTypeErrMsg || this.state.heatingTypeErrMsg === lang.newKeys.text_select) {
      //   heatingTypeErrMsg = true;
      // }
  
      this.setState({
        propertyNameErrMsg,
        totalRentingSpaceErrMsg,
        numberOfRentalUnitsErrMsg
        // addressErrMsg,
        // areaOfUnitsErrMsg,
        // noOfUnitsErrMsg,
        // cityErrMsg,
        // zipCodeErrMsg,
        // fuelTypeErrMsg,
        // heatingTypeErrMsg,
        // selectedOptionErrMsg,
        // heatingTempErrMsg,
      });
  
      if (!propertyNameErrMsg && !totalRentingSpaceErrMsg && !numberOfRentalUnitsErrMsg) {
        this._addGeneralInfo()
      } else {
        showNotification({
          message: 'Bitte ergänzen Sie die fehlenden Informationen.',
          icon: <img src={RedCross} alt='red-tick'/>,
        });
        this.setState({oldNumberOfRentalUnits: this.state.numberOfRentalUnits, oldTotalRentingSpace: this.state.totalRentingSpace});
      }
  }

  toggleCutOffDateAlert=()=>{
    this.setState({openCutOffDateAlert: !this.state.openCutOffDateAlert});
  }

  render() {
    const {propertySelected} = this.props;
    const {
      heatingType,
      heatingTemperature,
      previousCheck,
      heatingTypeDropDown,
      fuelTypes,
      consumptionValue,
      selectedOptionvalue,
      openCutOffDateAlert,
      allowCutOffDateChange,
      openCutOffDateModal,
      oldPropertyName,
      oldNumberOfRentalUnits,
      oldPropertyAddress,
      oldPropertyCity,
      oldPropertyZipCode,
      oldTotalRentingSpace,
      totalOccupantsCount,
      oldTotalOccupantsCount,
      rentalUnitSpaceErrMsg,
      oldRentalUnitSpace,
      rentalUnitSpace
    } = this.state;
    console.log('rentalUnitSpace: ', rentalUnitSpace);
    console.log('propertySelected: ', propertySelected);
    return (
      <>
        <div id="empty-generalinfo-page"></div>
        <div className="generalinfo-page" id="respond-generalinfo-page" style={{ display: 'block' }}>
          {this.props.allProperties.length > 0 ? (
            <div>
              <div>
                {/* <ProfilePicView borderRadius={4} width={108} height={108} /> */}
                <div className="profile-image-container">
                  <div className="profile-image">
                    <Skeleton visible={this.state.skeletonLoading}>
                      <img style={{width: '108px', height: '108px', objectFit: 'cover'}} alt="profile" src={this.state.file ? this.state.file : housingphoto} />
                    </Skeleton>
                  </div>
                  <div className="upload-container">
                    <div className="property-input-box">
                      <div id="save_property_picture">
                        <Skeleton visible={this.state.skeletonLoading}>
                        <label className="upload-btn gray-btn" 
                        style={{
                          fontWeight: '500',
                          fontSize: '14px',
                          fontFamily: 'sans-serif',
                          width: 'auto',
                          padding: '7px 11px'
                        }} htmlFor="uploadProfileImage_general">
                          {lang.property.generalinfo_text}
                        </label>
                        </Skeleton>
                        <input
                          id="uploadProfileImage_general"
                          name="uploadProfileImage_general"
                          style={{ display: 'none' }}
                          type="file"
                          accept="image/*"
                          onChange={this.handlePictureUpload_general}
                        />
                      </div>
                      <div id="delete-propertypicture-button_id">
                        <button
                          className="delete-propertypicture-button"
                          // onClick={() => this.deletePropertyPicture(this.props.propertyData.id)}
                          onClick={() =>
                            this.setState({ file: '', fileToUpload: '', fileUploadErr: { state: false, msg: '' } }, ()=> this.validateForm())
                          }
                        >
                          <Skeleton visible={this.state.skeletonLoading}>{lang.general.text_delete}</Skeleton>
                        </button>
                      </div>
                    </div>
                    <span className="file-desc">{lang.property.generalinfo_photo_update}</span>
                    <span className="file-err">{this.state.fileUploadErr.state && this.state.fileUploadErr.msg}</span>
                  </div>
                </div>
              </div>
              <Row>
                <Col xl={4} lg={6} md={6} xs={12}>
                  <Form.Group>
                  <div className='tooltips property-name-edit'><Form.Label>{lang.property.generalinfo_propertyname}</Form.Label></div>
                    <Skeleton visible={this.state.skeletonLoading}>
                      <Form.Control
                        id="property-name"
                        name="property-name"
                        type="text"
                        placeholder=""
                        value={this.state.propertyName}
                        isInvalid={this.state.propertyNameErr}
                        //        onKeyDown={this._handleKeyDown}
                        onChange={(e) => {
                          this.onInputChange('propertyName', e.target.value, true);
                        }}
                        onBlur={(e)=> oldPropertyName !== e.target.value && this.validateForm()}
                      />
                    </Skeleton>
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.property.generalinfo_propertynameError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={4} lg={6} md={6} xs={12}>
                  <Form.Group>
                  <div className='tooltips property-address-edit'><Form.Label>{lang.property.generalinfo_propertyaddress}</Form.Label></div>
                    <Skeleton visible={this.state.skeletonLoading}>
                    <Form.Control
                      value={this.state.propertyAddress}
                      // isInvalid={this.state.propertyAddressErr}
                      type="text"
                      placeholder=""
                      onChange={(e) => this.onInputChange('propertyAddress', e.target.value, true)}
                      onBlur={(e)=> oldPropertyAddress !== e.target.value && this.validateForm()}
                    />
                    </Skeleton>
                    {/* <Form.Control.Feedback className="err" type="invalid">
                      {lang.property.generalinfo_propertyaddressErr}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={3} lg={4} md={4} xs={9}>
                  <Form.Group>
                  <div className='tooltips property-city-edit'><Form.Label>{lang.general.text_city}</Form.Label></div>
                    <Skeleton visible={this.state.skeletonLoading}>
                    <Form.Control
                      type="text"
                      // isInvalid={this.state.propertyCityErr}
                      value={this.state.propertyCity}
                      placeholder=""
                      onChange={(e) => {
                        this.onInputChange('propertyCity', e.target.value, true);
                      }}
                      onBlur={(e)=> oldPropertyCity !== e.target.value && this.validateForm()}
                    />
                    </Skeleton>
                    {/* <Form.Control.Feedback className="err" type="invalid">
                      {lang.general.text_invalid_city}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col xl={1} lg={2} md={2} xs={3} className="zipcode-property">
                  <Form.Group>
                  <div className='tooltips property-postal-code-edit'><Form.Label>{lang.general.text_zip_code}</Form.Label></div>
                    <Skeleton visible={this.state.skeletonLoading}>
                    <Form.Control
                      type="text"
                      // isInvalid={this.state.propertyZipCodeErr}
                      value={this.state.propertyZipCode}
                      placeholder=""
                      onChange={(e) => {
                        this.onInputChange('propertyZipCode', e.target.value, true);
                      }}
                      onBlur={(e)=> oldPropertyZipCode !== e.target.value && this.validateForm()}
                    />
                    </Skeleton>
                    {/* <Form.Control.Feedback className="err" type="invalid">
                      {lang.general.text_invalid_zip_code}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                <Col xl={2} lg={4} xs={6} md={3} className="noofunit">
                  <Form.Group>
                    <Form.Label>{lang.property.generalinfo_Property_no_of_Units}</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder=""
                      value={this.state.propertyNoOfUnits}
                      isInvalid={this.state.propertyNoOfUnitsErr}
                      onChange={(e) => {
                        this.onInputChange('propertyNoOfUnits', e.target.value);
                      }}
                    />
                    <img
                      src={addicons}
                      onClick={() =>
                        this.setState({ propertyNoOfUnits: (parseInt(this.state.propertyNoOfUnits) + 1).toString() })
                      }
                      alt=""
                      className="addbtn-icon"
                    />
                    <img
                      src={subtracticon}
                      alt=""
                      onClick={() =>
                        this.setState({ propertyNoOfUnits: (parseInt(this.state.propertyNoOfUnits) - 1).toString() })
                      }
                      className="subbtn-icon"
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.property.generalinfo_Property_no_of_Units_error}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xl={2} lg={2} xs={6} md={3}>
                  <Form.Group>
                    <Form.Label>{lang.property.generalinfo_Area_of_unit}</Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.propertyAreaOfUnits}
                      isInvalid={this.state.propertyAreaOfUnitsErr}
                      placeholder="711 m²"
                      // onFocus={(e) => this.removeSymbol(e.target.value, 'm≤')}
                      // onBlur={(e) => this.addSymbol(e.target.value, 'm≤')}
                      onFocus={(e) => this.removeSymbol('propertyAreaOfUnits', e.target.value, ' m²')}
                      onBlur={(e) => this.addSymbol('propertyAreaOfUnits', e.target.value, ' m²')}
                      onChange={(e) => {
                        this.onInputChange('propertyAreaOfUnits', e.target.value, true);
                      }}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {lang.property.generalinfo_Property_no_of_Units_error}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row> */}
              {propertySelected.propertyType !== 'REGULAR' &&
              <>
                <Row>
                  <Col xl={2} lg={3} md={3} xs={6} style={{paddingRight: '7.5px'}}>
                    <Form.Group>
                    <div className='tooltips property-total-renting-space'><Form.Label>{'Gesamtmietfläche (WEG)'}</Form.Label></div>
                      <Skeleton visible={this.state.skeletonLoading}>
                        <NumericFormat 
                          className={`form-control ${this.state.totalRentingSpaceErrMsg && 'invalid'}`}
                          value={this.state.totalRentingSpace}
                          thousandSeparator="." 
                          decimalSeparator=","
                          onValueChange={(values, sourceInfo) => {
                            this.setState({
                              totalRentingSpace: values.floatValue,
                            })
                          }}
                          onBlur={(e)=> oldTotalRentingSpace ? `${formatNumber(oldTotalRentingSpace, false)} m²` !== e.target.value && this.validateForm() : this.validateForm()
                          }
                          suffix={' m²'}
                          onKeyPress={this.handleEnterKeyPress}
                          placeholder='0,00 m²'
                          decimalScale={2}
                          fixedDecimalScale={2}
                          allowNegative={false}
                          />
                      </Skeleton>
                      {/* <Form.Control
                        // isInvalid={this.state.addressErrMsg}
                        value={this.state.totalRentingSpace + ' m²'}
                        type="text"
                        placeholder="0,00 m²"
                        onChange={(e) =>
                          this.setState({
                            totalRentingSpace: e.target.value,
                          })
                        }
                        onKeyPress={this.handleEnterKeyPress}
                      /> */}
                      <Form.Control.Feedback style={{display: this.state.totalRentingSpaceErrMsg && 'block'}} className="err" type="invalid">
                        {lang.property.generalinfo_propertyRentingSpaceErr}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xl={2} lg={3} md={3} xs={6} style={{paddingLeft: '7.5px'}}>
                    <Form.Group>
                    <div className='tooltips property-number-of-rental-units'><Form.Label>{'Mieteinheiten (WEG)'}</Form.Label></div>
                      <Skeleton visible={this.state.skeletonLoading}>
                      <Form.Control
                        className={`form-control ${this.state.numberOfRentalUnitsErrMsg && 'invalid'}`}
                        // isInvalid={this.state.addressErrMsg}
                        onBlur={(e)=> oldNumberOfRentalUnits !== parseInt(e.target.value) && this.validateForm()}
                        value={this.state.numberOfRentalUnits}
                        type="number"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({
                            numberOfRentalUnits: parseInt(e.target.value),
                          })
                        }
                        onKeyPress={this.handleEnterKeyPress}
                      />
                      <img
                        src={addicons}
                        onClick={() => this.setState({ numberOfRentalUnits: this.state.numberOfRentalUnits ? this.state.numberOfRentalUnits + 1 : 1 }, ()=> this.validateForm())}
                        alt=""
                        className="addbtn-icon"
                        style={{right: '10px'}}
                      />
                      <img
                        src={subtracticon}
                        alt=""
                        onClick={() => this.state.numberOfRentalUnits > 0 && this.setState({ numberOfRentalUnits: this.state.numberOfRentalUnits - 1 }, ()=> this.validateForm())}
                        className="subbtn-icon"
                        style={{right: '40px'}}
                      />
                      </Skeleton>
                      <Form.Control.Feedback style={{display: this.state.numberOfRentalUnitsErrMsg && 'block'}} className="err" type="invalid">
                        {lang.property.generalinfo_propertyRentalUnitsErr}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl={2} lg={3} md={3} xs={6} style={{paddingRight: '7.5px'}}>
                    <Form.Group>
                    <div className='tooltips property-rental-space-condo'><Form.Label style={{whiteSpace: 'nowrap'}}>{'Mietfläche der Einheit'}</Form.Label></div>
                    <Skeleton visible={this.state.skeletonLoading}>
                      <NumericFormat 
                      className={`form-control ${rentalUnitSpaceErrMsg && 'invalid'}`}
                      value={this.state.rentalUnitSpace}
                      thousandSeparator="." 
                      decimalSeparator=","
                      onValueChange={(values, sourceInfo) => {
                        this.setState({
                          rentalUnitSpace: values.floatValue,
                        })
                      }}
                      onBlur={(e)=> oldRentalUnitSpace !== this.state.rentalUnitSpace && this.updateRentalUnit()}
                      suffix={' m²'}
                      onKeyPress={this.handleEnterKeyPress}
                      placeholder='0,00 m²'
                      decimalScale={2}
                      fixedDecimalScale={2}
                      allowNegative={false}
                      />
                    </Skeleton>
                      {/* <Form.Control
                        // isInvalid={this.state.addressErrMsg}
                        value={this.state.totalRentingSpace + ' m²'}
                        type="text"
                        placeholder="0,00 m²"
                        onChange={(e) =>
                          this.setState({
                            totalRentingSpace: e.target.value,
                          })
                        }
                        onKeyPress={this.handleEnterKeyPress}
                      /> */}
                      <Form.Control.Feedback style={{display: rentalUnitSpaceErrMsg && 'block'}} className="err" type="invalid">
                        {lang.property.generalinfo_propertyRentingSpaceErr}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xl={2} lg={3} md={3} xs={6} style={{paddingLeft: '7.5px'}}>
                    <Form.Group>
                    {/* <div className='tooltips property-total-number-of-people-in-condo-house'><Form.Label style={{whiteSpace: 'nowrap'}}>{'Gesamtpersonenanzahl (Haus)'}</Form.Label></div>
                      <Skeleton visible={this.state.skeletonLoading}>
                        <NumericFormat 
                          className='form-control'
                          value={totalOccupantsCount}
                          thousandSeparator="." 
                          decimalSeparator=","
                          onValueChange={(values, sourceInfo) => {
                              this.setState({
                                totalOccupantsCount: values.floatValue,
                              })
                          }}
                          onBlur={(e)=> oldTotalOccupantsCount !== this.state.totalOccupantsCount && this.validateForm()}
                          //onKeyPress={this.handleEnterKeyPress}
                          placeholder='0,00'
                          decimalScale={2}
                          fixedDecimalScale={2}
                          allowNegative={false}
                          />
                        <img
                          src={addicons}
                          onClick={() => this.setState({ totalOccupantsCount: this.state.totalOccupantsCount ? this.state.totalOccupantsCount + 1 : 1 }, ()=> this.validateForm())}
                          alt=""
                          className="addbtn-icon"
                          style={{right: '10px'}}
                        />
                        <img
                          src={subtracticon}
                          alt=""
                          onClick={() => this.state.totalOccupantsCount > 0 && this.setState({ totalOccupantsCount: this.state.totalOccupantsCount - 1 }, ()=> this.validateForm())}
                          className="subbtn-icon"
                          style={{right: '40px'}}
                        />
                      </Skeleton> */}
                      {/* <Form.Control.Feedback style={{display: this.state.numberOfRentalUnitsErrMsg && 'block'}} className="err" type="invalid">
                        {lang.property.generalinfo_propertyRentalUnitsErr}
                      </Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                </Row>
              </>}
              <Row>
                <Col xl={4} lg={6} md={6} xs={12}>
                  <Form.Group>
                    
                  <div className='tooltips property-cut-off-date-edit'><Form.Label>{lang.property.generalinfo_cut_off_date}</Form.Label></div>
                    {/* <br /> */}
                    <div style={{display: 'flex', width: '100%'}}>
                      <div style={{width: '170px' }}>
                      <Skeleton visible={this.state.skeletonLoading}>
                      {//!allowCutOffDateChange ?
                      <div className='form-control' style={{display: 'flex', alignItems: 'center', background: '#f7f7fa'}}>
                        <img src={calendar}/>
                        <span style={{fontFamily: 'sans-serif', fontSize: '14px', paddingTop: '3.5px', paddingLeft: '5px'}}>{moment(this.state.date.value.toString()).format('DD. MMMM')}</span>
                      </div>
                      // :
                      // <DatePicker
                      //   locale="de"
                      //   formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                      //   dateFormat="dd. MMMM"
                      //   dateFormatCalendar="LLLL"
                      //   customInput={<CustomDateInput/>}
                      //   yearItemNumber="1"
                      //   yearDropdownItemNumber="1"
                      //   style={{ display: 'block !important' }}
                      //   popperPlacement="bottom-end"
                      //   placeholderText={!this.state.date.isSelected && 'DD Month'}
                      //   selected={this.state.date.isSelected && this.state.date.value}
                      //   onChange={(e) => this.onDateChange('date', e)}
                      //   popperModifiers={{
                      //     preventOverflow: {
                      //       enabled: true,
                      //     },
                      //   }}
                      //   portalId="root-portal"
                      //   //disabled={!allowCutOffDateChange}
                      // />
                      }
                      </Skeleton>
                      </div>
                      {//!allowCutOffDateChange ?
                      <button className="cut-off-edit-button" onClick={() => !this.state.skeletonLoading && this.toggleCutOffDateAlert()}>
                        <Skeleton visible={this.state.skeletonLoading}>{lang.property.generalinfo_edit_property_cut_off_date}</Skeleton>
                      </button>
                      // :
                      // <div style={{display: 'flex', alignItems: 'center'}}>
                      // <Checkbox
                      // checked={previousCheck}
                      // style={{color: previousCheck ? '#5555FF' : '#9A9AB5'}}//selectedReceipts.length === this.props.data.length ? '#5555FF' : '#9A9AB5'}}
                      // inputProps={{ 'aria-labelledby': 0 }}
                      // onClick={()=> 
                      //   this.setState({previousCheck: !previousCheck})}
                      // //indeterminate={selectedReceipts.length !== 0 && selectedReceipts.length !== this.props.data.length && true}
                      // />
                      // <span className="cut-off-edit-button" style={{marginLeft: 0, whiteSpace: 'nowrap', cursor: 'default'}}>{'Include previous billingPeriods'}</span>
                      // </div>
                      }
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row style={{ marginTop: '15px' }}>
                <Col xl={4} lg={6} md={3} xs={12} className="generalinfo-buttons">
                  <Skeleton visible={this.state.skeletonLoading}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <ColorButton
                        onClick={() => this.props.history.push('/dashboard')}
                        className="gray-btn"
                        text={lang.general.text_cancel}
                        buttonStyle={{maxWidth: '100px'}}
                      />
                      <ColorButton
                        onClick={() => {
                          this.validateForm()
                        }}
                        buttonConfig={{ type: 'submit' }}
                        className="purple-btn"
                        text={lang.general.text_Save_Changes}
                        buttonStyle={{maxWidth: '100px'}}
                      />
                      </div>
                  </Skeleton>
                </Col>
              </Row> */}
              <div>
                {/* <h3 className="danger-text">{lang.general.text_danger_zone}</h3> */}
                <div
                  //id="delete-data-property"
                  style={{ marginBottom: '50px', display: 'flex', flexDirection: 'column' }}
                >
                  <span className='delete-property-label'>Immobilie löschen</span>
                  <button className="delete-button" onClick={() => !this.state.skeletonLoading && this.toggleDeleteUnitModal()}>
                    {'Klicken Sie hier, um diese Immobilie zu löschen.'}
                  </button>
                </div>
              </div>
              <AlertModal
              open={openCutOffDateAlert}
              heading={lang.property.generalinfo_edit_property_cut_off_date}
              headingClassName={'tooltips property-cut-off-date-alert'}
              text={lang.property.generalinfo_edit_property_cut_off_date_text}
              cancel
              rightButtonText={'Fortfahren'}
              toggle={()=>this.toggleCutOffDateAlert()}
              onClick={()=> this.setState({allowCutOffDateChange: true, openCutOffDateAlert: false, openCutOffDateModal: true})}
              onCancel={()=>this.toggleCutOffDateAlert()}
              redButton={true}
              />
              <AlertModal
              open={openCutOffDateModal}
              heading={lang.property.generalinfo_edit_property_cut_off_date}
              headingClassName={'tooltips property-cut-off-date-alert'}
              text={
                <Stack>
                  <Group><span>{`Bitte geben Sie den neuen Stichtag der Immobilie ${propertySelected.name} an.`}</span></Group>
                  <Group>
                    <DatePicker
                        locale="de"
                        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                        dateFormat="dd. MMMM"
                        dateFormatCalendar="LLLL"
                        customInput={<CustomDateInput/>}
                        yearItemNumber="1"
                        yearDropdownItemNumber="1"
                        style={{ display: 'block !important' }}
                        popperPlacement="bottom-end"
                        placeholderText={!this.state.date.isSelected && 'DD Month'}
                        selected={this.state.date.isSelected && this.state.date.value}
                        onChange={(e) => this.onDateChange('date', e)}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                          },
                        }}
                        portalId="root-portal"
                        disabled={!allowCutOffDateChange}
                      />
                      <Group>
                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                          <span className="cut-off-edit-button" style={{marginLeft: 0, cursor: 'default'}}>
                            <Checkbox
                            checked={previousCheck}
                            style={{color: previousCheck ? '#5555FF' : '#9A9AB5', padding: '0px', marginRight: '9px'}}//selectedReceipts.length === this.props.data.length ? '#5555FF' : '#9A9AB5'}}
                            inputProps={{ 'aria-labelledby': 0 }}
                            onClick={()=> 
                              this.setState({previousCheck: !previousCheck})}
                            />{'Die Änderung soll nur für die aktuelle und zukünftige Abrechnungsperioden wirksam sein.'}</span>
                        </div>
                      </Group>
                  </Group>
                </Stack>
              }
              cancel
              rightButtonText={'Ändern'}
              toggle={()=>this.setState({openCutOffDateModal: false})}
              onClick={()=> {
                this.setState({openCutOffDateModal: false});
                this.validateForm();
              }}
              onCancel={()=>this.setState({openCutOffDateModal: false})}
              redButton={true}
              />
              <DeleteModal
              open={this.state.deleteUnitModal}
              className="delete-rent-modal"
              toggle={this.toggleDeleteUnitModal}
              record={this.props.propertyData.id}
              handleDelete={() => this.deleteProperty(this.props.propertyData.id)}
              // showInfo={[this.props.propertyData.name,]}
              type={lang.property.text_property}
              showtext={false}
              showDeleteText={false}
              textShowModal={'Immobilie löschen'}
              typeText={
                'Sind Sie sicher, dass Sie diese Immobilie löschen wollen? Dabei werden auch alle Daten der Immobilie unwiderruflich gelöscht!'
              }
              modalId="delete-data-property"
            />
            </div>
          ) : (
            // <EmptyStateDashboard
            //   heading="Add New property"
            //   // subheading="You must add property first to manage receipts."
            //   className="emptystate-dashboard empty-state-btn-added "
            //   icon={emptyPropertyHome}
            //   btnHandler={() => {}}
            //   btnText="Add Property"
            //   blueButtonDisable={true}
            //   buttonDisable={false}
            // />
            <EmptyStateContainer
              heading={lang.newKeys.text_add_new_property}
              subheading="Hier können Sie später Ihre Immobilie verwalten."
              icon={emptyPropertyHome}
              btnHandler={() => {
                this.toggleAddPropertyTypeModal();
              }}
              btnText={lang.general.text_add_property}
            />
          )}
        </div>
        <AddPropertyModal
          className="add_proprty_modal"
          edit={false}
          open={this.state.addPropertyModal}
          toggle={() => {
            this.toggleAddPropertyModal();
          }}
          propertyType={this.state.selectedPropertyType}
        />
        {this.state.addPropertyTypeModal &&
          <AddPropertyTypeModal
            className="add_proprty_type_modal"
            open={this.state.addPropertyTypeModal}
            toggle={() => {
              this.toggleAddPropertyTypeModal();
            }}
            next={(selectedPropertyType)=> {
              this.setState({selectedPropertyType});
              this.toggleAddPropertyTypeModal();
              this.toggleAddPropertyModal();
            }}
          />
          }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertyData: state.generalReducer.property,
    profileData: state.generalReducer.profile,
    allProperties: state.generalReducer.allProperties,
    // propertySelected: state.generalReducer.property,
    propertySelected: state.generalReducer.property,
  };
};

const actions = {
  updatePropetyInfo,
  deleteProperty,
  editProfileData,
  setPropertyList,
  changeCurrentProperty,
  updatePropetyInfo,
};

export default connect(mapStateToProps, actions)(GeneralInformation);

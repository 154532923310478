import React, { Component, forwardRef, useState } from 'react';
import CustomModal from './index';
import {
  ColorButton,
  ValueText,
  SwitchContainer,
  DropFile,
  AddCostTypeModal
} from '../../../../index';
import { Row, Col, Form, Tooltip, OverlayTrigger, ButtonGroup, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';

import { showNotification, hideNotification } from '@mantine/notifications';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import Messenger from '../../../../../assets/images/module/general/Messenger.svg';

// Get receipts endpoint from new config
import { config } from '../../../../config/Setting';
import { Check, ThreeDRotationSharp } from "@material-ui/icons";
import { Alert, Group, Loader, Select, Stack, Text } from "@mantine/core";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import PdfViewer from '../PdfViewer/PdfViewer';
import Pdf, { usePdf } from '@mikecousins/react-pdf';
import { useRef } from 'react';
import { useEffect } from 'react';
import PostviewMessageEmail from '../PostviewMessage/PostviewMessageEmail';
import { ZoomIn, ZoomOut} from '@material-ui/icons';
import PostviewMessageTenantPortal from '../PostviewMessage/PostviewMessageTenantPortal';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

const FilePreview = React.memo(({file}) => {
    const [imgScale, setImgScale] = React.useState(100);
    if (file) {
    //   let re = /(?:\.([^.]+))?$/;
    //   var extension = re.exec(!edit ? file.name : typeof(file) === 'object' ? file.name : file)[1];
    //   extension = extension && extension.split('?')[0];
    
    //   if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
    //     const url = !edit ? URL.createObjectURL(file) : typeof(file) === 'object' ? URL.createObjectURL(file) : file;
    //     return (
    //     <div style={{width: `${imgScale}%`, textAlign:'center'}}>
    //       <img src={url} className="main-img" alt="no" />
    //       <div style={{textAlign: 'left', position: 'absolute', //bottom: !isMobile && 27, 
    //       top: isMobile ? 367 : 667, left: 4}}> 
    //         <nav>
    //             <Button size={'sm'} disabled={imgScale === 200} onClick={() => setImgScale(imgScale + 10)} children={<ZoomIn/>}/>
    //             {' '}
    //             <Button size={'sm'} disabled={imgScale === 100} onClick={() => setImgScale(imgScale - 10)} children={<ZoomOut/>}/>
    //             {' '}
    //             {/* <Button size={'sm'} onClick={() => onExpand()} children={<img src={ExpandIcon}/>}/> */}
    //         </nav>
    //       </div>
    //     </div>
    //     );
    //   } else 
      if (typeof(file) === 'object') {
        const url =URL.createObjectURL(file);
        return (
          <PdfViewer file={url} preview={true}/>
        );
      }
    }
  }); 

// const MyPdfViewer = ({file, isMobile}) => {
//   const canvasRef = useRef(null);
//   const [imgScale, setImgScale] = React.useState(isMobile ? 0.5 : 1.3);

//   const { pdfDocument } = usePdf({
//     file,
//     canvasRef
//   });

//   console.log('MyPdfViewer: ', pdfDocument, file);

//   const pdfPages=()=>{
//     let pdfArray=[];
//     for (let index = 1; index <= pdfDocument.numPages; index++) {
//       pdfArray.push(<Pdf file={file} scale={imgScale} page={index}/>);
//     }
//     return pdfArray;
//   }
//   return (
//     <Stack>
//       {pdfDocument && pdfPages()}
//       {isMobile &&
//       <div style={{textAlign: 'left', position: 'absolute', //bottom: !isMobile && 27, 
//         top: 505, left: 40}}> 
//         <nav>
//           <Button style={{backgroundColor: '#f8f8fa', border: '1px solid #d5dce6', color: '#252563'}} size={'sm'} disabled={imgScale === 3} onClick={() => setImgScale(imgScale + 0.1)} children={<ZoomIn/>}/>
//           {' '}
//           <Button style={{backgroundColor: '#f8f8fa', border: '1px solid #d5dce6', color: '#252563'}} size={'sm'} disabled={imgScale === 0.5} onClick={() => setImgScale(imgScale - 0.1)} children={<ZoomOut/>}/>
//           {' '}
//         </nav>
//       </div>}
//     </Stack>
//   );
// };

const MyPdfViewer = ({file, isMobile}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const canvasRef = useRef(null);
  const [imgScale, setImgScale] = React.useState(isMobile ? 0.5 : 1.335);

  const { pdfDocument } = usePdf({
    file,
    canvasRef
  });

  console.log('MyPdfViewer: ', pdfDocument, file);

  const pdfPages=()=>{
    let pdfArray=[];
    for (let index = 1; index <= pdfDocument.numPages; index++) {
      pdfArray.push(<div style={{marginBottom: '20px'}}><Page loading={null} pageNumber={index} scale={imgScale} /></div>);
    }
    return pdfArray;
  }

  return (
    <Stack>
      <Document file={file} loading={null} onLoadError={(e)=> console.log('pdfLoadError: ', e)}>
        {pdfDocument && pdfPages()}
      </Document>
      {isMobile &&
      <div style={{textAlign: 'left', position: 'absolute', //bottom: !isMobile && 27, 
        top: 505, left: 40}}> 
        <nav>
          <Button style={{backgroundColor: '#f8f8fa', border: '1px solid #d5dce6', color: '#252563'}} size={'sm'} disabled={imgScale === 3} onClick={() => setImgScale(imgScale + 0.1)} children={<ZoomIn/>}/>
          {' '}
          <Button style={{backgroundColor: '#f8f8fa', border: '1px solid #d5dce6', color: '#252563'}} size={'sm'} disabled={imgScale === 0.5} onClick={() => setImgScale(imgScale - 0.1)} children={<ZoomOut/>}/>
          {' '}
        </nav>
      </div>}
    </Stack>
  );
};

const Item = forwardRef(({ label, value, ...others }, ref) => {
  console.log('Item: ', label, value, others);
  //const Flag = flags[value];
  return (
    <div ref={ref} {...others}>
      {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box mr={10}>
          <img src={others.picture}/>
        </Box>
        <div>{others.name}</div>
      </Box> */}

      {value === 'addRecipient' ?
      <span onClick={()=> others.openAddRecipientModal()}>{others.name}</span>
      :
      value === 'selectAll' ?
      <span onClick={()=> others.selectAllRecipients()}>{others.name}</span>
      :
      <Group spacing={10} className='message-card-ten-cards' key={value} 
      style={{
        height: '20.6px', 
        maxHeight: '20.6px', 
        background: 'transparent', 
        width: 'auto', 
        color: '#0e0e1d',
        fontSize: '14px',
        fontWeight: '500',
        fontFamily: 'sans-serif'
      }}>
          {others.picture && <img src={others.picture} style={{marginTop: '-3px'}}/>}
          <Group spacing={5}>
            <span>{others.name}</span>
            {others.rentalUnit && <span>{`(${others.rentalUnit})`}</span>}
          </Group>
          {/* {others.current && <span>(aktuell)</span>} */}
      </Group>}
    </div>
  );
});

class MessagePostviewModal extends Component {
  state = {
    selectedRecipient: null,
    selectedView: null,
    allBillingStatements: [],
    selectedBillingStatement: null,
    selectedCostAllocations: [],
    loader: false,
    fileUrl: null,
    selectedRecipientObject: null
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    let selectedRecipientObject = this.props.allRecipients.find((recipient)=> recipient.id === this.props.selectedRecipient);
    console.log('componentDidMount: ', selectedRecipientObject);
    if(this.props.allRecipients.length > 0){
      this.handleRecipientChange(this.props.selectedRecipient);
      this.setState({selectedRecipientObject});
    }
  }

  handleRecipientChange=(recipientId)=>{
    let recipient = this.props.allRecipients.find((recipient)=> recipient.id === recipientId);
    console.log('recipient: ', recipient);
    let {selectedView} = this.state;
    if(selectedView === 'email'){
     if(!recipient?.isEmailEnabled){
      if(recipient?.isPostEnabled){
        selectedView = 'postal'
      }else if(recipient?.isHiddenForTenantPortal === false){
        selectedView = 'tenantPortal'
      }else{
        selectedView = null
      }
     }
    }

    if(selectedView === 'postal'){
      if(!recipient?.isPostEnabled){
        if(recipient?.isEmailEnabled){
          selectedView = 'email'
        }else if(recipient?.isHiddenForTenantPortal === false){
          selectedView = 'tenantPortal'
        }else{
          selectedView = null
        }
      }
    }

    if(selectedView === 'tenantPortal'){
      if(recipient?.isHiddenForTenantPortal){
       if(recipient?.isEmailEnabled){
         selectedView = 'email'
       }else if(recipient?.isPostEnabled){
        selectedView = 'postal'
      }else{
         selectedView = null
       }
      }
    }

    if(selectedView === null){
      if(recipient?.isPostEnabled){
        selectedView = 'postal';
      }else if(recipient?.isEmailEnabled){
        selectedView = 'email';
      }else if(recipient?.isHiddenForTenantPortal === false){
        selectedView = 'tenantPortal'
      }
    }

    this.setState({selectedRecipient: recipientId, selectedRecipientObject: recipient, selectedView}, ()=> recipient !== undefined && selectedView === 'postal' && this.getDocumentsById(recipient.documentId));
  }

  getDocumentsById=(id)=>{
    if(id){
      this.setState({loader: true});
      fetch(Autoload.Setting.document_api_base_url + 'documents/' + id + '/download',
        {
          method: 'GET',
          headers: {
            'Authorization': Autoload.Utils.getAuthToken()
          }
        })
        .then(response => {
          response.blob()
          .then(data => {
            //download progress
            // var a = document.createElement("a");
            // document.body.appendChild(a);
            // a.style = "display: none";
            
            let url = window.URL.createObjectURL(data);
            this.setState({fileUrl: url, loader: false});
          })
        })
    }
  }

  renderSelectedView=()=>{
    const { isMobile} = this.props;
    const { selectedRecipient, selectedView, loader, fileUrl, selectedRecipientObject } = this.state;
    return (
      <ButtonGroup className='btn-group-messaging'>
        <Button
        className={`switch-btn ${selectedView === 'postal' && 'active'}`}
        style={{width: '110px', fontWeight: '400'}}
        onClick={() => this.setState({selectedView: 'postal'})}
        disabled={!selectedRecipientObject?.isPostEnabled}
        >
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span style={{marginTop: '-1px'}}>
                Brief
                </span>
            </div>
        </Button>
        <Button
        className={`switch-btn ${selectedView === 'email' && 'active'}`}
        style={{width: '110px', fontWeight: '400'}}
        onClick={() => this.setState({selectedView: 'email'})}
        disabled={!selectedRecipientObject?.isEmailEnabled}
        >
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span style={{marginTop: '-1px'}}>
                Email
                </span>
            </div>
        </Button>
      </ButtonGroup>
    )
  }

  renderPostalEmailButtonGroup=()=>{
    const { selectedView, selectedRecipientObject } = this.state;
    return(
      <ButtonGroup className='btn-group-messaging'>
                {selectedRecipientObject?.isPostEnabled === true &&
                <Button
                className={`switch-btn ${selectedView === 'postal' && 'active'}`}
                style={{width: '110px', fontWeight: '400'}}
                onClick={() => this.setState({selectedView: 'postal'})}
                disabled={!selectedRecipientObject?.isPostEnabled}
                >
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span style={{marginTop: '-1px'}}>
                        Brief
                        </span>
                    </div>
                </Button>}
                {selectedRecipientObject?.isEmailEnabled === true &&
                <Button
                className={`switch-btn ${selectedView === 'email' && 'active'}`}
                style={{width: '110px', fontWeight: '400'}}
                onClick={() => this.setState({selectedView: 'email'})}
                disabled={!selectedRecipientObject?.isEmailEnabled}
                >
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span style={{marginTop: '-1px'}}>
                        Email
                        </span>
                    </div>
                </Button>}
                {selectedRecipientObject?.isHiddenForTenantPortal === false &&
                <Button
                className={`switch-btn ${selectedView === 'tenantPortal' && 'active'}`}
                style={{width: '110px', fontWeight: '400'}}
                onClick={() => this.setState({selectedView: 'tenantPortal'})}
                disabled={selectedRecipientObject?.isHiddenForTenantPortal}
                >
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span style={{marginTop: '-1px'}}>
                        Mieterportal
                        </span>
                    </div>
                </Button>}
            </ButtonGroup>
    )
  }
  
  renderMessagePreviewBox=()=>{
    const { allRecipients, messageData, rentalUnits, propertySelected, accountData, isMobile} = this.props;
    const { selectedRecipient, selectedView, loader, fileUrl } = this.state;
    return (<div 
    className='message-preview-box'
    style={{
    background: 'grey', 
    // minWidth: '700px',//'285px', 
    // maxWidth: '700px',//'285px', 
    minHeight: isMobile ? '400px' : '700px',//'431px', 
    maxHeight: isMobile ? '400px' : '700px',//'431px', 
    padding: '10px',
    //overflow: edit ? (record && record.file_name !== '' ? 'scroll' : (selectedFile ? 'scroll' : 'hidden')) : selectedFile ? 'scroll' : 'hidden', 
    overflow: 'scroll',
    borderRadius: '4px', 
    border: '1px solid #DADAE6'}}>
        {!isMobile && 
        <Group style={{width: '100%', position: 'absolute', right: '34px'}} position='right'>
            {this.renderPostalEmailButtonGroup()}
        </Group>}
        {!loader ?
        <Group position='center' style={{marginTop: !isMobile && '50px', marginBottom: !isMobile && '50px', height: '100%'}}>
            {selectedView === 'postal' ?
            fileUrl &&
            <div style={{display: 'flex', backgroundColor:'grey', justifyContent: 'center', alignItems: 'center', padding: 2}}>
              <MyPdfViewer file={fileUrl} isMobile={isMobile}/>
            </div>
            :
            selectedView === 'email' ?
            allRecipients?.find((recipient)=> recipient.id === selectedRecipient)?.isEmailEnabled &&
            <PostviewMessageEmail 
            selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} 
            senderEmail={accountData?.companyEmail} 
            senderProfile={this.props.profile} 
            propertySelected={propertySelected} 
            accountData={accountData}
            rentalUnits={rentalUnits}
            messageData={messageData}
            isMobile={isMobile}
            />
            :
            allRecipients?.find((recipient)=> recipient.id === selectedRecipient)?.isHiddenForTenantPortal === false &&
            <PostviewMessageTenantPortal 
            selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} 
            senderEmail={accountData?.companyEmail} 
            senderProfile={this.props.profile} 
            propertySelected={propertySelected} 
            accountData={accountData}
            rentalUnits={rentalUnits}
            messageData={messageData}
            isMobile={isMobile}
            />
            }
            
        </Group>
        :
        <Stack style={{width: '100%', height: '680px'}} align='center' justify={'center'}>
            <Loader size='lg' variant='bars' color={'#e3e3fc'} />
        </Stack>
        }
        
    </div>)
  }
  render() {
    const { open, toggle, className, allRecipients, messageData, senderAddress, senderEmail, billingPeriod, rentalUnits, propertySelected, accountData, isMobile} = this.props;
    const { imgScale, selectedRecipient, selectedView, selectedBillingStatement, loader, fileUrl, selectedRecipientObject } = this.state;

    console.log('rentalUnits: ', rentalUnits);
    console.log('selectedBillingStatement: ', selectedBillingStatement, selectedRecipient);
    console.log('allRecipients: ', allRecipients);
    console.log('fileUrl: ', fileUrl);
    console.log('messageData: ', messageData);

    return (
      <>
        <CustomModal
          heading={'Empfängernachricht anzeigen'}
          headingClassName='preview-message-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className} ${isMobile ? 'message-preview-main-mobile-modal' : 'message-preview-main-modal'}`}
        >
            {isMobile ?
            <Stack>
              <Select
              label={'Empfänger'}
              searchable
              data={allRecipients.map((recipient)=> {return {...recipient, label: recipient.name, value: recipient.id}})}
              zIndex={9999999}
              onChange={(value)=> this.handleRecipientChange(value)}
              value={selectedRecipient}
              />
              {this.renderPostalEmailButtonGroup()}
              {this.renderMessagePreviewBox()}
            </Stack>
            :
            <Group style={{width: '100%'}} position='apart' align={'flex-start'} noWrap>
                <Stack style={{width: '25%'}}>
                    <Group>
                        <span className='add-allocation-sub-headings'>Empfänger</span>
                    </Group>    
                    <Stack className='preview-recipients-list' spacing={5}>
                        {allRecipients.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                        .map((recipient)=>
                            <Group className={`preview-recipients-list-item ${recipient.id === selectedRecipient && 'preview-selected-recipient'}`} onClick={()=> this.handleRecipientChange(recipient.id)} position='apart'>
                                <Group spacing={8} noWrap>
                                    <Group style={{background: '#454576', width: '22px', borderRadius: '120px', minWidth: '22px'}} position='center' align={'center'}>
                                        <span style={{textTransform: 'capitalize', color: 'white'}}>{recipient.name[0]}</span>
                                    </Group>
                                    <span>{recipient.name}</span>
                                </Group>
                            </Group>
                        )}
                    </Stack>
                </Stack>
                
                <Stack style={{width: '100%'}}>
                  {this.renderMessagePreviewBox()}
                </Stack>
            </Group>}
            <Group style={{width: '100%', marginTop: '20px'}} position='right'>
                <div>
                <ColorButton
                text={'Schließen'}
                className={'gray-btn'}
                onClick={()=> toggle()}
                />
                </div>
            </Group>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    propertySelected: state.generalReducer.property,
    profile: state.generalReducer.profile,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    accountData: state.generalReducer.accountData,
  };
};

export default connect(mapStateToProps)(MessagePostviewModal);

import React, { Component } from 'react';
import CustomModal from './index';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { Group, Stack } from '@mantine/core';
import DropFile from '../DropFile/DropFile';
import ColorButton from '../Buttons/ColorButton';
import { Form } from 'react-bootstrap';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import AddUpdateDuplicateDocumentModal from './AddUpdateDuplicateDocumentModal';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import BuyPremiumModal from './BuyPremiumModal';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import { Tooltip } from '@material-ui/core';
import {
  setAddFileModal,
  setFiles,
  setCurrentFolderId,
  setSharedFolder,
  setAddTenantDocument, 
  setTenantFiles, 
  setRefreshTenantDocumentsData,
  setFileType
} from '../../../../../redux/action/General/generalActions';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment, loader } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;
const myWorker = new Worker("worker.js");

class AddDocumentModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    files: [],
    documentType: [
      {
        id: 0,
        name: 'general',
        label: 'General'
      },
      {
        id: 1,
        name: 'receipt',
        label: 'Receipt'
      },
      {
        id: 2,
        name: 'tenant',
        label: 'Tenant'
      },
      {
        id: 3,
        name: 'utility',
        label: 'Utility'
      }
    ],
    selectedDocumentType: [],
    rentalUnits: [],
    selectedRentalUnit: [],
    tenants: [],
    selectedTenant: [],
    duplicateFiles: [],
    filesNotDuplicate: [],
    openDuplicateFilesModal: false,
    openPaidModal: false,
    memoryStatus: null, 
    usedSpaceInMB: null, 
    memoryStatusExceededPackageWise: null,
    error: []
  };

  componentDidMount(){
    console.log('this.props.propertySelected: ', this.props.propertySelected);
    console.log('this.props.showTenantDocuments: ', this.props.showTenantDocuments);
    this.setState({files: this.props.files});
    this.getDocumentsStatusByAccountId();
    //this.props.showTenantDocuments ? this.props.setAddTenantDocument(true) : 
    this.props.setAddFileModal(true);
    // let button = document.querySelector('#upload');
    // if(window.Worker){
    //   const {files} = this.state;
    //   button.onclick = function() {
    //     myWorker.postMessage({files: files});
    //     console.log('messagePost: ', myWorker);
    //   }
      

    //   myWorker.onmessage = function(e) {
    //     console.log('message received from worker', e);
    //   }
    // }
  }

  componentDidUpdate(prevState){
    // let button = document.querySelector('#upload');
    // if(window.Worker){
    //   const {files} = this.state;
    //   const {propertySelected, currentFolderId} = this.props;
    //   const token= Autoload.Utils.getAuthToken();
    //   button.onclick = function() {
    //     myWorker.postMessage({files, currentFolderId, propertySelected, token});
    //     console.log('messagePost: ', myWorker);
    //   }
    // }
  }

  componentWillUnmount(){
    this.props.setAddFileModal(false);
    this.props.setAddTenantDocument(false);
  }

  handleFileChange = (acceptedFile, rejectedFile) => {
    console.log('acceptedFile: ', acceptedFile);
    if (acceptedFile.length > 0) {
      if(acceptedFile.length > 1){
        //acceptedFile.map((file)=> this.addDocApi(file));
      }else{
        //this.addDocApi(acceptedFile[0]);
      }
      let {files} = this.state;
      if(files.length > 0){
        acceptedFile.map((af)=> files.find((file)=> file.path === af.path) === undefined && files.push(af));
        this.setState({files},()=> {
          if(this.props.showTenantDocuments){
            let tenantfiles = files.map((file)=> { return {file: file, tenantId: this.props.tenantId} });
            this.props.setFiles(tenantfiles);
            //this.props.setTenantFiles(tenantfiles);
            this.props.setSharedFolder(this.props.hideShareFolder)
            this.props.setCurrentFolderId(this.props.currentFolderId);
          }else{
            this.props.setFiles(files);
            this.props.setSharedFolder(this.props.hideShareFolder)
            this.props.setCurrentFolderId(this.props.currentFolderId); 
          }
        });
      }else{
        this.setState({files: acceptedFile}, ()=> {
          if(this.props.showTenantDocuments){
            let tenantfiles = acceptedFile.map((file)=> { return {file: file, tenantId: this.props.tenantId} });
            this.props.setFiles(tenantfiles);
            //this.props.setTenantFiles(tenantfiles);
            this.props.setSharedFolder(this.props.hideShareFolder)
            this.props.setCurrentFolderId(this.props.currentFolderId);
          }else{
            this.props.setFiles(acceptedFile);
            this.props.setSharedFolder(this.props.hideShareFolder)
            this.props.setCurrentFolderId(this.props.currentFolderId);
          }
          
        });
      }
    }
    if (rejectedFile.length > 0) {
      console.log('rejectedFile: ', rejectedFile);
      rejectedFile.map((file, index)=>
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          this.setState({error: [...this.state.error, {fileName: file.file.name, message: 'Datei zu groß'}]}, ()=> 
          {
            if(rejectedFile.length === (index+1)){
              showNotification({
                message: 
                <Stack spacing={2}>
                  <Group>Es ist ein Fehler aufgetreten. Das Dokument ist zu groß. Maximal erlaubte Dokumentgröße ist 100 MB.</Group>
                  {this.state.error.map((err)=> 
                    <Group noWrap spacing={2}>
                      <span style={{
                        // maxWidth: '290px', 
                        // overflowX: 'hidden', 
                        // textOverflow: 'ellipsis', 
                        // whiteSpace: 'nowrap'
                        }}>
                          {`Dateiname: ${err.fileName}`}
                      </span>
                      {/* <span>{'->'}</span>
                      <span style={{whiteSpace: 'nowrap', color: '#db1738'}}>{`${err.message}`}</span> */}
                    </Group>)}
                </Stack>,
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'fileSelectionError',
                autoClose: false
              });
              this.setState({error: []});
            }
          })
        }
        if (err.code === 'file-invalid-type') {
          this.setState({error: [...this.state.error, {fileName: file.file.name, message: 'Dateityp ungültig'}]}, ()=> 
          {
            if(rejectedFile.length === (index+1)){
              showNotification({
                message: 
                <Stack spacing={2}>
                  {this.state.error.map((err)=> <Group noWrap spacing={2}><span style={{maxWidth: '290px', overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{`Dateiname: ${err.fileName}`}</span><span>{'->'}</span><span style={{whiteSpace: 'nowrap', color: '#db1738'}}>{`${err.message}`}</span></Group>)}
                </Stack>,
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'fileSelectionError',
                autoClose: false
              });
              this.setState({error: []});
            }
          });
        }
      }));
      // let {files} = this.state;
      // files.filter()
    } else {
      this.setState({ selectedFileErr: { state: false } });
    }
  };

  removeFile = (selected) => {
    console.log('removeFile: ', selected);
    let {files} = this.state;
    files = files.filter((file)=> selected.path !== file.path);
    this.setState({files});
  };

  handleDeleteDoc = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_Error_message + response.message);
    }
    //this.getDocsApi();
  };

  onDocumentTypeSelect=(selectedList, selectedItem)=>{
    this.setState({selectedDocumentType: [selectedItem]}, ()=> selectedItem.name === 'tenant' && this.getRentalUnits());
  }

  onRentalUnitSelect=(selectedList, selectedItem)=>{
    this.setState({selectedRentalUnit: [selectedItem], selectedTenant: []}, ()=> this.getTenants(selectedItem.id));
  }

  onTenantSelect=(selectedList, selectedItem)=>{
    this.setState({selectedTenant: [selectedItem]});
  }

  getRentalUnits=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + this.props.propertySelected.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetRentalUnits',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetRentalUnits(response){
    if(response.status && response.statuscode === 200){
      this.setState({rentalUnits: response.result},()=>
      response.result.map((ru) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + ru.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handleGetTenants',
          null,
          false,
          '',
          {
            //'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }));
    }
  }

  getTenants=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetTenants',
      null,
      false,
      '',
      {
        //'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetTenants(response){
    if(response.status && response.statuscode === 200){
      this.setState({tenants: response.result});
      //let {rentalUnits} = this.state;
      // rentalUnits = rentalUnits.map((rentalUnit)=> {
      //   if(rentalUnit.id === response.result[0].rentalUnitId){
      //     return {...rentalUnit, tenants: response.result};
      //   }else{
      //     return rentalUnit;
      //   }
      // });
      //this.setState({rentalUnits});
    }
  }

  checkForDuplicates=()=>{
    const {allDocumentsInCurrentFolder, toggle, hideShareFolder, showTenantDocuments, setFileType} = this.props;
    console.log('allDocumentsInCurrentFolder: ', allDocumentsInCurrentFolder);
    console.log('this.props.showTenantDocuments: ', showTenantDocuments);
    let {files} = this.state;
    if(files.length > 0){
      var duplicateFiles= [];
      var filesNotDuplicate= []
      allDocumentsInCurrentFolder.map((document)=> files.find((file)=> !hideShareFolder ? (file.name === document.name && document.isDeleted === false && document.propertyId !== null) : (file.name === document.name && document.isDeleted === false && document.propertyId === null)) !== undefined && duplicateFiles.push({...document, file: files.find((file)=> !hideShareFolder ? (file.name === document.name && document.isDeleted === false && document.propertyId !== null) : (file.name === document.name && document.isDeleted === false && document.propertyId === null))}));
      if(duplicateFiles.length > 0){
        filesNotDuplicate = files.filter((file)=> duplicateFiles.find((duplicateFile)=> duplicateFile.name === file.name) === undefined);
        //open duplicate files modal
        this.setState({filesNotDuplicate, duplicateFiles, openDuplicateFilesModal: true});
      }else{
        var elem = document.getElementById("upload");
        setFileType(showTenantDocuments ? 'TenantDocument' : 'General');
        console.log('checkForDuplicates: ');
        if (typeof elem.onclick == "function") {
            console.log('checkForDuplicates: inside', showTenantDocuments);
            setTimeout(() => {
              elem.onclick.apply(elem);
              toggle();
            }, 800);
            
        }
        //this.uploadFiles();
      }
    }else{
      showNotification({
        message: 'Bitte wählen Sie eine Datei zum Hochladen.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'filesNotSelected',
      });
    }
  }

  uploadFiles=(filesNotDuplicate=[])=>{
    const {files} = this.state;
    const {propertySelected, currentFolderId} = this.props;
    if(filesNotDuplicate.length > 0){
      loader(true);
      filesNotDuplicate.map((file, index)=> {
        const data = new FormData();
        data.append('file', file);
        data.append('accountId', propertySelected.accountId);
        data.append('propertyId', propertySelected.id);
        data.append('name', file.name);
        data.append('isDirectory', false);
        data.append('isReadOnly', false);
        data.append('isShared', false);
        data.append('fileType', 'General');
        currentFolderId !== null && data.append('parentDirectoryId', currentFolderId);
  
        Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.document_api_base_url + 'documents',
        'POST',
        data,
        null,
        false,
        true,
        false,
        this,
        'handleUploadFile',
        {end: filesNotDuplicate.length === (index + 1)},
        false,
        '',
        {
          //'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
        )
      });
    }else if(files.length > 0){
      loader(true);
      files.map((file, index)=> {
        const data = new FormData();
        data.append('file', file);
        data.append('accountId', propertySelected.accountId);
        data.append('propertyId', propertySelected.id);
        data.append('name', file.name);
        data.append('isDirectory', false);
        data.append('isReadOnly', false);
        data.append('isShared', false);
        data.append('fileType', 'General');
        currentFolderId !== null && data.append('parentDirectoryId', currentFolderId);
  
        Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.document_api_base_url + 'documents',
        'POST',
        data,
        null,
        false,
        true,
        false,
        this,
        'handleUploadFile',
        {end: files.length === (index + 1)},
        false,
        '',
        {
          //'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
        )
      });
    }else{
      showNotification({
        message: 'Bitte wählen Sie eine Datei zum Hochladen.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'filesNotSelected',
      });
    }
  }

  handleUploadFile(response){
    console.log('handleUploadFile: ', response);
    if(response.status && response.statuscode === 200 && response.other.end){
      console.log('all done');
      this.props.getDocuments();
      this.props.toggle();
      loader(false);
    }else if(response.other.end){
      showNotification({
        message: 'Einige Dateien wurden nicht erfolgreich hochgeladen.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'filesNotUploadedSuccessfully',
      });
      loader(false);
    }
  }

  getDocumentsStatusByAccountId=()=>{
    const {propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.document_api_base_url + 'documents/status?accountId=' + propertySelected.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetDocumentsStatusByAccountId',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetDocumentsStatusByAccountId(response){
    if(response.status && response.statuscode === 200){
      let usedSpaceInMB = (response.result.usedSpaceInBytes/1000000);//this.convertFileSize(response.result.usedSpace);
      let memoryStatusExceededPackageWise = this.props.currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' && usedSpaceInMB >= 100
      this.setState({memoryStatus: response.result, usedSpaceInMB, memoryStatusExceededPackageWise});
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        //'Documents file size': response.result.usedSpace
        'document file size': (Math.round(response.result.usedSpaceInBytes / (1024*1024) * 100) / 100)
      });
    }
  }

  convertFileSize=(sizeString)=>{
    if(sizeString){
      if(sizeString.includes('KB')){
        return parseFloat(sizeString) / 1000;
      }else if(sizeString.includes('GB')){
        return parseFloat(sizeString) * 1000;
      }else if(sizeString.includes('TB')){
        return parseFloat(sizeString) * 1000000;
      }else if(sizeString.includes('B') && (!sizeString.includes('M') || !sizeString.includes('G') || !sizeString.includes('T') || !sizeString.includes('K'))){
        return parseFloat(sizeString) / 1000000;
      }else {
        return parseFloat(sizeString);
      }
    }else{
      return 0;
    }
  }
  
  render() {
    const { open, toggle, className, allDocumentsInCurrentFolder, currentFolderId, currentSubscription, setFiles, setCurrentFolderId, showTenantDocuments, tenantData, tenantId, hideShareFolder, setFileType } = this.props;
    const {files, documentType, selectedDocumentType, rentalUnits, selectedRentalUnit, selectedTenant, tenants, openDuplicateFilesModal, duplicateFiles, filesNotDuplicate, openPaidModal, memoryStatus, memoryStatusExceededPackageWise, usedSpaceInMB} = this.state;
    console.log('files: ', files);
    console.log('rentalUnits: ', rentalUnits);
    return (
      <>
        <CustomModal
          heading={
          <Group spacing={10}>
            <NoteAddIcon 
            style={{
              color: '#5655FE',
              fontSize: '20px'
            }}/>
            <span>{'Datei hochladen'}</span>
          </Group>
          }
          headingClassName='document-upload-modal-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className}`}
        >
          <Stack style={{width: '100%'}}>
            <Group grow>
              <div>
              <DropFile
              downloadable
              accept={null}
              //accept={'audio/*,video/*,image/*,application/*,application/pdf,text/*,.csv,.zip,.docx,.doc,.xls,.xlm,.dbf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pptx,.ppt'}
              customProps={{ multiple: true }}
              handleDrop={this.handleFileChange}
              file={files}
              removeFile={this.removeFile}
              multiSelect={true}
              maxSize={100000000}
              hideSecondText={true}
              />
              </div>
            </Group>
            {/* {files.length > 0 &&
            <Stack>
              <Form className="test">
                <Group grow>
                  <Form.Group>
                      <Form.Label>Document type</Form.Label>
                      <SelectDropdown
                      options={documentType} // Options to display in the dropdown
                      selectedValue={selectedDocumentType.length > 0 && selectedDocumentType[0]} // Preselected value to persist in dropdown
                      onSelect={this.onDocumentTypeSelect} // Function will trigger on select event
                      // onRemove={this.onVatPercentageRemove} // Function will trigger on remove event
                      displayValue="label"
                      placeholder={lang.newKeys.text_select}
                      showSelectedHighlight={false}
                      ddHeaderClassName={`custom-select-dropdown ${'custom-select-dropdown-pt-0'}`}
                      //disable={this.state.amount ? false : true}
                      />
                  </Form.Group>
                </Group>
                {selectedDocumentType.length > 0 && selectedDocumentType[0].name === 'tenant' &&
                <>
                <Group grow>
                  <Form.Group>
                      <Form.Label>Rental unit</Form.Label>
                      <SelectDropdown
                      options={rentalUnits} // Options to display in the dropdown
                      selectedValue={selectedRentalUnit.length > 0 && selectedRentalUnit[0]} // Preselected value to persist in dropdown
                      onSelect={this.onRentalUnitSelect} // Function will trigger on select event
                      // onRemove={this.onVatPercentageRemove} // Function will trigger on remove event
                      displayValue="name"
                      placeholder={lang.newKeys.text_select}
                      showSelectedHighlight={false}
                      ddHeaderClassName={`custom-select-dropdown ${'custom-select-dropdown-pt-0'}`}
                      disable={selectedDocumentType.length > 0 ? false : true}
                      />
                  </Form.Group>
                </Group>
                <Group grow>
                  <Form.Group>
                      <Form.Label>Tenants</Form.Label>
                      <SelectDropdown
                      options={tenants} // Options to display in the dropdown
                      selectedValue={selectedTenant.length > 0 && selectedTenant[0]} // Preselected value to persist in dropdown
                      onSelect={this.onTenantSelect} // Function will trigger on select event
                      // onRemove={this.onVatPercentageRemove} // Function will trigger on remove event
                      displayValue="fullName"
                      placeholder={lang.newKeys.text_select}
                      showSelectedHighlight={false}
                      ddHeaderClassName={`custom-select-dropdown ${'custom-select-dropdown-pt-0'}`}
                      disable={selectedRentalUnit.length > 0 ? false : true}
                      />
                  </Form.Group>
                </Group>
                </>}
              </Form>
            </Stack>} */}
            <Group position='apart'>
              <div>
                  <ColorButton
                  onClick={()=> toggle()}
                  className="gray-btn"
                  text={'Abbrechen'}
                  />
              </div>
              <div>
                  <ColorButton
                  //id='upload'
                  onClick={()=> memoryStatusExceededPackageWise ? this.setState({openPaidModal: true}) : this.checkForDuplicates()}
                  className="purple-btn"
                  text={'Hochladen'}
                  />
              </div>
              <button style={{display: 'none'}} id={'upload'}/>
            </Group>
          </Stack>
          {openDuplicateFilesModal &&
          <AddUpdateDuplicateDocumentModal
          duplicateFiles={duplicateFiles}
          files={filesNotDuplicate}
          open={openDuplicateFilesModal}
          showTenantDocuments={showTenantDocuments}
          hideShareFolder={hideShareFolder}
          tenantId={tenantId}
          getDocuments={()=> this.props.getDocuments()}
          toggle={()=> this.setState({openDuplicateFilesModal: false}, ()=> toggle())}
          uploadFiles={()=> {
            loader(false);
            var elem = document.getElementById("upload");
            setFiles(filesNotDuplicate);
            setCurrentFolderId(currentFolderId);
            setFileType(showTenantDocuments ? 'TenantDocument' : 'General');
            setTimeout(() => {
              if (typeof elem.onclick == "function") {
                elem.onclick.apply(elem);
                toggle();
            }  
            }, 1000);
          }
          //this.uploadFiles(filesNotDuplicate)
          }
          allDocumentsInCurrentFolder={allDocumentsInCurrentFolder}
          currentFolderId={currentFolderId}
          />}
          {<BuyPremiumModal
          heading={'Zusätzlichen Speicherplatz freischalten'}
          open={openPaidModal}
          text={<span style={{fontSize: '15px', color: 'rgb(19, 19, 92)', fontWeight: '400', textAlign: 'center', width: '350px'}}>Sie haben {usedSpaceInMB ? formatNumber(usedSpaceInMB, false) : '0,00'} MB von 100,00 MB verfügbarem Speicherplatz im Starter Paket genutzt. Schalten Sie hellohousing <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{color: 'rgb(85, 85, 255)', textDecoration: 'underline', cursor: 'pointer'}}>{currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? 'Premium' : 'Professional'}</span> frei, um weiteren Speicherplatz zu nutzen.</span>}
          onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
          onCancel={()=> this.setState({openPaidModal: false})}
          />}
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
  };
};

const actions = {
  setAddFileModal,
  setFiles,
  setCurrentFolderId,
  setSharedFolder,
  setFileType,
  //for tenant documents
  setAddTenantDocument, 
  setTenantFiles, 
  setRefreshTenantDocumentsData
};
export default connect(mapStateToProps, actions)(AddDocumentModal);

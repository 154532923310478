import React, { Component, useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import CustomImageCard from '../../CustomImageCard/CustomImageCard';
import PropertyImage from '../../../../../../assets/images/module/general/house-log.png';
import DropdownIcon from '../../../../../../assets/images/module/general/Arrows.svg';
import GoldenLockIcon from '../../../../../../assets/images/module/general/GoldenLockIcon.svg';
import CheckIcon from '../../../../../../assets/images/module/general/CheckIcon.svg';
import ColorButton from '../../Buttons/ColorButton';
import ColorButton_C from '../../Buttons/ColorButton_C';
import AddPropertyModal from '../../../General/CustomModal/AddPropertyModal';
import EmptyState from '../../../../../../neoverv/components/Module/General/EmptyStateContainer/EmptyStateContainer';
//import { useSpring, animated } from "react-spring";

import {
  changeCurrentProperty,
  propertyPicture,
  setPropertyList,
  updatedProfileWithPicture,
  editProfileData,
  resetData
} from '../../../../../../redux/action/General/generalActions';
import {getRentalUnitTenants} from '../../../../../../redux/action/Tenant/TenantActions'
import { connect } from 'react-redux';
import Autoload from '../../../../../../neoverv/Autoload';

// Fresh imports
import { Box, Button, Group, ScrollArea, Stack, Text, Image, Loader, Center, Skeleton, Tooltip } from "@mantine/core";
import { v4 as uuidv4 } from 'uuid';
import Icon from '../../../../../../components/Icon';
import AddPropertyTypeModal from '../../CustomModal/AddPropertyTypeModal';
import moment from 'moment';
import dayjs from 'dayjs';
import RestrictedMenuMessageModal from '../../CustomModal/RestrictedMenuMessageModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { checkProperty } = Autoload.MainHelper;

// Temporary solution to make the PropertySelect component look nice
const defaultPropertyAvatar = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAABuCAYAAADGWyb7AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAO2SURBVHja7Ns9aBtnHIDx/1kfsWXlXOhBS30mWxW4rc6Qq9whBKPRXXzQrbRaWxoZ0tWhW4pl6CxDx6LLolFoyBJVGhq6VGDRqdGVdjhDc8gi6OR7M8SmJighTlL7Tn2e9Q5xvD/uvfc+pCml5LihUBLKi4hox3BDpSQThpOsUiKnMCkmpVKapNPpsaZJKCJ5TSk1VEoy4/EkC1j8u3QpM9Y0CedERMLwCLSEFIaTrIjInIhIFEWMSEKKomcn2BxDkcyAA46AI+CAI+AIOOAIOAKOgAOOgCPggCPgCDgCDjgCjoADjoAj4Ag44Ag4mnW4IBh5/2e4dNwPsF5vN4NglO31Hn0YBKMFz/Pzg8FBVtdzH3S7d4GLY57n9yqVvdK0bbqeY6ok4Ag4Ag44Ao6AA26GmvUnK+kZBPNrtdYftVprVddzkW0X/tra2vjHNA0LuJhWq7XuV6uNG0EwMo4R51zXX3bd9rJtFw4dZ+3B5maxBFxMqtfbzWq1se55/o0X7dPp9Bc7nX5pZ6cROU7xV8cpzif5LNSUUsMnT8LFOB6c5/m969dvTx1c0zSi3d0v7m9v//RJr/co+zq/v7lZ/NNxivu2ffVmktDm5zOHiYV7m5mmEW1tbbRsu2Am4SwE7rl0PSeOU+xvb39WiDvcTN4O6HpOLGtlfNZXP0EwklqtVXDddpPFyTmDlcvrD8vl9Su6njNOFi6u+2Ct0+m/8qwyGPgGcOeQbRcOK5VPu7ZduCkiq6e3OU6x5DhF8Ty/t7PTeMd128s8ObngLGtl3Gze+dl1v108RnvZAsTa3f1yudu926tUNh4Cd4Ht7X31u2WtfHzGFaRVqWys2nbhELgLyDSN6E2W7rZ9dR84Ai4OBcEoD1wCe/wYOAKOZu4G/Pk6nf0fB4ODwdLSwuVS6aNvgEtA1Wrjh2q18fWp24a/79277ZmmcY2pMqbV6+3vTqOJiHie//6tW3tc4+Kc5/lL06fO/jXgCDgCDjgCjoAj4ICjiyzWj7xM07C63e97L9nltd+Al8vrVxxnbepv6/rCe8C9Md67/8kHsbqeM04+4WOqpHMr1p+gnzXP838ZDA5+m7bNtgufzwpa7P87QC+GY6rkGkfAEXDAEXAEHHAEHAFHwAFHwBFwwBFwBBwBBxwBR8ABR8ARcAQccAQcnQVO0xiIhMIhl5RSKe1fuEwmNcYu/mmaJul0aizy7P9xIiJDpVRmMjnKHh0pRiiGYJp2coJpoYjkT+BERIYMUSLKi4g8HQDQflcjAOF6KQAAAABJRU5ErkJggg==';

// Property select item in select scroll area
const PropertySelectItem = ({isTablet, isMobile, id, name, note, checked, propertyImage= null, currentSubscription, index, ...props }) => {
  const maxNameLength = isTablet ? 15 : isMobile ? 30 : 16; // Max length of the property name
  const [key, setKey] = useState(1);
  const [hover, setHover] = useState(false);
  //const maxNameLengthTablet = 15;

  // const scrolling = useSpring({
  //   from: { transform: "translate(10%,0)" },
  //   to: { transform: "translate(-170%,0)" },
  //   config: { duration: 3000 },
  //   reset: true,
  //   //reverse: key % 2 == 0,
  //   onRest: () => {
  //     setKey(key + 1);
  //   }
  // });
  return (
    <Group 
      sx={(theme) => ({
        width: '100%',
        cursor: 'pointer',
        borderRadius: 2,
        transition: 'all .3s ease',
        backgroundColor: checked ? theme.colors.brandLight : 'transparent',
        position: 'relative',
        '&:hover': {
          backgroundColor: theme.colors.brandLight
        }
      })} 
      p={8}
      //pr={16}
      position={isMobile && 'apart'}
      {...props}
      noWrap
    >
      <Group spacing={0} noWrap>
        <Group noWrap>

          <Image src={propertyImage ? propertyImage : defaultPropertyAvatar} alt={name && name} width={32} height={32} sx={{ borderRadius: 4, opacity: (currentSubscription ? index > (currentSubscription?.limitProperties-1) : index > 0) ? 0.5 : 1 }} />
          <Stack spacing={0}>
            <Text weight={500} size="md" sx={(theme) => ({ color: theme.colors.brandDarkTextAlt, fontSize: isTablet && '12px'})}>
              {!isMobile ? <div 
              className='text-property-item'
              key={key} 
              onMouseEnter={()=> setHover(true)} 
              onMouseLeave={()=> setHover(false)}
              style={{whiteSpace: 'nowrap', width: (checked || (currentSubscription ? index > (currentSubscription?.limitProperties-1) : index > 0)) ? isTablet ? '125px' : '145px' : isTablet ? '155px' : '170px', textOverflow: 'ellipsis', overflowX: 'scroll' }}
              >
                <div className='text-property-item' style={{whiteSpace: 'nowrap', width: (checked || (currentSubscription ? index > (currentSubscription?.limitProperties-1) : index > 0)) ? isTablet ? '125px' : '145px' : isTablet ? '155px' : '170px', textOverflow: 'ellipsis', overflowX: 'scroll', opacity: currentSubscription ? (index > (currentSubscription?.limitProperties-1) ? 0.5 : 1) : index > 0 ? 0.5 : 1}}>{name}</div>
              </div>
              : name && name.length > maxNameLength ? name.substring(0, maxNameLength).trimEnd() + '…' : name
              }
              {/* {name && name.length > maxNameLength ? name.substring(0, maxNameLength).trimEnd() + '…' : name} */}
            </Text>
            <Text weight={400} size="sm" sx={{ opacity: .7 }}>{note}&nbsp;</Text>
          </Stack>
        </Group>
      </Group>
      {checked && <Icon name="check" />}
      {(currentSubscription ? (index > (currentSubscription?.limitProperties-1)) : index > 0) && <img src={GoldenLockIcon}/>}
    </Group>
  )
}
let rentalUnits = [];
let rentalListData = [];
class SideBarDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addPropertyModal: false,
      addPropertyTypeModal: false,
      propertyData: [],
      propertyImage: '',
      singlePropetyData: null,
      selectedPropertyType: '',
      dropdownOpen: false,
      openRestrictedMenuMessage: false
    };
  }
  // action sent complete property -- check kisko update krna hai -- mtach id then replace

  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };

  toggleAddPropertyTypeModal = () => {
    this.setState({ addPropertyTypeModal: !this.state.addPropertyTypeModal });
  };

  sendPropertyToLocalStorage = (propertyId) => {
    console.log("PROPERTYID: ", propertyId);
    localStorage.setItem('chosenProperty', propertyId);
  }

  // sendPropertyToLocalStorage = (property) => {

  //   console.log("sendPropertyToLocalStorage");

  //   // Set chosen property to first from list if it's not selected by user
  //   if (property) {
  //     localStorage.setItem('chosenProperty', property.id);
  //     console.log("PROPERTY: ", property);
  //   } else if (localStorage.getItem('chosenProperty')) {
  //     localStorage.setItem('chosenProperty', this.props.properties[0].id);
  //     console.log("EMPTY PROPERTY: ", this.props.properties[0]);
  //   }

  // }

  componentDidMount() {
    // console.log('this.props.propertySelected',this.props.propertySelected.id)
    // this.getPropertyImgae();
    //this.getAllPropertyImages();
    if (!localStorage.getItem('chosenProperty') && this.props.properties.length) {  this.sendPropertyToLocalStorage(this.props.properties[0].id) }
    this.setState({
      propertyData: [...this.props.properties],
    });
    this.bind_rental_unit_list_data();
  }

  // getPropertyImgae = () => {
  //   const propertyID = Autoload.MainHelper.checkProperty(this.props.propertySelected, 'id');
  //   Autoload.HttpRequestAxios.HttpRequest(
  //     Autoload.Setting.property_api_base_url + 'properties/' + propertyID + '/picture',
  //     'GET',
  //     null,
  //     null,
  //     false,
  //     true,
  //     false,
  //     this,
  //     '_getPropertyPictureResponse',
  //     null,
  //     false,
  //     '',
  //     {
  //       'Content-Type': 'application/json',
  //       Authorization: Autoload.Utils.getAuthToken(),
  //     }
  //   );
  // };

  // _getPropertyPictureResponse(response) {
  //   // console.log('get image in SB', response);
  //   //this.props.propertyPicture(response.result);
  //   if (response.status == false) {
  //     Autoload.Redirection.redirection_error();
  //   }
  //   if (response.status == true && response.statuscode == 200) {
  //     this.setState({ propertyImage: response.result ? response.result : PropertyImage });
  //   }
  // }

  getAllPropertyImages() {
    // console.log(this.props.properties, 'properties in redux');
    var list_allProperties = this.props.properties;
    list_allProperties.map((property) => {
      // console.log(property, 'property');
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'properties/' + property.id + '/picture',
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        '_getAllPropertyPictureResponse',
        { propertyData: property },
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    });
  }

  _getAllPropertyPictureResponse = (response) => {
    // console.log(response, '_getAllPropertyPictureResponse');
    if (response.result != 0) {
      let updatedPicturePropertyData = {
        ...response.other.propertyData,
        fileName: response.result === null ? PropertyImage : response.result,
      };
      console.log(updatedPicturePropertyData, 'updated one');
      this.setState({singlePropetyData: updatedPicturePropertyData});
      this.props.editProfileData(updatedPicturePropertyData);
    }
  };
  //get all properties
  // map
  // get properttty id of each property
  // response main jo picture , set that to respoective id
  // sent complete data of property to redux

  // change_dropdown_action = (property) => {
  //   changeCurrentProperty(property);
  //   window.location.reload();
  //  };

  componentDidUpdate(prevProps) {
    if (this.props.properties.length > 0) {
      // // ;
      if (prevProps.propertySelected.id != this.props.propertySelected.id) {
        // // ;

        if (window.location.href.indexOf('meter/reading/interium') != -1) {
          window.location.href = '/meter';
        } else if (window.location.href.indexOf('meter/reading/annual') != -1) {
          window.location.href = '/meter';
        } else if (window.location.href.indexOf('/utility-billing/utility?currentBillingId') != -1) {
          window.location.href = '/utility-billing';
        } else if (window.location.href.indexOf('/messaging/newMessage') != -1) {
          window.location.href = '/messaging';
        } else {
          window.location.reload();
        }
      }
    }
  }
  

  bind_rental_unit_list_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_rental_unit',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_get_list_rental_unit = (response) => {
    this.props.getRentalUnitTenants([]);
    if (response.status === false) {
    }else{
      rentalUnits = response.result.map((ru) => ({
        id: checkProperty(ru, 'id'),
        name: checkProperty(ru, 'name'),
        size: checkProperty(ru, 'space'),
        propertyId: checkProperty(ru, 'propertyId'),
        tenantsData: [],
      }));
      response.result.map((ru) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + ru.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handle_get_list_rental_tenants',
          null,
          false,
          '',
          {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }); 
    }
  };
  ChangeDateFormat = (dateString) => {
    const dateArray = dateString.split('.');
    return `${dateArray[1]}.${dateArray[0]}.${dateArray[2]}`;
  };
  handle_get_list_rental_tenants = (response) => {
    rentalListData = this.props.rentalUnits;

    if (response.result.length > 0) {
      //sort for move out
      let dashMoveOut = response.result.filter((item) => item.out === '');
      let filledMovedout = response.result.filter((item) => item.out !== '');
      filledMovedout.map((item) => dashMoveOut.push(item));
      //let current = false;
      let tenantsData = [...dashMoveOut];
      // first vacant false check
      let isFirstVacantFound = false;
      //first vancant false = index
      let firstOriginalDataIndex;

      tenantsData = response.result.map((tenant, idx) => {
        console.log(tenant, idx, 'tenant current idx');
        // isVacant value
        let isVacant = tenant.vacant;
        let current = false;

        if (
          (dayjs(tenant.moveInDate).isBefore(dayjs()) || dayjs(tenant.moveInDate).isSame(dayjs())) //logic for moveInDate
          && 
          (tenant.moveOutDate === null || dayjs(tenant.moveOutDate).isAfter(dayjs()) || dayjs(tenant.moveOutDate).isSame(dayjs())) //logic for moveOutDate
        ) {
          firstOriginalDataIndex = idx;
          current = true;
          isFirstVacantFound = true;
        }else if(tenant.vacant && tenant.moveInDate === null && tenant.moveOutDate === null && response.result.length === 1){
          current = true;
        }

        return {
          id: checkProperty(tenant, 'id'),
          name: checkProperty(tenant, 'fullName'),
          email: checkProperty(tenant, 'email'),
          phone: checkProperty(tenant, 'phone'),
          in: checkProperty(tenant, 'moveInDate') 
            ? moment(checkProperty(tenant, 'moveInDate')).format('DD.MM.YYYY')
            : '',
          out: checkProperty(tenant, 'moveOutDate')
            ? moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY')
            : '',
          type: checkProperty(tenant, 'contractType'),
          note: checkProperty(tenant, 'notes'),
          current: current,
          createdOn: checkProperty(tenant, 'createdOn'),
          vacant: checkProperty(tenant, 'vacant'),
          rentalUnitId: checkProperty(tenant, 'rentalUnitId'),
          updatedOn: checkProperty(tenant, 'updatedOn'),
        };
      });
      if (tenantsData.length === 1 && tenantsData[0].vacant == true) {
        //tenantsData[0].current = true;
      } else if (firstOriginalDataIndex !== 0 && firstOriginalDataIndex !== undefined) {
        let moveoutDate = tenantsData[firstOriginalDataIndex].out;
        let dateCompare = moment(this.ChangeDateFormat(moveoutDate)).isSameOrAfter(moment(new Date()));
        console.log(moveoutDate, dateCompare, tenantsData[firstOriginalDataIndex], '====abc======');
        if (dateCompare === false) {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
          tenantsData[firstOriginalDataIndex].current = false;
        } else {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
        }
      }

      //action
      let field = 'name';
      let rentalunitsorted = rentalUnits.sort((a, b) =>
        (a[field] || '').toString().localeCompare((b[field] || '').toString())
      );
      rentalUnits = rentalunitsorted.map((ru) => {
        if (ru.id == tenantsData[0].rentalUnitId) {
          return {
            ...ru,
            tenantsData: [...tenantsData],
          };
        } else {
          return ru;
        }
      });
    }
    this.props.getRentalUnitTenants(rentalUnits);
  };

  render() {
    const { changeCurrentProperty, propertySelected, properties, isMobile, isTablet, currentSubscription, accountData, blockUsersFromUsingApp, hasTested } = this.props;
    const {openRestrictedMenuMessage} = this.state;
    //console.log(properties, 'all new properties from redux');

    return (
      <div className="sidebar-dropdown-container">
        {properties.length > 0 ? (
          <div>
            <UncontrolledDropdown>
                <DropdownToggle className="property-dropdown" //onClick={()=> this.getAllPropertyImages()}
                disabled={blockUsersFromUsingApp}
                // onClick={()=> this.props.dropdownOpen(true)}
                // onBlur={()=> this.props.dropdownOpen(false)}
                >
                  {this.props.skeletonPropertyDropdown 
                  ?
                  <Skeleton visible={this.props.skeletonPropertyDropdown
                  } style={{height: '56px', width: isTablet ? '228px' : '248px', marginTop: '-8px'}}/>
                  :
                  <Tooltip label={accountData?.accountPaymentStatus === 'PAYMENT_ERROR' ? 'Der Zugriff auf diese Funktion ist derzeit nicht möglich.' : hasTested ? 'Wählen Sie ein Abonnement, um auf diese Funktion zuzugreifen.' : 'Bitte starten Sie den Testzeitraum, um auf diese Funktion zuzugreifen.'} disabled={blockUsersFromUsingApp === false}>
                    <CustomImageCard
                    isTablet={isTablet}
                    isMobile={isMobile}
                    heading={propertySelected.propertyName || propertySelected.name}
                    className="property-parent"
                    subheading={propertySelected.propertyAddress}
                    image={propertySelected.pictureLocation ? propertySelected.pictureLocation : defaultPropertyAvatar}
                    cardSize="sm"
                    sideContent={<img src={DropdownIcon} className="arrow-icon" alt="add" />}
                    sideBar={true}
                    cardBodyClassName={'side-bar-dropdown-card-body'}
                    />
                  </Tooltip>}
                </DropdownToggle>
              <DropdownMenu
                className="property-dropdown-menu dropdown-menu-with-auto-height"
                style={{ overflow: 'auto', paddingBottom: 0, paddingTop: 0, maxHeight: 'fit-content' }}
              >
                <ScrollArea
                  style={{
                    maxWidth: '100%',
                    padding: 2,
                    height: properties.length >= 4 ? 220 : 'auto',
                    //height: currentSubscription ? currentSubscription?.limitProperties >= 4 ? 100 : 'auto' : 'auto',
                  }}
                >
                  <Stack spacing={2}>
                    {(currentSubscription === null || (currentSubscription && currentSubscription?.subscriptionName === 'Starter')) ?
                    this.props.properties//.filter((property, index)=> currentSubscription ? index+1 <= currentSubscription?.limitProperties : property)
                    .reverse()
                    .map((property, i) => (
                      <PropertySelectItem
                        isTablet={isTablet}
                        isMobile={isMobile}
                        key={'property' + i}
                        id={property.id}
                        name={property.propertyName || property.name}
                        propertyImage={property.pictureLocation}
                        note={property.propertyAddress}
                        checked={property.id === propertySelected.id}
                        currentSubscription={currentSubscription}
                        index={i}
                        onClick={() => {
                          if(currentSubscription == null){
                            if(i < 1){
                              console.log("PROPERTY ID CHOSEN: ", property.id);
                              this.sendPropertyToLocalStorage(property.id);
                              changeCurrentProperty(property);
                              this.bind_rental_unit_list_data();
                            }
                          }else if(i < currentSubscription?.limitProperties){
                            console.log("PROPERTY ID CHOSEN: ", property.id);
                            this.sendPropertyToLocalStorage(property.id);
                            changeCurrentProperty(property);
                            this.bind_rental_unit_list_data();
                          }
                        }}
                      />
                    ))
                    :
                    this.props.properties//.filter((property, index)=> currentSubscription ? index+1 <= currentSubscription?.limitProperties : property)
                    .map((property, i) => (
                      <PropertySelectItem
                        isTablet={isTablet}
                        isMobile={isMobile}
                        key={'property' + i}
                        id={property.id}
                        name={property.propertyName || property.name}
                        propertyImage={property.pictureLocation}
                        note={property.propertyAddress}
                        checked={property.id === propertySelected.id}
                        currentSubscription={currentSubscription}
                        index={i}
                        onClick={() => {
                          if(currentSubscription == null){
                            if(i < 1){
                              console.log("PROPERTY ID CHOSEN: ", property.id);
                              this.sendPropertyToLocalStorage(property.id);
                              changeCurrentProperty(property);
                              this.bind_rental_unit_list_data();
                            }
                          }else if(i < currentSubscription?.limitProperties){
                            console.log("PROPERTY ID CHOSEN: ", property.id);
                            this.sendPropertyToLocalStorage(property.id);
                            changeCurrentProperty(property);
                            this.bind_rental_unit_list_data();
                          }
                        }}
                      />
                    ))
                    }
                  </Stack>
                </ScrollArea>
                <Box p={8} sx={(theme) => ({ borderTop: `1px solid ${theme.colors.divider}` })}>
                  {properties.length < currentSubscription?.limitProperties ?
                  <ColorButton
                    className='gray-btn'
                    text='Immobilie hinzufügen'
                    buttonStyle={{width: '100%'}}
                    disabled={blockUsersFromUsingApp}
                    onClick={() => this.toggleAddPropertyTypeModal()}
                  />
                  :
                  <Stack>
                    <Group><span style={{color: 'rgba(85, 85, 255, 1)'}}>{currentSubscription?.subscriptionName === 'Professional' ? 'Weitere Immobilien freischalten' : 'Deine Immobilien'}</span></Group>
                    <Group style={{paddingRight: 0}}>
                      <img src={GoldenLockIcon}/>
                      {currentSubscription?.subscriptionName === 'Professional' ?
                      <span style={{color: '#5f5f7a', fontSize: '11px', width: isTablet ? '180px' : '200px', fontWeight: '400'}}>Bitte wenden Sie sich an den hellohousing Kundenservice, um weitere Immobilien freizuschalten.</span>
                      :
                      <span style={{color: '#5f5f7a', fontSize: '11px', width: isTablet ? '180px' : '200px', fontWeight: '400'}}>Schalte weitere Immobilien und vieles mehr mit dem <span onClick={() => this.props.jumpToSubscriptions()} style={{fontWeight: '500', textDecoration: 'underline', cursor: 'pointer'}}>{currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? 'Premium-Paket' : 'Professional-Paket'}</span> frei.</span>}
                    </Group>
                    <Group>
                      <ColorButton
                        className='purple-btn'
                        text={currentSubscription ? (currentSubscription?.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : currentSubscription?.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : currentSubscription?.subscriptionName === 'Professional' ? 'Jetzt kontaktieren' : 'Jetzt freischalten') : 'Jetzt freischalten'}
                        buttonStyle={{width: '100%'}}
                        onClick={() => currentSubscription?.subscriptionName === 'Professional' ? window.Intercom('showNewMessage') : this.props.jumpToSubscriptions()}
                      />
                    </Group>
                  </Stack>
                  }
                </Box>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ) : (
          <div>
            {/* {console.log(this.state.propertyData,'2')} */}
            {this.props.skeletonPropertyDropdown 
              ?
              <Skeleton visible={this.props.skeletonPropertyDropdown
              } style={{height: '56px', width: isTablet ? '228px' : '248px', marginLeft: '0.75rem', marginRight: '0.75rem', marginTop: '-8px'}}/>
              :
              <EmptyState
              className="siderbar_empty_property"
              heading={lang.newKeys.text_add_your_property}
              subheading={lang.newKeys.text_add_Property_and_manage}
              // icon={}
              btnHandler={() => {
                if(accountData?.accountPaymentStatus !== 'PAYMENT_ERROR'){
                  if(blockUsersFromUsingApp){
                    this.setState({openRestrictedMenuMessage: true});
                  }else{
                    this.toggleAddPropertyTypeModal();
                  }
                }
              }}
              buttonStyle={{opacity: blockUsersFromUsingApp ? 0.6 : 1}}
              disabledButtonTooltipText={blockUsersFromUsingApp ? (accountData?.accountPaymentStatus === 'PAYMENT_ERROR' ? 'Der Zugriff auf diese Funktion ist derzeit nicht möglich.' : hasTested ? 'Wählen Sie ein Abonnement, um auf diese Funktion zuzugreifen.' : 'Bitte starten Sie den Testzeitraum, um auf diese Funktion zuzugreifen.') : null}
              btnText={lang.general.text_add_property}
            />}
          </div>
        )}
        <div>
          {/* {this.state.addPropertyModal && ( */}
          <AddPropertyModal
            className="add_proprty_modal"
            edit={false}
            open={this.state.addPropertyModal}
            toggle={() => {
              this.toggleAddPropertyModal();
            }}
            propertyType={this.state.selectedPropertyType}
          />
          <AddPropertyTypeModal
            className="add_proprty_type_modal"
            open={this.state.addPropertyTypeModal}
            toggle={() => {
              this.toggleAddPropertyTypeModal();
            }}
            next={(selectedPropertyType)=> {
              this.setState({selectedPropertyType});
              this.toggleAddPropertyTypeModal();
              this.toggleAddPropertyModal();
            }}
          />

      {openRestrictedMenuMessage &&
      <RestrictedMenuMessageModal
      open={openRestrictedMenuMessage}
      toggle={()=> this.setState({openRestrictedMenuMessage: false})}
      history={this.props.history}
      //next={()=> }
      />}
          {/* )} */}
          {/* <AddPropertyModal
            className="add_proprty_modal"
            edit={false}
            open={this.state.addPropertyModal1}
            toggle={this.toggleAddPropertyModal1}
          /> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    properties: state.generalReducer.allProperties,
    // newProperties: state.generalReducer.allProperties,
    propertyPictureforSB: state.generalReducer.profilePic,
    accountData: state.generalReducer.accountData,
    hasTested: state.generalReducer.hasTested
  };
};
const actions = {
  changeCurrentProperty,
  propertyPicture,
  updatedProfileWithPicture,
  setPropertyList,
  editProfileData,
  getRentalUnitTenants,
  resetData
};
export default connect(mapStateToProps, actions)(SideBarDropDown);

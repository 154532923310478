import React, { Component } from 'react';
import {
  ColorButton,
  SearchContainer,
  SortContainer,
  Divider,
  RentalUnitModal,
  AddPropertyModal,
  DeleteModal,
  EmptyStateContainer,
  AddTenantModal,
  SortAlphabetically,
} from '../../../../neoverv';
import { TenantRecordRow, PageHeader } from '../../../elements/index';
import {
  getRentalUnitTenants,
  setCurrentTenant,
  updateFilteredList,
  DeleteUnit,
  loadingTenantsData
} from '../../../../redux/action/Tenant/TenantActions';
import {
  setRefreshTenantDocumentsData
} from '../../../../redux/action/General/generalActions';
import { connect } from 'react-redux';
import UnitsIcon from '../../../../assets/images/module/general/UnitsIcon.svg';
import BlueUnitsIcon from '../../../../assets/images/module/general/BlueEmptyTenant.svg';
import moment from 'moment';
import Autoload from '../../../../neoverv/Autoload';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Skeleton } from '@mantine/core';
import AddPropertyTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyTypeModal';
import dayjs from 'dayjs';
// import {
//   getRentaUnitsApi,
//   getTenantsApi,
// } from '../../../../neoverv/helpers/services/tenantServices';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const {
  filterBySearchBarRentalTenants,
  deleteRecord,
  checkProperty,
  mergeArrayByProperty,
  nestedAlphabeticSort,
  filterTenantsAndUnits,
  filterByDateUtility,
} = Autoload.MainHelper;

let rentalListData = [];
let rentalUnits = [];
let tenants = [];

const routes = [
  {
    name: 'tenantsRecord',
    className: 'tooltips tenants-record',
    route: '/user',
    label: 'Mieter',
    heading: lang.newKeys.text_heading_meter_readings,
  },
  {
    name: 'tenantsAdministration',
    className: 'tooltips tenants-administration',
    route: '/meter/master',
    label: 'Verwaltung',
    heading: lang.newKeys.text_heading_metermaster_data,
  },
];

class FilteredTenantsList extends Component {
  constructor(props) {
    super(props);
    // console.log('constructor')
    this.state = {
      rentalUnits: [],
      addUnitModal: false,
      deleteUnitModal: false,
      editUnit: false,
      editUnitModal: false,
      currentUnit: {},
      ascendingOrder: false,
      searchValue: '',
      focus: false,
      showAllTenants: false,
      addPropertyModal: false,
      rentalUnit: [],
      rentalTenants: [],
      addTenantModal: false,
      currentRentalUnitId: '',
      TenantPostAddTenant: false,
      skeletonLoading: false,
      addPropertyTypeModal: false,
      selectedPropertyType: '',
      emptyTenantGapsData: {
        moveIn: '',
        moveOut: ''
      },
      emptyTenantEdit: false,
      rentalUnits: []
    };
  }

  componentDidMount() {
    console.log('component did mount ');

    //loader
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.props.propertySelected && this.props.propertySelected.id !== undefined && this.setState({skeletonLoading: true});
    //filter

    //call api
    this.props.getRentalUnitTenants([]);
    this.bind_rental_unit_list_data();
  }

  componentDidUpdate(){
    if(this.props.refreshTenantDocumentsData){
      this.bind_rental_unit_list_data();
      this.props.setRefreshTenantDocumentsData(null);
    }
  }
  showAllTenantFunction = (value) => {
    console.log(value, 'abc TenantPostAddTenant value');
    this.setState({
      TenantPostAddTenant: value,
    });
  };

  //new
  bind_rental_unit_list_data = () => {
    this.props.loadingTenantsData(true);
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    // console.log('property id', propertyid,  Autoload.Setting.rental_unit_api_base_url+"?propertyId="+propertyid)
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_rental_unit',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  ChangeDateFormat = (dateString) => {
    const dateArray = dateString.split('.');
    return `${dateArray[1]}.${dateArray[0]}.${dateArray[2]}`;
  };
  handle_get_list_rental_unit = (response) => {
    if (response.result === null || (response.result && response.result.length === 0)) {
      this.ShowLoader();
      this.props.loadingTenantsData(false);
    }

    console.log(response, 'add tenant response');
    this.props.getRentalUnitTenants([]);
    if (response.status === false) {
      this.props.loadingTenantsData(false);
      // Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(
      //   true,
      //   lang.newKeys.text_error_not_found
      //   // + response.message
      // );
    }else{
      this.setState({rentalUnits: response.result.map((ru) => 
        (
          {
            id: checkProperty(ru, 'id'),
            name: checkProperty(ru, 'name'),
            size: checkProperty(ru, 'space'),
            propertyId: checkProperty(ru, 'propertyId'),
            tenantsData: [],
          }
        ))
      }, ()=> {
        if(response.result.length > 0){
          response.result.map((ru, index) => {
            Autoload.HttpRequestAxios.HttpRequest(
              Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + ru.id,
              'GET',
              null,
              null,
              false,
              true,
              false,
              this,
              'handle_get_list_rental_tenants',
              {eol: index === (response.result.length - 1)},
              false,
              '',
              {
                Authorization: Autoload.Utils.getAuthToken(),
              }
            );
          });
        }else{
          this.ShowLoader();
        }
      });
    }
  };
  handle_get_list_rental_tenants = (response) => {
    // this.ShowLoader();
    // console.log('last api function  handle_get_list_rental_tenants', response);
    let { rentalUnits } = this.state;

    if (response.result.length > 0) {
      // let tenantsData = response.result.sort(function (a, b) {
      //   return new Date(a.moveInDate) - new Date(b.moveInDate);
      // });
      //sort for move out
      let dashMoveOut = response.result.filter((item) => item.out === '');
      let filledMovedout = response.result.filter((item) => item.out !== '');
      filledMovedout.map((item) => dashMoveOut.push(item));
      //let current = false;
      let tenantsData = [...dashMoveOut];
      // first vacant false check
      let isFirstVacantFound = false;
      //first vancant false = index
      let firstOriginalDataIndex;

      tenantsData = response.result.map((tenant, idx) => {
        console.log(tenant, idx, 'tenant current idx');
        // isVacant value
        let isVacant = tenant.vacant;
        let current = false;

        //console.log(dateCompare, moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY'),'dateCompare')
        // if(dateCompare==false&&response.result.moveOutDate!=='-'){
        if (
          (dayjs(tenant.moveInDate).isBefore(dayjs()) || dayjs(tenant.moveInDate).isSame(dayjs())) //logic for moveInDate
          && 
          (tenant.moveOutDate === null || dayjs(tenant.moveOutDate).isAfter(dayjs()) || dayjs(tenant.moveOutDate).isSame(dayjs())) //logic for moveOutDate
        ) { //isFirstVacantFound == false && isVacant == false
          //
          console.log('testingCurrentTenants: ', tenant);
          firstOriginalDataIndex = idx;
          current = true;
          isFirstVacantFound = true;
        }else if(tenant.vacant && tenant.moveInDate === null && tenant.moveOutDate === null && response.result.length === 1){
          current = true;
        }

        return {
          id: checkProperty(tenant, 'id'),
          name: checkProperty(tenant, 'fullName'),
          email: checkProperty(tenant, 'email'),
          phone: checkProperty(tenant, 'phone'),
          in: checkProperty(tenant, 'moveInDate') 
            ? moment(checkProperty(tenant, 'moveInDate')).format('DD.MM.YYYY') 
            : '',
          out: checkProperty(tenant, 'moveOutDate')
            ? moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY')
            : '',
          type: checkProperty(tenant, 'contractType'),
          note: checkProperty(tenant, 'notes'),
          current: current,
          createdOn: checkProperty(tenant, 'createdOn'),
          vacant: checkProperty(tenant, 'vacant'),
          // rentalUnit: tenant.rentalUnit,
          rentalUnitId: checkProperty(tenant, 'rentalUnitId'),
          updatedOn: checkProperty(tenant, 'updatedOn'),
          numberOfOccupants: tenant?.numberOfOccupants
        };
      });
      console.log(tenantsData, '======tenantsData');
      if (tenantsData.length === 1 && tenantsData[0].vacant == true) {
        //tenantsData[0].current = true;

        // let moveoutDate=tenantsData[0].out;
        // let dateCompare = moment(moment(moveoutDate).format('DD.MM.YYYY')).isSameOrAfter(moment(new Date()).format('DD.MM.YYYY'));
        // console.log(moveoutDate,dateCompare,tenantsData[0],'====abc======')
        // if(dateCompare===true){
        //   tenantsData[0].current = true;
        // }
      } else if (firstOriginalDataIndex !== 0 && firstOriginalDataIndex !== undefined) {
        // console.log('abc', firstOriginalDataIndex, tenantsData[1]);
        // tenantsData[firstOriginalDataIndex - 1].current = true;
        let moveoutDate = tenantsData[firstOriginalDataIndex].out;
        let dateCompare = moment(this.ChangeDateFormat(moveoutDate)).isSameOrAfter(moment(new Date()));
        console.log(moveoutDate, dateCompare, tenantsData[firstOriginalDataIndex], '====abc======');
        if (dateCompare === false) {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
          tenantsData[firstOriginalDataIndex].current = false;
        } else {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
        }
      }

      //action
      //row ko array
      let field = 'name';
      let rentalunitsorted = rentalUnits.sort((a, b) =>
        (a[field] || '').toString().localeCompare((b[field] || '').toString())
      );

      rentalUnits= rentalunitsorted.map((ru) => {
        console.log('worked', ru.id == tenantsData[0].rentalUnitId)
        if (ru.id == tenantsData[0].rentalUnitId) {
          console.log('worked', tenantsData)
          return {
            ...ru,
            tenantsData: [...tenantsData],
          };
        } else {
          return ru;
        }
      });
      this.setState({
        rentalUnits
      }, ()=> {
        console.log('tenant of rental unit', tenantsData, rentalUnits);
        if(response.other.eol){
          setTimeout(() => {
            this.props.getRentalUnitTenants(rentalUnits);
            this.getRentsApi();  
          }, 1000);
        }
      });
    }

    // console.log('data formated', data);
    //   rentalListData.push(data);
    //   let sorted=  rentalListData.sort(function(a, b) {
    //     return a.name.localeCompare(b.name);
    //  });
    // console.log('global array', rentalListData, sorted )
    // const sortedData = nestedAlphabeticSort(rentalListData, 'name', true, null);
    //pass data received to redux
    
  };
  //new ends

  getRentsApi = () => {
    this.state.rentalUnits.map((rentalUnit, rentalUnitIndex)=> rentalUnit?.tenantsData.map((tenant, tenantIndex)=> {
      if(tenant){
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'rents?offset=0&fetch=100&tenantId=' + tenant.id,
          // checkProperty(this.props.formData, 'id'),
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handle_get_rents',
          {eolRentalUnits: (this.props.rentalUnits.length-1) === rentalUnitIndex,  eolTenants: (rentalUnit?.tenantsData.length-1) === tenantIndex},
          false,
          '',
          {
            // 'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }
    }))
    
  };

  handle_get_rents(response){
    const {rentalUnits} = this.state;
    let newRentalUnits = [];
    if(response.status && response.statuscode === 200){
      rentalUnits.map((rentalUnit)=> {
        let newTenantsData = [];
        rentalUnit?.tenantsData.map((tenant)=> {
          if(tenant){
            if(response.result.length > 0){
              let tenantRent = response.result.find((rent)=> rent.tenantId === tenant.id);
              if(tenantRent !== undefined){
                newTenantsData.push({
                  ...tenant,
                  tenantRent: response.result
                })
              }else{
                newTenantsData.push(tenant);
              }
            }else{
              newTenantsData.push(tenant);
            }
          }
        });

        console.log('newTenantsData: ', newTenantsData);
        newRentalUnits.push({
          ...rentalUnit,
          tenantsData: newTenantsData
        });
      });
      this.setState({rentalUnits: newRentalUnits}, ()=> 
      setTimeout(() => {
        this.props.getRentalUnitTenants(newRentalUnits);
      }, 1000));
      console.log('newRentalUnits: ', newRentalUnits);
    }
    if(response.other.eolRentalUnits && response.other.eolTenants){
      this.ShowLoader();
    }
  }
  ShowLoader = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    var emptyDiv = document.getElementById('empty-main-tenant-page');
    emptyDiv.style.display = 'none';
    var dataDiv = document.getElementById('main-tenant-page');
    dataDiv.style.display = 'block';
    this.setState({skeletonLoading: false});
    this.props.loadingTenantsData(false);
  };
  deleteUnit = (unitId) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    //call delete api for rental unit
    let btnelement = document.getElementById('unit-delete-modal-footer');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'RentalUnits/' + unitId,
      'DELETE',
      null,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleDelete',
      { cancelbtn: btnelement.children[0], id: unitId },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleDelete = (response) => {
    // console.log(response , 'delete unit tenant api')

    //handle api response
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';

    //action to delete unit
    this.props.DeleteUnit(response.other.id);
    response.other.cancelbtn.click();

    // this.bind_rental_unit_list_data();
  };
  setCurrentUnit = (id) => {
    let data = this.props.rentalUnits.filter((rc) => rc.id == id);
    this.setState({ currentUnit: { ...data[0] } });
  };
  changePage = (route, state) => {
    this.props.history.push(route, state);
  };
  toggleEditUnitModal = (id) => {
    this.setCurrentUnit(id);
    this.setState({ editUnitModal: !this.state.editUnitModal });
  };
  toggleDeleteUnitModal = (id) => {
    if (id !== undefined) {
      this.setCurrentUnit(id);
    }
    this.setState({ deleteUnitModal: !this.state.deleteUnitModal });
  };
  toggleAddUnitModal = () => {
    this.setState({ addUnitModal: !this.state.addUnitModal });
  };
  addTenant = (id, startDate, endDate) => {
    this.props.setCurrentTenant(
      {},
      {
        name: '',
        email: '',
        phone: '',
        in: '',
        out: '',
        type: 'private',
        // address: '',
        // city: '',
        // zip: null,
        // area: '',
        // //doc: pdf,
        note: '',
        rents: [],
      }
    );
    
    this.setState({emptyTenantGapsData: {
      moveIn: startDate !== null ? startDate : '',
      moveOut: endDate !== null ? endDate : ''
    }})
    // this.changePage('/user/tenant', { unitId: id });
    this.toggleAddTenantModal(id);
  };
  handleSort = (data, param, ascendingOrder) => {
    // const sortedList = nestedAlphabeticSort(data, param, false);
    const sortedList = data.sort((a, b) => (a[param] || '').toString().localeCompare((b[param] || '').toString()));
    console.log('handle sort', sortedList);
    this.props.updateFilteredList(sortedList);
    this.setState({ ascendingOrder: !this.state.ascendingOrder });
    // this.setState({ ascendingOrder: !this.state.ascendingOrder }, () => this.handleSearchAndSort(this.state.searchValue, this.state.ascendingOrder));
  };
  handleSearchChange = (e) => {
    this.props.updateFilteredList(this.props.rentalUnits);
    let show = false;
    if (e.target.value == '') {
      show = false;
    } else {
      show = true;
    }
    this.setState({ searchValue: e.target.value, showAllTenants: show }, () => this.filterListBySearch());

    // this.setState({ searchValue: e.target.value, showAllTenants: show }, () => this.handleSearchAndSort(this.state.searchValue, this.state.ascendingOrder));
  };
  handleSearchAndSort = (value, order) => {
    //set searched value and order of sort in state
    //run search funtion and get list
    //apply sort on that list
    //save it as filtered list
  };
  handleDeleteTenant = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }

    response.other.cancelbtn.click();
    this.bind_rental_unit_list_data();
  };
  filterListBySearch = (e) => {
    let tenantsSearch;
    tenantsSearch = filterBySearchBarRentalTenants(this.state.searchValue, this.props.filteredList);
    // console.log('filtered list after search', tenantsSearch)
    this.props.updateFilteredList(tenantsSearch);
  };
  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };
  toggleAddPropertyTypeModal = () => {
    this.setState({ addPropertyTypeModal: !this.state.addPropertyTypeModal });
  };
  toggleAddTenantModal = (id) => {
    // console.log('toggle add tenant modal', id)
    // match id and get tenants
    this.setState({ addTenantModal: !this.state.addTenantModal, currentRentalUnitId: id });
  };
  sortAlphabetically = () => {
    // A to Z
    let filteredArray = [];
    let field = 'name';
    filteredArray = this.props.filteredList.sort((a, b) =>
      (a[field] || '').toString().localeCompare((b[field] || '').toString())
    );
    this.props.updateFilteredList(filteredArray);
  };
  sortReverseAlphabetically = () => {
    // Z to A
    let filteredArray = [];
    let field = 'name';
    filteredArray = this.props.filteredList.sort((a, b) =>
      (b[field] || '').toString().localeCompare((a[field] || '').toString())
    );
    this.props.updateFilteredList(filteredArray);
  };

  render() {
    const { addUnitModal, editUnitModal, currentUnit, deleteUnitModal, skeletonLoading, emptyTenantGapsData } = this.state;
    const { rentalUnits, filteredList, loadingTenantsDataLoader, isMobile } = this.props;
    let pathname = this.props.location.pathname;

    console.log(rentalUnits, 'props');
    console.log(this.state.rentalUnits, filteredList, rentalUnits, 'data rental unit');

    return (
      <>
        <div id="empty-main-tenant-page"></div>
        <div className="main-tenant-page" 
        id="main-tenant-page" style={{ display: 'block' }}>
          {/* page header */}
          {/* <PageHeader
            heading={lang.tenant.text_tenant}
            headingClassName="main-tenant-heading"
            rightContent={
              <ButtonGroup 
              style={{
                background: '#e9e9f0',
                height: '36px',
                width: 'auto',
                padding: '0px 7px',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center'
              }}
              >
              {routes.map((btn, idx) => {
                return (
                  <Button
                    key={idx}
                    //className={`switch-btn ${btn.name}-btn ${btn.route === pathname && 'active'}`}
                    style={{
                      width: '110px',
                      height: '26px',
                      margin: '3% auto',
                      paddingTop: '1%',
                      paddingBottom: '1%',
                      borderRadius: '4px',
                      background: btn.route === pathname ? 'white' : 'transparent',
                      color: '#252563',
                      border: 'none',
                      boxShadow: 'none',
                    }}
                    
                    // style={{
                    //   color: btn.route === pathname ? '#111147' : '#727293',
                    //   borderBottom: btn.route === pathname ? '3px solid #5555FF' : null,
                    //   fontSize: btn.route === pathname ? '14px' : '14px',
                    //   marginRight: '25px',
                    //   padding: '32px 0px',
                    // }}
                    onClick={() => {
                      this.changePage(btn.route);
                    }}
                  >
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                      <span style={{//marginTop: '0px'
                      }}
                      >
                        {btn.label}
                      </span>
                    </div>
                  </Button>
                );
              })}
            </ButtonGroup> 
            }
            searchElement={
              <div
                className={this.props.rentalUnits.length == 0 || this.props.properties.length == 0 ? 'emptyState' : ''}
              >
                <SearchContainer
                  placeholder={`${lang.general.text_search}...`}
                  value={this.state.searchValue}
                  onChange={this.handleSearchChange}
                  disabled={false}
                  onFocus={() => this.setState({ focus: true })}
                  onBlur={() => this.setState({ focus: false })}
                  // disabled={this.props.rentalUnits.length == 0 || this.props.properties.length == 0}
                />
              </div>
            }
            // sortElement={
            //   <div
            //     className={this.props.rentalUnits.length == 0 || this.props.properties.length == 0 ? 'emptyState' : ''}
            //   >
            //     <SortContainer
            //       sortingParam="alphabet"
            //       ascendingOrder={this.state.ascendingOrder}
            //       filterHandler={() =>
            //         this.props.rentalUnits.length == 0 || this.props.properties.length == 0
            //           ? () => {}
            //           : this.handleSort(this.props.rentalUnits, 'name', this.state.ascendingOrder)
            //       }
            //     />
            //   </div>
            // }
            sortElement={
              <div style={{display: 'flex', alignItems: 'center'}}>
                <SortAlphabetically
                  sortingParam="alphabet"
                  handleAscending={this.sortAlphabetically}
                  handleDescending={this.sortReverseAlphabetically}
                />
                <ColorButton
                  onClick={this.toggleAddUnitModal}
                  text={lang.tenant.text_add_rental_unit}
                  color="purple-btn"
                  className={'purple-btn'}
                  buttonStyle={{marginLeft: '10px'}}
                  disabled={this.props.properties.length == 0}
                />
              </div>
            }
          /> */}
          {/* list of record */}
          {(filteredList && skeletonLoading === false && loadingTenantsDataLoader === false) &&
            filteredList
            .sort((a, b) => {
              const extractParts = (name) => {
                const match = name.match(/(\D+)(\d+)?/); // Split into text and number parts
                return [match[1], parseInt(match[2] || "0", 10)];
              };
            
              const [textA, numA] = extractParts(a.name.toLowerCase());
              const [textB, numB] = extractParts(b.name.toLowerCase());
            
              if (textA === textB) {
                return numA - numB; // Sort numerically if text is the same
              }
            
              return textA.localeCompare(textB); // Otherwise, sort alphabetically
            }).map((unit, index) => {
              return (
                <div key={index}>
                  <TenantRecordRow
                    parent={this}
                    showAll={this.state.showAllTenants}
                    history={this.props.history}
                    handleEditUnit={this.toggleEditUnitModal}
                    handleUnitDelete={this.toggleDeleteUnitModal}
                    handleAddTenant={() => this.addTenant(unit.id)}
                    handleAddEmptyTenant={(startDate= null, endDate=null)=> {
                      this.setState({emptyTenantEdit: true});
                      this.addTenant(unit.id, startDate, endDate);
                    }}
                    TenantsAfterAddModal={this.state.TenantPostAddTenant}
                    // showAllTenants={(value) => this.showAllTenants(value)}
                    unitData={unit}
                    propertySelected={this.props.propertySelected}
                  />
                  {filteredList.length - 1 !== index && <Divider />}
                </div>
              );
            })}
          {(skeletonLoading || loadingTenantsDataLoader) ?
          [...Array(5)].map((e, i) => 
            <div>
              <div style={{marginBottom: '29px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Skeleton visible={true} style={{width: '100px'}}/>
                  <div style={{display: 'flex', justifyContent: 'space-between', width: '180px'}}>
                    <Skeleton visible={true} style={{width: '40px'}}/>
                    <Skeleton visible={true} style={{width: '135px', height: '36px'}}/>
                  </div>
                </div>
                <div style={{marginTop: '29px'}}>
                  <div>
                    <Skeleton visible={true} style={{height: '23px', marginBottom: '10px'}}/>
                    <Skeleton visible={true} style={{height: '75px', marginBottom: '10px'}}/>
                    <Skeleton visible={true} style={{height: '24px', width: '86px'}}/>
                  </div>
                </div>
              </div>
              <div className='divider'/>
            </div>
          )
          :
          this.props.filteredList.length == 0 && this.props.properties.length > 0 && (
            <EmptyStateContainer
              heading={lang.tenant.text_no_rental_units}
              subheading={'Legen Sie Mieteinheiten an, um Ihre Mietverhältnisse zu verwalten.'}
              icon={BlueUnitsIcon}
              btnHandler={() => {
                this.toggleAddUnitModal();
              }}
              btnText={lang.tenant.text_add_rental_unit}
            />
          )}
          {this.props.properties.length === 0 && (
            <EmptyStateContainer
              heading={lang.tenant.text_no_rental_units}
              subheading={lang.tenant.text_no_unit_and_property}
              icon={BlueUnitsIcon}
              btnHandler={() => {
                this.toggleAddPropertyTypeModal();
              }}
              btnText={lang.general.text_add_property}
            />
          )}
          {/* add and edit rental unit modal */}
          {/* {addUnitModal &&  */}
          <RentalUnitModal
            record={null}
            edit={false}
            open={addUnitModal}
            toggle={this.toggleAddUnitModal}
            updateStore={this.bind_rental_unit_list_data}
            className="add-rental-unit-modal"
          />
          {/* } */}
          <RentalUnitModal
            record={currentUnit}
            edit={true}
            open={editUnitModal}
            toggle={this.toggleEditUnitModal}
            updateStore={this.bind_rental_unit_list_data}
            className="add-rental-unit-modal"
          />
          {/* delete rental unit modal */}
          <DeleteModal
            open={deleteUnitModal}
            className="delete-rent-modal"
            toggle={this.toggleDeleteUnitModal}
            record={currentUnit}
            handleDelete={() => this.deleteUnit(currentUnit.id)}
            // showInfo={[currentUnit.name, `${currentUnit.size} m²`]}
            type={lang.tenant.text_rental_unit}
            modalId="unit-delete-modal-footer"
            // type={lang.property.text_property}
            showtext={false}
            typeText={
              'Sind Sie sicher, dass Sie diese Mieteinheit löschen wollen? Daduch werden auch alle gespeicherten Mietverhältnisse dieser Mieteinheit entfernt.'
            }
            textShowModal={"Mieteinheit löschen"}
            showDeleteText={false}
          />
          {/* add property */}
          <AddPropertyModal
            className="add_proprty_modal"
            edit={false}
            open={this.state.addPropertyModal}
            toggle={() => {
              this.toggleAddPropertyModal();
            }}
            propertyType={this.state.selectedPropertyType}
          />
          {this.state.addPropertyTypeModal &&
          <AddPropertyTypeModal
            className="add_proprty_type_modal"
            open={this.state.addPropertyTypeModal}
            toggle={() => {
              this.toggleAddPropertyTypeModal();
            }}
            next={(selectedPropertyType)=> {
              this.setState({selectedPropertyType});
              this.toggleAddPropertyTypeModal();
              this.toggleAddPropertyModal();
            }}
          />
          }
          {/* add tenant */}
          {this.state.addTenantModal == true && (
            <AddTenantModal
              updateStore={this.bind_rental_unit_list_data}
              rentalId={this.state.currentRentalUnitId}
              className="add_tenant_modal"
              unitData={filteredList}
              open={this.state.addTenantModal}
              toggle={() => {
                this.toggleAddTenantModal();
                this.setState({emptyTenantEdit: false});
              }}
              emptyTenantGapsData={emptyTenantGapsData}
              emptyTenantEdit={this.state.emptyTenantEdit}
              showAllTenants={(value) => {
                console.log(value, 'value');
                this.showAllTenantFunction(value);
              }}
              history={this.props.history}
              isMobile={isMobile}
            />
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    rentalUnits: state.tenantDataReducer.rentalUnits,
    filteredList: state.tenantDataReducer.filteredList,
    properties: state.generalReducer.allProperties,
    propertySelected: state.generalReducer.property,
    refreshTenantDocumentsData: state.generalReducer.refreshTenantDocumentsData,
    loadingTenantsDataLoader: state.tenantDataReducer.loadingTenantsData
  };
};
const actions = {
  getRentalUnitTenants,
  setCurrentTenant,
  updateFilteredList,
  DeleteUnit,
  setRefreshTenantDocumentsData,
  loadingTenantsData
};
export default connect(mapStateToProps, actions)(FilteredTenantsList);

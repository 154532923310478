import React, { Component } from 'react';
import { ColorButton } from '../../../../../neoverv';
import Autoload from '../../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { Group, Stack } from '@mantine/core';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import paypal from '../../../../../assets/images/module/general/paypal.svg';
import mastercard from '../../../../../assets/images/module/general/mastercard.svg';
import visa from '../../../../../assets/images/module/general/visa.svg';
import { setCookie } from '../../../../../neoverv/helpers/Utils';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty } = Autoload.MainHelper;
export class PaymentTemplate extends Component {
  state = {
    paymentMethodExist: false,
    type: null,
    showLoader: false
  };

  componentDidMount() {
    let url = new URL(window.location.href);
    console.log('type: ',url.searchParams.get("type"));
    this.setState({
      type: url.searchParams.get("type")
    });
    this.getPaymentDetails();
  }

  getPaymentDetails = () => {
    console.log('this.props.profile', this.props.profile.accountId, this.props.profile);
    const userAccountID = this.props.profile.accountId;
    // console.log(userAccountID, 'userAccountID from redux - payment details page');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + userAccountID + '/paymentMandate',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_account_payment_details',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_account_payment_details(response) {
    console.log('handle_account_payment_details response', response);
    if (response.status == true && response.statuscode == 200) {
      this.setState({
        paymentMethodExist: (response.result && response.result.paymentMethod) ? true : false,
        type: response.result === null ? 'authorize' : 'reAuthorize'
      });
    }
}

authorizePayment=()=>{
  this.setState({showLoader: true});
  const userAccountID = this.props.profile.accountId;
  Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + userAccountID + '/authorizePayment',
      'PUT',
      {
          accountId: userAccountID,
          paymentMethod: null
      },
      null,
      false,
      true,
      false,
      this,
      'handle_account_authorize_payment',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
}
handle_account_authorize_payment(response){
    console.log('handle_account_authorize_payment: ', response);
    if(response.status){
        setCookie('PaymentAuthorizeInProgress', '1');
        setCookie('paymentReAuthorizeInProgress', '0');
        window.location.href = response.result;
    }
    this.setState({showLoader: false});
}

reAuthorizePayment = () => {
  this.setState({showLoader: true});
  let accountId = this.props.profile.accountId;
  let payload = { accountId: accountId, paymentMethod: null };

  Autoload.HttpRequestAxios.HttpRequest(
    Autoload.Setting.api_base_url + `user-api/Accounts/${accountId}/reauthorizePayment`,
    'PUT',
    payload,
    null,
    false,
    true,
    false,
    this,
    'handleChangePaymentOptionResponse',
    null,
    false,
    '',
    { Authorization: Autoload.Utils.getAuthToken() }
  );
};
handleChangePaymentOptionResponse = (response) => {
  if (response.result) {
    setCookie('paymentReAuthorizeInProgress', '1');
    setCookie('PaymentAuthorizeInProgress', '0');
    window.location.href = response.result;
  }
  this.setState({showLoader: false});
};

  render() {
    const {paymentMethodExist, type, showLoader} = this.state;
    return (
        <Stack className='payment-template' align={'center'} >
            <Group>
                <img src={Logo} width={'155px'} height={'79px'} />
            </Group>
            <Group>
                <span className='payment-template-title'>Weiterleitung zum Zahlungsanbieter</span>
            </Group>
            <Group>
                <span className='payment-template-text'>{type === 'authorize' ?
                'Sie haben noch kein Zahlungsmittel angegeben. Wir leiten Sie jetzt zu unserem Zahlungsanbieter mollie weiter. Sie werden im Anschluss wieder zurück geleitet.' 
                : 
                'Wir leiten Sie jetzt zu unserem Zahlungsanbieter mollie weiter. Sie werden im Anschluss wieder zu hellohousing zurück geleitet.'} </span>
            </Group>
            <Group>
                <img src={paypal} />
                <img src={mastercard} />
                <img src={visa} />
            </Group>
            <Group style={{width: '400px', justifyContent: 'space-between', marginTop: '60px'}} >
                <div>
                    <ColorButton 
                        text={'Abbrechen'}
                        className='gray-btn'
                        onClick={()=> window.close()}
                    />
                </div>
                <div>
                    <ColorButton 
                        text={'Weiter'}
                        className='purple-btn'
                        onClick={()=> type === 'authorize' ? this.authorizePayment() : this.reAuthorizePayment()}
                        showLoader={showLoader}
                    />
                </div>
            </Group>
        </Stack>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.generalReducer.profile,
    AccountApi: state.generalReducer.account_api_id,
  };
};

export default connect(mapStateToProps, null)(PaymentTemplate);

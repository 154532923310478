import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { hideNotification, showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import { Checkbox, Group, Loader, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import { NumericFormat } from 'react-number-format';
import Autoload from '../../../../Autoload';
import {setAddReceiptModal, setReceiptCurrentId, setReceiptFiles, setRefreshReceiptDocumentsData} from '../../../../../redux/action/General/generalActions';
import { connect } from 'react-redux';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class ReceiptDuplicateProgressModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    processComplete: 0,
    error: false
  };
  
  constructor(props) {
    super(props);
  }
  
  componentDidMount(){
    hideNotification('receiptDuplicationProcess');
    showNotification({
      message: 'Belegkopie wird erstellt.',
      icon: <Loader size={'xs'} variant={'dots'}/>,
      id: 'receiptDuplicationProcess',
      autoClose: false
    });
    this.postDuplicateReceipt();
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'block';
  }

  componentWillUnmount(){
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'none';
  }

  generateDuplicateName(originalName, existingNames) {
    const baseName = originalName.trim();
    let newName = `${baseName} (Kopie)`;
    let counter = 1;
  
    // Check if the new name already exists
    while (existingNames.includes(newName)) {
      newName = `${baseName} (Kopie) ${counter}`;
      counter++;
    }
  
    return newName;
  }

  postDuplicateReceipt=()=>{
    const {selectedReceiptForDuplication} = this.props;
    const {data} = this.props;
    console.log('selectedReceiptForDuplication: ', selectedReceiptForDuplication);
    //this.generateDuplicateName(selectedReceiptForDuplication?.name, data.map((receipt)=> receipt.name));
    const payload = JSON.stringify({
      id: null,
      name: selectedReceiptForDuplication?.name,//this.generateDuplicateName(selectedReceiptForDuplication?.name, data.map((receipt)=> receipt.name)),//`${selectedReceiptForDuplication?.name} (Kopie)`, //${nameOccurance > 1 ? ` ${nameOccurance - 1}` : ''}
      receiptDate: selectedReceiptForDuplication?.receiptDate,
      date: selectedReceiptForDuplication?.receiptDate,
      costs: parseFloat(selectedReceiptForDuplication?.costs),
      cost: parseFloat(selectedReceiptForDuplication?.costs),
      utilityBillingRelevant: selectedReceiptForDuplication?.utilityBillingRelevant,
      receiptPeriodStart: selectedReceiptForDuplication?.receiptPeriodStart,
      receiptPeriodEnd: selectedReceiptForDuplication?.receiptPeriodEnd,
      propertyId: selectedReceiptForDuplication?.propertyId,
      selectedCostTypes: selectedReceiptForDuplication?.SelectedCostTypes,
      vatPercent: parseFloat(selectedReceiptForDuplication?.vatPercent),
      vatAmount: parseFloat(selectedReceiptForDuplication?.vatAmount),
      netAmount: parseFloat(selectedReceiptForDuplication?.netAmount),
      laborCost: parseFloat(selectedReceiptForDuplication?.laborCost),
      taxCostType: selectedReceiptForDuplication?.taxCostType,
      receiptNumber: selectedReceiptForDuplication?.receiptNumber,
      notes: selectedReceiptForDuplication?.notes
    });
    console.log('selectedReceiptForDuplication: ', payload);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'create',
      'POST',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handlePostDuplicateReceipt',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePostDuplicateReceipt(response){
    const {selectedReceiptForDuplication} = this.props;
    if(response.status && response.statuscode === 200){
      this.setState({processComplete: 1});
      if(selectedReceiptForDuplication?.fileName){
        this.downloadReceiptAttachment(response?.result);
      }else{
        this.props.toggle(true);
      }
    }else{
      this.setState({error: true});
      hideNotification('receiptDuplicationProcess');
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'receiptDuplicationProcess',
        autoClose: false
      });
    }
  }

  downloadReceiptAttachment=(id)=>{
    const {selectedReceiptForDuplication} = this.props;
    fetch(Autoload.Setting.rm_api_base_url + 'attachment/'+ selectedReceiptForDuplication?.id +'/download',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
      .then(response => {
        console.log('downloadReceiptAttachment: ', response);
        if(response.status === 200){
          this.setState({processComplete: 2});
          response.blob()
          .then(blob => {
            const file = new File([blob], selectedReceiptForDuplication?.fileName, { type: blob.type, lastModified: new Date() });
            setTimeout(() => {
              this.postUploadReceipt(file, id);  
            }, 600);
          })
        }else{
          this.setState({error: true});
          hideNotification('receiptDuplicationProcess');
          showNotification({
            message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
            icon: <img src={RedCross} alt='red-cross'/>,
            id: 'receiptDuplicationProcess',
            autoClose: false
          });
        }        
      });
  }

  postUploadReceipt=(file, id)=>{
    const data = new FormData();
    data.append('files', file);
    // this.props.showFirstRowLoader() 
    // this.props.setAddReceiptModal(true); 
    // this.props.setReceiptCurrentId(id);
    // this.props.setReceiptFiles([file]);
    // console.log('handleaddReceipt:');
    // var elem = document.getElementById("uploadReceiptDocs");
    // console.log('handleaddReceipt:', typeof elem.onclick);
    // if (typeof elem.onclick == "function") {
    //     console.log('handleaddReceipt: inside');
    //     elem.onclick.apply(elem);
    //     this.props.toggle(true);  
    // }else{
    //   this.props.toggle();
    // }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'upload/' + id,
      'PUT',
      data,
      null,
      false,
      true,
      false,
      this,
      'handlePostUploadReceipt',
      null,
      false,
      '',
      {
        'Content-Type': 'multipart/form-data',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePostUploadReceipt(response){
    if(response.status && response.statuscode === 200){
      this.setState({processComplete: 3});
      setTimeout(() => {
        this.props.toggle(true);  
      }, 1000);
    }else{
      this.setState({error: true});
      hideNotification('receiptDuplicationProcess');
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'receiptDuplicationProcess',
        autoClose: false
      });
    }
  }
  
  render() {
    return <div style={{display: 'none'}}/>;
  }
}

const actions = {
  setAddReceiptModal, 
  setReceiptCurrentId, 
  setReceiptFiles,
  setRefreshReceiptDocumentsData
}

export default connect(null, actions)(ReceiptDuplicateProgressModal);

import React, { Component } from 'react'
import CustomModal from './index';
import {ColorButton} from '../../../../index';
import Autoload from "../../../../Autoload";
import { Group, Stack } from '@mantine/core';
import { connect } from 'react-redux';
import visa from '../../../../../assets/images/module/general/visa.svg';
import mastercard from '../../../../../assets/images/module/general/mastercard.svg';
import amex from '../../../../../assets/images/module/general/amex.svg';
import paypal from '../../../../../assets/images/module/general/paypal.svg';
import StarterBadgeIcon from '../../../../../assets/images/module/general/StarterBadgeIcon.svg';
import PremiumBadgeIcon from '../../../../../assets/images/module/general/PremiumBadgeIcon.svg';
import ProfessionalBadgeIcon from '../../../../../assets/images/module/general/ProfessionalBadgeIcon.svg';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import moment from 'moment';
import dayjs from 'dayjs';
import accounting from 'accounting';
import SubscriptionBadgeIcon from '../../../SVGs/SubscriptionBadgeIcon';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

class PaymentConfirmationModal extends Component {
    state={
        holderName: '',
        holderCardNo: '',
        isDowngrade: false
    }
    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.getPaymentDetails();
        this.setIsDowngrade();
    }

    componentDidUpdate(prevProps){
        if(prevProps.subscriptionTypes.length !== this.props.subscriptionTypes.length){
            this.setIsDowngrade();
        }
    }

    setIsDowngrade=()=>{
        const {currentSubscription, subscriptionTypes, subscribtionSelected} = this.props;

        let selectedSubscription = subscriptionTypes.find((subscriptionType)=> subscriptionType?.subscriptionTypeId === subscribtionSelected);
        console.log('selectedSubscription: ', selectedSubscription, subscribtionSelected, subscriptionTypes);
        if(currentSubscription?.subscriptionName === 'Professional' && currentSubscription?.subscriptionKey !== 'PROFESSIONAL_TESTING_12_2024'){
            if(selectedSubscription?.name === 'Starter' || selectedSubscription?.name === 'Premium'){
                this.setState({isDowngrade: true});
            }
        }else if(currentSubscription?.subscriptionName === 'Premium'){
            if(selectedSubscription?.name === 'Starter'){
                this.setState({isDowngrade: true});
            }
        }
    }

    getPaymentDetails = () => {
        console.log('this.props.profile', this.props.profile.accountId, this.props.profile);
        const userAccountID = this.props.profile.accountId;
        // console.log(userAccountID, 'userAccountID from redux - payment details page');
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.account_api_baseurl + userAccountID + '/paymentMandate',
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handle_account_payment_details',
          null,
          false,
          '',
          {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      };
      handle_account_payment_details(response) {
        console.log('handle_account_payment_details response', response);
        if (response.status == true && response.statuscode == 200) {
          this.setState({
            holderName: Autoload.MainHelper.checkProperty(response.result, 'holderName'),
            holderCardNo: Autoload.MainHelper.checkProperty(response.result, 'holderAccount'),
            consumerAccount: Autoload.MainHelper.checkProperty(response.result, 'consumerAccount'),
            consumerBic: Autoload.MainHelper.checkProperty(response.result, 'consumerBic'),
            cardNumber: Autoload.MainHelper.checkProperty(response.result, 'cardNumber'),
            cardLabel: Autoload.MainHelper.checkProperty(response.result, 'cardLabel'),
            cardExpiryDate: Autoload.MainHelper.checkProperty(response.result, 'cardExpiryDate'),
            accountCreated: moment(Autoload.MainHelper.checkProperty(response.result, 'createdAt')).format('DD.MM.YYYY'),
            accountStatus: Autoload.MainHelper.checkProperty(response.result, 'mandateStatus'),
            heading: Autoload.MainHelper.checkProperty(response.result, 'paymentMethod'),
          });
        }
      }
    render(){
        const {open, toggle, className, confirmPayment, subscribtionSelected, subscriptionTypes, accountData, showLoader, currentSubscription, hasTested} = this.props;
        const {isDowngrade} = this.state;
        console.log('accountData: ', accountData);
        console.log('currentSubscriptionConfirmation: ', currentSubscription);

        console.log('isDowngrade: ', isDowngrade);
        return (
            <CustomModal 
            heading= {'Abonnement abschließen'}  
            open={open} 
            toggle={toggle} 
            className={`${className}`}>
                <Stack spacing={30}>
                 <Group>
                     <span style={{fontSize: '14px', color: '#454576', fontWeight: '400'}}>Bevor Sie das Abonnement abschließen, prüfen Sie bitte Ihre Angaben.</span>
                 </Group>
                 {subscriptionTypes.filter((subscriptionType)=> subscriptionType?.subscriptionTypeId === subscribtionSelected).map((subscriptionType)=> {
                const paidPlan= ((subscriptionType?.subscriptionKey.includes('STARTER') && subscriptionType?.subscriptionKey.includes('2024')) || subscriptionType?.subscriptionKey.split('_')[0] !== 'STARTER');
                 return (<Stack>
                    <Stack spacing={5}>
                        <span className="payment-heading-main" style={{fontSize: '16px', marginBottom: '16px'}}>Abonnement</span>
                        <Group grow>
                            <div className='upgrade-subscription-card' style={{cursor: 'auto', marginTop: 0}}>
                                {subscriptionType.name === 'Premium' ? (
                                // accountData.isPremiumPlanTested === false ?
                                // <Group spacing={0} style={{marginTop: '-5px'}}>
                                //     <span className='upgrade-subscription-card-value'>{'0'}<span style={{fontSize: '13px'}}>{'00'}</span>€</span>
                                //     <span className='upgrade-subscription-card-monat' style={{marginTop: '8px'}} >im 1. Monat -</span>
                                //     <span className='upgrade-subscription-card-monat' style={{marginLeft: 0, marginTop: '-12px'}}>{`danach ${accounting.formatMoney(subscriptionType.price, '€', 2, '.', ',', '%v %s')} / Monat, monatlich kündbar.`}</span>
                                // </Group>
                                // :
                                <Group align={'center'} spacing={0}>
                                    <span className='upgrade-subscription-card-value'>{subscriptionType.price.toString().split('.')[0]}<span style={{fontSize: '13px'}}>{paidPlan && subscriptionType.price.toString().split('.')[1]+'0'}</span>€</span>
                                    <span className='upgrade-subscription-card-monat'> / {subscriptionType.billing === 'daily' ? 'täglich' : 'Monat'}</span>
                                </Group>)
                                :
                                subscriptionType.name === 'Professional' ? (
                                // accountData.isProfessionalPlanTested === false ?
                                // <Group spacing={0}>
                                //     <span className='upgrade-subscription-card-value'>{'0'}<span style={{fontSize: '13px'}}>{'00'}</span>€</span>
                                //     <span className='upgrade-subscription-card-monat' style={{marginTop: '8px'}} >im 1. Monat -</span>
                                //     <span className='upgrade-subscription-card-monat' style={{marginLeft: 0, marginTop: '-12px'}}>{`danach ${accounting.formatMoney(subscriptionType.price, '€', 2, '.', ',', '%v %s')} / Monat, monatlich kündbar.`}</span>
                                // </Group>
                                // :
                                <Group align={'center'} spacing={0}>
                                    <span className='upgrade-subscription-card-value'>{subscriptionType.price.toString().split('.')[0]}<span style={{fontSize: '13px'}}>{paidPlan && subscriptionType.price.toString().split('.')[1]+'0'}</span>€</span>
                                    <span className='upgrade-subscription-card-monat'> / {subscriptionType.billing === 'daily' ? 'täglich' : 'Monat'}</span>
                                </Group>)
                                :
                                <Group align={'center'} spacing={0}>
                                    <span className='upgrade-subscription-card-value'>{subscriptionType.price.toString().split('.')[0]}<span style={{fontSize: '13px'}}>{paidPlan && subscriptionType.price.toString().split('.')[1]+'0'}</span>€</span>
                                    <span className='upgrade-subscription-card-monat'> / {subscriptionType.billing === 'daily' ? 'täglich' : 'Monat'}</span>
                                </Group>}
                                <Stack justify={'center'}>
                                    {/* <img src={subscriptionType.name === 'Premium' ? PremiumBadgeIcon : subscriptionType.name === 'Starter' ? StarterBadgeIcon : subscriptionType.name === 'Professional' && ProfessionalBadgeIcon} height={'24px'} /> */}
                                    <SubscriptionBadgeIcon name={subscriptionType?.name}/>
                                </Stack>
                            </div>
                        </Group>
                    </Stack>
                    <Group>
                        {subscriptionType.name === 'Premium' ? (
                        // accountData.isPremiumPlanTested === false ? 
                        // <span style={{fontSize: '14px', color: '#454576', fontWeight: '400'}}>Sie starten das kostenlose {subscriptionType.name} Probeabo. Das Probeabo wird nach Ablauf des Probezeitraums automatisch in ein bezahlpflichtiges Abo umgewandelt. Sie können das Probeabo jederzeit kündigen.</span>
                        // :
                        ((currentSubscription === null && !hasTested) || currentSubscription?.subscriptionName === 'Professional') ?
                        <span style={{fontSize: '14px', color: '#454576', fontWeight: '400'}}>{(currentSubscription?.subscriptionTypeId === '97f226d5-a137-48e6-aa9a-605d4488dec7' || (currentSubscription === null && !hasTested)) ? `Sie testen hellohousing noch kostenlos und unverbindich bis zum ${currentSubscription ? currentSubscription?.subscriptionEndDate : dayjs().add(30, 'days').format('DD.MM.YYYY')}. Im Anschluss` : `Nach dem Ablauf Ihres aktuellen Professional Paketes am ${currentSubscription?.subscriptionRenewDate},`} wechseln Sie zu hellohousing {subscriptionType.name}. {accounting.formatMoney(subscriptionType.price, '€', 2, '.', ',', '%v %s')} werden jeden Monat von Ihrem Zahlungsmittel eingezogen. Beginn der Laufzeit am {(currentSubscription?.subscriptionTypeId === '97f226d5-a137-48e6-aa9a-605d4488dec7' || (currentSubscription === null && !hasTested)) ? currentSubscription ? dayjs(currentSubscription?.subscriptionEndDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY') : dayjs().add(31, 'days').format('DD.MM.YYYY') : dayjs(currentSubscription?.subscriptionRenewDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY')}. {subscriptionType.cancellation === 'monthly' ? 'Monatliche' : 'täglich'} Kündigung möglich.</span>
                        :
                        <span style={{fontSize: '14px', color: '#454576', fontWeight: '400'}}>
                            {isDowngrade ?  
                            `Nach Ablauf Ihres aktuellen Abonnements, wechsel Sie am ${currentSubscription?.subscriptionRenewDate} zu hellohousing ${subscriptionType.name}. ${accounting.formatMoney(subscriptionType.price, '€', 2, '.', ',', '%v %s')} werden jeden Monat Ihrem Zahlungsmittel eingezogen. Monatliche Kündigung möglich.`
                            : 
                            `Sie wechseln zum ${subscriptionType.name} Paket. ${accounting.formatMoney(subscriptionType.price, '€', 2, '.', ',', '%v %s')} werden jeden Monat von Ihrem Zahlungsmittel eingezogen. Beginn der Laufzeit am ${moment().format('DD.MM.YYYY').toString()}. ${subscriptionType.cancellation === 'monthly' ? 'Monatliche' : 'täglich'} Kündigung möglich.`}
                        </span>)
                        :
                        subscriptionType.name === 'Professional' ? (
                        // accountData.isProfessionalPlanTested === false ? 
                        // <span style={{fontSize: '14px', color: '#454576', fontWeight: '400'}}>Sie starten das kostenlose {subscriptionType.name} Probeabo. Das Probeabo wird nach Ablauf des Probezeitraums automatisch in ein bezahlpflichtiges Abo umgewandelt. Sie können das Probeabo jederzeit kündigen.</span>
                        // :
                        ((currentSubscription === null && !hasTested) || currentSubscription?.subscriptionName === 'Professional') ?
                        <span style={{fontSize: '14px', color: '#454576', fontWeight: '400'}}>{(currentSubscription?.subscriptionTypeId === '97f226d5-a137-48e6-aa9a-605d4488dec7' || (currentSubscription === null && !hasTested)) ? `Sie testen hellohousing noch kostenlos und unverbindich bis zum ${currentSubscription ? currentSubscription?.subscriptionEndDate : dayjs().add(30, 'days').format('DD.MM.YYYY')}. Im Anschluss` : `Nach dem Ablauf Ihres aktuellen Professional Paketes am ${currentSubscription?.subscriptionRenewDate},`} wechseln Sie zu hellohousing {subscriptionType.name}. {accounting.formatMoney(subscriptionType.price, '€', 2, '.', ',', '%v %s')} werden jeden Monat von Ihrem Zahlungsmittel eingezogen. Beginn der Laufzeit am {(currentSubscription?.subscriptionTypeId === '97f226d5-a137-48e6-aa9a-605d4488dec7' || (currentSubscription === null && !hasTested)) ? currentSubscription ? dayjs(currentSubscription?.subscriptionEndDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY') : dayjs().add(31, 'days').format('DD.MM.YYYY') : dayjs(currentSubscription?.subscriptionRenewDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY')}. {subscriptionType.cancellation === 'monthly' ? 'Monatliche' : 'täglich'} Kündigung möglich.</span>
                        :
                        <span style={{fontSize: '14px', color: '#454576', fontWeight: '400'}}>
                            {isDowngrade ?  
                            `Nach Ablauf Ihres aktuellen Abonnements, wechsel Sie am ${currentSubscription?.subscriptionRenewDate} zu hellohousing ${subscriptionType.name}. ${accounting.formatMoney(subscriptionType.price, '€', 2, '.', ',', '%v %s')} werden jeden Monat Ihrem Zahlungsmittel eingezogen. Monatliche Kündigung möglich.`
                            : 
                            `Sie wechseln zum ${subscriptionType.name} Paket. ${accounting.formatMoney(subscriptionType.price, '€', 2, '.', ',', '%v %s')} werden jeden Monat von Ihrem Zahlungsmittel eingezogen. Beginn der Laufzeit am ${moment().format('DD.MM.YYYY').toString()}. ${subscriptionType.cancellation === 'monthly' ? 'Monatliche' : 'täglich'} Kündigung möglich.`}
                        </span>)
                        :
                        ((currentSubscription === null && !hasTested) || currentSubscription?.subscriptionName === 'Professional') ?
                        <span style={{fontSize: '14px', color: '#454576', fontWeight: '400'}}>{(currentSubscription?.subscriptionTypeId === '97f226d5-a137-48e6-aa9a-605d4488dec7' || (currentSubscription === null && !hasTested)) ? `Sie testen hellohousing noch kostenlos und unverbindich bis zum ${currentSubscription ? currentSubscription?.subscriptionEndDate : dayjs().add(30, 'days').format('DD.MM.YYYY')}. Im Anschluss` : `Nach dem Ablauf Ihres aktuellen Professional Paketes am ${currentSubscription?.subscriptionRenewDate},`} wechseln Sie zu hellohousing {subscriptionType.name}. {accounting.formatMoney(subscriptionType.price, '€', 2, '.', ',', '%v %s')} werden jeden Monat von Ihrem Zahlungsmittel eingezogen. Beginn der Laufzeit am {(currentSubscription?.subscriptionTypeId === '97f226d5-a137-48e6-aa9a-605d4488dec7' || (currentSubscription === null && !hasTested)) ? currentSubscription ? dayjs(currentSubscription?.subscriptionEndDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY') : dayjs().add(31, 'days').format('DD.MM.YYYY') : dayjs(currentSubscription?.subscriptionRenewDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY')}. {subscriptionType.cancellation === 'monthly' ? 'Monatliche' : 'täglich'} Kündigung möglich.</span>
                        :
                        <span style={{fontSize: '14px', color: '#454576', fontWeight: '400'}}>
                            {isDowngrade ?  
                            `Nach Ablauf Ihres aktuellen Abonnements, wechsel Sie am ${currentSubscription?.subscriptionRenewDate} zu hellohousing ${subscriptionType.name}. ${accounting.formatMoney(subscriptionType.price, '€', 2, '.', ',', '%v %s')} werden jeden Monat Ihrem Zahlungsmittel eingezogen. Monatliche Kündigung möglich.`
                            : 
                            `Sie wechseln zum ${subscriptionType.name} Paket. ${accounting.formatMoney(subscriptionType.price, '€', 2, '.', ',', '%v %s')} werden jeden Monat von Ihrem Zahlungsmittel eingezogen. Beginn der Laufzeit am ${moment().format('DD.MM.YYYY').toString()}. ${subscriptionType.cancellation === 'monthly' ? 'Monatliche' : 'täglich'} Kündigung möglich.`}
                        </span>}
                    </Group>
                 </Stack>)})}
                 <Stack spacing={5}>
                    <span className="payment-heading-main" style={{fontSize: '16px', marginBottom: '16px'}}>Zahlungsmethode</span>
                    <Group grow>
                        {this.state.heading &&
                        <Stack spacing={0} style={{border: '1px solid #DADAE5', marginBottom: '11px', borderRadius: '3px'}}>
                            <Stack style={{borderBottom: '1px solid #DADAE5', padding : '10px'}} spacing={0}>
                                    <div style={{fontSize: '12px', color: '#727293', fontWeight: '400'}}>{'Zahlungsmittel'}</div>
                                    <div style={{fontSize: '14px', color: '#454576', fontWeight: '500'}}>{this.state.heading === 'creditcard' ? 'Kreditkarte' : this.state.heading === 'directdebit' ? 'Lastschrifteinzug' : 'Paypal'}</div>
                            </Stack>
                            {this.state.holderName &&
                            <Stack style={{borderBottom: '1px solid #DADAE5', padding : '10px'}} spacing={0}>
                                    <div style={{fontSize: '12px', color: '#727293', fontWeight: '400'}}>{lang.general.text_Inhaber}</div>
                                    <div style={{fontSize: '14px', color: '#454576', fontWeight: '500'}}>{this.state.holderName}</div>
                            </Stack>}
                            <Group style={{ padding : '7px 10px'}} grow>
                                <Stack spacing={5}>
                                    <div className="payment-head" style={{display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '12px', color: '#727293', fontWeight: '400'}}>
                                        {this.state.heading === 'directdebit' ? 'IBAN' : this.state.heading === 'creditcard' ? this.state.cardLabel : this.state.heading === 'paypal' ? 'Konto' : this.state.holderCardNo.split(' ')[0]}
                                    </div>
                                    <div className="payment-text" style={{fontSize: '14px', color: '#454576', fontWeight: '500'}}>{this.state.heading === 'directdebit' ? this.state.consumerAccount : this.state.heading === 'creditcard' ? `xxxx xxxx xxxx ${this.state.cardNumber}` : this.state.heading === 'paypal' ? this.state.consumerAccount : `xxxx xxxx xxxx ${this.state.holderCardNo.split(' ')[1]}`}</div>
                                </Stack>
                                <Group style={{justifyContent: 'flex-end'}}>
                                    {this.state.heading === 'directdebit' ? 
                                    <Group spacing={5}>
                                        <img src={'https://www.mollie.com/external/icons/payment-methods/sofort.svg'}/>
                                        <img src={'https://www.mollie.com/external/icons/payment-methods/directdebit.svg'}/>
                                    </Group>
                                    :
                                    this.state.heading === 'creditcard' ? 
                                    <Group><img src={'https://www.mollie.com/external/icons/payment-methods/creditcard.svg'}/></Group>
                                    :
                                    this.state.heading === 'paypal' &&
                                    <Group><img src={'https://www.mollie.com/external/icons/payment-methods/paypal.svg'}/></Group>
                                    }
                                </Group>
                            </Group>
                            {this.state.consumerBic &&
                            <Stack style={{borderTop: '1px solid #DADAE5', padding : '7px 10px'}} spacing={5}>
                                    <div className="payment-head" style={{fontSize: '12px', color: '#727293', fontWeight: '400'}}>{'BIC'}</div>
                                    <div className="payment-text" style={{fontSize: '14px', color: '#454576', fontWeight: '500'}}>{this.state.consumerBic}</div>
                            </Stack>}
                        </Stack>}
                    </Group>
                    <span style={{fontSize: '14px', color: '#454576', fontWeight: '400'}}>Bitte drücken Sie Bestätigen, um das Abonnement zu starten.</span>
                 </Stack>
                 
                 <Group grow>
                    <div style={{justifyContent: 'flex-end', display: 'flex'}}>
                        <div>
                        <ColorButton
                            text={'Bestätigen'}
                            className='purple-btn'
                            onClick={()=>{
                                confirmPayment();
                                toggle();
                            }}
                            showLoader={showLoader}
                        />
                        </div>
                    </div>
                 </Group>
                </Stack>
            </CustomModal>
         )
    }
}
const mapStateToProps = (state) => {
    return {
        propertySelected: state.generalReducer.property,
        profile: state.generalReducer.profile,
        AccountApi: state.generalReducer.account_api_id,
        accountData: state.generalReducer.accountData,
        currentSubscription: state.generalReducer.currentSubscription,
        hasTested: state.generalReducer.hasTested
    };
};

export default connect(mapStateToProps)(PaymentConfirmationModal);

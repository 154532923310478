import { Group, Stack, Text, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { useLocation, useHistory, matchPath } from "react-router-dom";
import RestrictedMenuMessageModal from '../neoverv/components/Module/General/CustomModal/RestrictedMenuMessageModal';

import Icon from './Icon';

// Single menu item
export const MainMenuItem = ({ icon, route, children, href, target, color=null, ...props }) => {
  const [ hovered, setHovered ] = useState(false);
  const [ openRestrictedMenuMessage, setOpenRestrictedMenuMessage ] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const handleNavigate = (event) => {
    if(props.blockUsersFromUsingApp === false){
      event.preventDefault();

      if (route) {
        history.push(route);
        history.replace(route);
      }
  
      if (href) {
        window.open(href);
      }
    }else{
      if(props?.accountData?.accountPaymentStatus !== 'PAYMENT_ERROR'){
        setOpenRestrictedMenuMessage(true);
      }
    }
  }

  const pathMatches = () => matchPath(pathname, { path: route });

  return (
    <>
      <Tooltip label={props?.accountData?.accountPaymentStatus === 'PAYMENT_ERROR' ? 'Der Zugriff auf diese Funktion ist derzeit nicht möglich.' : props.hasTested ? 'Wählen Sie ein Abonnement, um auf diese Funktion zuzugreifen.' : 'Bitte starten Sie den Testzeitraum, um auf diese Funktion zuzugreifen.'} disabled={props.blockUsersFromUsingApp === false}>
        <Group 
          sx={{
            cursor: props.blockUsersFromUsingApp ? 'not-allowed' : 'pointer',
            position: 'relative',
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 20
          }}
          onClick={handleNavigate} 
          onMouseOver={() => props.blockUsersFromUsingApp === false && setHovered(true)}
          onMouseOut={() => setHovered(false)}
          {...props}
        >
          {pathMatches() && (
            <div 
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: -3,
                width: 6,
                borderRadius: 3,
                backgroundColor: 'rgba(86, 85, 255, 1.00)'
              }}
            />
          )}
          {icon && <Icon name={icon} color={pathMatches() || hovered ? color ? color : 'rgba(86, 85, 255, 1.00)' : 'rgba(154, 154, 181, 1.00)'} />}
          <Text className={route === '/documents' ? 'main-menu-documents-text tooltips' : route === '/messaging' ? 'main-menu-messaging-text tooltips' : children === 'Hilfe' ? 'main-menu-help-text tooltips' : children === 'Konto' && 'main-menu-account-text tooltips'} sx={{ color: pathMatches() || hovered ? color ? color : 'rgba(86, 85, 255, 1.00)' : 'rgba(154, 154, 181, 1.00)' }}>{children}</Text>
        </Group>
      </Tooltip>
      {openRestrictedMenuMessage &&
      <RestrictedMenuMessageModal
      open={openRestrictedMenuMessage}
      toggle={()=> setOpenRestrictedMenuMessage(false)}
      history={props.history}
      //next={()=> }
      />}
    </>
  )
}

// Main app menu
const MainMenu = (props) => {
  return (
    <Stack spacing={6}>
      <MainMenuItem icon="dashboard" hasTested={props.hasTested} accountData={props.accountData} history={props.history} route="/dashboard" blockUsersFromUsingApp={false}>Übersicht</MainMenuItem>
      <MainMenuItem icon="home" hasTested={props.hasTested} accountData={props.accountData} history={props.history} route="/property" blockUsersFromUsingApp={props.blockUsersFromUsingApp}>Immobilie</MainMenuItem>
      <MainMenuItem icon="users" hasTested={props.hasTested} accountData={props.accountData} history={props.history} route="/user" blockUsersFromUsingApp={props.blockUsersFromUsingApp}>Mieter</MainMenuItem>
      <MainMenuItem icon="receipt" hasTested={props.hasTested} accountData={props.accountData} history={props.history} route="/accounting" blockUsersFromUsingApp={props.blockUsersFromUsingApp}>Buchhaltung</MainMenuItem>
      <MainMenuItem icon="meter" hasTested={props.hasTested} accountData={props.accountData} history={props.history} route="/meter" blockUsersFromUsingApp={props.blockUsersFromUsingApp}>Ablesungen</MainMenuItem>
      <MainMenuItem icon="tap" hasTested={props.hasTested} accountData={props.accountData} history={props.history} route="/utility-billing" blockUsersFromUsingApp={props.blockUsersFromUsingApp}>Betriebskosten</MainMenuItem>
      <MainMenuItem icon="messenger" hasTested={props.hasTested} accountData={props.accountData} history={props.history} route="/messaging" blockUsersFromUsingApp={props.blockUsersFromUsingApp}>Nachrichten</MainMenuItem>
      <MainMenuItem icon="documents" hasTested={props.hasTested} accountData={props.accountData} history={props.history} route="/documents" blockUsersFromUsingApp={props.blockUsersFromUsingApp}>Dokumente</MainMenuItem>
      <MainMenuItem icon="tenantPortal" hasTested={props.hasTested} accountData={props.accountData} history={props.history} route="/tenantPortal" blockUsersFromUsingApp={props.blockUsersFromUsingApp}>Mieterportal</MainMenuItem>
    </Stack>
  )
}

export default MainMenu
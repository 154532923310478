/*
    created on 3/2/2021
    added by Shahood Hussain
    desc App route name constants
*/
export const CHANGE_PROPERTY = 'CHANGE_PROPERTY';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const RESET_DATA = 'RESET_DATA';
export const SET_PROPERTY_LIST = 'SET_PROPERTY_LIST';
export const SET_FUEL_TYPES_LIST = 'SET_FUEL_TYPES_LIST';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_PROPERTY_INFO = 'UPDATE_PROPERTY_INFO';
export const SET_UPDATE_PROPERTY_DATA = 'SET_UPDATE_PROPERTY_DATA';
export const ADD_NEW_PROPERTY_DATA = 'ADD_NEW_PROPERTY_DATA';
export const SET_DELETE_PROPERTY_DATA = 'SET_DELETE_PROPERTY_DATA';
export const PROFILE_PICTURE = 'PROFILE_PICTURE';
export const NEW_PICTURE_PROPERTY_DATA = 'NEW_PICTURE_PROPERTY_DATA';
export const STEPS_COUNTER_SIGNUP = 'STEPS_COUNTER_SIGNUP';
export const VERIFICATION_STATUS = 'VERIFICATION_STATUS';
export const USER_INFO_SIGNUP = 'USER_INFO_SIGNUP';
export const CLEAR_USER_INFO_SIGNUP = 'CLEAR_USER_INFO_SIGNUP';
export const UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE';
export const ACCOUNT_INFO_APIDATA = 'ACCOUNT_INFO_APIDATA';
export const CUSTOM_MODAL_STATE='CUSTOM_MODAL_STATE';
export const METER_READINGS_TO_PRINT='METER_READINGS_TO_PRINT';
export const SET_SUBSCRIPTION_DATA='SET_SUBSCRIPTION_DATA';
export const SET_CURRENT_SUBSCRIPTION_DATA='SET_CURRENT_SUBSCRIPTION_DATA';
export const SET_CURRENT_SUBSCRIPTION_WITH_PAYMENT_ERROR_DATA='SET_CURRENT_SUBSCRIPTION_WITH_PAYMENT_ERROR_DATA';
export const SET_SELECTED_SUBSCRIPTION_FOR_PAYMENT_CONFIRMATION='SET_SELECTED_SUBSCRIPTION_FOR_PAYMENT_CONFIRMATION';
export const UPDATE_SUBSCRIPTION_DATA='UPDATE_SUBSCRIPTION_DATA';
export const SET_COMPANY_LOGO='SET_COMPANY_LOGO';
export const UPDATE_COMPANY_LOGO='UPDATE_COMPANY_LOGO';
export const SET_ACCOUNT_DATA='SET_ACCOUNT_DATA';
export const ADD_FILE_MODAL='ADD_FILE_MODAL';
export const SET_CURRENT_FOLDER_ID='SET_CURRENT_FOLDER_ID';
export const SET_FILES='SET_FILES';
export const SET_REFRESH_DOCUMENTS_DATA='SET_REFRESH_DOCUMENTS_DATA';
export const ADD_TENANT_DOCUMENT='ADD_TENANT_DOCUMENT';
export const SET_TENANT_FILES='SET_TENANT_FILES';
export const SET_TENANT_CURRENT_FOLDER_ID='SET_TENANT_CURRENT_FOLDER_ID';
export const SET_REFRESH_TENANT_DOCUMENTS_DATA='SET_REFRESH_TENANT_DOCUMENTS_DATA';
export const ADD_RECEIPT_DOCUMENT='ADD_RECEIPT_DOCUMENT';
export const SET_RECEIPT_FILES='SET_RECEIPT_FILES';
export const SET_RECEIPT_CURRENT_ID='SET_RECEIPT_CURRENT_ID';
export const SET_REFRESH_RECEIPT_DOCUMENTS_DATA='SET_REFRESH_RECEIPT_DOCUMENTS_DATA';
export const SET_SHARED_FOLDER='SET_SHARED_FOLDER';
export const SET_FILE_TYPE='SET_FILE_TYPE';
export const SET_PUBLISH_MESSAGE_ID='SET_PUBLISH_MESSAGE_ID';
export const SET_REFRESH_MESSAGES_DATA='SET_REFRESH_MESSAGES_DATA';
export const SET_CURRENT_MESSAGE_DATA='SET_CURRENT_MESSAGE_DATA';
export const SET_RUN_UPDATE_TENANT_OCCUPATIONS='SET_RUN_UPDATE_TENANT_OCCUPATIONS';
export const SET_RUN_UPDATE_RECEIPT_ASSIGNMENT = 'SET_RUN_UPDATE_RECEIPT_ASSIGNMENT';
export const SET_RUN_UPDATE_CONSUMPTIONS = 'SET_RUN_UPDATE_CONSUMPTIONS';
export const SET_RUN_UPDATE_HEATING_COST_SPLIT = 'SET_RUN_UPDATE_HEATING_COST_SPLIT';
export const SET_SELECTED_TAX_YEAR_FOR_TAX_DECLARATION = 'SET_SELECTED_TAX_YEAR_FOR_TAX_DECLARATION';
export const SET_NEXT_SUBSCRIPTION_DATA='SET_NEXT_SUBSCRIPTION_DATA';
export const SET_SUBSCRIPTION_MAIN_LOADER='SET_SUBSCRIPTION_MAIN_LOADER';
export const SET_TEST_SUBSCRIPTION_COMPLETE='SET_TEST_SUBSCRIPTION_COMPLETE';
export const SET_OPEN_RECEIPT_DUPLICATE_PROGRESS_MODAL='SET_OPEN_RECEIPT_DUPLICATE_PROGRESS_MODAL';
export const SET_SELECTED_RECEIPT_FOR_DUPLICATION='SET_SELECTED_RECEIPT_FOR_DUPLICATION';
export const SET_SHOW_AFTER_SIGN_IN='SET_SHOW_AFTER_SIGN_IN';
export const SET_IS_TESTING='SET_IS_TESTING';